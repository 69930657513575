const dddList = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "21",
  "22",
  "24",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "71",
  "73",
  "74",
  "75",
  "77",
  "79",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
];

export function phoneValue(phone: string) {
  const regExp = /\s|\(|\)|\+|-/g;
  return phone.replace(regExp, "");
}

export function phoneObject(phone: string) {
  const value = phoneValue(phone);
  const Ddi = value.slice(0, 2);
  const Ddd = value.slice(2, 4);
  const Number = value.slice(4);
  return { Ddi, Ddd, Number };
}

export function phoneValidator(phone: string) {
  const phoneObj = phoneObject(phone);
  const DDD = phoneObj.Ddd;
  const NUMBER = phoneObj.Number;

  if (!DDD) {
    return null;
  }

  if (!dddList.includes(DDD)) {
    return "Informe um DDD válido.";
  }

  if (NUMBER.length <= 7) {
    return "Informe um número válido.";
  }

  return null;
}
