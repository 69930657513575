o-breadcrumb {
  .o-breadcrumb {
    font-family: inherit;
    display: none;

    @include breakpoint-up(large) {
      display: flex;
    }
  }
}
