import { Component, h, Host, Listen, Prop, Watch } from "@stencil/core";
import { IType, ISize } from "../../globals/types";

@Component({
  tag: "o-switch",
  styleUrl: "index.scss",
})
export class Switch {
  /**
   * Estado do switch.
   *
   * @sbCategory Style
   */
  @Prop({ mutable: true }) value = false;
  /**
   * Desabilita o componente.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Cor do swtich quando `value === true`.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  private inputElement?: HTMLInputElement;

  @Listen("input")
  handleInput() {
    this.value = this.inputElement?.checked ?? false;
  }

  /**
   * Alter `checked` property of native input when <o-checkbox> `checked` is set
   * externally
   */
  @Watch("value")
  handleSetChecked(newValue: boolean) {
    if (!this.inputElement) return;

    this.inputElement.checked = newValue;
  }

  render() {
    const { value, type, size, disabled } = this;

    const classes = {
      "o-switch": true,
      "o-switch__input": true,
      [`o-switch__input--size-${size}`]: true,
      [`o-switch__input--type-${type}`]: true,
    };

    return (
      <Host class={{ [`o-switch--size-${size}`]: true }}>
        <input
          ref={(el) => (this.inputElement = el)}
          type="checkbox"
          class={classes}
          checked={value}
          disabled={disabled}
        />
      </Host>
    );
  }
}
