import Joi from "joi";
import { cnpjVerifier } from "./cnpj.validator";
import { cpfVerifier } from "./cpf.validator";

/**
 * Checks if a string contains an array of numbers in the
 * following format:
 *
 * 11,2,3,24
 *
 * @param value The string with numbers separated by comma
 */
export const validateNumberArray = (value: string) => {
  if (!value) return null;

  const regex = /^(\d+,?)*\d+$/;

  const isValid = regex.test(value);

  if (!isValid) {
    return "Separe os números por vírgula (1,10,15)";
  }

  return null;
};

export function cnpjValidator(value: string) {
  if (!value) {
    return null;
  }

  const replacedValue = value.replace(/[/\-.]/g, "");
  const schema = Joi.object().keys({
    cnpj: Joi.string().regex(/^([0-9]{2}[0-9]{3}[0-9]{3}[0-9]{4}[0-9]{2})/),
  });
  const result = Joi.validate({ cnpj: replacedValue }, schema);

  if (result.error || !cnpjVerifier(replacedValue)) {
    return "CNPJ inválido";
  }

  return null;
}

export function cpfValidator(value: string) {
  if (!value) {
    return null;
  }

  const replacedValue = value.replace(/\.|-/g, "");

  const schema = Joi.object().keys({
    cpf: Joi.string().regex(/^([0-9]{3}[0-9]{3}[0-9]{3}[0-9]{2})/),
  });
  const result = Joi.validate({ cpf: replacedValue }, schema);

  if (result.error || !cpfVerifier(replacedValue)) {
    return "CPF inválido";
  }

  return null;
}

export function cpfCnpjValidator(taxId: string | undefined) {
  if (taxId?.length === 14) {
    return cnpjValidator(taxId);
  }
  if (taxId?.length === 11) {
    return cpfValidator(taxId);
  }
  return "";
}

export function emailValidator(value: string) {
  if (!value) {
    return null;
  }

  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = regEx.test(value.toLowerCase());

  if (!valid) {
    return "E-mail inválido";
  }

  return null;
}

export function badStringEmailValidator(value: string) {
  if (!value) {
    return null;
  }

  const regEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const valid = regEx.test(value.toLowerCase());

  if (!valid) {
    return "E-mail inválido";
  }

  return null;
}

export function cellphoneValidator(value: string) {
  if (!value) {
    return null;
  }

  const schema = Joi.object().keys({
    cellphone: Joi.string()
      .regex(/^(\([0-9]{2}\)[0-9]{5}-[0-9]{4})/)
      .required(),
  });
  const result = Joi.validate({ cellphone: value }, schema);

  if (result.error) {
    return "Telefone inválido";
  }

  return null;
}

export function rangeValidator(value: string, min: number, max: number) {
  if (!value && !min && !max) {
    return null;
  }

  const number = Number(value);
  if (number < min || number > max) {
    return `O valor deve estar entre ${min} e ${max}`;
  }

  return null;
}

export function passwordValidatorPortal(value: string) {
  if (!value) {
    return null;
  }

  const schema = Joi.object().keys({
    password: Joi.string().regex(/^(?=.*[0-9])(?=.*[a-z]).{8,}$/),
  });
  const result = Joi.validate({ password: value }, schema);

  if (result.error) {
    return "A senha deve conter no mínimo 8 caracteres, letras minúsculas e um número";
  }

  return null;
}

export function passwordValidatorAdmin(value: string) {
  if (!value) {
    return null;
  }

  const schema = Joi.object().keys({
    password: Joi.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
  });
  const result = Joi.validate({ password: value }, schema);

  if (result.error) {
    return "A senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas, números e símbolos";
  }

  return null;
}

export function passwordConfirmationValidator(value: string, compare: string) {
  if (value !== compare) {
    return "As senhas não coincidem.";
  }
  return null;
}

export function zipCodeValue(zipCode: string) {
  const regExp = /\s|\.|-/g;
  const zipValue = zipCode.replace(regExp, "");
  return zipValue;
}

export function zipCodeValidator(zipCode: string) {
  const zipValue = zipCodeValue(zipCode);
  if (zipValue && zipValue.length < 8) return "CEP inválido";

  return null;
}

export function timeValidator(time = "") {
  if (!time) {
    return null;
  }
  const [h, m] = time.split(":").map(Number);

  const isHourInvalid = isNaN(h) || h < 0 || +h > 24;
  const isMinutesInvalid = isNaN(m) || m < 0 || m > 60;

  if (isHourInvalid || isMinutesInvalid) {
    return "Horário inválido";
  }

  return null;
}
