o-step-header {
  &.o-step-header {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(var(--font-xxxl) * var(--base-height));

    &--disable-navigation {
      & .o-step,
      & button {
        cursor: default;
      }
    }
  }
}
