export const nextServerSideWindowHandler = () => {
  if (typeof window === "undefined") {
    // @ts-ignore
    global.window = {
      session: {
        roleFilter: (...args: any[]) => false,
        current: undefined,
        authorization: undefined,
      },
    };
  }
};

export function roleFilterBase(
  requiredRoles: string[] | undefined,
  roles: string[] | undefined,
) {
  if (roles) {
    if (requiredRoles) {
      return roles.some((role) => requiredRoles.includes(role) || role === "*");
    } else {
      return true;
    }
  } else {
    return false;
  }
}
