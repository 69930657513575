import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Listen,
  Prop,
} from "@stencil/core";
import type { OExpanderHeaderCustomEvent } from "../../components";
import { ICollapseInteractionDetail } from "./types";

@Component({
  tag: "o-expander",
})
export class Expander {
  @Element() self!: HTMLElement;

  /**
   * Declaring this just for `OExpander` type to have `onCollapseInteraction`
   * prop
   */
  @Event() collapseInteraction!: EventEmitter<ICollapseInteractionDetail>;

  /**
   * Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) expanded = false;

  @Listen("collapseInteraction")
  handleCollapseInteraction(
    event: OExpanderHeaderCustomEvent<ICollapseInteractionDetail>
  ) {
    this.expanded = event.detail.expanded;
  }

  componentWillRender() {
    [...this.self.children].forEach((child) => {
      if (
        !["O-EXPANDER-HEADER", "O-EXPANDER-CONTENT"].includes(child.tagName)
      ) {
        throw new Error(
          "The only valid children for <o-expander> are <o-expander-header> and <o-expander-content>"
        );
      }

      (child as HTMLOCollapseHeaderElement).expanded = this.expanded;
    });
  }

  render() {
    return <slot />;
  }
}
