$toastWidth: 21rem;
$initialPosition: -31.25rem;
$toastMaxLines: 5;
$toastMaxLinesMobile: 2;
$transitionFast: 0.3s;
$transitionSlow: 0.5s;
$positionsAndArrivals: (
  "top": "vertical",
  "right": "horizontal",
  "bottom": "vertical",
  "left": "horizontal",
);

o-toast {
  .o-toast {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: flex-start;
    gap: var(--font-sm);
    width: $toastWidth;
    height: auto;
    background-color: var(--theme-quaternary);
    padding: var(--font-xxl);
    margin: var(--font-xxs);
    border-radius: var(--border-radius-xxs);
    transition: var(--position-transition), var(--arrive-transition);
    pointer-events: auto;
    border: 1px solid var(--theme-dark-10);

    // Generate classes for transitions based on props
    // Example:
    // &.position-top {                                          // position of toasts
    //   top: 0;                                                 // value of position
    //   --position-transition: top ease-in-out $transitionFast; // the property that will be changed to move toasts is `top`
    //   &.arrive-vertical {                                     // where toasts come from
    //     top: $initialPosition;                                // initial position before arriving
    //     --arrive-transition: top ease-out $transitionSlow;    // transition for arriving animation
    //   }
    // }
    @each $position, $arrival in $positionsAndArrivals {
      // position of toasts
      &__position-#{$position} {
        #{$position}: 0; // value of position

        @if $position == top or $position == bottom {
          --position-transition: #{$position}
            ease-in-out
            #{$transitionFast}; // the property that will be changed to move toasts
        }

        // where toasts come from
        &.arrive-#{$arrival} {
          #{$position}: $initialPosition; // initial position before arriving
          --arrive-transition: #{$position}
            ease-out
            #{$transitionSlow}; // transition for arriving animation
        }
      }
    }

    // Fade transition before toast is removed
    &.fade {
      opacity: 0;
      transition: opacity linear 0.5s;
    }

    // Close button
    .icon-close {
      padding: 0 var(--font-xs);
      all: unset;
      position: absolute;
      top: var(--font-xxl);
      right: var(--font-xxl);
      justify-self: end;

      &:hover {
        cursor: pointer;
      }

      &:active {
        filter: drop-shadow(0 0 0 var(--theme-dark));
      }
    }

    // Message container. It grows to take up all available horizontal space
    &__message {
      display: flex;
      flex-direction: column;
      gap: var(--font-sm);
      flex-grow: 1;
      margin-right: var(--font-md);
    }

    // Limits max vertical size of message
    // References:
    // * https://developer.mozilla.org/en-US/docs/Web/CSS/::part
    // * https://css-tricks.com/line-clampin/#aa-the-standardized-way
    o-typography {
      text-align: start;

      & > * {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $toastMaxLinesMobile;
        overflow: hidden;

        @include breakpoint-up(large) {
          -webkit-line-clamp: $toastMaxLines;
        }
      }
    }

    o-icon {
      color: var(--theme-light);
    }
  }
}
