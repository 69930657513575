import { Component, h, Prop } from "@stencil/core";
import { TToastArrivalDirection, TToastPositions, TToastTypes } from "./types";

const icons = {
  success: { name: "orq-status-success", type: "success" },
  danger: { name: "orq-status-disapproved", type: "danger" },
  warning: { name: "orq-status-warning", type: "warning" },
  info: { name: "orq-info", type: "light" },
} as const;

@Component({
  tag: "o-toast",
  styleUrl: "index.scss",
})
export class Toast {
  /**
   * Cor e qual ícone.
   *
   * @sbCategory Style
   */
  @Prop() type: TToastTypes = "info";
  /**
   * Controla se será mostrado um ícone.
   *
   * @sbCategory Style
   */
  @Prop() hasIcon = true;
  /**
   * Controla se será mostrado um botão de fechar.
   *
   * @sbCategory Style
   */
  @Prop() hasCloseButton = true;
  /**
   * Título da mensagem.
   *
   * @sbCategory Input
   */
  @Prop() messageTitle?: string;
  /**
   * Corpo da mensagem.
   *
   * @sbCategory Input
   */
  @Prop() messageBody?: string;
  /**
   * Qual canto da tela onde serão posicionados os toasts.
   *
   * @sbCategory Style
   */
  @Prop() position: TToastPositions = "top-right";
  /**
   * De qual direção os toasts aparecem na tela. Pode ser de cima/baixo ou
   * esquera/direita, dependento do valor de `position`.
   *
   * @sbCategory Style
   */
  @Prop() arrivalDirection: TToastArrivalDirection = "horizontal";
  /**
   * Callback executado quando o botão de fechar é clicado.
   *
   * @sbCategory Callback
   */
  @Prop() closeButtonCallback: () => void = () => {};

  /** Which icon will be rendered */
  private icon = icons[this.type];

  render() {
    const {
      position,
      arrivalDirection,
      type,
      hasIcon,
      icon,
      messageTitle,
      messageBody,
      hasCloseButton,
    } = this;

    const classes = {
      "o-toast": true,
      "o-toast__position-top": /top/g.test(position),
      "o-toast__position-right": /right/g.test(position),
      "o-toast__position-bottom": /bottom/g.test(position),
      "o-toast__position-left": /left/g.test(position),
      [`o-toast--type-${type}`]: true,
      [`arrive-${arrivalDirection}`]: true,
    };

    return (
      <div
        role="alertdialog"
        class={classes}
        aria-label={messageTitle}
        aria-description={messageBody}
      >
        {hasIcon && (
          <o-icon
            category="orq"
            icon={icon.name}
            type={icon.type}
            size="lg"
          ></o-icon>
        )}

        <div class="o-toast__message">
          {!!messageTitle && (
            <o-typography tag="h4" type="light" size="lg" title={messageTitle}>
              {messageTitle}
            </o-typography>
          )}

          {!!messageBody && (
            <o-typography tag="p" type="light" size="md" title={messageBody}>
              {messageBody}
            </o-typography>
          )}
        </div>

        {hasCloseButton && (
          <button
            class="icon-close"
            aria-label="fechar"
            onClick={() => {
              this.closeButtonCallback();
            }}
          >
            <o-icon
              category="far"
              icon="fa-times"
              type="light"
              size="lg"
            ></o-icon>
          </button>
        )}
      </div>
    );
  }
}
