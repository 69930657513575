import Glide from "@glidejs/glide";
import { Component, Element, h, Prop, State, Watch } from "@stencil/core";
import { visibilityController } from "./utils";

export interface IPeek {
  before: number;
  after: number;
}

@Component({
  tag: "o-carousel",
  styleUrl: "index.scss",
})
export class Carousel {
  /** Reference to the webcomponent DOM element */
  @Element() self!: HTMLElement;
  /** References to children nodes of self */
  @State() children: Element[] = [];

  /**
   * Controla se as setas devem aparecer.
   *
   * @sbCategory Style
   */
  @Prop() arrows = false;
  /**
   * Controla se os _dots_ devem aparecer.
   *
   * @sbCategory Style
   */
  @Prop() dots = false;
  /** GlideJS props */
  /**
   * Tipo do movimento. Opções:
   *
   * - `carousel`: faz com que o slider passe continuamente do último para o
   *   primeiro slide e vice-versa.
   * - `slider`: o carrossel para no primeiro/último slide ou dá a volta para o
   *   extremo oposto, de acordo com a prop `rewind`
   *
   * [Mais informações](https://glidejs.com/docs/options/#type).
   *
   * @sbCategory Style
   */
  @Prop() carouselType: "slider" | "carousel" = "slider";
  /**
   * O número de slides visível no carrossel.
   *
   * [Mais informações](https://glidejs.com/docs/options/#perview).
   *
   * @sbCategory Style
   */
  @Prop() slidesPerView = 1;
  /**
   * Índice do slide focado inicialmente (começa no 0).
   *
   * [Mais informações](https://glidejs.com/docs/options/#startat).
   *
   * @sbCategory Style
   */
  @Prop() startAt: number = 0;
  /**
   * Foca o slide ativo em uma posição específica do carrossel. Opções:
   *
   * - `"center"`: slide ativo será posicionado no centro do carrossel.
   * - `1,2,3...`: slide ativo será posicionado de acordo com o índice.
   *
   * [Mais informações](https://glidejs.com/docs/options/#focusat).
   *
   * @sbCategory Style
   */
  @Prop() focusAt: number | "center" = "center";
  /**
   * Espaçamento entre os slides.
   *
   * [Mais informações](https://glidejs.com/docs/options/#gap).
   *
   * @sbCategory Style
   */
  @Prop() gap = 1;
  /**
   * > **Aplica-se apenas quando `carouselType === slider`.**
   *
   * Faz com que o carrossel "rebobine" para o primeiro/útimo slide após chegar
   * ao fim.
   *
   * [Mais informações](https://glidejs.com/docs/options/#rewind).
   *
   * @sbCategory Style
   */
  @Prop() rewind: boolean = true;
  /**
   * O tanto que os slides fora da tela aparecem.
   *
   * [Mais informações](https://glidejs.com/docs/options/#peek).
   *
   * @sbCategory Style
   * @sbControl number
   */
  @Prop() peek: number | IPeek = 0;
  /**
   * Controla o intervalo em ms entre a mudança automática de slides. Para
   * desligar, definir como `false`.
   *
   * [Mais informações](https://glidejs.com/docs/options/#peek).
   *
   * @sbCategory Style
   * @sbControl text
   */
  @Prop() autoplay: number | false = false;
  /**
   * Pausa o _autoplay_ quando o usuário posiciona o mouse em cima do carrossel.
   *
   * [Mais informações](https://glidejs.com/docs/options/#hoverpause).
   *
   * @sbCategory Style
   */
  @Prop() hoverpause = true;

  private glideElement?: HTMLDivElement;
  private glideIntance?: Glide.Properties;

  /** Store references to children */
  componentWillLoad() {
    this.children = Array.from(this.self.children);
  }

  /** GlideJS needs to be mounted */
  componentDidLoad() {
    const glideOptions = {
      type: this.carouselType,
      perView: this.slidesPerView,
      startAt: this.startAt,
      focusAt: this.focusAt,
      gap: this.gap,
      rewind: this.rewind,
      peek: this.peek,
      autoplay: this.autoplay,
      hoverpause: this.hoverpause,
    };

    /**
     * Glide's documentation says that the first parameter must be a string (CSS
     * selector), but it's only working with a reference to the HTMLElement, so
     * we cast as any to get around that.
     */
    this.glideIntance = new Glide(
      this.glideElement as any,
      glideOptions
    ).mount();
  }

  /** Allow controlling currently active slide via JS prop */
  @Watch("startAt")
  startAtWatcher(newStartAt: number) {
    this.glideIntance?.update({ startAt: newStartAt });
  }

  /** Render slots inside `li.glide__slide` to make it transparent to user */
  private renderSlides() {
    return this.children.map((child, index) => {
      child.slot = `slide-${index}`;
      return (
        <li key={index} class="glide__slide">
          <slot name={`slide-${index}`} />
        </li>
      );
    });
  }

  /** Render dots based on the number of slides */
  private renderDotsAndArrows() {
    const dots = this.children.map((_, index) => (
      <button key={index} class="glide__bullet" data-glide-dir={`=${index}`} />
    ));

    return (
      (this.arrows || this.dots) && (
        <div class="glide__bullets" data-glide-el="controls">
          <button
            style={visibilityController(this.arrows)}
            class="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          >
            <o-icon category="orq" icon="orq-chevron-left" size="lg" />
          </button>

          {this.dots && (
            <div
              class="d-flex align-items-center"
              data-glide-el="controls[nav]"
            >
              {dots}
            </div>
          )}

          <button
            style={visibilityController(this.arrows)}
            class="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          >
            <o-icon category="orq" icon="orq-chevron-right" size="lg" />
          </button>
        </div>
      )
    );
  }

  render() {
    return (
      <div ref={(el) => (this.glideElement = el)} class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">{this.renderSlides()}</ul>
        </div>
        {this.renderDotsAndArrows()}
      </div>
    );
  }
}
