import { Component, Element, h, Host, Prop, Watch } from "@stencil/core";
import { ITypeComplete } from "../../../globals/types";
import { handleItemsActiveProp, handleMenuItemsClick } from "./utils";

@Component({
  tag: "o-menu",
  styleUrl: "index.scss",
})
export class Menu {
  @Element() self!: HTMLElement;

  /**
   * Menu ativo.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeItem?: string;
  /**
   * Cor de fundo do Menu.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "neutral-light";
  /**
   * Largura do menu. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width = "16rem";
  /**
   * Callback chamado quando um menu é selecionado.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (id: string) => void;

  private menuItems?: HTMLOMenuItemElement[];

  private setWidth() {
    this.self.style.width = this.width;
    this.self.style.minWidth = this.width;
    this.self.style.maxWidth = this.width;
  }

  @Watch("activeItem")
  handleActiveItem(id: string) {
    if (this.handleSelected) this.handleSelected(id);
    handleItemsActiveProp(id, this.menuItems);
  }

  // listen children click events and update activeItem property
  componentDidLoad() {
    const children = [...this.self.children] as HTMLOMenuItemElement[];
    this.menuItems = children.filter(
      (child) => child.localName === "o-menu-item"
    );

    if (this.activeItem) this.handleActiveItem(this.activeItem);

    this.menuItems?.forEach((el) => {
      el.addEventListener("click", (event) => {
        handleMenuItemsClick(event, el);

        this.activeItem = el.id;
      });
    });

    this.setWidth();
  }

  render() {
    const { type } = this;

    const classes = {
      "o-menu": true,
      [`o-menu--type-${type}`]: true,
    };

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
