import { Component, Element, h, Host, Prop, Watch } from "@stencil/core";
import { ITypeComplete } from "../../../globals/types";
import {
  handleItemsActiveProp,
  handleMenuItemsClick,
} from "../container/utils";

@Component({
  tag: "o-menu-subitem",
  styleUrl: "index.scss",
})
export class MenuSubitem {
  @Element() self!: HTMLOMenuSubitemElement;

  /**
   * Menu ativo.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeItem?: string;
  /**
   * Título do Menu anterior.
   *
   * @sbCategory Input
   */
  @Prop() previous!: string;
  /**
   * Título da lista de subitens.
   *
   * @sbCategory Input
   */
  @Prop() title!: string;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) active = false;
  /**
   * Cor de fundo do Menu.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "neutral-light";
  /**
   * Largura do menu. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width = "16rem";
  /**
   * Callback chamado quando um menu é selecionado.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (id: string) => void;
  /**
   * Callback chamado quando ocorre um click no componente o-menu-previous.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handlePreviousClick?: (event: MouseEvent) => void;

  private menuItems?: HTMLOMenuItemElement[];

  private setWidth() {
    this.self.style.width = this.width;
    this.self.style.minWidth = this.width;
    this.self.style.maxWidth = this.width;
  }

  private handleClick(event: MouseEvent) {
    this.self.style.opacity = "0";

    setTimeout(() => {
      this.active = false;
      this.self.style.opacity = "unset";
    }, 200);

    if(this.handlePreviousClick) this.handlePreviousClick(event);
  }

  @Watch("activeItem")
  handleActiveItem(id: string) {
    if (this.handleSelected) this.handleSelected(id);
    handleItemsActiveProp(id, this.menuItems);
  }

  // listen children click events and update activeItem property
  componentDidLoad() {
    const list = this.self.querySelector(".o-menu-list__items")?.children ?? [];
    const children = [...list] as HTMLOMenuItemElement[];
    this.menuItems = children.filter(
      (child) => child.localName === "o-menu-item"
    );

    if (this.activeItem) this.handleActiveItem(this.activeItem);

    this.menuItems?.forEach((el) => {
      el.addEventListener("click", (event) => {
        handleMenuItemsClick(event, el);

        this.activeItem = el.id;
      });
    });

    this.setWidth();
  }

  render() {
    const { type, previous, title, active, activeItem } = this;

    const classes = {
      "o-menu-subitem": true,
      [`o-menu-subitem--type-${type}`]: true,
      [`o-menu-subitem--active`]: active,
    };

    return (
      <Host class={classes}>
        <o-menu-previous
          dataAction={`menu:botao:voltar-${previous}`}
          dataLabel={previous}
          handleClick={(e) => this.handleClick(e)}
        >
          {previous}
        </o-menu-previous>
        <o-divider
          class="mt-2 mb-4"
          size="xxs"
          type="light"
          position="horizontal"
        />
        <o-menu-list title={title} hasActiveItem={!!activeItem}>
          <slot />
        </o-menu-list>
      </Host>
    );
  }
}
