import { Component, Element, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-unordered-list",
  styleUrl: "index.scss",
})
export class UnorderedList {
  @Element() self!: HTMLElement;

  /**
   * Estilo dos marcadores da lista.
   *
   * @sbCategory Style
   */
  @Prop() defaultType: "disc" | "square" | "circle" = "disc";

  componentWillRender() {
    this.self.querySelectorAll("o-list-item").forEach((el) => {
      el["defaultType"] = this.defaultType;
    });
  }

  render() {
    return (
      <Host class="o-unordered-list">
        <ul class="slot-container">
          <slot />
        </ul>
      </Host>
    );
  }
}
