o-filter-footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: var(--theme-light);

  .o-filter-footer {
    box-sizing: border-box;
    width: 100%;

    .slot {
      padding: var(--font-md);
    }
  }
}