export enum BorderoStatus {
  WaitingContractSignature = "WaitingContractSignature",
  Approved = "Approved",
  Pending = "Pending",
}

export const BorderoStatusLibrary: { [key in BorderoStatus]: string } = {
  WaitingContractSignature: "Aguardando Assinatura",
  Approved: "Aprovado",
  Pending: "Pendente",
};

export interface IBorderoSummaryDto {
  id: number;
  createdAt: string;
  totalReceivables: number;
  totalAmount: number;
  status: BorderoStatus;
}
