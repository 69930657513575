import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { menuItemAtom } from "./entire-menu";
import createDynamicMenu, { getActiveMenu } from "./menu-helper";
import { ILeafItem, IMenuItem, TMenu } from "./menu.types";

declare const $: any;

function empty(value: any) {
  if (!value) return true;
  if (typeof value === "string" && value.trim().length === 0) return true;
  if (typeof value === "number" && value === 0) return true;
  return false;
}

interface ILinkItemProps extends ILeafItem {
  currentIndex: number;
}

const LinkItem = (props: ILinkItemProps) => {
  const menuItem = useRecoilValue(menuItemAtom(props.title));
  return (
    <Link
      to={props.url}
      data-filter-tags="font icons fontawesome light"
      className="waves-effect waves-themed"
      data-e2e={menuItem["data-e2e"]}
    >
      {props.currentIndex < 2 && props.icon && <i className={props.icon}></i>}
      <span
        className="nav-link-text"
        data-i18n="nav.font_icons_fontawesome_light"
      >
        {props.title}
      </span>
      {!empty(menuItem.badge) && (
        <span className="dl-ref bg-primary-500 hidden-nav-function-minify hidden-nav-function-top">
          {menuItem.badge}
        </span>
      )}
      {props.babel && (
        <span className="dl-ref label bg-primary-500 hidden-nav-function-minify hidden-nav-function-top  ml-2">
          {props.babel}
        </span>
      )}
    </Link>
  );
};

interface IDropdownLinkProps extends IMenuItem {
  currentIndex: number;
}

const DropdownLink = (props: IDropdownLinkProps) => {
  return (
    <a
      href="/"
      onClick={(e) => e.preventDefault()}
      title={props.title}
      data-filter-tags="font icons fontawesome light"
      className="waves-effect waves-themed"
    >
      {props.currentIndex < 2 && props.icon && <i className={props.icon}></i>}
      <span
        className="nav-link-text"
        data-i18n="nav.font_icons_fontawesome_light"
      >
        {props.title}
      </span>
      {props.babel && (
        <span className="dl-ref label bg-primary-500 hidden-nav-function-minify hidden-nav-function-top  ml-2">
          {props.babel}
        </span>
      )}
    </a>
  );
};

const createMenu = (
  currentIndex: number,
  parent: IMenuItem | null,
  menu: TMenu,
): JSX.Element => {
  return (
    <>
      {menu.map((menuItem) => {
        return (() => {
          if ("items" in menuItem) {
            if (!parent) {
              const classes = menuItem.active
                ? "nav-title active"
                : "nav-title";
              const data = { "data-active": menuItem.active };
              return (
                <>
                  {menuItem.title && (
                    <li key={menuItem.title} className={classes} {...data}>
                      {menuItem.title}
                    </li>
                  )}
                  {createMenu(currentIndex + 1, menuItem, menuItem.items)}
                </>
              );
            } else {
              const data = { "data-active": menuItem.active };
              return (
                <>
                  <li
                    key={menuItem.title}
                    className={menuItem.active ? "active open" : ""}
                    {...data}
                  >
                    <DropdownLink {...menuItem} currentIndex={currentIndex} />
                    <ul>
                      {createMenu(currentIndex + 1, menuItem, menuItem.items)}
                    </ul>
                  </li>
                </>
              );
            }
          } else {
            const data = { "data-active": menuItem.active };

            return (
              <li
                key={menuItem.url}
                className={menuItem.active ? "active" : ""}
                {...data}
              >
                <LinkItem {...menuItem} currentIndex={currentIndex} />
              </li>
            );
          }
        })();
      })}
    </>
  );
};

export default function Menu() {
  const location = useLocation();
  const [menu, setMenu] = useState(createDynamicMenu());

  const updateActiveMenu = useCallback(() => {
    setMenu((prevMenu) => getActiveMenu(prevMenu));

    // close menu mobile when route change
    const menuOpen = $("body.mobile-view-activated.mobile-nav-on");

    if (menuOpen) {
      $("#overlay-menu-mobile").click();
    }
  }, []);

  useEffect(() => {
    updateActiveMenu();
  }, [location, updateActiveMenu]);

  useLayoutEffect(() => {
    setTimeout(() => {
      $("#js-nav-menu").navigationDestroy();
      $("#js-nav-menu").navigation({
        accordion: "true",
        speed: 400,
        closedSign: '<em class="fal fa-angle-down"></em>',
        openedSign: '<em class="fal fa-angle-up"></em>',
      });

      $("[data-active=true]").each(function(_: unknown) {
        // @ts-ignore
        $(this).addClass("active");
      });
    }, 500);
  }, []);

  return (
    <ul id="js-nav-menu" className="nav-menu js-nav-built">
      {menu && createMenu(0, null, menu)}
    </ul>
  );
}
