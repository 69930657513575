import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-dropdown-action",
  styleUrl: "index.scss",
})
export class DropdownAction {
  /**
   * Visibilidade do componente.
   *
   * @sbCategory Style
   */
  @Prop() show = false;
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = true;

  render() {
    return (
      <o-dropdown
        class="o-dropdown-action"
        position-y="bottom"
        position-x="left"
        show={this.show}
        floating={this.floating}
      >
        <o-button
          dataLabel="dropdown"
          dataAction="dropdown"
          outline
          bordered={false}
        >
          <o-icon
            category="fal"
            icon="fa-ellipsis-h"
            size="xxl"
            type="dark"
          ></o-icon>
        </o-button>
        <o-dropdown-list class="o-dropdown-action__list" type="dark">
          <div class="d-flex">
            <slot />
          </div>
          <o-dropdown-item class="o-dropdown-action__close-item">
            <o-icon
              category="fal"
              icon="fa-times"
              size="sm"
              type="dark"
            ></o-icon>
          </o-dropdown-item>
        </o-dropdown-list>
      </o-dropdown>
    );
  }
}
