import { Component, h, Host, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-upload-tip",
  styleUrl: "index.scss",
})
export class UploadTip {
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  render() {
    const { size } = this;

    return (
      <Host>
        <o-icon category="far" icon="fa-info-circle" size={size} type="dark" />

        <o-typography tag="p" type="dark-80" size={size}>
          <slot />
        </o-typography>
      </Host>
    );
  }
}
