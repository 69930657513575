import { Component, h, Host, Prop } from "@stencil/core";
import { IAspect, IAutocomplete, ISize } from "../../globals/types";

@Component({
  tag: "o-input",
  styleUrl: "index.scss",
})
export class Input {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop() value?: string | number;
  /**
   * Propriedade `type` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() inputType!: string;
  /**
   * Propriedade `max` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() max?: number;
  /**
   * Propriedade `min` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() min?: number;
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Callback de referência do `input` nativo.
   *
   * @sbCategory Ref
   */
  @Prop() nativeRef?: (el?: HTMLInputElement) => void;

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      inputType,
      max,
      min,
      maxlength,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    const hostClass = {
      [`size-${size}`]: true,
      [`o-input--${aspect}`]: true,
    };

    const inputClass = {
      "o-input": true,
      [`o-input--${aspect}`]: true,
      "error": error,
    };

    return (
      <Host class={hostClass}>
        <slot name="before" />
        <slot name="after" />

        <input
          ref={this.nativeRef}
          data-action={dataAction}
          data-label={dataLabel}
          id={`o-${id}`}
          name={name}
          value={value}
          max={max}
          min={min}
          maxlength={maxlength}
          placeholder={placeholder}
          type={inputType}
          autocomplete={autocomplete}
          disabled={disabled}
          readonly={readonly}
          class={inputClass}
        />
      </Host>
    );
  }
}
