import type { IField, IPosition } from "@maestro/core";
import type { FieldError } from "react-hook-form";

type FieldOmitted = "error" | "htmlFor" | "message" | "required";

type PropsPicked =
  | "description"
  | "label"
  | "labelSize"
  | "labelType"
  | "required"
  | "disabled";

interface TooltipProps {
  tooltip?: React.ReactNode;
  tooltipPosition?: IPosition;
  tooltipMaxWidth?: string;
}

export interface FieldProps extends Omit<IField, FieldOmitted>, TooltipProps {
  id: string;
  name: string;
  required?: boolean;
}

interface FieldOptions extends Pick<FieldProps, PropsPicked> {
  htmlFor: string;
  error?: boolean;
  message?: string;
}

export const fieldOptionsBuilder = (
  error: Omit<FieldError, "type"> | undefined,
  props: FieldProps
) => {
  const { id, description, label, labelSize, labelType, required, disabled } =
    props;

  const options: FieldOptions = {
    htmlFor: id,
    error: !!error,
    message: error?.message,
    ...(description && { description }),
    ...(disabled && { disabled }),
    ...(label && { label }),
    ...(labelSize && { labelSize }),
    ...(labelType && { labelType }),
    ...(required && { required }),
  };

  return options;
};
