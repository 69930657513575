import { Component, h } from "@stencil/core";

@Component({
  tag: "o-filter-body",
  styleUrl: "index.scss",
})
export class FilterBody {
  render() {
    return (
      <div tabindex="0" class="o-filter-body slot-container">
        <slot />
      </div>
    );
  }
}
