import classNames from "classnames";
import moment from "moment";
import { RangePicker } from "rc-picker";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { RangeValue } from "rc-picker/lib/interface";
import ptBR from "rc-picker/lib/locale/pt_BR";
import React, { useEffect, useState } from "react";
import { OIcon } from "../../stencil-generated";
import { GlobalStyle } from "../input-date/styles";
import { DateRangeGlobalStyle } from "./styles";
import { IOInputDateRange } from "./types";
import { getPlaceholder } from "./utils";

export const OInputDateRange = ({
  aspect = "flushed",
  error = false,
  disabled = false,
  allowClear = true,
  placeholder,
  size = "sm",
  value: defaultValues,
  onChange,
  onBlur,
  onFocus,
  disabledDate,
  className = "",
  ...rest
}: IOInputDateRange) => {
  const [momentValue, setMomentValue] = useState<RangeValue<moment.Moment>>();
  const [value, setValue] = useState<string[]>();
  const internalRef = React.useRef<HTMLDivElement>(null);
  const { "data-filterparam": dataFilterparam, ...rest2 } =
    rest as Partial<IOInputDateRange> & { "data-filterparam"?: string };

  const format = "DD/MM/YYYY";

  useEffect(() => {
    setValue(
      defaultValues?.map((defaultValue) => {
        if (typeof defaultValue === "string") {
          return defaultValue;
        } else {
          return defaultValue.format(format);
        }
      })
    );
  }, [defaultValues]);

  useEffect(() => {
    if (!value || value.length < 2) {
      setMomentValue(undefined);
      return;
    }

    setMomentValue([moment(value[0], format), moment(value[1], format)]);
  }, [value]);

  useEffect(() => {
    if (dataFilterparam) {
      const dateInput = internalRef.current?.querySelectorAll("input");
      const [dataFilterparamStartDate, dataFilterparamEndDate] =
        dataFilterparam.split(",");
      dateInput?.[0].setAttribute("data-filterparam", dataFilterparamStartDate);
      dateInput?.[1].setAttribute(
        "data-filterparam",
        dataFilterparamEndDate ?? `${dataFilterparamStartDate}2`
      );
    }
  }, []);

  return (
    <div ref={internalRef}>
      <GlobalStyle />
      <DateRangeGlobalStyle />
      <RangePicker
        {...rest2}
        picker="date"
        generateConfig={momentGenerateConfig}
        locale={ptBR}
        placeholder={getPlaceholder(placeholder)}
        disabled={disabled}
        allowClear={allowClear}
        format="DD/MM/YYYY"
        value={momentValue}
        suffixIcon={
          <OIcon
            className="calendar"
            category="fal"
            icon="fa-calendar"
            size={size}
          />
        }
        clearIcon={<OIcon category="fal" icon="fa-times" size={size} />}
        prevIcon={<OIcon category="fal" icon="fa-angle-left" />}
        nextIcon={<OIcon category="fal" icon="fa-angle-right" />}
        superPrevIcon={<OIcon category="fal" icon="fa-angle-double-left" />}
        superNextIcon={<OIcon category="fal" icon="fa-angle-double-right" />}
        prefixCls="o-input-date"
        className={classNames(
          {
            error: error,
            [`o-input-date-input--${aspect}`]: true,
            [`o-input-date-input--size-${size}`]: true,
          },
          className
        )}
        dropdownClassName={`o-input-date-dropdown--size-${size}`}
        onChange={(values, formatString) => {
          setValue(formatString);
          onChange?.(values, formatString);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        disabledDate={disabledDate}
      />
    </div>
  );
};

export default OInputDateRange;
