import { Component, h, Prop } from "@stencil/core";
import type { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-loader",
  styleUrl: "index.scss",
})
export class Loader {
  /**
   * Centraliza o loader usando `position: absolute;`.
   *
   * @sbCategory Style
   */
  @Prop() absolute = false;
  /**
   * Cria um backdrop por trás do loader.
   *
   * @sbCategory Style
   */
  @Prop() backdrop = false;
  /**
   * Cor do loader.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "primary";
  /**
   * Tamanho do loader.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  render() {
    const { absolute, backdrop, type, size } = this;

    const positionClasses = {
      "o-loader": true,
      "o-loader--absolute": absolute,
    };

    const loaderClasses = {
      "o-loader__spinner": true,
      [`o-loader__spinner--size-${size}`]: true,
      [`o-loader__spinner--type-${type}`]: true,
      "o-loader__spinner--absolute": absolute,
    };

    return (
      <div class={positionClasses}>
        <div class={{ "o-loader--backdrop": backdrop }} />
        <div class={loaderClasses} />
      </div>
    );
  }
}
