import {
  Component,
  Event,
  EventEmitter,
  h,
  Listen,
  Prop,
  Watch,
} from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-checkbox",
  styleUrl: "index.scss",
})
export class Checkbox {
  @Event() refresh!: EventEmitter<void>;

  /**
   * Propriedade `id` da checkbox.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` da checkbox.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Valor da checkbox.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Propriedade `checked` da checkbox. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) checked = false;
  /**
   * Propriedade `disabled` da checkbox.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor da checkbox.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";

  private inputElement?: HTMLInputElement;

  @Listen("input")
  handleInput() {
    this.checked = this.inputElement?.checked ?? false;
  }

  /**
   * Alter `checked` property of native input when <o-checkbox> `checked` is set
   * externally
   */
  @Watch("checked")
  handleSetChecked(newChecked: boolean) {
    if (this.inputElement) this.inputElement.checked = newChecked;
  }

  componentDidLoad() {
    // emit refresh event onDidLoad to tell <o-checkbox-group>
    // that this <o-checkbox> has finished rendering
    this.refresh.emit();
  }

  render() {
    const { id, name, value, checked, disabled, type, size } = this;

    const classes = {
      "o-checkbox__input": true,
      [`o-checkbox__input--size-${size}`]: true,
      [`o-checkbox__input--type-${type}`]: true,
    };

    return (
      <input
        ref={(el) => (this.inputElement = el)}
        type="checkbox"
        id={`o-${id}`}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        class={classes}
      />
    );
  }
}
