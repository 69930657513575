import styled from "styled-components";

export const StyledCard = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0 0 13px 0 rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid #e0e0e0;
`;

export const CardList = styled.div`
  ${StyledCard} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
