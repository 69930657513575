import React from "react";
import { StyledCard } from "./card.styles";

interface ICardProps {
  children: any;
  fullSized?: boolean;
}

export function Card(props: ICardProps) {
  return (
    <StyledCard
      style={
        props.fullSized ? { height: "100%", alignItems: "flex-start" } : {}
      }
    >
      {props.children}
    </StyledCard>
  );
}
