import { Component, h, Prop } from "@stencil/core";
import type { ISize, ITypeComplete, IWeight } from "../../globals/types";
import type { ITarget } from "./types";

@Component({
  tag: "o-link",
  styleUrl: "index.scss",
})
export class Link {
  /**
   * URL do link.
   *
   * @sbCategory Input
   */
  @Prop() href?: string;
  /**
   * Contexto do browser onde o link será aberto.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target).
   *
   * @sbCategory Input
   */
  @Prop() target: ITarget = "_self";
  /**
   * Cor do texto.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "primary";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Peso da fonte (negrito).
   *
   * @sbCategory Style
   */
  @Prop() weight: IWeight = "normal";

  render() {
    const { href, size, target, type, weight } = this;

    const classes = {
      "o-link": true,
      [`o-link--type-${type}`]: true,
      [`o-link--font-${size}`]: true,
      [`o-link--weight-${weight}`]: true,
      "slot-container": true,
    };

    return (
      <a class={classes} href={href} target={target}>
        <slot />
      </a>
    );
  }
}
