import { Component, Element, h, Prop } from "@stencil/core";
import { ITypeComplete } from "../../../globals/types";

@Component({
  tag: "o-menu-item",
  styleUrl: "index.scss",
})
export class MenuItem {
  @Element() self!: HTMLElement;

  /**
   * Propriedade `id` do `button`.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Desabilita o item do menu.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;
  /**
   * Cor de fundo do item.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "light";
  /**
   * Callback chamado quando ocorre click no item do menu.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: (event: MouseEvent) => void;

  private hasSubitem = false;

  componentWillRender() {
    this.hasSubitem = !!this.self.querySelector("o-menu-subitem");
  }

  render() {
    const {
      id,
      dataAction,
      dataLabel,
      disabled,
      active,
      hasSubitem,
      type,
      handleClick,
    } = this;

    const classes = {
      "o-menu-item": true,
      "o-menu-item__active": active,
      [`o-menu-item--type-${type}`]: true,
    };

    return (
      <button
        id={`o-${id}`}
        data-label={dataLabel}
        data-action={dataAction}
        disabled={disabled}
        class={classes}
        onClick={handleClick}
      >
        <slot />

        {hasSubitem && (
          <o-icon
            category="orq"
            icon="orq-chevron-right"
            size="md"
            type="dark"
          />
        )}
      </button>
    );
  }
}
