import React from "react";
import styled from "styled-components";

const StyledHr = styled.hr`
  border: 0;
  border-top: 1px solid #dedada;
`;

export function Separator() {
  return <StyledHr />;
}
