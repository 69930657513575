import { CalculateDesiredPosition } from "./types";

export interface TargetPosition {
  top: number;
  left: number;
}

export interface OriginalSize {
  height: number;
  width: number;
}

/**
 * Calculates correct position for floating element
 *
 * @param floatingElement Floating element
 * @param targetPosition Desired absolute position, top, left values in px
 * @param originalSize Size of document before element was inserted, to detect
 *   "overflow"
 * @param preventOverflow Whether overflow should be prevented
 */
export const calculateFloatingPosition = (
  anchorElement: HTMLElement,
  floatingElement: HTMLElement,
  targetPosition: TargetPosition,
  originalSize: OriginalSize,
  preventOverflow: boolean
) => {
  let top = "";
  let left = "";
  let right = "";
  let bottom = "";

  const anchorRect = anchorElement.getBoundingClientRect();
  const floatingRect = floatingElement.getBoundingClientRect();

  if (preventOverflow && targetPosition.top < 0) {
    top = "0px";
  } else {
    top = `${targetPosition.top}px`;
  }

  if (
    preventOverflow &&
    targetPosition.top + floatingRect.height > originalSize.height
  ) {
    top = `${targetPosition.top - floatingRect.height - anchorRect.height}px`;
  }

  if (preventOverflow && targetPosition.left < 0) {
    left = "0px";
  } else {
    left = `${targetPosition.left}px`;
  }

  if (
    preventOverflow &&
    targetPosition.left + floatingRect.width > originalSize.width
  ) {
    left = `${originalSize.width - floatingRect.width}px`;
  }

  return { top, left, bottom, right };
};

/** Handle possible position types */
const parsePosition = (position?: string | number) =>
  position === undefined
    ? ""
    : typeof position === "string"
    ? position
    : `${position}px`;

/**
 * Calculates final position of `floatingElement`
 *
 * @param anchorElement Reference to the anchor element (element which is always
 *   visible)
 * @param floatingElement Reference to the floating element (element which pops
 *   up)
 * @param calculateDesiredPosition Callback function to calculate the desired
 *   position of the floating element
 * @param floating Indicates whether the element is "floating" (appended to
 *   body)
 * @param heightWithout Page height before element was inserted
 * @param widthWithout Page width before element was inserted
 * @param preventOveflow Controls if overflow is to be prevented
 */
export const calculatePosition = (
  anchorElement: HTMLElement,
  floatingElement: HTMLElement,
  calculateDesiredPosition: CalculateDesiredPosition,
  floating: boolean,
  heightWithout: number,
  widthWithout: number,
  preventOveflow: boolean
) => {
  // initialize values
  let top = "";
  let left = "";
  let right = "";
  let bottom = "";

  // get desired position from callback
  const desiredPosition = calculateDesiredPosition(
    anchorElement,
    floatingElement
  );

  // destructuring
  const desiredTop = desiredPosition?.top;
  const desiredLeft = desiredPosition?.left;
  const desiredBottom = desiredPosition?.bottom;
  const desiredRight = desiredPosition?.right;
  const transform = desiredPosition?.transform;

  if (floating) {
    /** If `floating` is enabled, calculate position to prevent overflow */
    if (typeof desiredTop !== "number" || typeof desiredLeft !== "number")
      throw new Error(
        "If floating is set to true, calculateDesiredPosition must return numbers"
      );

    const floatingPosition = calculateFloatingPosition(
      anchorElement,
      floatingElement,
      {
        top: desiredTop,
        left: desiredLeft,
      },
      { height: heightWithout, width: widthWithout },
      preventOveflow
    );

    top = floatingPosition.top;
    left = floatingPosition.left;
    bottom = floatingPosition.bottom;
    right = floatingPosition.right;
  } else {
    /** If `floating` is disabled, just handle different types */
    top = parsePosition(desiredTop);
    left = parsePosition(desiredLeft);
    bottom = parsePosition(desiredBottom);
    right = parsePosition(desiredRight);
  }

  floatingElement.style.top = top;
  floatingElement.style.left = left;
  floatingElement.style.bottom = bottom;
  floatingElement.style.right = right;
  floatingElement.style.transform = transform ?? "";
};
