import { cpfValidator, cnpjValidator } from ".";

export function validateDocumentInput(value: string) {
  const replacedValue = value.replace(/\.|-/g, "");

  if (replacedValue?.length < 12) {
    return cpfValidator(value);
  } else if (replacedValue.length >= 12) {
    return cnpjValidator(value);
  }

  return null;
}
