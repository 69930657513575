import { Component, h, Host, Listen, Prop } from "@stencil/core";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-button",
  styleUrl: "index.scss",
})
export class Button {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Atributo `type` do botão.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type).
   *
   * @sbCategory Input
   */
  @Prop() typeButton: "button" | "submit" = "button";
  /**
   * Desabilita o botão.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Deixa sem background e com bordas e fonte da cor escolhida.
   *
   * @sbCategory Style
   */
  @Prop() outline = false;
  /**
   * Deixa as bordas arredondadas.
   *
   * @sbCategory Style
   */
  @Prop() rounded = false;
  /**
   * Habilita e desabilita a borda.
   *
   * @sbCategory Style
   */
  @Prop() bordered = true;
  /**
   * Faz o botão ocupar 100% da largura do container.
   *
   * @sbCategory Style
   */
  @Prop() fullWidth = false;
  /**
   * Faz o botão se tornar um círculo.
   *
   * @sbCategory Style
   */
  @Prop() circle = false;
  /**
   * Atributo `aria-label`.
   *
   * @sbCategory Accessibility
   */
  @Prop() ariaLabel: string | null = null;

  @Listen("click")
  handleClick(e: MouseEvent) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const {
      dataLabel,
      dataAction,
      ariaLabel,
      typeButton,
      type,
      size,
      disabled,
      outline,
      rounded,
      bordered,
      fullWidth,
      circle,
    } = this;

    const classes = {
      "full-width": fullWidth,
    };

    const classesButton = {
      "o-button": true,
      [`o-button--background-${type}`]: !outline,
      [`o-button--outline-${type}`]: outline,
      [`o-button--size-${size}`]: true,
      "o-button--rounded": rounded,
      "o-button--bordered": bordered,
      "o-button--circle": circle,
      "o-button--full-width": fullWidth,
      "slot-container": true,
    };

    return (
      <Host class={classes}>
        <button
          class={classesButton}
          type={typeButton}
          data-label={dataLabel}
          data-action={dataAction}
          aria-label={ariaLabel}
          disabled={disabled}
        >
          <slot />
        </button>
      </Host>
    );
  }
}
