import { Component, Element, h, Prop, Watch } from "@stencil/core";

@Component({
  tag: "o-tabs",
})
export class Tabs {
  @Element() self!: HTMLElement;

  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeTab = 0;
  /**
   * Callback chamado quando uma tab é selecionada.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (index: number) => void;

  @Watch("activeTab")
  handleChangeActiveTab(newValue: number) {
    const header = this.self.querySelector("o-tab-header");
    const body = this.self.querySelector("o-tab-body");

    if (header) header.activeTab = newValue;
    if (body) {
      body.activeTab = newValue;
      body.focus();
    }
  }

  componentDidLoad() {
    const header = this.self.querySelector("o-tab-header");

    this.handleChangeActiveTab(this.activeTab);

    header?.addEventListener("selected", (event) => {
      const target = event.target as HTMLOTabHeaderElement;

      this.activeTab = target.activeTab;
      if (this.handleSelected) this.handleSelected(target.activeTab);
    });
  }

  render() {
    return <slot />;
  }
}
