import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-menu-previous",
  styleUrl: "index.scss",
})
export class MenuPrevious {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Callback chamado quando ocorre click no botão.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: (event: MouseEvent) => void;

  render() {
    const { dataLabel, dataAction, handleClick } = this;

    const classes = {
      "o-menu-previous": true,
    };

    return (
      <button
        class={classes}
        data-label={dataLabel}
        data-action={dataAction}
        onClick={handleClick}
      >
        <o-icon category="fal" icon="fa-angle-left" type="info" />
        <slot />
      </button>
    );
  }
}
