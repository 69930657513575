import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Listen,
  Prop,
  Watch,
} from "@stencil/core";
import { IType } from "../../globals/types";

@Component({
  tag: "o-clickable-card",
  styleUrl: "index.scss",
})
export class ClickableCard {
  @Element() self!: HTMLElement;
  /** Evento do input nativo */
  @Event() input!: EventEmitter<string>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Valor atual do input. Funciona como identificador do o-radio.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Tipo do input.
   *
   * @sbCategory Style
   */
  @Prop() inputType: "radio" | "checkbox" = "checkbox";
  /**
   * Propriedade `checked` do radio button. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) checked = false;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Dimensão do card. Sintaxe conforme a propriedade width | height CSS.
   *
   * @sbCategory Style
   */
  @Prop() size = "unset";
  /**
   * Cor do radio.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Remove o _background_ e muda a cor do texto para a definida por `type`.
   *
   * @sbCategory Style
   */
  @Prop() outline = false;

  private inputElement?: HTMLInputElement;
  private labelElement?: HTMLDivElement;

  @Listen("input")
  handleInput() {
    this.checked = this.inputElement?.checked ?? false;
  }

  /**
   * Alter `checked` property of native input when <o-radio> `checked` is set
   * externally
   */
  @Watch("checked")
  handleSetChecked(newChecked: boolean) {
    if (this.inputElement) this.inputElement.checked = newChecked;

    this.input.emit();
  }

  componentDidLoad() {
    if (this.labelElement) {
      this.labelElement.style.width = this.size;
      this.labelElement.style.height = this.size;
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      value,
      inputType,
      type,
      disabled,
      name,
      checked,
      outline,
    } = this;

    const classes = {
      "o-clickable-card": true,
      [`o-clickable-card--type-${type}`]: !outline,
      [`o-clickable-card--outline-${type}`]: outline,
    };

    return (
      <div class={classes}>
        <input
          ref={(el) => (this.inputElement = el)}
          type={inputType}
          data-action={dataAction}
          data-label={dataLabel}
          id={`o-${id}`}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
        />

        <div
          class="o-clickable-card__label"
          ref={(el) => (this.labelElement = el)}
        >
          <div class="o-clickable-card__status" />
          <slot />
        </div>
      </div>
    );
  }
}
