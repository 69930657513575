o-option {
  .o-option {
    font-family: inherit;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    user-select: none;
    text-align: left;
    width: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: var(--theme-neutral-normal);
    cursor: pointer;
    padding: 0 var(--font-md);

    &:hover {
      background-color: var(--theme-dark-10);
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &--selected {
      background-color: var(--theme-tertiary);
      color: var(--theme-tertiary-inverse);

      &:hover {
        background-color: var(--theme-tertiary);
      }

      &::after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        display: block;
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        line-height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
