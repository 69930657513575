import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Method,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import { maskRegexType } from "./types";

@Component({
  tag: "o-pin-field",
})
export class PinField {
  @Element() self!: HTMLElement;
  /** Evento emitido ao validar o conteúdo dos input-pin. */
  @Event() valid!: EventEmitter<string>;
  @State() pinValues: string[] = [];

  /**
   * Determina quais tipos de caracteres são aceitos..
   *
   * @sbCategory Input
   */
  @Prop() pinType: "all" | "text" | "number" = "all";
  /**
   * Determina se o input iniciará com foco.
   *
   * @sbCategory Input
   */
  @Prop() autoFocus = true;
  /**
   * Determina se o foco mudará automaticamente para o próximo input ao
   * preencher o atual.
   *
   * @sbCategory Input
   */
  @Prop() autoTab = true;
  /**
   * Calllback chamado ao ocorre mudança nos valores dos input-pin.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleChange?: (value: string[]) => void;

  private inputList?: NodeListOf<HTMLOInputPinElement>;
  private pinInputList?: NodeListOf<HTMLOInputPinElement>;

  @Method()
  async clear() {
    this.pinInputList?.forEach((el) => (el.value = ""));
    this.inputList?.forEach((el) => (el.value = ""));

    this.initPinValues();
  }

  /** Set focus on input pin */
  @Method()
  async setFocus(position: number) {
    if (!this.inputList || !this.inputList[position]) return;

    this.inputList[position].focus();
  }

  // emit onValid event when all o-input-pin has value
  private isValid() {
    if (!this.pinValues.includes("")) {
      this.valid.emit(this.pinValues.join(""));
    }
  }

  // emit onValid event when all o-input-pin has value
  private initPinValues() {
    if (!this.inputList) return;

    const pinLength = this.inputList.length;
    this.pinValues = Array(pinLength).fill("");
  }

  private handleInput(event: Event, position: number) {
    const { value } = event.target as HTMLInputElement;
    const valid = maskRegexType[this.pinType].test(value);

    if (value && valid) {
      const nextPosition = position + 1;
      this.pinValues[position] = value;

      // next o-input-pin
      if (this.autoTab && this.pinValues.length !== nextPosition) {
        this.setFocus(nextPosition);
      }

      this.isValid();
    }
  }

  private handleDelete(position: number) {
    const previous = position - 1;
    const hasValue = this.pinValues[position];

    this.pinValues[position] = "";

    // previous o-input-pin
    if (this.autoTab && previous >= 0 && !hasValue) {
      this.pinValues[previous] = "";
      this.setFocus(previous);
    }
  }

  private handlePaste(event: ClipboardEvent, position: number) {
    event.preventDefault();

    const value = event.clipboardData?.getData("text");

    if (value) {
      const validArr = value
        .split("")
        .filter((val) => maskRegexType[this.pinType].test(val));

      const lastPosition = position + validArr.length;
      const pinLength = this.pinValues.length - 1;

      validArr.forEach((char, index) => {
        if (!this.inputList) return;

        const arrPosition = position + index;
        const pinField = this.inputList[arrPosition];

        if (pinField && arrPosition <= pinLength) {
          this.pinValues[arrPosition] = char;
          pinField.value = char;
        }
      });

      if (this.autoTab) {
        if (lastPosition <= pinLength) {
          // focus on next o-input-pin
          this.setFocus(lastPosition);
        } else {
          // focus on last o-input-pin
          this.setFocus(pinLength);
        }
      }

      this.isValid();
    }
  }

  @Watch("pinValues")
  handlePinValues() {
    this.handleChange && this.handleChange(this.pinValues);
  }

  /**
   * Set initial focus if autoFocus is true get all inputs from input-pin
   * components and add event listeners to them
   */
  componentDidLoad() {
    this.pinInputList = this.self.querySelectorAll("o-input-pin");
    this.inputList = this.self.querySelectorAll(".o-input-pin");

    this.initPinValues();

    if (this.autoFocus) this.setFocus(0);

    if (this.pinInputList) {
      this.pinInputList.forEach((el) => {
        el["pinType"] = this.pinType;
      });
    }

    if (this.inputList) {
      this.inputList.forEach((el, index) => {
        el.addEventListener("input", (event) => this.handleInput(event, index));
        el.addEventListener("paste", (event) => this.handlePaste(event, index));
        el.addEventListener("keydown", (event) => {
          if (event.key === "Backspace") this.handleDelete(index);
        });
      });
    }
  }

  render() {
    return <slot />;
  }
}
