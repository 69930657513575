o-floater {
  .o-floater__anchor-element {
    position: relative;
  }
}

.o-floater__floating-element {
  position: absolute;
  opacity: 0;
  visibility: hidden !important;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 99999;

  &.visible {
    opacity: 1;
    visibility: visible !important;
  }
}
