import { OButton, OIcon, OTypography } from "@maestro/react";
import React from "react";
import { env } from "utils";

export const NewAdminLink = () => {
  return (
    <OButton
      dataAction="header:botao:link_portal_corporativo"
      dataLabel="link_portal_corporativo"
      type="tertiary"
      onClick={() => window.open(env.NEW_ADMIN_URL ?? "", "_blank")}
      outline
    >
      <div className="d-flex flex-row">
        <OIcon category="fas" icon="fa-external-link" className="me-2" />
        <OTypography>Ir para o portal novo</OTypography>
      </div>
    </OButton>
  );
};
