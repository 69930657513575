o-pill-tab-header {
  &.o-pill-tab-header {
    font-family: inherit;
    box-sizing: border-box;
    display: flex;
    width: max-content;
    outline: 1px solid var(--theme-dark-20);
    border-radius: var(--border-radius-xxs);

    o-pill-tab:not(:last-child) .o-pill-tab {
      border-right: 1px solid var(--theme-dark-20);
    }

    o-pill-tab:first-child .o-pill-tab {
      border-radius: var(--border-radius-xxs) 0 0 var(--border-radius-xxs);
    }

    o-pill-tab:last-child .o-pill-tab {
      border-radius: 0 var(--border-radius-xxs) var(--border-radius-xxs) 0;
    }
  }
}
