import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../globals/types";

@Component({
  tag: "o-field-error",
  styleUrl: "index.scss",
})
export class FieldError {
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Visibilidade da mensagem de erro.
   *
   * @sbCategory Input
   */
  @Prop() visible = false;

  render() {
    const classes = {
      "o-field-error": true,
      [`o-field-error--size-${this.size}`]: true,
      "o-field-error--visible": this.visible,
    };

    return (
      <div class={classes}>
        <o-icon category="fas" icon="fa-exclamation-circle" size={this.size} />
        <o-typography size={this.size}>
          <slot />
        </o-typography>
      </div>
    );
  }
}
