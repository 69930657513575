import { Component, Element, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-pill-tab-body",
  styleUrl: "index.scss",
})
export class OPillTabBody {
  @Element() self!: HTMLElement;
  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop() activeTab = 0;

  // update active property in content
  componentWillRender() {
    const contents = this.self.querySelectorAll("o-pill-tab-content");

    contents.forEach((el, index) => {
      if (this.activeTab === index) {
        el.active = true;
      } else {
        el.active = false;
      }
    });
  }

  render() {
    return (
      <Host tabindex={0}>
        <slot />
      </Host>
    );
  }
}
