o-pill-tab {
  display: flex;

  .o-pill-tab {
    font-family: inherit;
    appearance: none;
    position: relative;
    display: inline-block;
    padding: 0 var(--font-md);
    box-sizing: border-box;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 0;
    cursor: pointer;
    user-select: none;

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }

    &:disabled,
    &:disabled:hover {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    @each $type in $types {
      &--type-#{$type} {
        border-color: var(--theme-#{$type});
        background-color: var(--theme-#{$type});
        color: var(--theme-#{$type}-inverse);

        &:hover {
          border-color: var(--theme-#{$type}-hover);
          background-color: var(--theme-#{$type}-hover);
        }

        &:active:not(:disabled) {
          border-color: var(--theme-#{$type}-active);
          background-color: var(--theme-#{$type}-active);
        }

        &:disabled {
          border-color: var(--theme-light-hover);
          background-color: var(--theme-light-hover);
          color: var(--theme-light-inverse);
        }
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        min-height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
