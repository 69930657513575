import { IFileUpload, OUploadCustomEvent } from "@maestro/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  OField,
  OIcon,
  OUpload,
  OUploadInput,
  OUploadItem,
  OUploadLabel,
  OUploadTip,
} from "../../../stencil-generated";
import { fieldOptionsBuilder } from "../../../utils";
import { TooltipContainer } from "../../tooltip-container";
import { ORFieldUploadInputProps } from "./types";

export const ORFieldUploadInput = (props: ORFieldUploadInputProps) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    inputLabel,
    tip,
    size = "md",
    handleDownload,
    handleRemove,
    handleAddFile,
    onAddFile,
    children,
    ...rest
  } = props;
  const { register, formState, getFieldState, watch, getValues, setValue } =
    useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  const resolveAddFile = (file: IFileUpload) => {
    const files: IFileUpload[] = getValues(name) ?? [];
    const newFiles = [...files, file];

    setValue(name, newFiles);
  };

  const resolveRemove = (file: IFileUpload) => {
    const files: IFileUpload[] = getValues(name) ?? [];
    const newFiles = files.filter((f) => f !== file);

    setValue(name, newFiles);
  };

  const registered = register(name);
  const fieldOptions = fieldOptionsBuilder(fieldErrors, props);
  const watchValue: IFileUpload[] = watch(name) ?? [];

  return (
    <OUpload
      value={watchValue}
      onAddFile={(e: OUploadCustomEvent<File>) => {
        onAddFile?.(e);
        handleAddFile?.(e, resolveAddFile);
      }}
    >
      <div>
        <OField {...fieldOptions}>
          {tooltip && (
            <TooltipContainer
              position={tooltipPosition}
              maxWidth={tooltipMaxWidth}
            >
              {tooltip}
            </TooltipContainer>
          )}

          <div className="d-flex flex-column gap-3">
            <OUploadInput {...(rest as any)} {...registered} />

            <OUploadLabel htmlFor={props.id} size={size}>
              {inputLabel}
              <OIcon category="far" icon="fa-cloud-upload" size={size} />
            </OUploadLabel>

            {tip && <OUploadTip size={size}>{tip}</OUploadTip>}
          </div>
        </OField>

        {!!watchValue.length && (
          <div className="d-flex flex-column gap-3">
            {watchValue.map((file, index) => (
              <OUploadItem
                key={file.name + index}
                size={size}
                errorMessage={file.errorMessage}
                handleDownload={
                  handleDownload ? () => handleDownload(file) : undefined
                }
                handleRemove={
                  handleRemove
                    ? () => handleRemove(file, resolveRemove)
                    : undefined
                }
              >
                {file.name}
              </OUploadItem>
            ))}
          </div>
        )}
      </div>
    </OUpload>
  );
};
