import { Component, Element, h, Host, Prop } from "@stencil/core";
import { IType } from "../../../globals/types";

@Component({
  tag: "o-dropdown-list",
  styleUrl: "index.scss",
})
export class DropdownList {
  @Element() self!: HTMLElement;

  /**
   * Cor do detalhe.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Largura mínima. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() minWidth = "max-content";
  /**
   * Habilita borda nos `o-dropdown-item`.
   *
   * @sbCategory Style
   */
  @Prop() bordered = true;

  componentDidLoad() {
    this.self.style.minWidth = this.minWidth;
  }

  render() {
    const { type, bordered } = this;

    const classes = {
      "o-dropdown-list": true,
      [`o-dropdown-list--type-${type}`]: true,
      "o-dropdown-list--bordered": bordered,
    };

    return (
      <Host class={classes} role="listbox" slot="floating-content">
        <slot />
      </Host>
    );
  }
}
