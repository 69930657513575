import { Component, Element, h, Host } from "@stencil/core";

@Component({
  tag: "o-list",
  styleUrl: "index.scss",
})
export class List {
  @Element() self!: HTMLElement;

  componentWillRender() {
    this.self.querySelectorAll("o-list-item").forEach((el) => {
      el["defaultType"] = "none";
    });
  }

  render() {
    return (
      <Host class="o-list">
        <ul class="slot-container">
          <slot />
        </ul>
      </Host>
    );
  }
}
