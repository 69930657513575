import React, { useContext, useMemo } from "react";
import { AxiosInstance } from "axios";
import { RestClientContext } from "utils/RestClient";
import { Endpoints } from "../endpoints";

export class TradeService {
  restClient: AxiosInstance;
  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async downloadComprovante(tradeId: number): Promise<any> {
    const result = await this.restClient.get(
      Endpoints.trades.downloadComprovante(tradeId),
      {
        responseType: "blob",
      },
    );
    return result.data;
  }
}

export const TradeServiceContext = React.createContext<TradeService | null>(
  null,
);

export function useTradeService() {
  return useContext(TradeServiceContext)!;
}

export const TradeServiceProvider: React.StatelessComponent = (props) => {
  const restClient = useContext(RestClientContext);
  const tradeService = useMemo(() => new TradeService(restClient), [
    restClient,
  ]);
  return (
    <TradeServiceContext.Provider value={tradeService}>
      {props.children}
    </TradeServiceContext.Provider>
  );
};
