import { recebiveisCartaoClient } from "legacy/services/services";
import { useCallback, useEffect } from "react";
import { atom, selector, useRecoilState } from "recoil";
import { endpoints } from "utils/endpoints";

export const cartaoCreditoAlertData = atom({
  key: "Cartão de Crédito Alerts",
  default: {
    CERC: {
      agendas_pendentes_por_trava: [],
      agendas_pendentes_por_solicitacao: [],
    },
    CIP: {
      agendas_pendentes_por_trava: [],
      agendas_pendentes_por_solicitacao: [],
    },
  },
});

export const cartaoCreditoBadgeSelector = selector({
  key: "Menu.Badge.Cartão de Crédito",
  get: ({ get }) => {
    const alertsData = get(cartaoCreditoAlertData);
    return (
      alertsData.CERC.agendas_pendentes_por_solicitacao.length +
      alertsData.CERC.agendas_pendentes_por_trava.length +
      alertsData.CIP.agendas_pendentes_por_solicitacao.length +
      alertsData.CIP.agendas_pendentes_por_trava.length
    );
  },
});

export function useRecebiveisCartaoAlertasRefresher(refreshTime: number) {
  const [alertas, setAlertas] = useRecoilState(cartaoCreditoAlertData);

  const refreshRecebiveisCartaoBadges = useCallback(async () => {
    const fetchResult = await recebiveisCartaoClient.get(
      endpoints.recebiveiscartao.agendas.alerts,
    );
    setAlertas(fetchResult.data);
  }, [setAlertas]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshRecebiveisCartaoBadges();
    }, refreshTime);
    return () => clearInterval(interval);
  }, [refreshTime, refreshRecebiveisCartaoBadges]);

  useEffect(() => {
    refreshRecebiveisCartaoBadges();
  }, [refreshRecebiveisCartaoBadges]);

  return alertas;
}
