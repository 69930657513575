import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-skeleton-line",
  styleUrl: "index.scss",
})
export class SkeletonLine {
  /**
   * Tamanho da linha.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  render() {
    const classes = {
      "o-skeleton-line": true,
      [`o-skeleton-line--size-${this.size}`]: true,
    };

    return <div class={classes} />;
  }
}
