import type { JSX } from "@maestro/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import { TooltipContainer } from "../../tooltip-container";
import { OField, OInputUpload } from "../../../stencil-generated";
import { fieldOptionsBuilder, FieldProps } from "../../../utils";

export interface ORFieldUploadProps extends JSX.OInputUpload, FieldProps {
  name: string;
  children?: React.ReactNode;
}

/**
 * @deprecated Usar `ORFieldUploadInput`
 * @example
 *   // import { ORFieldUpload } from "@maestro/react";
 *   import { ORFieldUploadInput } from "@maestro/react";
 */
export const ORFieldUpload = (props: ORFieldUploadProps) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    onFileChange,
    ...rest
  } = props;
  const { register, formState, getFieldState } = useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  const registered = register(name);
  const fieldOptions = fieldOptionsBuilder(fieldErrors, props);

  return (
    <OField {...fieldOptions}>
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}

      <OInputUpload
        {...(rest as any)}
        error={!!fieldErrors}
        {...registered}
        onFileChange={(e) => {
          registered.onChange(e);

          if (onFileChange) {
            onFileChange(e);
          }
        }}
      />
    </OField>
  );
};
