o-card {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--theme-dark-10);

  &.o-card--rounded {
    border-radius: var(--border-radius-xxs);
  }

  @each $type in $types {
    &.o-card--type-#{$type} {
      color: var(--theme-#{$type}-inverse);
      background-color: var(--theme-#{$type});
    }

    &.o-card--outline-#{$type} {
      background-color: transparent;
      border-color: var(--theme-#{$type});
      color: var(--theme-#{$type});
    }
  }
}
