import React, { createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { HTMLStencilElement } from "../../stencil-generated/react-component-lib/createComponent";
import { StencilReactExternalProps } from "../../stencil-generated/react-component-lib/utils";

const Portal = (props: { children: React.ReactNode; el: Element }) => {
  return ReactDOM.createPortal(props.children, props.el);
};

/**
 * Case: Use this HOC to fix the issue: "DOMException: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node." \
 * Issue link: https://github.com/ionic-team/stencil/issues/2259 \
 * Use case: Wrapper Stencil Generated Components fixing dynamic slot that suffer with state changes, like: <OComponent>{list.map((i) => <span key={i}>{i}</span>)}</OComponent> \
 * Problem: This issue occurs when a slot is contained with pure html element and the VDOM node was removed, before the stencil update DOM content \
 * Solution: Prevent VDOM unmount the content with the class .slot-container before the DOM node has been updated \
 */
export const withSlotRef = <PropType, ElementType extends HTMLStencilElement>(
  Component: React.ElementType,
) => {
  return React.forwardRef(function withSlotRef(
    { children, ...props }: StencilReactExternalProps<PropType, ElementType>,
    ref: React.Ref<ElementType>,
  ) {
    const hostElementRef: React.Ref<ElementType> =
      ref && "current" in ref ? ref : createRef();
    const [slotRef, setChildRef] = useState<Element | null | undefined>(null);

    useEffect(() => {
      const node = hostElementRef.current;

      const selectChildElement = () => {
        const childElement = node?.querySelector(".slot-container");

        setChildRef(childElement);
      };

      if (ref && !("current" in ref)) {
        ref(hostElementRef.current);
      }

      node?.componentOnReady
        ? node?.componentOnReady().then(selectChildElement)
        : node?.addEventListener("ready", selectChildElement);
    }, []);

    return (
      <Component ref={hostElementRef} {...props}>
        {slotRef && <Portal el={slotRef}>{children}</Portal>}
      </Component>
    );
  });
};
