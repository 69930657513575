import { Component, h, Host, Prop } from "@stencil/core";
import { IType } from "../../globals/types";

@Component({
  tag: "o-card",
  styleUrl: "index.scss",
})
export class Card {
  /**
   * Cor do _background_.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Arredonda os cantos do _card_.
   *
   * @sbCategory Style
   */
  @Prop() rounded = true;
  /**
   * Remove o _background_ e muda a cor do texto para a definida por `type`.
   *
   * @sbCategory Style
   */
  @Prop() outline = false;

  render() {
    const { type, rounded, outline } = this;

    const classes = {
      "o-card": true,
      [`o-card--type-${type}`]: true,
      "o-card--rounded": rounded,
      [`o-card--outline-${type}`]: outline,
    };

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
