import { useCallback, useContext, useRef, useState } from "react";
import { RestClientContext } from "utils/RestClient";

interface IUseMutateProps {
  path: string;
  method: "POST" | "PUT" | "PATCH" | "DELETE";
  service?: any;
}

interface IUseMutate<TData, TResponse> {
  data: TResponse | undefined;
  loading: any;
  error: any;
  fetch: (data?: TData) => Promise<TResponse | undefined>;
  setUrl: any;
  setHeaders: (newHeaders: any) => void;
}

export function useMutate<TData, TResponse>(
  props: IUseMutateProps,
): IUseMutate<TData, TResponse> {
  const clientv2 = useContext(RestClientContext);
  const innerClient = useRef<any>(!!props.service ? props.service : clientv2);
  const fetched = useRef(false);

  const url = useRef<string>(props.path);

  const [data, setData] = useState<TResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const headers = useRef<any>({});

  const fetch = useCallback(
    async (data?: TData): Promise<TResponse | undefined> => {
      setLoading(true);
      fetched.current = true;

      if (!url.current.length) {
        console.warn("É necessário informar a URL para realizar a mutation.");

        return;
      }

      try {
        const r = await innerClient.current({
          method: props.method,
          url: url.current,
          headers: headers.current,
          data,
        });

        setLoading(false);
        setData(r.data as TResponse | undefined);

        return r.data as TResponse;
      } catch (e) {
        setLoading(false);
        setError(true);

        console.warn("Error on fetch serviceRequest: ", url.current, e);
        throw e;
      }
    },
    [innerClient, props.method, url],
  );

  const setHeaders = useCallback((newHeaders: any) => {
    headers.current = newHeaders;
  }, []);

  const setUrl = useCallback((newUrl: string) => {
    url.current = newUrl;
  }, []);

  return { data, loading, error, fetch, setUrl, setHeaders };
}
