import { env } from "utils";
import { ProductType } from "./company/company-types";

/** @deprecated */
export const hosts = {
  ApiUrl: () => env.QUICKFIN_API || "",
  HubLoan: () => env.API_LOAN || "",
  HubCreditManager: () => env.API_CREDIT_MANAGER || "",
  HubFx: () => env.API_FX || "",
  CustomerService: () => env.API_CUSTOMER || "",
  Raven: () => env.API_RAVEN || "",
  PaperclipV2Url: () => env.PAPERCLIPV2_URL || "",
  B2BAPIUrl: () => env.API_B2B || "",
  GatekeeperUrl: () => env.GATEKEEPER_URL || "",
  GatekeeperFraudUrl: () => env.GATEKEEPERFRAUD_URL || "",
  PartnersUrl: () => env.API_PARCEIROS || "",
  BankingAdminUrl: () => env.URL_BANKINGADMIN || "",
  HubOnboardingUrl: () => env.API_ONBOARDING || "",
  FIDCUrl: () => env.FIDC_URL || "",
  RecebiveisCartaoUrl: () => env.RECEBIVEIS_CARTAO_URL || "",
  FIDCBankslipManagerUrl: () => env.FIDC_BANKSLIP_MANAGER_URL || "",
  ClerkUrl: () => env.CLERK_API || "",
  EnergyBackURL: () => env.ENERGY_URL || "",
  CalculatorURL: () => env.CALCULATOR_URL || "",
  HubCollectionUrl: () => env.API_COLLECTION || "",
  RelationshipsUrl: () => env.API_RELATIONSHIPS || "",
};

const {
  ApiUrl,
  B2BAPIUrl,
  BankingAdminUrl,
  CalculatorURL,
  ClerkUrl,
  CustomerService,
  EnergyBackURL,
  FIDCBankslipManagerUrl,
  FIDCUrl,
  GatekeeperFraudUrl,
  GatekeeperUrl,
  HubCollectionUrl,
  HubCreditManager,
  HubFx,
  HubLoan,
  HubOnboardingUrl,
  PaperclipV2Url,
  PartnersUrl,
  Raven,
  RecebiveisCartaoUrl,
  RelationshipsUrl,
} = hosts;

/** @deprecated */
export const Endpoints = {
  agendamento: {
    comprovante: (
      agendamentoAndReceivableId: number,
      isAgendamentoId: boolean,
    ) =>
      `${ApiUrl()}scheduling/${agendamentoAndReceivableId}/comprovante/${isAgendamentoId}`,
    getCancelable: `/scheduling/cancelable`,
    cancel: `/scheduling/cancel`,
  },
  attendance: {
    registerAttendant: `${CustomerService()}/api/v1/operator`,
  },
  raven: {
    adminOdata: `${Raven()}/api/notifications/admin_odata`,
    template: `${Raven()}/api/notification_config/odata`,
    templateDetails: (templateConfigName: string) =>
      `${Raven()}/api/notification_config/${templateConfigName}`,
    templateRegister: `${Raven()}/api/notification_config`,
    removeTemplate: (templateConfigName: string) =>
      `${Raven()}/api/notification_config/${templateConfigName}`,
  },
  cedentes: {
    getCedentes: (id: number) => `/companies/${id}/cedentes`,
    createCedente: (id: number) => `/companies/${id}/cedentes`,
    updateCedente: (id: number) => `/companies/${id}/cedentes`,
    verifyCedentes: (id: number) => `/companies/${id}/cedentes/upload/verify`,
    createCedentes: (id: number) => `/companies/${id}/cedentes/upload`,
    uploadRiscoCedente: () => `/visao-cedente`,
    verifyRiscoCedente: () => `/visao-cedente/verify`,
    verifyReceivables: (companyId: number) =>
      `/companies/${companyId}/borderos/verify`,
    uploadReceivables: (companyId: number) =>
      `/companies/${companyId}/borderos/upload`,
    verifyCCCReceivables: (companyId: number) =>
      `${ApiUrl()}companies/${companyId}/borderos/ccc/verify`,
    uploadCCCReceivables: (companyId: number) =>
      `${ApiUrl()}companies/${companyId}/borderos/ccc/upload`,
    verifyAgenda: (companyId: number) =>
      `/companies/${companyId}/borderos/cartao/verify`,
    uploadAgenda: (companyId: number) =>
      `/companies/${companyId}/borderos/cartao/upload`,
    updateCadastroRelacionamento: (
      companyId: number,
      estrategiaId: number,
      updateCedentes: boolean,
    ) =>
      `/estrategia-comercial/${companyId}/${estrategiaId}/${updateCedentes}/relacionamento`,
    notifyLegalRepresentatives: "companies/notify-Legal-Representatives",
    operationById: (companyId: number, operationId: number) =>
      `${ApiUrl()}admin/companies/${companyId}/operations/${operationId}`,
  },
  sacados: {
    getSacados: (id: number) => `/companies/${id}/sacados`,
    createSacado: (id: number) => `/companies/${id}/sacados`,
    updateSacado: (id: number) => `/companies/${id}/sacados`,
    verifySacados: (id: number) => `/companies/${id}/sacados/upload/verify`,
    createSacados: (id: number) => `/companies/${id}/sacados/upload`,
  },
  borderos: {
    admin: {
      getById: (id: number) => `/borderos/${id}`,
    },
  },
  estrategiaComercial: {
    getEstrategiaTaxasByCurrency: (
      companyId: number,
      estrategiaId: number,
      currency: string,
    ) =>
      `/companies/${companyId}/estrategias/${estrategiaId}/taxas/${currency}`,
    getEstrategias: (id: number) => `/estrategia-comercial/${id}`,
    deleteEstrategia: (companyId: number, estrategiaId: number) =>
      `${ApiUrl()}estrategia-comercial/${companyId}/${estrategiaId}`,
    createEstrategia: (companyId: number) =>
      `${ApiUrl()}estrategia-comercial/${companyId}`,
    updateEstrategia: (companyId: number) =>
      `${ApiUrl()}estrategia-comercial/${companyId}`,

    setEstrategiaDefault: (companyId: number, estrategiaId: number) =>
      `${ApiUrl()}estrategia-comercial/${companyId}/${estrategiaId}/padrao`,
    getTemplatesEstrategia: `${ApiUrl()}template-estrategia-comercial`,
    createTemplateEstrategia: `${ApiUrl()}template-estrategia-comercial`,
    updateTemplateEstrategia: `${ApiUrl()}template-estrategia-comercial`,
    setTemplateRelacionamento: (
      estrategiaId: number,
      atualizaEstrategias: boolean,
      atualizaCedentes: boolean,
    ) =>
      `${ApiUrl()}template-estrategia-comercial/${estrategiaId}/${atualizaEstrategias}/${atualizaCedentes}/relacionamento`,
    updateTaxasLinkedToTemplate: (estrategiaId: number) =>
      `${ApiUrl()}template-estrategia-comercial/${estrategiaId}/configTaxa`,
  },
  marketplace: {
    getMarketplaces: () => `${ApiUrl()}marketplaces`,
  },
  companyContractTemplates: {
    getContractTemplates: (companyId: number) =>
      `/companies/${companyId}/contract-templates`,
    createContractTemplate: (companyId: number) =>
      `/companies/${companyId}/contract-templates`,
    updateContractTemplate: (companyId: number, templateId: number) =>
      `/companies/${companyId}/contract-templates/${templateId}`,
    deleteContractTemplate: (companyId: number, templateId: number) =>
      `/companies/${companyId}/contract-templates/${templateId}`,
    downloadContractTemplate: (companyId: number, templateId: number) =>
      `/companies/${companyId}/contract-templates/${templateId}/file`,
    setContractTemplateAsDefault: (companyId: number, templateId: number) =>
      `/companies/${companyId}/contract-templates/${templateId}/set-default`,
  },
  company: {
    getCompany: (id: number) => `/companies/${id}`,
    createCompany: "/companies",
    updateCompanyInfo: (id: number) => `/companies/${id}`,
    getUsers: (id: number) => `/companies/${id}/users`,
    createUser: `${HubOnboardingUrl()}/api/admin/onboard/user`,
    createUserAdmin: `${HubOnboardingUrl()}/api/admin/User/create-administrator`,
    sendWelcomeEmail: `${HubOnboardingUrl()}/api/admin/onboard/user/welcome-email`,
    createAdmin: `${HubOnboardingUrl()}/api/admin/onboard/user-admin`,
    getCompaniesFromSacado: (id: number) => `/companies/from-sacado/${id}`,
    signatureGroups: (id: number) => `/companies/${id}/signature-groups`,
    getCompanyWithOnboarding: (id: number) =>
      `/companies/with-onboarding/${id}`,
    getCompanies: `/companies`,
    updateCompanyWithOnboarding: (id: number) => `/companies/update/${id}`,
    getPendingContractsByCompanyId: (companyId: number) =>
      `/companies/${companyId}/pending-contracts
        `,
    getEntityByTaxId: (cnpjOrCpf: string) =>
      `/companies/search?cnpj=${cnpjOrCpf}
        `,
    getCampaigns: `${HubOnboardingUrl()}/api/admin/Campaign/all`,
    getLegalEntityDetails: `${HubOnboardingUrl()}/api/SearchLegalEntity/details`,
    legalEntitySearch: `${HubOnboardingUrl()}/api/SearchLegalEntity`,
    getAllCompanies: (query: string) =>
      `${ApiUrl()}companies/admin/get-all-companies?${query}`,
  },
  trades: {
    downloadComprovante: (tradeId: number) => `/trades/${tradeId}/comprovante`,
  },
  contracts: {
    getContractFileById: (id: number) => `/contracts/${id}/file`,
  },
  contractTemplates: {
    getPublicContractTemplates: `${ApiUrl()}contract-templates`,
    createPublicContractTemplate: `${ApiUrl()}contract-templates`,
    updatePublicContractTemplate: (templateId: number) =>
      `${ApiUrl()}contract-templates/${templateId}`,
    deletePublicContractTemplate: (templateId: number) =>
      `${ApiUrl()}contract-templates/${templateId}`,
    downloadPublicContractTemplate: (templateId: number) =>
      `${ApiUrl()}contract-templates/${templateId}/file`,
  },
  receivable: {
    approve: "/admin/receivables/approve",
    getAllCompanies: `${ApiUrl()}/admin/get-companies`,
    getAllCancelableReceivables: `${ApiUrl()}admin/cancelable-receivables`,
    getAllReceivables: "/admin/receivables",
    receivablesSummary: `admin/receivables/summary`,
    exportAllReceivables: "/admin/receivables/export",
    getReceivablesDaily: `admin/receivables/dailySummary`,
    getReceivablesDailyFIDC: `admin/receivables/fidc/dailySummary`,
    getCancelableReceivables: `/receivables/cancelable`,
    cancel: `${ApiUrl()}receivables/cancel`,
  },
  notificationQuantities: {
    get: (entityName: string) => `/quantities/${entityName}`,
  },
  notifications: {
    get: () => "/notifications",
    updateStatus: (id: number) => `/notifications/${id}`,
  },
  onboarding: {
    basicRoute: "/onboarding",
    admin: {
      deskOnboarding: `${env.API_ONBOARDING}/api/admin/desk/onboarding`,
      deskOnboardingRegisterLegalEntity: `${env.API_ONBOARDING}/api/admin/desk/onboarding/register-entity-from-desk`,
      getSemaphore: `${env.API_ONBOARDING}/api/admin/get-semaphore`,
    },
    customerInvite: {
      openAccount: `${env.API_ONBOARDING}/api/CustomerInvite/open-account`,
    },
    searchLegalEntity: `${env.API_ONBOARDING}/api/SearchLegalEntity`,
  },
  dashboardOnboarding: {
    infos: `/admin/dashboard-onboardings/infos`,
    oData: `odata/OnboardingsFornecedoresOData`,
  },

  superAdmin: {
    publishEvent: () => "/super-admin/events",
    updateReceivableState: () => `/super-admin/receivables/status`,
    updateReceivableStateMachineState: () =>
      `/super-admin/receivable-state-machines/status`,
    updateAgendamentoState: () => "super-admin/agendamento",
    createOperations: () => "/admin/operations",
    forceGroupCompletion: () => "/super-admin/groups/force-completion",
  },
  balances: {
    getBalances: () => "/admin/balances",
  },
  tiers: {
    getTiers: (cedenteId?: number) =>
      cedenteId ? `/admin/tiers?cedenteId=${cedenteId}` : "/admin/tiers",
    getTier: (id: number) => `/admin/tiers/${id}`,
    createTiers: () => "/admin/tiers",
    updateTier: (id: number) => `/admin/tiers/${id}`,
    destroyTier: (id: number) => `/admin/tiers/${id}`,
    getRelationships: (id?: string) =>
      "/admin/sacadocedentetiers/tiers/" + (id ? id : ""),
    destroyRelationship: (
      tierId: number,
      sacadoId: number,
      cedenteId: number,
    ) =>
      `/admin/sacadocedentetiers/tiers/${tierId}/sacados/${sacadoId}/cedentes/${cedenteId}`,
    createRelationship: (id: number) => `/admin/sacadocedentetiers/tiers/${id}`,
    cedenteLimits: () => "/admin/limit/cedentes",
    cedenteSacadoLimits: (cedenteLimitId: number) =>
      `/admin/cedentelimits/${cedenteLimitId}/sacados`,
    editCedenteSacadoLimits: (cedenteLimitId: number, sacadoId: number) =>
      `/admin/cedentelimits/${cedenteLimitId}/sacados/${sacadoId}`,
    editCedenteLimit: (id: number) => `/admin/limit/cedentes/${id}`,
    sacadoTierConfigs: () => "/admin/tiers/sacados",
    editSacadoTierConfig: (id: number) => `/admin/tiers/sacados/${id}`,
  },
  fidc: {
    createFund: () => `${FIDCUrl()}/api/fund`,
    getAssets: (batchId: string) =>
      `${FIDCUrl()}/api/dashboard/assets?batchId=${batchId}`,
    getBatches: (fundTaxId: string, from: string, to: string) =>
      `${FIDCUrl()}/api/dashboard/batches?fundTaxId=${fundTaxId}&from=${from}&to=${to}`,
    getSigners: (productName: string) =>
      `${FIDCUrl()}/api/dashboard/signers/${productName}`,
    getNotifications: (productId: string) =>
      `${FIDCUrl()}/api/notifications/${productId}`,
    getAmbevNotifications: () => `${FIDCUrl()}/api/ambev/notifications/`,
    manageAmbevAssets: () => `${FIDCUrl()}/api/manage/asset/ambev`,
    getContract: (batchId: string) =>
      `${FIDCUrl()}/api/dashboard/contract/${batchId}`,
    getProcessedAssets: () => `${FIDCUrl()}/api/validator/processedAssets`,
    getRules: () => `${FIDCUrl()}/api/validator/rules`,
    getFunds: () => `${FIDCUrl()}/api/fund/admin`,
    getInteractionsByFundTaxId: (fundTaxId: string) =>
      `${FIDCUrl()}/api/snake/interaction/tracking?fundTaxId=${fundTaxId}`,
    resendInteraction: () => `${FIDCUrl()}/api/snake/interaction/tracking`,
    createFinancial: () => `${FIDCUrl()}/api/snake/financial`,
    getCompanyById: (id: string) => `${FIDCUrl()}/api/snake/company/${id}`,
    updateFinancial: (financialId: string) =>
      `${FIDCUrl()}/api/snake/financial/${financialId}`,
    deleteFinancial: (financialId: string) =>
      `${FIDCUrl()}/api/snake/financial/${financialId}`,
    odata: {
      getInteractionsOData: (fundTaxId: string = "") =>
        `${FIDCUrl()}/api/snake/odata/InteractionsOData?fundTaxId=${fundTaxId}`,
    },
    renewContract: (batchId: string) =>
      `${FIDCUrl()}/api/renew/contract/${batchId}`,
    sendFundNotification: (fundTaxId: string) =>
      `${FIDCUrl()}/api/send/notification/fund?cnpj=${fundTaxId}`,
    overallStatus: (params: any) =>
      `${FIDCUrl()}/api/dashboard/overallStatus?${params}`,
    getCharges: (fundCge: string, clientCge: string) =>
      `${FIDCUrl()}/api/dashboard/charges?fundCge=${fundCge}&clientCge=${clientCge}`,
    getProducts: `${FIDCUrl()}/api/dashboard/products`,
    getConsolidatedContracts: (productId: string, params: string) =>
      `${FIDCUrl()}/api/ContractGenerator/${productId}?${params}`,
    consolidatedContract: `${FIDCUrl()}/api/ContractGenerator/`,
    generateAllContracts: (fundTaxId: string) =>
      `${FIDCUrl()}/api/ambev/generate/all-contracts?cnpj=${fundTaxId}`,
    getBankslip: (slipId: string) =>
      `${FIDCUrl()}/api/dashboard/bankSlip/${slipId}`,
  },
  fidcBankslipManager: {
    getBankslip: (slipId: string) =>
      `${FIDCBankslipManagerUrl()}v1/bankslip/${slipId}`,
    putBankslip: `${FIDCBankslipManagerUrl()}v1/bankslip/`,
    getNextBusDay: (date: string, units: number) =>
      `${FIDCBankslipManagerUrl()}v1/NextBusDay?referenceDate=${date}&units=${units}`,
  },
  bankingAdmin: {
    getContactsByCompany: (identification: string) =>
      `${BankingAdminUrl()}/Contact/${identification}`,
    createAndUpdateAccount: (identification?: string) =>
      `${BankingAdminUrl()}/Contact/${identification ? identification : ""}`,
    deleteAccount: (identification: string, contactId: string) =>
      `${BankingAdminUrl()}/Contact/${identification}/${contactId}`,
    getBalance: (bankCode: number, accountNumber: number, agency: number) =>
      `${BankingAdminUrl()}/Balance/balance/${bankCode}/${accountNumber}/${agency}`,
    getBalanceYield: (identification: string) =>
      `${BankingAdminUrl()}/BalanceYield/balanceyield/${identification}`,
    getOverdraft: (identification: string) =>
      `${BankingAdminUrl()}/Overdraft/limit/${identification}`,
  },
  bankManager: {
    createAndUpdateAccount: () => `${BankingAdminUrl()}/BankingManager`,
  },
  paperclip: {
    signatures: {
      getRulesAndGroupsOfSignatures: (taxId: string) =>
        `/SignatureRule?cnpj=${taxId}`,
      createRuleAndGroup: () => `/SignatureRule`,
      createGroup: (signatureRuleId: number) =>
        `/SignatureRule/${signatureRuleId}/GroupOfSignatures`,
      updateGroup: (signatureRuleId: number, groupId: number) =>
        `/SignatureRule/${signatureRuleId}/GroupOfSignatures/${groupId}`,
      deleteGroup: (signatureRuleId: number, groupId: number) =>
        `/SignatureRule/${signatureRuleId}/GroupOfSignatures/${groupId}`,
      updateRule: (signatureRuleId: number) =>
        `/SignatureRule/${signatureRuleId}`,
    },
    contracts: {
      updateContract: (UUID: string) =>
        `${PaperclipV2Url()}Contract/${UUID}/update-contract`,
      getContractFile: (UUID: string) =>
        `${PaperclipV2Url()}Contract/${UUID}/file-binary.pdf`,
      getSignedContractFile: (UUID: string) =>
        `${PaperclipV2Url()}Contract/${UUID}/file-signed-binary.pdf`,
      resendNotification: (UUID: string) =>
        `${PaperclipV2Url()}Contract/${UUID}/send-notifications`,
    },
  },
  turtleRoad: {
    configTaxa: {
      getUniqueTaxes: (convenioUniqueId: string) =>
        `/taxas/configTaxa/${convenioUniqueId}`,
      createAndUpdateTaxaPrazo: (currencyId: number) =>
        `/taxas/taxaPrazo/${currencyId}`,
      createAndUpdateFullConfig: () => "/taxas/configTaxa/",
      deleteTaxaPrazo: (taxaPrazoId: number) =>
        `/taxas/taxaPrazo/${taxaPrazoId}`,
    },
  },
  oData: {
    getUsers: () => "odata/UsersView",
    getSacados: () => "odata/SacadosCompaniesView",
    getCedentes: () => "odata/CedentesCompaniesView",
    getAuditsLogs: () => "odata/AuditsView",
    getEventsLogs: () => "odata/LogEventosViews",
    getOperations: "odata/OperationsOData",
    getOperationsCreditCard: `odata/OperationsOData?tipoProduto=${ProductType.Cartao}`,
    getOperationsInvoice: `odata/OperationsOData?tipoProduto=${ProductType.RiscoSacado}`,
    getOperationsOffshore: `odata/OperationsOData?tipoProduto=${ProductType.RiscoSacado}&tipoProduto=${ProductType.Offshore}`,
    getOperationsFIDC: "odata/FIDCOperationsOData",
    getBorderos: "odata/BorderosOData",
    getBorderosFIDC: "odata/FIDCBorderosOData",
    getReceivablesState: "odata/ReceivableStateMachineODataView",
    getReceivablesStateFIDC: "odata/FIDCReceivableStateMachineODataView",
    getOperationsLoan: () => `${HubLoan()}/odata/LoanAdmin`,
    getAgroProposals: () => `${HubLoan()}/odata/AgricultureProposalsAdmin`,
    getInstallments: (query: string) =>
      `${HubLoan()}/odata/InstallmentsDueDatesAdmin${query}`,
    getOperationsLoanFIDC: `${HubLoan()}/odata/LoanFIDCAdmin`,
    getFailedRequests: () => `${HubLoan()}/odata/FailedRequests`,
    getOperationsFx: () => `${HubFx()}/odata/AdminOperationsOData`,
    getOrdersFx: () => `${HubFx()}/odata/AdminOrdersOData`,
    getPreticketsFx: () => `${HubFx()}/odata/AdminPreTicketsOData`,
    getAgendamentosState: () => "odata/AgendamentoView",
    getAditamentos: () => "odata/AditamentoOData",
    getRelacionamentos: "odata/CompaniesRelationshipsOData",
    getTradeImports: `${HubLoan()}/odata/TradeImportsAdmin`,
  },
  fx: {
    getQuoteDetails: (quoteId: string) =>
      `${HubFx()}/api/admin/quote/${quoteId}`,
    cancelOperation: (quoteId: string) =>
      `${HubFx()}/api/admin/quote/${quoteId}/cancel`,
    getPreTicketDetails: (preTicketId: string) =>
      `${HubFx()}/api/admin/pre-ticket/${preTicketId}`,
    updatePreTicket: `${HubFx()}/api/admin/pre-ticket/status`,
    getOrderDetails: (orderId: string) =>
      `${HubFx()}/api/admin/order/${orderId}`,
    downloadContract: (contractId: string) =>
      `${HubFx()}/api/file/contract?contractId=${contractId}`,
    downloadReceipt: (contractId: string) =>
      `${HubFx()}/api/file/receipt?contractId=${contractId}`,
    downloadFile: (key: string) => `${HubFx()}/api/file/download?key=${key}`,
    getUpsertCommonDomainAdmin: `${HubFx()}/api/admin/common`,
    upsertNatureRule: `${HubFx()}/api/admin/common/nature-rules`,
    getNatureRules: `${HubFx()}/api/admin/common/nature-rules`,
  },
  loan: {
    getDetails: (loanId: number) =>
      `${HubLoan()}/api/admin/loan/${loanId}/details`,
    downloadFile: (key: string) =>
      `${HubLoan()}/api/admin/company/download?key=${key}`,
    getAgendamentosState: () => "/odata/AgendamentoView",
    cancelLoan: (loanId: number) =>
      `${HubLoan()}/api/admin/loan/${loanId}/cancel`,
    republishTrade: () => `${HubLoan()}/api/admin/loan/republish`,
    updateStatus: () => `${HubLoan()}/api/admin/loan/status`,
    businessApproval: `${HubLoan()}/api/admin/loan/business/approve`,

    agreements: `${HubLoan()}/api/admin/agreement`,
    agreementContracts: {
      contract: `${HubLoan()}/api/admin/agreement-contract`,
      getContracts: (agreementId: number) =>
        `${HubLoan()}/api/admin/agreement-contract/${agreementId}`,
      deleteContract: (id: number) =>
        `${HubLoan()}/api/admin/agreement-contract/${id}`,
    },
    agreementsFund: `${HubLoan()}/api/admin/agreement/fund`,
    channel: `${HubLoan()}/api/admin/channel`,
    companyReadiness: (taxId: string) =>
      `${HubLoan()}/api/admin/company/${taxId}/readiness`,
    partner: `${HubLoan()}/api/admin/partner`,
    arrangement: `${HubLoan()}/api/admin/arrangement/`,
    arrangementsByPartner: (partnerName: string) =>
      `${HubLoan()}/api/admin/arrangement/${partnerName}`,
    arrangementById: (partnerChannelAgreementId: number) =>
      `${HubLoan()}/api/admin/arrangement/id/${partnerChannelAgreementId}`,
    failedRequest: (id: number) => `${HubLoan()}/api/admin/failedrequest/${id}`,
    setArrangementToCompany: (taxId: string) =>
      `${HubLoan()}/api/admin/company/${taxId}/arrangements`,
    getDisbursements: (loanId: number) =>
      `${HubLoan()}/api/admin/loan/${loanId}/disbursements`,
    limitControl: {
      allPedingApproval: `${HubLoan()}/api/admin/limit-control/all-pending-approval`,
      avaliableLimit: (taxId: string, product: string) =>
        `${HubLoan()}/api/admin/limit-control/${taxId}/${product}/available`,
      limitApproval: (limitUpdatedId: string) =>
        `${HubLoan()}/api/admin/limit-control/${limitUpdatedId}/approve`,
      requestNewLimit: (taxId: string, product: string) =>
        `${HubLoan()}/api/admin/limit-control/${taxId}/${product}/request-new-limit`,
    },
    importFtsTrade: `${HubLoan()}/api/admin/trades/import`,
    contingency: {
      getContingency: `${HubLoan()}/api/admin/configuration-variable/all`,
      updateContingency: `${HubLoan()}/api/admin/configuration-variable`,
      loanContractsConfig: `${HubLoan()}/api/admin/loan-contracts-config`,
      getLoanContractsConfig: `${HubLoan()}/api/admin/loan-contracts-config/all`,
      getLoanContractsConfigDetails: (loanContractsId: string) =>
        `${HubLoan()}/api/admin/loan-contracts-config/${loanContractsId}`,
      contractTemplateConfig: `${HubLoan()}/api/admin/contract-template-config`,
      deleteContractTemplateConfig: (contractTemplateId: string) =>
        `${HubLoan()}/api/admin/contract-template-config/${contractTemplateId}`,
      downloadContractTemplateConfig: (key: string) =>
        `${HubLoan()}/api/admin/company/download?key=${key}`,
      uploadContractTemplate: `${HubLoan()}/api/admin/company/upload`,
    },
    summary: {
      summaryReport: `${HubLoan()}/api/reports/mainreport/summary`,
    },
    report: {
      downloadReport: `${HubLoan()}/api/reports/mainreport/download`,
    },
    disbursement: {
      updateValue: `${HubLoan()}/api/admin/disbursement/value`,
    },
    contract: {
      getContractsByLoanId: (loanId: number) =>
        `${HubLoan()}/api/admin/contract/${loanId}`,
    },
    guarantee: {
      getGuaranteesByContractId: (contractId: number) =>
        `${HubLoan()}/api/admin/guarantee/contract/${contractId}`,
      getGuaranteeByLoanId: (loanId: number) =>
        `${HubLoan()}/api/admin/guarantee/loan/${loanId}`,
    },
    pendency: {
      resolve: (pendencyId: number) =>
        `${HubLoan()}/api/admin/loan/pendency/${pendencyId}/resolve`,
      resolveAll: (loanId: number) =>
        `${HubLoan()}/api/admin/loan/${loanId}/pendency/resolve-all`,
    },
  },
  creditManager: {
    limits: `${HubCreditManager()}/api/admin/limits`,
    sendApproval: `${HubCreditManager()}/api/admin/limits/approval`,
    ODataLimits: `${HubCreditManager()}/odata/LimitsAdmin`,
    transactionsByLimit: (transactionId: number) =>
      `${HubCreditManager()}/api/admin/transaction/${transactionId}/get-all-by-limit`,
    createTransaction: `${HubCreditManager()}/api/admin/transaction/create`,
    reverseTransaction: `${HubCreditManager()}/api/admin/transaction/reverse`,
    createGuarantor: `${HubCreditManager()}/api/guarantors/create-credit-line-guarantor`,
    admin: {
      limitRequests: {
        getLimitRequest: (limitRequestId: number) =>
          `${HubCreditManager()}/api/admin/limit-requests/get-limit-request/${limitRequestId}`,
        updateStatus: `${HubCreditManager()}/api/admin/limit-requests/update-status`,
        updateExpirationDate: `${HubCreditManager()}/api/admin/limit-requests/update-expiration-date`,
        removeGuarantor: `${HubCreditManager()}/api/admin/limit-requests/remove-guarantor`,
        updateQuotesAndIndexCode: `${HubCreditManager()}/api/admin/limit-requests/update-quotes-indexcode`,
      },
      invoices: {
        getInvoicesByLimitRequestId: (limitRequestId: number) =>
          `${HubCreditManager()}/api/admin/invoice/${limitRequestId}`,
      },
      additionalData: {
        agro: (limitRequestId: string) =>
          `${HubCreditManager()}/api/admin/additional-data/agro/${limitRequestId}`,
      },
      collateral: {
        getCollateral: (collateralId: string) =>
          `${HubCreditManager()}/api/admin/collateral/${collateralId}`,
        updateCollateral: `${HubCreditManager()}/api/admin/collateral/update`,
      },
      collateralDocument: {
        create: `${HubCreditManager()}/api/admin/limits/create-collateral-document`,
      },
      property: {
        update: `${HubCreditManager()}/api/admin/property/update`,
      },
    },
    additionalFees: {
      getAdditionalFeeByLimitRequestId: (limitRequestId: number) =>
        `${HubCreditManager()}/api/admin/additional-fee/get-by-limit-request-id/${limitRequestId}`,
      getAdditionalFeeById: (additionalFeeId: number) =>
        `${HubCreditManager()}/api/admin/additional-fee/get-by-id/${additionalFeeId}`,
      delete: `${HubCreditManager()}/api/admin/additional-fee/delete`,
      create: `${HubCreditManager()}/api/admin/additional-fee/create`,
      update: `${HubCreditManager()}/api/admin/additional-fee/update`,
    },
    disbursementInstructions: {
      getDisbursementInstructionsByLimitRequestId: (limitRequestId: number) =>
        `${HubCreditManager()}/api/admin/disbursement-instructions/get-by-limit-request-id/${limitRequestId}`,
      getDisbursementInstructionsById: (disbursementInstructionsId: number) =>
        `${HubCreditManager()}/api/admin/disbursement-instructions/get-by-id/${disbursementInstructionsId}`,
    },
    contractTemplate: {
      getContractTemplatesByLimitRequestId: (limitRequestId: number) =>
        `${HubCreditManager()}/api/admin/contract-template/get-by-limit-request-id/${limitRequestId}`,
      create: `${HubCreditManager()}/api/admin/contract-template/create`,
      update: `${HubCreditManager()}/api/admin/contract-template/update`,
      delete: (contractTemplateId: number) =>
        `${HubCreditManager()}/api/admin/contract-template/delete/${contractTemplateId}`,
    },
    foreignCurrencyIndex: {
      getForeignCurrencyIndexByLimitRequestId: (limitRequestId: number) =>
        `${HubCreditManager()}/api/admin/foreign-currency-index/get-by-limit-request-id/${limitRequestId}`,
      create: `${HubCreditManager()}/api/admin/foreign-currency-index/create`,
      update: `${HubCreditManager()}/api/admin/foreign-currency-index/update`,
    },
    file: {
      download: `${HubCreditManager()}/api/FileAdmin/download`,
      upload: `${HubCreditManager()}/api/FileAdmin/upload`,
    },
  },
  agro: {
    proposalApprovement: () => `${HubLoan()}/api/admin/agro/proposals/approve`,
    getProposalDetails: (proposalId: number) =>
      `${HubLoan()}/api/admin/agro/proposals/get-details/${proposalId}`,
    updatePortion: () => `${HubLoan()}/api/admin/agro/proposals/update-portion`,
  },
  gateKeeper: {
    impersonate: () => `${GatekeeperUrl()}/impersonate`,
    users: {
      getAll: () => `${GatekeeperUrl()}/users/all`,
      getUser: (email: string) => `${GatekeeperUrl()}/users/${email}`,
      getUserByEmail: `${GatekeeperUrl()}/users/get_user_by_email`,
      updateUser: (email: string) => `${GatekeeperUrl()}/users/${email}`,
      createUser: () => `${GatekeeperUrl()}/users`,
      getCompanyUsers: (cnpj: string) =>
        `${GatekeeperUrl()}/users/companies/${cnpj}`,
      getRelationProfiles: (email: string) =>
        `${GatekeeperUrl()}/relationships/user/${email}`,
      deactivateUser: (email: string) =>
        `${GatekeeperUrl()}/users/${email}/deactivate`,
      activateUser: (email: string) =>
        `${GatekeeperUrl()}/users/${email}/activate`,
      userFromCpf: `${env.GATEKEEPER_URL}/users/get_from_cpf`,
    },
    systems: {
      getAll: () => `${GatekeeperUrl()}/systems/all`,
      createSystem: () => `${GatekeeperUrl()}/systems`,
      updateSystem: (name: string) => `${GatekeeperUrl()}/systems/${name}`,
      getSystem: (name: string) => `${GatekeeperUrl()}/systems/${name}`,
    },
    profiles: {
      getAll: `${env.GATEKEEPER_URL}/profiles/all`,
      createProfile: () => `${env.GATEKEEPER_URL}/profiles`,
      getProfile: (name: string) => `${env.GATEKEEPER_URL}/profiles/${name}`,
      updateProfile: (name: string) => `${env.GATEKEEPER_URL}/profiles/${name}`,
      updateSubProfiles: `${env.GATEKEEPER_URL}/profiles/update_sub_profiles`,
      getDetails: () => `${env.GATEKEEPER_URL}/profiles/get_details`,
      deleteDetail: () => `${env.GATEKEEPER_URL}/profiles/details/delete`,
      insertDetail: () => `${env.GATEKEEPER_URL}/profiles/details/create`,
      updateDetail: () => `${env.GATEKEEPER_URL}/profiles/details/update`,
      getActs: () => `${env.GATEKEEPER_URL}/profiles/get_acts`,
      insertAct: () => `${env.GATEKEEPER_URL}/profiles/add_acts`,
      deleteAct: () => `${env.GATEKEEPER_URL}/profiles/remove_acts`,
      removeRoles: () => `${env.GATEKEEPER_URL}/profiles/remove_roles`,
      addRoles: () => `${env.GATEKEEPER_URL}/profiles/add_roles`,
    },
    acts: {
      getActs: () => `${env.GATEKEEPER_URL}/get_acts`,
    },
    roles: {
      getAll: () => `${GatekeeperUrl()}/roles`,
      createRole: () => `${GatekeeperUrl()}/roles`,
      updateRole: (name: string) => `${GatekeeperUrl()}/profiles/${name}`,
      getRole: (name: string) => `${GatekeeperUrl()}/roles/${name}`,
      updateRoleById: () => `${GatekeeperUrl()}/roles/update_by_id`,
    },
    invalidation: {},
    companies: {
      getAll: () => `${GatekeeperUrl()}/companies`,
      createCompany: () => `${GatekeeperUrl()}/companies`,
      updateCompany: (cnpj: string) => `${GatekeeperUrl()}/companies/${cnpj}`,
      getCompany: (cnpj: string) => `${GatekeeperUrl()}/companies/${cnpj}`,
      addCompanyUsers: (cnpj: string) =>
        `${GatekeeperUrl()}/companies/${cnpj}/users/profiles/add`,
      removeCompanyUsers: (cnpj: string) =>
        `${GatekeeperUrl()}/companies/${cnpj}/users/profiles/remove`,
      getRelationProfiles: (cnpj: string) =>
        `${GatekeeperUrl()}/relationships/company/${cnpj}`,
      removeRelationship: (cpf: string, cnpj: string) =>
        `${GatekeeperUrl()}/relationships/${cpf}/${cnpj}`,
    },
    companiesMonitor: {
      odata: `${GatekeeperUrl()}/odata/companies`,
    },
    usersMonitor: {
      odata: `${GatekeeperUrl()}/odata/users`,
    },
    customerService: {
      generateToken: `${env.GATEKEEPER_URL}/internal_customer_service/generate`,
    },
    allowOperation: {
      getSignatureCombinations: `${GatekeeperUrl()}/allow_operation/get_signature_combinations`,
    },
  },
  clerk: {
    users: {
      getUser: `${ClerkUrl()}/api/users/get_by_email`,
      getUserByCpf: `${ClerkUrl()}/api/users/get_by_tax_id`,
      updateUser: `${ClerkUrl()}/api/users/update_by_email`,
      getAll: () => `${ClerkUrl()}/api/users`,
      getDetailsByEmail: `${ClerkUrl()}/api/users/get_by_email`,
      createUser: () => `${ClerkUrl()}/api/users`,
      updateUserEmail: `${ClerkUrl()}/api/users/update_email`,
      deleteUser: (cpf: string) => `${ClerkUrl()}/api/entities/${cpf}`,
      getByTaxId: `${env.CLERK_API}/api/users/get_by_tax_id`,
    },
    relations: {
      getAll: (cnpj: string) =>
        `${ClerkUrl()}/api/entities_relationships/from_user/${cnpj}`,
    },
    companies: {
      getAll: () => `${ClerkUrl()}/api/odata/admin/companies`,
      createCompany: () => `${ClerkUrl()}/api/companies`,
      deleteUser: (identification: string) =>
        `${ClerkUrl()}/api/entities/${identification}`,
      getCompanyDetails: `${ClerkUrl()}/api/companies/get_by_tax_id`,
      getCompany: `${ClerkUrl()}/api/companies/get_by_tax_id`,
      updateCompany: `${ClerkUrl()}/api/companies/update_by_tax_id`,
      strategy: (identification: string, strategyUniqueId: string) =>
        `${ClerkUrl()}/api/strategies/${identification}/${strategyUniqueId}`,
      getBranches: (entityId: number) =>
        `${ClerkUrl()}/api/company_relationships/${entityId}/Matriz`,
      updateBranches: () =>
        `${ClerkUrl()}/api/entities_relationships/companies`,
      lessAccounts: (identification: string) =>
        `${BankingAdminUrl()}/Account/accounts?taxId=${identification}`,
      insertAccount: (id: string) =>
        `${BankingAdminUrl()}/Account/accounts/${id}`,
      removeAccount: (id: string) =>
        `${BankingAdminUrl()}/Account/accounts/${id}`,
    },
    groups: {
      getAll: () => `${ClerkUrl()}/api/groups`,
      updateEntities: () => `${ClerkUrl()}/api/groups/association`,
      getRelations: (id: string) =>
        `${ClerkUrl()}/api/groups/${id}/GrupoEconomico`,
      getGroup: (id: string) => `${ClerkUrl()}/api/groups/${id}`,
      deleteGroup: (id: string) => `${ClerkUrl()}/api/groups/${id}`,
      createGroup: () => `${ClerkUrl()}/api/groups`,
    },
    systems: {
      createSystem: (name: string) => `${ClerkUrl()}/api/systems/${name}`,
      getAll: () => `${ClerkUrl()}/api/systems`,
      getDetails: () => `${ClerkUrl()}/api/systems`,
      getStrategies: (id: string) =>
        `${ClerkUrl()}/api/strategies/entity/${id}`,
      updateStrategies: () => `${ClerkUrl()}/api/strategies`,
    },
    strategies: {
      getAll: () => `${ClerkUrl()}/api/strategies`,
      createStrategy: () => `${ClerkUrl()}/api/strategies`,
      getByCompany: (identification: string) =>
        `${ClerkUrl()}/api/strategies/${identification}`,
    },
    onboardingMonitor: {
      odata: `/api/odata/admin/entities`,
    },
    onboardingMonitorLevels: {
      odata: "/api/odata/admin/onboarding-levels/companies",
    },
    bankAccounts: {
      getByTaxId: (taxId: string) =>
        `${ClerkUrl()}/api/bank_accounts/?company=${taxId}`,
      create: `${ClerkUrl()}/api/bank_accounts`,
      update: (id: number) => `${ClerkUrl()}/api/bank_accounts/${id}`,
      delete: (id: number) => `${ClerkUrl()}/api/bank_accounts/${id}`,
    },
    usersMonitor: {
      odata: `${ClerkUrl()}/api/odata/admin/users`,
    },
    relationship: {
      update: (userIdentification: string, relatedIdentification: string) =>
        `${ClerkUrl()}/api/entities_relationships/${userIdentification}/${relatedIdentification}`,
      delete: (userIdentification: string, relatedIdentification: string) =>
        `${ClerkUrl()}/api/entities_relationships/${userIdentification}/${relatedIdentification}`,
    },
    adminOdata: {
      desksCompaniesSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/self`,
      desksCompaniesAccountPFSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/account_pf/self`,
      desksCompaniesAccountPJSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/account_pj/self`,
      desksCompaniesAssignorSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/assignor/self`,
      desksCompaniesEnergySelf: `${env.CLERK_API}/api/admin/odata/desks/companies/energy/self`,
      desksCompaniesNoOnboardingSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/no_onboarding/self`,
      desksCompaniesPayerSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/payer/self`,
      desksUsersSelf: `${env.CLERK_API}/api/admin/odata/desks/users/self`,
    },
    desks: {
      getAll: `${env.CLERK_API}/api/desks`,
      get: `${env.CLERK_API}/api/desks/`,
      getEntityDesks: `${env.CLERK_API}/api/desks/get_entity_desks`,
      upsertEntity: `${env.CLERK_API}/api/desks/upsert_entity`,
    },
    searchEngineEntity: `${env.CLERK_API}/api/search_engine/entity`,
    powerOfAttorney: {
      isEloaAvailable: `${env.CLERK_API}/api/power_of_attorney/is_eloa_available`,
    },
  },
  recebiveisCartao: {
    dataGrids: {
      agendas: () => "/api/filter-table/agendas-without-operated-value",
      agendasFullData: () => "/api/filter-table/agendas",
      registroSolicitations: () => "/api/filter-table/registros-solicitations",
      registros: () => "/api/filter-table/registros",
      agendasReduzidas: () => "/api/filter-table/agendas-reduzidas",
      tradesVencimentos: () => "/api/filter-table/trades-vencimentos",
      recebiveis: () => "/api/filter-table/recebiveis",
      companies: () => "/api/filter-table/companies",
      newest_agendas: () => "/api/filter-table/monitora-company-agendas",
      errors: () => "/api/filter-table/errors",
      jobs: () => "/api/filter-table/jobs",
      trades: () => "/api/filter-table/trades",
      companyMappings: () => "/api/filter-table/agendas-company-map",
      estrategiasComerciais: () =>
        `${ApiUrl()}odata/EstrategiasComerciaisOData`,
    },
    registros: {
      download: (queryString: string) =>
        `/api/registros/download?${queryString}`,
      entubaInclusao: () => `api/registros/entuba-inclusao`,
      entubaAlteracao: () => `api/registros/entuba-alteracao`,
      entubaCancelamento: () => `api/registros/entuba-cancelamento`,
    },
    agendas: {
      upload_file: (type: "cip" | "cerc") => `/api/agendas-${type}`,
      import_download: (queryString: string) =>
        `/api/agendas/import?${queryString}`,
      alerts: () => "/api/agendas/alerts",
    },
    companies: {
      download_cerc_agenda_request_file: () => "/api/export-cerc/download",
      optin: () => "/api/cip/request-agendas",
      all: () => `${RecebiveisCartaoUrl()}/api/companies`,
      bandeiras: () => `${RecebiveisCartaoUrl()}/api/bandeiras/all`,
      not_credenciadoras: (params = "") =>
        `${RecebiveisCartaoUrl()}/api/companies/not-credenciadoras${params}`,
      credenciadoras_subcredenciadoras: () =>
        `${RecebiveisCartaoUrl()}/api/companies/credenciadoras-subcredenciadoras`,
      credenciadoras: () =>
        `${RecebiveisCartaoUrl()}/api/companies/credenciadoras`,
      subcredenciadoras: () =>
        `${RecebiveisCartaoUrl()}/api/companies/subcredenciadoras`,
      map: {
        index: (tax_id: string | null = null) =>
          `${RecebiveisCartaoUrl()}/api/companies/map/${tax_id || ""}`,
        group: (tax_id: string | null = null) =>
          `${RecebiveisCartaoUrl()}/api/companies/map/group/${tax_id || ""}`,
      },
    },
    jobs: {
      crontab: () => "api/jobs/crontab",
      runWorker: () => "api/jobs/run-worker",
      runJob: () => "api/jobs/run-job",
      stopJob: () => "api/jobs/stop-job",
    },
    convenios: {
      all: () => "/api/convenios/grid",
      create: () => "/api/convenios",
      update: (id: number | string) => `/api/convenios/${id}`,
    },
    whitelists: {
      all: () => "/api/whitelists/grid",
      create: () => "/api/whitelists",
      update: (id: number | string) => `/api/whitelists/${id}`,
    },
    deflatores: {
      index: () => "/api/deflatores/grid",
      create: () => "/api/deflatores",
      update: (id: number | string) => `/api/deflatores/${id}`,
      default: {
        index: () => "/api/deflatores/default",
        update: () => "/api/deflatores/default",
      },
    },
    trades: {
      conciliacao: (beginDate: string, endDate: string) =>
        `${RecebiveisCartaoUrl()}/api/conciliacao?beginDate=${beginDate}&endDate=${endDate}`,
      children: (id: number) =>
        `${RecebiveisCartaoUrl()}/api/trades/${id}/children`,
    },
    emailAutomatico: {
      index: () => `${RecebiveisCartaoUrl()}/api/email_configs`,
      create: () => `${RecebiveisCartaoUrl()}/api/email_configs`,
      update: (id: number | string) =>
        `${RecebiveisCartaoUrl()}/api/email_configs/${id}`,
      delete: (id: number | string) =>
        `${RecebiveisCartaoUrl()}/api/email_configs/${id}`,
    },
  },
  partners: {
    partnersFeed: `${PartnersUrl()}/odata/feedOdata`,
  },
  monitoring: {
    portal: {
      portalGet: "/portal",
      createBlacklist: `${PartnersUrl()}/admin/blacklist`,
      removeBlacklist: (id: number | string) =>
        `${PartnersUrl()}/admin/blacklist/${id}`,
      reprocessPme: `${HubOnboardingUrl()}/api/admin/contracts/reprocess`,
      reprocessContract: `${HubOnboardingUrl()}/api/admin/contracts/batch/create`,
      acceptTerms: `${PartnersUrl()}/admin/optin/sign`,
    },
    onboarding: {
      get: `${HubOnboardingUrl()}/api/admin/Onboard/get-onboard-with-type`,
      downloadDocument: (key: string) =>
        `${HubOnboardingUrl()}/api/file/${key}/download`,
      updateStatus: `${HubOnboardingUrl()}/api/admin/Onboard/status`,
    },
    odata: {
      customer: `odata/CustomerOdata`,
      blacklist: `${PartnersUrl()}/odata/BlackListOData`,
      contractsOfEntity: (cnpj: string) =>
        `${PaperclipV2Url()}odata/ContractAdminOData?$filter=contains(tolower(CompanyCNPJ)%2C%27${cnpj}%27)`,
      contractsByType: `${PaperclipV2Url()}odata/ContractAdminOData`,
      contractsOfEntityByType: (cnpj: string, ContractType: string) =>
        `${PaperclipV2Url()}odata/ContractAdminOData?$filter=contains(tolower(CompanyCNPJ)%2C%27${cnpj}%27)%20and%20ContractType%20eq%20%27${ContractType}%27`,
      customerFromQF: `odata/CedentesCompaniesView`,
      contractByUUID: (uuid: string) =>
        `${PaperclipV2Url()}odata/ContractAdminOData?$apply=filter(UUID eq ${uuid})`,
    },
    creditCard: {
      quickfinResumoTrades: () => `${ApiUrl()}admin/monitor/resumo-diario`,
      quickfinMaturingTrades: () => `${ApiUrl()}admin/monitor/trades-maturing`,
      quickfinTabelaRecebiveisCartoes: () => `${ApiUrl()}odata/MonitorDiario`,
      recebiveisCartaoResumoAgendas: () =>
        `${RecebiveisCartaoUrl()}/api/monitor/resumo-diario`,
      recebiveisCartaoHealthCheck: () =>
        `${RecebiveisCartaoUrl()}/api/monitor/health-check`,
      recebiveisCartaoTradeHealth: () =>
        `${RecebiveisCartaoUrl()}/api/monitor/trades`,
    },
  },
  socialImpact: {
    rpaData: `${HubOnboardingUrl()}/api/admin/onboard/all-rpa`,
  },
  adminOnboarding: {
    getLegalRepresentativesWithRisk: (cnpj: string) =>
      `${ApiUrl()}admin/onboarding/${cnpj}/CedenteContaEscrow/manual-validation/legal-rep-with-risk`,
    sendManualValidation: (cnpj: string) =>
      `${ApiUrl()}admin/onboarding/${cnpj}/CedenteContaEscrow/manual-validation/validate`,
    getLegalEntityByCpf: `${HubOnboardingUrl()}/api/admin/person`,
    getUserEmailUpdates: `${HubOnboardingUrl()}/api/admin/user/get-email-updates`,
    updateUserEmail: `${HubOnboardingUrl()}/api/admin/user`,
    updateOnboardingRiskStatus: `${HubOnboardingUrl()}/api/admin/OnboardingAnalysis`,
    getLastRiskAnalysis: `${HubOnboardingUrl()}/api/admin/OnboardingAnalysis/last-analysis`,
    deleteRelationship: `${HubOnboardingUrl()}/api/admin/user/related-entity`,
  },
  energy: {
    getCompanyByCnpj: `${HubOnboardingUrl()}/api/admin/company`,
    getOnboardByTaxId: `${HubOnboardingUrl()}/api/admin/energy`,
    preRegisterCompany: `${HubOnboardingUrl()}/api/onboard/pre-register`,
    preRegisterUpdate: `${HubOnboardingUrl()}/api/admin/energy/update`,
    getConsumers: `${EnergyBackURL()}/api/company/consumers`,
    getCompanies: `${EnergyBackURL()}/api/company`,
    getDistributors: `${EnergyBackURL()}/api/company/distributors`,
    putPostCompany: `${EnergyBackURL()}/api/company`,
    getCompanyById: (id: string) => `${EnergyBackURL()}/api/company/${id}`,
    getConsumerById: (id: string) =>
      `${EnergyBackURL()}/api/company/consumer/${id}`,
    getConsumersByTaxId: (taxId: string) =>
      `${EnergyBackURL()}/api/company/${taxId}/consumers`,
    getDistributorById: (id: string) =>
      `${EnergyBackURL()}/api/company/distributor?id=${id}`,
    getCompanyByTaxId: (taxId: string) =>
      `${EnergyBackURL()}/api/company/name?taxId=${taxId}`,
    getOnboardingCompanies: `${EnergyBackURL()}/api/odata`,
    getDocument: (taxId?: string, documentTypeEnum?: number) =>
      `${EnergyBackURL()}/api/admin-dashboard/${taxId}/document?documentType=${documentTypeEnum}`,
    getDownload: (taxId?: string, downloadTypeEnum?: number) =>
      `${EnergyBackURL()}/api/admin-dashboard/${taxId}/download?downloadType=${downloadTypeEnum}`,
    getRequiredDocuments: (distributorId: string) =>
      `${EnergyBackURL()}/api/company/requiredDistributorDocuments?id=${distributorId}`,
  },
  calculator: {
    getDistributors: `${CalculatorURL()}/api/v1/distribuidor/`,
    getCurrentVersions: () => `${CalculatorURL()}/api/v1/health/version`,
    postCotacao: () => `${CalculatorURL()}/api/v1/cotacao/`,
    getCotacaoById: (id: number) => `${CalculatorURL()}/api/v1/cotacao/${id}`,
    getReport: `${CalculatorURL()}/api/v1/relatorio/`,
    deleteCotacao: (id: number) => `${CalculatorURL()}/api/v1/cotacao/${id}`,
    getHistoricoCotacao: `${CalculatorURL()}/api/v1/cotacao/historico_agrupamento`,
    getConsolidado: `${CalculatorURL()}/api/v1/cotacao/consolidado`,
    postCurva: `${CalculatorURL()}/api/v1/curva/`,
    getCurvaByDate: (date: string) => `${CalculatorURL()}/api/v1/curva/${date}`,
    postBill: `${CalculatorURL()}/api/v1/leitura_conta/upload_conta`,
    reprocessBillData: `${CalculatorURL()}/api/v1/leitura_conta/reprocessar_dados`,
    getBill: `${CalculatorURL()}/api/v1/leitura_conta/processar_conta`,
    postPSR: `${CalculatorURL()}/api/v1/distribuidor/psr`,
  },
  collection: {
    odata: `${HubCollectionUrl()}/odata/CollectionOData`,
    resendNotification: `${HubCollectionUrl()}/api/notification/resend`,
    notification: (tradeId: string) =>
      `${HubCollectionUrl()}/api/notification/${tradeId}`,
    tempaltesCCB: `${HubCollectionUrl()}/api/template/ccb`,
  },
  gateKeeperFraud: {
    allowedlist: {
      index: () => `${GatekeeperFraudUrl()}/api/allow_list/no_biometry`,
      create: () => `${GatekeeperFraudUrl()}/api/allow_list/create`,
      delete: (taxId?: String | undefined) =>
        `${GatekeeperFraudUrl()}/api/allow_list/no_biometry/${taxId}`,
    },
  },
  gateHolder: {
    odata: `/api/fraud_sys/grid`,
    details: (requestId: string) =>
      `${env.GATEHOLDER_API}/api/fraud_sys/analysis_details/${requestId}`,
    sendAnalysis: (requestId: string) =>
      `${env.GATEHOLDER_API}/api/fraud_sys/manual_analysis/${requestId}`,
    downloadFile: (fileKey: string) =>
      `${env.GATEHOLDER_API}/api/files/download/${fileKey}`,
  },
  b2b: {
    partner: {
      getPartnerFeed: () => `${B2BAPIUrl()}/api/partner/companies/feeds`,
    },
  },
  dashboard: {
    getUsersAmount: () => `${ClerkUrl()}/api/reports/total_number_of_users`,
    getCompleteRegistrationsAmount: () =>
      `${ClerkUrl()}/api/reports/total_number_of_active_clients`,
    getAccountsAmount: () =>
      `${ClerkUrl()}/api/reports/total_number_of_clients_with_btg_business_account`,
    getClientsAmount: () => `${ClerkUrl()}/api/reports/total_number_of_clients`,
    getActiveCompaniesAmount: () =>
      `${ClerkUrl()}/api/reports/total_number_of_active_clients`,
    getCompaniesWithBTGBusinessAmount: () =>
      `${ClerkUrl()}/api/reports/total_number_of_clients_with_btg_business_account`,
    getDailyClientsAmount: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_new_clients`,
    getDailyClientsWithPendingOnboardingAmount: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_clients_with_pending_onboarding`,
    getDailyClientsWithFinishedOnboardingAmount: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_clients_with_finished_onboarding`,
    getDailyClientsWithReprovedOnboardingAmount: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_clients_with_reproved_onboarding`,
    getDailyAccountsByStatus: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_btg_accounts_by_status`,
    getElegibilityAmount: () =>
      `${ClerkUrl()}/api/reports/total_number_of_clients_by_eligibility_status`,
    getDailyElegibilityAmount: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_clients_by_eligibility_status`,
    getProducts: () => `${ClerkUrl()}/api/products`,
    getDailyProducts: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_clients_by_products`,
    getDailyPendingUsers: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_users_creating_account`,
    getDailyFinishedUsers: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_users_with_active_account`,
    getDailyNotStartedBiometryUsers: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_users_with_not_started_biometry`,
    getDailyUnfinishedBiometryUsers: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_users_with_pending_biometry`,
    getDailyCompletedBiometryUsers: () =>
      `${ClerkUrl()}/api/reports/daily_number_of_users_with_completed_biometry`,
  },
  hubOnboarding: {
    firstAccessSemaphore: `${HubOnboardingUrl()}/api/onboarding-levels/get-semaphore-first-access`,
    createCompany: `${HubOnboardingUrl()}/api/admin/onboarding-levels/register-legal-entity`,
    accountPfApproval: `${HubOnboardingUrl()}/api/contingency/account-pf/approval`,
    verifyCompany: `${HubOnboardingUrl()}/api/admin/company`,
    resendOnboardingLess: `${HubOnboardingUrl()}/api/contingency/onboarding/resend-to-less`,
    reopenOnboarding: `${HubOnboardingUrl()}/api/contingency/onboarding/reopen`,
    contingencyApprove: `${HubOnboardingUrl()}​/api/contingency/onboarding/approve`,
    documentsDevolutive: `${HubOnboardingUrl()}​/api/contingency/documents/occurrences`,
    generateTerm: `${HubOnboardingUrl()}​/api/customer-service/contracts/create-account-opening`,
    loadAccountOpening: `${HubOnboardingUrl()}​/api/customer-service/invites/load-account-opening`,
    createAccountOpening: `${HubOnboardingUrl()}/api/customer-service/invites/create-account-opening`,
    companyDocuments: `${HubOnboardingUrl()}​/api/contingency/documents/load`,
    documents: {
      download: (key: string) =>
        `${HubOnboardingUrl()}/api/admin/onboarding-levels/download/${key}`,
      uploadFile: (taxId: string, documentType: string) =>
        `${HubOnboardingUrl()}/api/GuarantorLevels/upload?guarantorTaxId=${taxId}&documentTypeLevels=${documentType}&filePageType=COMPLETO`,
    },
    getUserRecomendedProfiles: `${HubOnboardingUrl()}/api/admin/user/get-profiles`,
    finishAccess: `${HubOnboardingUrl()}/api/contingency/workflow/terminate-relationship`,
    companyGroups: {
      getGroup: (identification: string) =>
        `${HubOnboardingUrl()}/api/admin/companyGroup/get-companies-from-group/${identification}`,
      createGroup: `${HubOnboardingUrl()}/api/admin/companyGroup/register-group-and-associations`,
      verifyCompanies: `${HubOnboardingUrl()}/api/admin/companyGroup/register-group-and-associations/verify`,
    },
    findCep: `${HubOnboardingUrl()}/api/address/by-zip-code`,
    legalEntitySearch: `${HubOnboardingUrl()}/api/SearchLegalEntity`,
    administrativeUnit: {
      getCities: (stateCode: string) =>
        `${HubOnboardingUrl()}/api/AdministrativeUnit/cities?stateCode=${stateCode}&countryCode=BR`,
      getCityByExternalId: (externalId: string) =>
        `${HubOnboardingUrl()}/api/AdministrativeUnit/city?externalId=${externalId}`,
    },
  },
  onboardingRelationships: {
    guarantor: {
      loadGuarantors: `${RelationshipsUrl()}/api/guarantor/load-guarantors`,
      onboardingGuarantor: `${RelationshipsUrl()}/api/guarantor/onboarding-guarantor`,
    },
    admin: {
      guarantor: {
        getAll: `${RelationshipsUrl()}/api/admin/guarantors/get-all`,
        resend: `${RelationshipsUrl()}/api/admin/guarantors/resend`,
      },
    },
    company: {
      createUser: `${RelationshipsUrl()}/api/user/users/register`,
    },
  },
  cardOffer: {
    eligibility: {
      result: {
        getCompanyResultsByProduct: (governmentId: string, productId: number) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/product/${productId}/company/${governmentId}`,
        getCompanyResultsByOffer: (governmentId: string, offerId: number) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/offer/${offerId}/company/${governmentId}`,
        getCompanyResultsByProductGroup: (
          governmentId: string,
          baseKind: string,
        ) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/product-group/${baseKind}/company/${governmentId}`,
      },
      group: {
        create: `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group`,
        update: (groupId: number) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group/${groupId}`,
        getByProductGroup: (baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group/product-group/${baseKind}`,
      },
      productGroup: {
        processAll: (baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/product-group/${baseKind}`,
        processByCompany: (governmentId: string, baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/product-group/${baseKind}/company/${governmentId}`,
      },
    },
    product: {
      getAll: `${env.CARDS_OFFER_ENGINE_URL}/product`,
    },
    criteria: {
      getTypes: `${env.CARDS_OFFER_ENGINE_URL}/criteria/types`,
    },
    offer: {
      getAll: `${env.CARDS_OFFER_ENGINE_URL}/offer`,
      changeIsEligibleStatus: (offerId: number) =>
        `${env.CARDS_OFFER_ENGINE_URL}/offer/${offerId}`,
    },
  },
};
