import { IStylesheet } from "../../../utils/helpers/IStylesheet";

export const modalStyles: IStylesheet = {
  modal: {
    display: "flex",
    flexDirection: "column",
    outline: "none",
    overflow: "scroll",
  },
  container: {
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    textAlign: "center" as "center",
    margin: "auto",
    padding: "30px",
    width: "100%",
    outline: "none",
  },
  acceptButton: {
    minWidth: "90px",
  },
};
