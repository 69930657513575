import type { OModalCustomEvent } from "@maestro/core";

type EventCallback = (e: Event) => void;

function show(id: string) {
  document.dispatchEvent(new CustomEvent("modalOpen", { detail: { id } }));
}

function hide(id: string) {
  document.dispatchEvent(new CustomEvent("modalClose", { detail: { id } }));
}

function on(
  id: string | string[],
  type: "modalOpen" | "modalClose",
  callback: () => void
) {
  let internalCallback: (e: OModalCustomEvent<HTMLElement>) => void;

  if (typeof id === "string") {
    internalCallback = function useModalOnInternalCallback(e) {
      if (e.detail.id === id) callback();
    };
  } else {
    internalCallback = function useModalOnInternalCallback(e) {
      if (id.includes(e.detail.id)) callback();
    };
  }

  document.addEventListener(type, internalCallback as EventCallback);

  return () =>
    document.removeEventListener(type, internalCallback as EventCallback);
}

/**
 * @deprecated Usar `modalManager`
 * @example
 *   // import { useModal } from "@maestro/react";
 *   import { modalManager } from "@maestro/react";
 *
 *   // const { show } = useModal();
 *   // show(id);
 *   modalManager.show(id);
 */
export const useModal = () => {
  return { show, hide, on };
};
