import { Component, Element, h, Prop } from "@stencil/core";
import { IAspect, IAutocomplete, ISize } from "../../globals/types";
import { IInputType } from "../input/story";

@Component({
  tag: "o-input-text",
})
export class InputText {
  @Element() self!: HTMLElement;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `type` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() inputType: IInputType = "text";
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;

  private handleInput() {
    if (this.inputElement) {
      this.value = this.inputElement.value;
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      maxlength,
      placeholder,
      inputType,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        inputType={inputType}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
        onInput={() => this.handleInput()}
      >
        <slot name="before"></slot>
        <slot name="after"></slot>
      </o-input>
    );
  }
}
