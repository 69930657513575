import { Component, h, Host, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-upload-item",
  styleUrl: "index.scss",
})
export class UploadItem {
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Desabilita o botão de remover o arquivo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Adiciona mensagem de erro
   *
   * @sbCategory Input
   */
  @Prop() errorMessage?: string;
  /**
   * Calllback chamado ao clicar no botão de download.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleDownload?: (event: MouseEvent) => void;
  /**
   * Calllback chamado ao clicar no botão de remover.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleRemove?: (event: MouseEvent) => void;

  render() {
    const { size, disabled, handleDownload, handleRemove, errorMessage } = this;

    const itemClass = {
      "o-upload-item": true,
      [`o-upload-item--size-${size}`]: true,
    };

    const buttonClass = {
      "o-upload-item__button": true,
    };

    return (
      <Host>
        <div class={itemClass}>
          <o-icon category="far" icon="fa-file-alt" size={size} />
          <o-typography
            tag="span"
            type="dark-80"
            size={size}
            class="o-upload-item__name"
          >
            <slot />
          </o-typography>
          <div class="d-flex gap-1">
            {handleRemove && (
              <button
                type="button"
                aria-label="remover"
                data-action="upload_item:botao:remover"
                data-label="remover"
                disabled={disabled}
                onClick={handleRemove}
                class={buttonClass}
              >
                <o-icon
                  category="far"
                  icon="fa-trash-alt"
                  type="dark"
                  size={size}
                />
              </button>
            )}

            {handleDownload && (
              <button
                type="button"
                aria-label="download"
                data-action="upload_item:botao:download"
                data-label="download"
                onClick={handleDownload}
                class={buttonClass}
              >
                <o-icon
                  category="far"
                  icon="fa-arrow-to-bottom"
                  type="dark"
                  size={size}
                />
              </button>
            )}
          </div>
        </div>

        {errorMessage && (
          <o-field-error size={size} visible={!!errorMessage}>
            {errorMessage}
          </o-field-error>
        )}
      </Host>
    );
  }
}
