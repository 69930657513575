import type { Moment } from "moment";
import type { PickerProps } from "rc-picker";
import type { IAspect, IDatePosition, ISize } from "../../globals/types";

export interface IOInputDate {
  aspect?: IAspect;
  error?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  picker?: IPicker;
  placeholder?: string;
  position?: IDatePosition;
  size?: ISize;
  value?: string | Moment;
  onChange?: PickerProps<Moment>["onChange"];
  onBlur?: PickerProps<Moment>["onBlur"];
  onFocus?: PickerProps<Moment>["onFocus"];
  className?: string;
  disabledDate?: PickerProps<Moment>["disabledDate"];
  children?: React.ReactNode;
}

export type IPicker = "year" | "month" | "week" | "date" | "time";
export const pickers = ["year", "month", "week", "date", "time"];

interface IDatePicker {
  [id: string]: string;
}

export const dateFormat: IDatePicker = {
  month: "MM/YYYY",
  date: "DD/MM/YYYY",
};

export const datePlaceholder: IDatePicker = {
  year: "Selecionar ano",
  month: "Selecionar mês",
  week: "Selecionar semana",
  date: "Selecionar data",
  time: "Selecionar hora",
};
