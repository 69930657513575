o-skeleton {
  .o-skeleton {
    display: none;
    flex-direction: column;

    &--visible {
      display: flex;
    }

    @each $size in $fontSizes {
      &--font-#{$size} {
        gap: var(--font-#{$size});
      }
    }
  }

  .o-skeleton-slot {
    display: none;

    &--visible {
      display: block;
    }
  }
}
