o-collapse-header {
  o-expander-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: var(--font-md) 0;
    border: 0;
    outline: 2px solid transparent;
    background: inherit;

    &:hover {
      border-color: var(--border-color-hover);
    }

    &.o-collapse-header--disabled {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    @each $size in $fontSizes {
      &.o-collapse-header--size-#{$size} {
        font-size: var(--font-#{$size});
      }
    }

    @each $type in $types {
      &.o-collapse-header--type-#{$type} {
        background-color: var(--theme-#{$type});
      }
    }
  }
}
