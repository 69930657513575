import { Component, h, Host, Prop } from "@stencil/core";
import { ISize, ITypeComplete, IWeight } from "../../globals/types";

@Component({
  tag: "o-breadcrumb-item",
  styleUrl: "index.scss",
})
export class BreadcrumbItem {
  /**
   * URL do breadcrumb.
   *
   * @sbCategory Input
   */
  @Prop() href?: string;
  /**
   * Cor do texto.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "dark";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Peso da fonte (negrito).
   *
   * @sbCategory Style
   */
  @Prop() weight: IWeight = "400";
  /**
   * Prop definida pelo `o-breadcrumb`.
   *
   * @sbHide true
   */
  @Prop() separator?: string;

  render() {
    const { href, separator, type, size, weight } = this;

    const classes = {
      "o-breadcrumb-item": true,
      [`o-breadcrumb-item--type-${type}`]: true,
      [`o-breadcrumb-item--font-${size}`]: true,
      [`o-breadcrumb-item--weight-${weight}`]: true,
      "slot-container": true,
    };

    return (
      <Host data-separator={separator}>
        <a href={href} class={classes}>
          <slot />
        </a>
      </Host>
    );
  }
}
