export type IAspect = typeof aspects[number];
export const aspects = ["outline", "flushed", "unstyled"] as const;

export type ISize = typeof sizes[number];
export const sizes = [
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "xxxl",
] as const;

export type IDatePosition = typeof datePositions[number];
export const datePositions = [
  "bottomLeft",
  "bottomRight",
  "topLeft",
  "topRight",
] as const;

interface IDatePicker {
  [id: string]: string;
}

interface IOverflow {
  adjustX: number;
  adjustY: number;
}

export interface IDropdownPositionReturn {
  points: string[];
  offset: number[];
  overflow: IOverflow;
}

interface IDropdownPosition {
  [id: string]: IDropdownPositionReturn;
}

export const dateFormat: IDatePicker = {
  month: "MM/YYYY",
  date: "DD/MM/YYYY",
};

export const datePlaceholder: IDatePicker = {
  year: "Selecionar ano",
  month: "Selecionar mês",
  week: "Selecionar semana",
  date: "Selecionar data",
  time: "Selecionar hora",
};

export const dropdownPosition: IDropdownPosition = {
  bottomLeft: {
    points: ["tl", "bl"],
    offset: [0, 4],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
  },
  bottomRight: {
    points: ["tr", "br"],
    offset: [0, 4],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
  },
  topLeft: {
    points: ["bl", "tl"],
    offset: [0, -4],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
  },
  topRight: {
    points: ["br", "tr"],
    offset: [0, -4],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
  },
};
