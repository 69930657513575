o-collapse-content {
  display: block;

  o-expander-content {
    display: block;
    padding: 0 var(--font-xxs);
    border-bottom: var(--border);

    &.o-collapse-content--disabled {
      cursor: not-allowed;
      opacity: var(--disabled-opacity);
    }

    @each $type in $types {
      &.o-collapse-content--type-#{$type} {
        background-color: var(--theme-#{$type});
      }
    }
  }
}
