import { Component, h, Prop, State } from "@stencil/core";
import { IAspect, IAutocomplete, ISize } from "../../globals/types";

@Component({
  tag: "o-input-search",
  styleUrl: "index.scss",
})
export class InputSearch {
  @State() populated = false;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder = "Faça sua busca aqui";
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Callback executado ao limpar o campo.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClear?: () => void;
  /**
   * Callback chamado ao efetuar a pesquisa.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSearch?: () => void;

  private inputElement?: HTMLInputElement;

  private handleInput() {
    this.value = this.inputElement?.value;
  }

  private handleFocus() {
    this.populated = true;
  }

  private handleBlur() {
    if (!this.inputElement?.value) {
      this.populated = false;
    }
  }

  private clear() {
    if (!this.inputElement) return;

    this.value = "";
    this.populated = false;
    this.handleClear && this.handleClear();
  }

  private searchClick() {
    this.value = this.inputElement?.value;

    if (this.handleSearch) this.handleSearch();
  }

  componentDidLoad() {
    this.inputElement?.addEventListener("input", () => this.handleInput());
    this.inputElement?.addEventListener("focus", () => this.handleFocus());
    this.inputElement?.addEventListener("blur", () => this.handleBlur());
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      maxlength,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
      populated,
    } = this;

    const before = !populated ? (
      <button
        slot="before"
        onClick={() => this.searchClick()}
        data-label="pesquisar"
        data-action="search:botao:pesquisar"
        aria-label="clique aqui para pesquisar"
      >
        <o-icon category="orq" icon="orq-tools-search" size={size} />
      </button>
    ) : (
      <button
        slot="before"
        onClick={() => this.clear()}
        data-label="limpar-pesquisa"
        data-action="search:botao:limpar-pesquisa"
        aria-label="clique aqui para limpar sua pesquisa"
      >
        <o-icon category="orq" icon="orq-arrow-left" size={size} />
      </button>
    );

    const after = populated && (
      <button
        slot="after"
        onClick={() => this.clear()}
        data-label="apagar-pesquisa"
        data-action="search:botao:apagar-pesquisa"
      >
        <o-icon category="orq" icon="orq-close" size={size} />
      </button>
    );

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType="text"
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
      >
        {before}

        {after}
      </o-input>
    );
  }
}
