import { Component, h, Prop } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-pill-tab",
  styleUrl: "index.scss",
})
export class OPillTab {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Desabilita o tab.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;
  /**
   * Callback chamado quando ocorre click na tab.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: () => void;

  render() {
    const { active, size, disabled, type, dataLabel, dataAction } = this;

    const typeActive = active ? type : "light";
    const classes = {
      "o-pill-tab": true,
      [`o-pill-tab--size-${size}`]: true,
      [`o-pill-tab--type-${typeActive}`]: true,
    };

    return (
      <button
        class={classes}
        data-label={dataLabel}
        data-action={dataAction}
        disabled={disabled}
        onClick={() => {
          if (this.handleClick) this.handleClick();
        }}
      >
        <slot />
      </button>
    );
  }
}
