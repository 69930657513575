import * as React from "react";
import Menu from "../menu/menu.component";
import PageLogo from "../../components/page-logo/page-logo.component";

export default function Sidebar() {
  return (
    <aside className="page-sidebar">
      <PageLogo />
      <nav
        id="js-primary-nav"
        className="primary-nav js-list-filter"
        role="navigation"
      >
        <Menu />
      </nav>
    </aside>
  );
}
