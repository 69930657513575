import { Component, Element, h, Listen, Prop, State } from "@stencil/core";
import { IPosition, IType } from "../../globals/types";

@Component({
  tag: "o-tooltip",
  styleUrl: "index.scss",
})
export class Tooltip {
  @Element() self!: HTMLElement;
  @State() hovering = false;

  /**
   * Cor do background da tooltip.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "quaternary";
  /**
   * Posição da tooltip.
   *
   * @sbCategory Style
   */
  @Prop() position: IPosition = "top";
  /**
   * Se a tooltip possui uma seta.
   *
   * @sbCategory Style
   */
  @Prop() arrow = true;
  /**
   * Visibilidade da tooltip.
   *
   * @sbCategory Style
   */
  @Prop() visible = false;
  /**
   * Largura máxima da tooltip. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() maxWidth = "unset";
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = false;
  /**
   * Callback de referência do elemento âncora.
   *
   * @sbCategory Ref
   */
  @Prop() anchorRef?: (el: HTMLElement) => void;
  /**
   * Callback de referência do elemento flutuante.
   *
   * @sbCategory Ref
   */
  @Prop() floatingRef?: (el: HTMLElement) => void;

  private anchorElement?: HTMLElement;
  private floatingElement?: HTMLElement;

  @Listen("focusin", { target: "window" })
  @Listen("focusout", { target: "window" })
  @Listen("mouseover", { target: "window" })
  @Listen("mouseleave", { target: "window" })
  enterHandler(e: Event) {
    this.hovering = [this.anchorElement, this.floatingElement].some(
      (el) => !!el?.contains(e.target as Node)
    );
  }

  render() {
    const { type, position, arrow, visible, hovering, maxWidth, floating } =
      this;

    return (
      <o-popover
        type={type}
        position={position}
        arrow={arrow}
        visible={visible || hovering}
        maxWidth={maxWidth}
        floating={floating}
        anchorRef={(el) => {
          this.anchorElement = el;
          this.anchorRef && this.anchorRef(el);
        }}
        floatingRef={(el) => {
          this.floatingElement = el;
          this.floatingRef && this.floatingRef(el);
        }}
      >
        <div slot="popover-content">
          <slot name="tooltip-content" />
        </div>
        <slot />
      </o-popover>
    );
  }
}
