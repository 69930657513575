import { Component, Element, h, Host, Prop } from "@stencil/core";
import { TNotificationTypes } from "./types";

const icons = {
  success: "orq-status-success",
  danger: "orq-status-disapproved",
  warning: "orq-status-warning",
  info: "orq-info",
} as const;

@Component({
  tag: "o-notification",
  styleUrl: "index.scss",
})
export class Notification {
  @Element() self!: HTMLElement;

  /**
   * Cor e qual ícone.
   *
   * @sbCategory Style
   */
  @Prop() type: TNotificationTypes = "info";
  /**
   * Largura do componente. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width = "auto";

  componentDidLoad() {
    this.self.style.width = this.width;
  }

  render() {
    const { type } = this;

    const classes = {
      "o-notification": true,
      [`o-notification--type-${type}`]: true,
    };

    return (
      <Host class={classes}>
        {type !== "default" && (
          <o-icon
            category="orq"
            icon={icons[type]}
            type={type}
            size="md"
          ></o-icon>
        )}
        <div>
          <slot />
        </div>
      </Host>
    );
  }
}
