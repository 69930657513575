import React from "react";
import { StyledContainerSpacing } from "./container-spacing.styles";

export interface IContainerSpacingProps {
  card?: boolean;
  spacingOnTop?: boolean;
  children: any;
  style?: React.CSSProperties;
  themeType?: "hub" | "portal";
  titlePage?: string;
  loading?: boolean;
  rightHeader?: React.ReactElement;
}

export function ContainerSpacing(props: IContainerSpacingProps) {
  return (
    <StyledContainerSpacing
      card={props.card}
      spacingOnTop={props.spacingOnTop}
      style={{ ...props.style }}
      themeType="portal"
    >
      {props.children}
    </StyledContainerSpacing>
  );
}
