import { Component, h, Host, Prop } from "@stencil/core";
import { IAspect, ISize } from "../../../globals/types";

@Component({
  tag: "o-select-base",
  styleUrl: "index.scss",
})
export class Select {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Atributo global do `select` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Especifica se o drop-down irá receber foco quando a página carregar.
   *
   * @sbCategory Input
   */
  @Prop() autofocus = false;
  /**
   * Desabilita interação com o componente.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Define a qual form o drop-down pertence.
   *
   * @sbCategory Input
   */
  @Prop() form?: string;
  /**
   * Especifica quais múltiplas opções podem ser selecionadas de uma vez.
   *
   * @sbCategory Input
   */
  @Prop() multiple = false;
  /**
   * Define um nome para o drop-down.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Especifica ao usuário a necessidade de selecionar um valor antes de
   * submeter o formulário.
   *
   * @sbCategory Input
   */
  @Prop() required = false;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      autofocus,
      disabled,
      form,
      multiple,
      name,
      required,
      size,
      aspect,
    } = this;

    const hostClass = {
      "o-select-base": true,
      [`o-select-base--size-${size}`]: true,
      [`o-select-base--aspect-${aspect}`]: true,
      "o-select-base--multiple": multiple,
    };

    const selectClass = {
      "o-select-base__select": true,
      [`o-select-base__select--aspect-${aspect}`]: true,
      [`o-select-base__select--size-${size}`]: true,
      "o-select-base__select--disabled": disabled,
    };

    return (
      <Host class={hostClass}>
        <select
          class={selectClass}
          data-action={dataAction}
          data-label={dataLabel}
          id={id}
          autoFocus={autofocus}
          disabled={disabled}
          form={form}
          multiple={multiple}
          name={name}
          required={required}
        >
          <slot />
        </select>
      </Host>
    );
  }
}
