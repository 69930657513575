import { Component, Element, h, Prop } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-tab-dropdown",
  styleUrl: "index.scss",
})
export class TabDropdown {
  @Element() self!: HTMLElement;

  // Dropdown Props
  /**
   * Visibilidade do componente.
   *
   * @sbCategory Input
   */
  @Prop() show = false;
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = false;

  // Tab Props
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Desabilita o tab.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;
  /**
   * Callback chamado quando ocorre click na tab.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: () => void;

  render() {
    const {
      show,
      floating,
      active,
      size,
      disabled,
      type,
      dataLabel,
      dataAction,
    } = this;

    return (
      <o-dropdown
        class="o-tab-dropdown"
        show={show}
        position-x="left"
        position-y="bottom"
        floating={floating}
      >
        <o-tab
          data-tab-dropdown="true" //this is to tab-header ignore the click event emitted
          active={active}
          size={size}
          type={type}
          dataLabel={dataLabel}
          dataAction={dataAction}
          disabled={disabled}
          onClick={() => {
            if (this.handleClick) this.handleClick();
          }}
        >
          <slot />
        </o-tab>

        <o-dropdown-list>
          <slot name="list" />
        </o-dropdown-list>
      </o-dropdown>
    );
  }
}
