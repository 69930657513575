import React, { MouseEventHandler } from "react";

interface ResumoItemProps {
  header: string;
  amount: string;
  properties: { key: string; value: string | number | undefined | null }[];
  iconClassName?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const ResumoItem = (props: ResumoItemProps) => {
  const { amount, header, properties, iconClassName, onClick } = props;
  return (
    <div className="p-4" style={{ background: "#1c65ca" }}>
      <div className="row">
        <div className="col-sm-11 text-light">
          <h3 className="font-weight-normal text-light">{header}</h3>
        </div>
        <div className="col-sm-1 text-light">
          {iconClassName && <i className={iconClassName} onClick={onClick}></i>}
        </div>
      </div>
      <h1 className="display-4" style={{ fontSize: "2rem" }}>
        {amount}
      </h1>
      {properties.map((p) => (
        <div key={p.key} className="row">
          <div className="col-sm-6 text-light">{p.key}</div>
          <div className="col-sm-6 text-right text-light">{p.value}</div>
        </div>
      ))}
    </div>
  );
};
