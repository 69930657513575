import { Component, h, Prop, State } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-progress-bar",
  styleUrl: "index.scss",
})
export class ProgressBar {
  @State() progress = "";

  /**
   * Valor do progresso.
   *
   * @sbCategory Input
   */
  @Prop() value!: number;
  /**
   * Valor máximo.
   *
   * @sbCategory Input
   */
  @Prop() max = 100;
  /**
   * Valor mínimo.
   *
   * @sbCategory Input
   */
  @Prop() min = 0;
  /**
   * Cor do indicador de progresso.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "xxs";
  /**
   * Deixa as bordas arredondadas.
   *
   * @sbCategory Style
   */
  @Prop() rounded = true;

  componentWillRender() {
    const value = this.value - this.min;
    const max = this.max - this.min;
    const progress = (value / max) * 100;
    this.progress = `${progress}%`;
  }

  render() {
    const { size, type, progress, rounded } = this;

    const classes = {
      "o-progress-bar": true,
      [`o-progress-bar--size-${size}`]: true,
      ["o-progress-bar--rounded"]: rounded,
    };
    const classBackground = {
      "o-progress-bar__background": true,
      [`o-progress-bar__background--${type}`]: true,
      "o-progress-bar__background--rounded": rounded,
    };

    return (
      <div role="progressbar" class={classes}>
        <div class={classBackground} style={{ width: progress }} />
      </div>
    );
  }
}
