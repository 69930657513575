$borderColor: var(--theme-dark);
$borderSize1: 0.375rem;
$borderSize2: calc($borderSize1 * 2);
$minusBorderSize2: calc($borderSize2 * -1);

o-input-upload {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .upload-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    // --------------------------------------------------- //
    // ----------------- ANIMATED BORDER ----------------- //
    // source: https://codepen.io/amit_sheen/pen/xxZeyjO - //
    // --------------------------------------------------- //

    background-image: repeating-linear-gradient(
        0deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        90deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        180deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        270deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      );
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 2px calc(98% + $borderSize2), calc(100% + $borderSize2) 2px,
      2px calc(100% + $borderSize2), calc(99% + $borderSize2) 2px;
    background-repeat: no-repeat;
    border-image: repeating-linear-gradient(
      0deg,
      $borderColor,
      $borderColor $borderSize1,
      transparent $borderSize1,
      transparent $borderSize2
    );

    &.is-hovering:not(.input-upload-disabled) {
      animation: 1s infinite linear boxBorderAnimation;
      background-color: var(--theme-neutral-light);

      @keyframes boxBorderAnimation {
        from {
          background-position: 0 0, $minusBorderSize2 0, 100% $minusBorderSize2,
            0 100%;
        }
        to {
          background-position: 0 $minusBorderSize2, 0 0, 100% 0,
            $minusBorderSize2 100%;
        }
      }
    }

    & label {
      margin: 0;
      cursor: pointer;
      pointer-events: none;
    }

    @each $size in $fontSizes {
      &.size-#{$size } {
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }

    &.input-upload-disabled {
      cursor: not-allowed;
      opacity: var(--disabled-opacity);
    }
  }

  // tip style
  .tip {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
    margin: var(--font-xs) 0;

    & o-icon i {
      line-height: var(--font-xl);
    }

    &.error {
      color: var(--theme-danger);
    }

    &.tip-disabled {
      cursor: unset;
      opacity: var(--disabled-opacity);
    }
  }

  // list of selected files
  .file-list {
    width: 100%;
  }

  // hidden input
  input {
    display: none;
    visibility: hidden;
  }
}
