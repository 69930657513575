import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Method,
} from "@stencil/core";
import { OUrl } from "./utils/url";

@Component({
  tag: "o-filter",
})
export class Filter {
  private readonly filterAttr = "data-filterparam";

  @Element() self!: HTMLElement;

  /** Evento emitido ao iniciar filtro. */
  @Event() init!: EventEmitter<Record<string, any>>;

  private applyChanges(hasValue?: boolean) {
    this.self
      .querySelectorAll("o-filter-item")
      .forEach((element) => element.applyChanges(hasValue));
  }

  private getFilterElements() {
    return this.self.querySelectorAll<HTMLFormElement>(`[${this.filterAttr}]`);
  }

  /**
   * Metodo para aplicar parametros do filtro na url.
   *
   * Return objeto com params do filtro
   */
  @Method()
  async filter<T extends Record<string, any>>() {
    const filterParams = {} as T;
    this.getFilterElements().forEach((element) => {
      const value = element.value;
      const filterParam = element.getAttribute(this.filterAttr) as string &
        keyof T;
      filterParams[filterParam] = value;
      OUrl.setParam(filterParam, value);
    });
    OUrl.build();
    this.applyChanges();
    return filterParams;
  }

  /** Metodo para iniciar filtro com params da url */
  @Method()
  async initFilter() {
    OUrl.builder();
    const params = OUrl.paramsToObject();
    this.getFilterElements().forEach((element) => {
      const filterParam = element.getAttribute(this.filterAttr) as string;
      element.value = params[filterParam] ?? element.value;
    });
    this.applyChanges();
    this.init.emit(params);
  }

  /**
   * Metodo para limpar filtro
   *
   * Param cleanValues Objeto com valores utilizados para limpar filtro
   *
   * Return Objeto com valores do filtro
   */
  @Method()
  async clearFilter<T extends Record<string, any>>(
    cleanValues: Partial<T> = {}
  ) {
    const filterParams = {} as T;
    this.getFilterElements().forEach((element) => {
      const filterParam = element.getAttribute(this.filterAttr) as string &
        keyof T;
      const cleanValue = cleanValues[filterParam] ?? null;
      element.value = cleanValue;
      filterParams[filterParam] = element.value;
      OUrl.setParam(filterParam, undefined);
    });
    OUrl.build();
    this.applyChanges(false);
    return filterParams;
  }

  /**
   * Metodo para indicar se tem algum valor no filtro
   *
   * Param {string[]} valuesToIgnore com valores ignorados
   *
   * Return {boolean} True se tiver valor no filtro e "false" se não tiver
   */
  @Method()
  async filterHasValue(valuesToIgnore: string[] = []) {
    return [...this.getFilterElements()].some((element) => {
      const value = OUrl.valueToString(element.value);
      return ![...OUrl.paramsToIgnore, ...valuesToIgnore].includes(value);
    });
  }

  componentDidLoad() {
    this.initFilter();
  }

  render() {
    return <slot />;
  }
}
