// transparency gradient
$gradientLength: 50px;
$fadeGradient: linear-gradient(
  90deg,
  transparent 0%,
  red $gradientLength,
  red calc(100% - $gradientLength),
  transparent 100%
);

o-tab-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;

  .o-tab-header__container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
  }

  &.o-tab-header__pagination {
    .o-tab-header__container {
      overflow-x: hidden;
      -webkit-mask-image: $fadeGradient;
      mask-image: $fadeGradient;
      -webkit-mask-position: right;
      mask-position: right;
      -webkit-mask-size: 105% 100%;
      mask-size: 105% 100%;
      transition: var(--transition-primary);
    }

    &.o-tab-header__pagination-left .o-tab-header__container {
      -webkit-mask-position: left;
      mask-position: left;
      -webkit-mask-size: 105% 100%;
      mask-size: 105% 100%;
    }

    &.o-tab-header__pagination-right .o-tab-header__container {
      -webkit-mask-position: right;
      mask-position: right;
      -webkit-mask-size: 105% 100%;
      mask-size: 105% 100%;
    }

    &.o-tab-header__pagination-left.o-tab-header__pagination-right
      .o-tab-header__container {
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
    }

    .o-tab-header__arrow {
      display: inline-block;
    }

    &.o-tab-header__pagination-left .o-tab-header__arrow-left,
    &.o-tab-header__pagination-right .o-tab-header__arrow-right {
      cursor: pointer;

      &:hover,
      &:focus {
        color: var(--theme-tertiary-hover);
      }

      &:focus-visible {
        outline: 2px solid var(--theme-focus);
      }
    }
  }

  .o-tab-header__arrow {
    padding: var(--font-xxs);
    display: none;
    border: none;
    background: none;
    cursor: default;
    color: var(--theme-dark);
    align-self: stretch;
    transition: var(--transition-primary);
  }
}
