import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
  State,
} from "@stencil/core";
import IMask from "imask";
import {
  IAspect,
  IAutocomplete,
  IChangeMaskedEvent,
  ISize,
} from "../../globals/types";

@Component({
  tag: "o-input-percentage",
})
export class InputPercentage {
  @Element() self!: HTMLElement;
  /** Evento emitido ao ocorrer input, enviando os valores com e sem máscara. */
  @Event() changeMasked!: EventEmitter<IChangeMaskedEvent>;
  @State() maskValue = "";

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   * @sbControl text
   */
  @Prop({ mutable: true }) value?: number | null;
  /**
   * Valor máximo aceito. Trava o input se for passar dele.
   *
   * @sbCategory Input
   */
  @Prop() max?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;

  /** Avoid weird float division problems */
  private divideStringBy100(value: number) {
    const str = value.toFixed(2);
    const dotIndex = str.indexOf(".");
    const divided = ["0", "0", ...str.replace(".", "")];
    divided.splice(dotIndex, 0, ".");
    return Number(divided.join(""));
  }

  private formatInput(value: string) {
    const digits = value.match(/\d/g) || ["0"];

    while (digits.length <= 2) {
      digits.unshift("0");
    }

    digits.splice(digits.length - 2, 0, ".");

    return Number(digits.join(""));
  }

  private masker = IMask.createPipe({
    mask: [
      {
        mask: "",
      },
      {
        mask: "num%",
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ".",
            padFractionalZeros: true,
            mapToRadix: [","],
          },
        },
      },
    ],
  });

  private handleInput() {
    if (!this.inputElement) return;

    const value = this.inputElement.value;
    const newInput = this.formatInput(value);

    // block if greater than max
    if (this.max && newInput > this.max) {
      this.inputElement.value = this.maskValue;
      return;
    }
    const valueMasked = this.masker(newInput.toFixed(2).replace(".", ","));

    this.value = this.divideStringBy100(newInput);
    this.inputElement.value = valueMasked;
    this.maskValue = valueMasked;

    // make backspace easier
    if (this.maskValue.includes("%")) {
      const position = this.maskValue.length - 1;

      this.inputElement.setSelectionRange(position, position);
    }

    this.changeMasked.emit({
      value: String(valueMasked),
      unmaskedValue: String(newInput),
    });
  }

  componentWillRender() {
    this.maskValue = this.masker(
      ((this.value ?? 0) * 100).toFixed(2).replace(".", ",")
    );
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      maskValue,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType="tel"
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={maskValue}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
        onInput={() => this.handleInput()}
      />
    );
  }
}
