import { Component, Element, h, Host, Listen, Prop } from "@stencil/core";
import { keyboardNavigationEventHandler } from "../../../globals/listbox-keyboard-navigation";
import { IDesiredPosition } from "../../../globals/types";

const opposite = {
  top: "bottom",
  left: "right",
  bottom: "top",
  right: "left",
} as const;

@Component({
  tag: "o-dropdown",
  styleUrl: "index.scss",
})
export class Dropdown {
  @Element() self!: HTMLElement;
  /**
   * Visibilidade do componente.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) show = false;
  /**
   * Posição horizontal.
   *
   * @sbCategory Style
   */
  @Prop() positionX: "left" | "right" = "right";
  /**
   * Posição vertical.
   *
   * @sbCategory Style
   */
  @Prop() positionY: "top" | "bottom" = "bottom";
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = false;

  /** Reference to floating element */
  private floatingElement?: HTMLElement;

  private calculateDesiredPosition(
    anchorElement: HTMLElement,
    floatingElement: HTMLElement
  ) {
    const desiredPosition: IDesiredPosition = {
      top: undefined,
      left: undefined,
      bottom: undefined,
      right: undefined,
    };

    if (!this.floating) {
      desiredPosition[opposite[this.positionY]] = "100%";
      desiredPosition[opposite[this.positionX]] = 0;
    } else {
      const floatingPosition = floatingElement.getBoundingClientRect();
      const anchorPosition = anchorElement.getBoundingClientRect();
      let top = anchorPosition.top + window.scrollY;
      let left = anchorPosition.left + window.scrollX;

      if (this.positionY === "top") {
        top = top - floatingPosition.height;
      } else {
        top = top + anchorPosition.height;
      }

      if (this.positionX === "left") {
        left = left - floatingPosition.width + anchorPosition.width;
      }

      desiredPosition.top = top;
      desiredPosition.left = left;
    }

    return desiredPosition;
  }

  @Listen("click", { target: "document" })
  handleClick(event: MouseEvent) {
    const dropdownList = this.floatingElement?.querySelector("o-dropdown-list");

    this.show =
      this.self.contains(event.target as Node) &&
      !dropdownList?.contains(event.target as Node);
  }

  // listbox navigation with keyboard
  @Listen("keydown", { target: "document" })
  keyDownHandler(e: KeyboardEvent) {
    const dropdownList = this.floatingElement?.querySelector("o-dropdown-list");

    // return if this o-dropdown is not focused
    if (
      !dropdownList ||
      !(
        this.self.contains(document.activeElement) ||
        dropdownList.contains(document.activeElement)
      )
    ) {
      return;
    }

    keyboardNavigationEventHandler({
      event: e,
      listbox: dropdownList,
      listboxVisible: this.show,
      setListboxVisibility: (newActive) => {
        this.show = newActive;
      },
    });
  }

  render() {
    return (
      <Host class="o-dropdown">
        <o-floater
          visible={this.show}
          floating={this.floating}
          calculateDesiredPosition={(
            anchorElement: HTMLElement,
            floatingElement: HTMLElement
          ) => this.calculateDesiredPosition(anchorElement, floatingElement)}
          floatingRef={(el) => (this.floatingElement = el)}
        >
          <slot />
        </o-floater>
      </Host>
    );
  }
}
