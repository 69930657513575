import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Listen,
  Prop,
} from "@stencil/core";
import { OUploadLabelCustomEvent } from "../../components";

@Component({
  tag: "o-upload",
  styleUrl: "index.scss",
})
export class Upload {
  @Element() self!: HTMLElement;
  /** Evento emitido ao adicionar um arquivo. */
  @Event() addFile!: EventEmitter<File>;

  /**
   * Valor atual do Input. É um array contendo os arquivos.
   *
   * @sbCategory Input
   * @sbControl false
   */
  @Prop({ mutable: true }) value?: File[] = [];

  private registerValue(files: File[]) {
    this.value = [...(this.value ?? []), ...files];

    files.forEach((file) => {
      this.addFile.emit(file);
    });
  }

  /** When the user dropped the dragged files on the component */
  @Listen("droppedFiles")
  droppedFilesHandler(event: OUploadLabelCustomEvent<Array<File>>) {
    this.registerValue(event.detail);
  }

  /** Fired when user clicks on input (instead of drag and drop) */
  @Listen("input")
  inputFilesHandler(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target?.files) return;

    const files = [...target.files];

    this.registerValue(files);
  }

  render() {
    return <slot />;
  }
}
