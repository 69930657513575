// create arrow in tooltip and popover
@mixin arrow($position) {
  // @if $position == "top" {
  $opposite: "bottom";
  $transf: translate(-50%, -100%);
  @if $position == "right" {
    $opposite: "left";
    $transf: translate(0, -50%);
  } @else if $position == "bottom" {
    $opposite: "top";
    $transf: translate(-50%, 0);
  } @else if $position == "left" {
    $opposite: "right";
    $transf: translate(-100%, -50%);
  }

  &::before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    width: 0;
    border-#{$opposite}: 0;
    border-#{$position}: 10px solid var(--theme);
    transform: $transf;
  }
}

o-popover {
  font-family: inherit;

  .o-popover {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
}

.o-popover__content {
  position: relative;
  width: max-content;
  text-align: center;
  background: var(--theme);
  color: var(--theme-inverse);
  border-radius: var(--border-radius-xxs);
  cursor: default;

  & > div {
    padding: 0 var(--font-md);
    font-size: var(--font-md);
    line-height: calc(var(--font-sm) * var(--base-height));
  }

  &.close-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;

    & > div {
      padding-right: 0;
    }
  }

  @each $type in $types {
    &.o-popover--#{$type},
    &.o-popover--#{$type} ~ .o-popover__arrow {
      --theme: var(--theme-#{$type});
      --theme-inverse: var(--theme-#{$type}-inverse);
    }
  }
}

.o-popover__arrow {
  position: absolute;

  &.top {
    @include arrow("top");
  }
  &.right {
    @include arrow("right");
  }
  &.bottom {
    @include arrow("bottom");
  }
  &.left {
    @include arrow("left");
  }
}
