export const styles = {
  grid: {
    margin: 0,
    padding: 0,
  },
  itemIcon: {
    margin: "0 4px",
  },
  card: {
    marginBottom: "16px",
    padding: "8px 0px",
    backgroundColor: "#195ab4",
    color: "#ffffff",
  },
  typography: {
    margin: "4px",
    color: "#6e6e6e",
  },
  list: {
    display: "flex",
    justifyContent: "space-around",
  },
  hr: {
    borderRight: "1px solid #6e6e6e",
    height: "auto",
    margin: "0 2px 0 8px",
  },
};
