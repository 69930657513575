import { Component, h, Prop, State } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";
import { strokeSizeMap } from "./types";

@Component({
  tag: "o-progress-circular",
  styleUrl: "index.scss",
})
export class ProgressCircular {
  @State() progress = "";

  /**
   * Valor do progresso.
   *
   * @sbCategory Input
   */
  @Prop() value!: number;
  /**
   * Valor máximo.
   *
   * @sbCategory Input
   */
  @Prop() max = 100;
  /**
   * Valor mínimo.
   *
   * @sbCategory Input
   */
  @Prop() min = 0;
  /**
   * Cor do indicador de progresso.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Espessura do círculo.
   *
   * @sbCategory Style
   */
  @Prop() strokeSize: ISize = "xxs";
  /**
   * Posição do texto.
   *
   * @sbCategory Style
   */
  @Prop() position: "inside" | "outside" = "inside";

  componentWillRender() {
    const defaultStroke = 264;
    const value = this.value - this.min;
    const max = this.max - this.min;
    const percent = value / max;
    const progress = defaultStroke * percent;
    this.progress = `${progress} ${defaultStroke - progress}`;
  }

  render() {
    const { progress, type, size, position, strokeSize } = this;

    const classes = `o-progress o-progress--size-${size}`;
    const classBackground = `o-progress__circular--background o-progress__circular--background--${type}`;
    const classContent = `slot-container o-progress__content ${position}`;

    return (
      <div class={classes}>
        <svg viewBox="-3.5 -3.5 110 110" class="o-progress__circular">
          <circle
            cx="52"
            cy="52"
            r="42"
            stroke-width={strokeSizeMap[strokeSize]}
            class="o-progress__circular--track"
          />
          <circle
            cx="52"
            cy="52"
            r="42"
            stroke-width={strokeSizeMap[strokeSize]}
            class={classBackground}
            stroke-dashoffset="66"
            stroke-dasharray={progress}
          />
        </svg>

        <div class={classContent}>
          <slot />
        </div>
      </div>
    );
  }
}
