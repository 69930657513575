o-select-base {
  &.o-select-base {
    position: relative;
    width: 100%;

    &::after {
      font-family: "Font Awesome 5 Pro";
      content: "\f107";
      font-weight: 400;
      position: absolute;
      right: var(--font-md);
      bottom: 0;
      align-self: center;
    }

    &--aspect {
      &-flushed,
      &-unstyled {
        &::after {
          right: var(--font-xxs);
        }
      }
    }

    &--multiple {
      &::after {
        display: none;
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
      }
    }

    .o-select-base__select {
      appearance: none;
      width: 100%;
      border: 0;
      transition: var(--transition-primary);
      background: inherit;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
      }

      &--aspect {
        &-flushed {
          border-bottom: var(--border);
          padding: calc((var(--font-xs) * var(--base-height)) / 2)
            var(--font-xxs);
        }

        &-outline {
          border: var(--border);
          border-radius: var(--border-radius-xxs);
          padding: calc((var(--font-xs) * var(--base-height)) / 2)
            var(--font-md);
        }

        &-unstyled {
          border: 0;
          border-radius: var(--border-radius-xxs);
          padding: calc((var(--font-xs) * var(--base-height)) / 2)
            var(--font-xxs);
        }

        &-flushed,
        &-outline {
          &:hover {
            border-color: var(--theme-neutral-normal);
          }

          &:focus {
            border-color: var(--theme-focus);
          }
        }
      }

      @each $size in $fontSizes {
        &--size-#{$size} {
          font-size: var(--font-#{$size});
        }
      }
    }
  }
}
