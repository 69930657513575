import {
  Component,
  Element,
  h,
  Listen,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import type { OExpanderHeaderCustomEvent } from "../../components";
import { ICollapseInteractionDetail } from "../expander/types";

@Component({
  tag: "o-accordion",
})
export class Accordion {
  @Element() self!: HTMLElement;
  @State() selected?: HTMLOExpanderHeaderElement;

  /**
   * ID do _collapse_ que inicia aberto.
   *
   * @sbCategory Input
   */
  @Prop() initialId?: string;

  @Listen("collapseInteraction")
  handleCollapseInteraction(
    event: OExpanderHeaderCustomEvent<ICollapseInteractionDetail>
  ) {
    if (event.detail.expanded === false) {
      this.selected = undefined;
    } else {
      this.selected = event.target;
    }
  }

  @Watch("selected")
  handleSelected(newSelected: HTMLOExpanderHeaderElement) {
    this.self.querySelectorAll("o-expander-header").forEach((el) => {
      if (!el.isEqualNode(newSelected) && el.parentElement?.parentElement)
        (el.parentElement.parentElement as HTMLOCollapseElement).expanded =
          false;
    });
  }

  componentDidLoad() {
    if (this.initialId) {
      const initialCollapse = this.self.querySelector<HTMLOCollapseElement>(
        `#${this.initialId}`
      );
      if (initialCollapse) initialCollapse.expanded = true;
    }
  }

  render() {
    return <slot />;
  }
}
