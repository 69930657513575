import { Component, h, Listen, Prop, State } from "@stencil/core";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-slider",
  styleUrl: "index.scss",
})
export class Slider {
  @State() progress = "";

  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: number;
  /**
   * Valor máximo.
   *
   * @sbCategory Input
   */
  @Prop() max = 100;
  /**
   * Valor mínimo.
   *
   * @sbCategory Input
   */
  @Prop() min = 0;
  /**
   * Intervalo entre os valores selecionáveis.
   *
   * @sbCategory Input
   */
  @Prop() step = 1;
  /**
   * Cor do slider.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "sm";

  private inputElement?: HTMLInputElement;

  componentWillRender() {
    if (!this.value) this.value = this.min;

    const value = this.value - this.min;
    const track = this.max - this.min;
    const progress = (value / track) * 100;
    this.progress = `${progress}%`;
  }

  @Listen("input")
  handleInput() {
    if (!this.inputElement) return;

    const value = Number(this.inputElement.value);

    if (value > this.max) {
      this.value = this.max;
    } else if (value < this.min) {
      this.value = this.min;
    } else {
      this.value = value;
    }
  }

  render() {
    const { id, name, value, min, max, step, type, progress, size } = this;

    const classes = `o-slider o-slider--size-${size}`;
    const classBackground = `o-slider__background o-slider__background--${type}`;
    const classRangeBackground = `o-slider__range o-slider__range--background-${type} o-slider__range--size-${size}`;

    return (
      <div class={classes}>
        <input
          ref={(el) => (this.inputElement = el)}
          type="range"
          id={`o-${id}`}
          name={name}
          value={value}
          min={min}
          max={max}
          step={step}
          class={classRangeBackground}
        />
        <div class={classBackground} style={{ width: progress }} />
      </div>
    );
  }
}
