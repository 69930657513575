import React, { FC } from "react";
import { modalStyles } from "./modal.styles";
import { GroupButton } from "../group-button/group-button.component";
import { StyledText } from "../text";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import ModalMaterialUI from "@material-ui/core/Modal/Modal";
import { Spinner } from "components/componentsv2/spinner/spinner.component";

export type ModalType = {
  opened: boolean;
  title: string;
  subtitle: string;
  action?: Function;
  loading?: boolean;
  actionText?: string;
  closeText?: string;
  closeX?: boolean;
  onClose?: Function;
  wide?: boolean;
  width?: string;
};

export const Modal: FC<ModalType> = (props) => {
  const {
    opened,
    title,
    subtitle,
    action,
    actionText,
    closeText,
    closeX,
    onClose,
    children,
    loading,
    wide,
    width,
  } = props;

  return (
    <ModalMaterialUI open={opened} style={modalStyles.modal}>
      <Grid
        container
        alignItems="center"
        style={{
          ...modalStyles.container,
          maxWidth: wide ? "1000px" : "800px",
        }}
      >
        <div className="w-100 d-flex flex-row justify-content-end">
          <Button onClick={() => onClose!()}>{closeX ? "X" : ""}</Button>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{
            marginBottom: 20,
            marginTop: -35,
            width: width,
          }}
        >
          <StyledText type="heading">{title}</StyledText>
        </div>
        <div style={{ marginBottom: 20, width: width }}>
          {children || <StyledText type="subheading">{subtitle}</StyledText>}
        </div>
        <GroupButton space="right">
          <Button disabled={loading} onClick={() => onClose!()}>
            {closeText ?? "Cancelar"}
          </Button>
          {action && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              style={modalStyles.acceptButton}
              onClick={() => action()}
            >
              {loading ? <Spinner size="20px" /> : actionText}
            </Button>
          )}
        </GroupButton>
      </Grid>
    </ModalMaterialUI>
  );
};
