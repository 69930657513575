import VMasker from "vanilla-masker";

export function cpfCnpjMask(value: string | undefined) {
  if (!value) {
    return;
  }

  value = value?.replace(/\D/g, "");

  if (value.length <= 11) {
    return VMasker.toPattern(value, "999.999.999-99");
  } else if (value?.length === 14) {
    return VMasker.toPattern(value, "99.999.999/9999-99");
  } else if (value.length > 14) {
    const cnpj = value?.slice(0, 14);
    return VMasker.toPattern(cnpj, "99.999.999/9999-99");
  }

  return value;
}
