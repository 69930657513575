import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-list-item",
})
export class ListItem {
  /**
   * Indica o valor ordinal atual do item na lista.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/li#attr-value).
   *
   * @sbCategory Input
   */
  @Prop() value?: number;
  /**
   * Estilo do marcador do item da lista.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type).
   *
   * @sbCategory Style
   */
  @Prop() defaultType?: string;

  render() {
    const { defaultType, value } = this;

    return (
      <li
        value={value}
        style={{ listStyleType: defaultType }}
        class="slot-container"
      >
        <slot />
      </li>
    );
  }
}
