import { E2EElement, E2EPage } from "@stencil/core/testing";

/**
 * Returns size of css variable in px
 *
 * @param variable String value of variable. Example: `--my-variable`
 */
export const getCssVariableValue = (variable: string) => {
  const dummy = document.createElement("div");

  dummy.style.width = `var(${variable})`;
  dummy.style.visibility = "hidden";
  document.body.appendChild(dummy);
  const size = window.getComputedStyle(dummy).getPropertyValue("width");
  dummy.remove();

  return Number([...(size.match(/[0-9.]/g) ?? [])].join(""));
};

/**
 * Unit test
 *
 * Checks if the attribute was passed to the child
 */
export const verifyChildAttribute = async (
  element: E2EElement,
  page: E2EPage,
  searchTag: string,
  hostAtt: string,
  childAtt: string,
  data: string | boolean
) => {
  await element.setAttribute(hostAtt, data);
  await page.waitForChanges();

  return await page.evaluate(
    (childAtt: string, searchTag: string) => {
      //@ts-ignore
      const hasType = document.querySelector(searchTag)?.[`${childAtt}`];
      const hasAtt = document.querySelector(searchTag)?.getAttribute(childAtt);

      return hasAtt || hasType;
    },
    childAtt,
    searchTag
  );
};
