import { Component, Element, h, Prop } from "@stencil/core";
import { ISize, ITypeComplete } from "../../globals/types";

@Component({
  tag: "o-field",
  styleUrl: "index.scss",
})
export class Fieldset {
  @Element() self!: HTMLElement;

  /**
   * Adiciona um `*` indicando que o campo é obrigatório.
   *
   * @sbCategory Input
   */
  @Prop() required = false;
  /**
   * Adiciona uma descrição para o input.
   *
   * @sbCategory Input
   */
  @Prop() description?: string;
  /**
   * Desabilita o fieldset.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Texto do label.
   *
   * @sbCategory Input
   */
  @Prop() label?: string;
  /**
   * Atributo `for` do label. Referencia o id de um input.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for).
   *
   * @sbCategory Input
   */
  @Prop() htmlFor?: string;
  /**
   * Cor do label.
   *
   * @sbCategory Style
   */
  @Prop() labelType?: ITypeComplete = "dark-80";
  /**
   * Tamanho da fonte do label.
   *
   * @sbCategory Style
   */
  @Prop() labelSize: ISize = "sm";
  /**
   * Texto da mensagem do erro.
   *
   * @sbCategory Input
   */
  @Prop() message?: string;
  /**
   * Define se será mostrado a mensagem de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  componentDidRender() {
    if (this.error) {
      this.self.querySelector("o-input")?.setAttribute("error", "true");
    }
  }

  render() {
    const {
      description,
      error,
      htmlFor,
      label,
      labelSize,
      labelType,
      message,
      required,
      disabled,
    } = this;

    return (
      <fieldset class="o-field" disabled={disabled}>
        <div class="d-flex align-items-center gap-1">
          {label && htmlFor && (
            <o-label
              htmlFor={htmlFor}
              type={labelType}
              size={labelSize}
              required={required}
              disabled={!!disabled}
            >
              {label}
            </o-label>
          )}

          <slot name="tooltip" />
        </div>

        <slot />

        <div class="d-flex justify-content-between align-items-center">
          {!error && description && (
            <o-typography class="description" size="sm">
              {description}
            </o-typography>
          )}

          <o-field-error size="sm" visible={error}>
            {message}
          </o-field-error>

          <slot name="progress" />
        </div>
      </fieldset>
    );
  }
}
