import { Component, h, Host, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-banner-content",
  styleUrl: "index.scss",
})
export class BannerContent {
  /**
   * Atributo do título.
   *
   * @sbCategory Input
   */
  @Prop() title!: string;
  /**
   * Atributo do título.
   *
   * @sbCategory Input
   */
  @Prop() subtitle = "";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "sm";
  /**
   * Para monitores maiores que 1400px, limita o tamanho do content em 1100px
   *
   * @sbCategory Style
   */
  @Prop() limitedSize = false;

  render() {
    const { title, subtitle, size, limitedSize } = this;

    const hostClass = {
      [`size-${size}`]: true,
      "limited-size": limitedSize,
    };

    return (
      <Host class={hostClass}>
        <o-typography tag="h1" size={size} class="mb-2">
          {title}
        </o-typography>

        {subtitle && (
          <o-typography tag="h3" size={size} class="subtitle mb-2">
            {subtitle}
          </o-typography>
        )}

        <o-typography tag="p" size={size} class="description">
          <slot />
        </o-typography>
      </Host>
    );
  }
}
