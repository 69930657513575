$gapIcon: 0.75rem;

.o-select {
  font-family: inherit;
  position: relative;
  display: flex;
  width: 100%;

  @each $type in $types {
    &--type-#{$type} {
      background-color: var(--theme-#{$type});
    }
  }
}

.o-select {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $gapIcon;
    width: 100%;
    padding: 0 var(--font-xs);
    border: 0;
    text-align: left;
    transition: var(--transition-primary);
    background: inherit;
    user-select: none;
    cursor: pointer;

    &:hover {
      border-color: var(--border-color-hover);
    }

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & o-icon {
      transition: var(--transition-primary);
      transform: rotate(0deg);
    }

    &[data-active] o-icon {
      transform: rotate(180deg);
    }

    &--disabled {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    &--aspect {
      &-flushed {
        border-bottom: var(--border);
      }

      &-outline {
        border: var(--border);
        border-radius: var(--border-radius-xxs);
        padding: 0 var(--font-md);
      }

      &-flushed,
      &-outline {
        &:hover {
          border-color: var(--theme-neutral-normal);
        }

        &:focus {
          border-color: var(--theme-focus);
        }
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }

  &__option {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    height: 0;
    opacity: 0;
    z-index: 9999;
    background: inherit;
    transition: var(--transition-primary);
    overflow-y: auto;
    max-height: 12.5rem;
    margin-top: var(--font-xxs);

    @include scrollbar();

    &[data-active] {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }
}
