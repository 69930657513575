import { dateFormat, datePlaceholder, IPicker } from "./types";

export function getPlaceholder(picker: IPicker, placeholder?: string) {
  if (placeholder) return placeholder;

  return datePlaceholder[picker];
}

export function formatPattern(picker: IPicker) {
  if (!dateFormat[picker]) return "";

  return dateFormat[picker];
}
