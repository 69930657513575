import { Component, h, Prop } from "@stencil/core";
import { ITypeComplete, ISize, IWeight } from "../../globals/types";

@Component({
  tag: "o-label",
  styleUrl: "index.scss",
})
export class Label {
  /**
   * Atributo `for` do label. Referencia o id de um input.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for).
   *
   * @sbCategory Input
   */
  @Prop() htmlFor!: string;
  /**
   * Adiciona um `*` indicando que o campo é obrigatório.
   *
   * @sbCategory Input
   */
  @Prop() required = false;
  /**
   * Cor do texto.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete | null = null;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Peso da fonte (negrito).
   *
   * @sbCategory Style
   */
  @Prop() weight: IWeight = "normal";
  /**
   * Estiliza o componente como desabilitado.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;

  render() {
    const { htmlFor, disabled, type, size, weight, required } = this;

    const classes = {
      "o-label": true,
      "o-label--disabled": disabled,
      [`o-label--type-${type}`]: !!type,
      [`o-label--font-${size}`]: true,
      [`o-label--weight-${weight}`]: true,
      "slot-container": true,
    };

    return (
      <label htmlFor={`o-${htmlFor}`} class={classes}>
        {required && "*"}
        <slot />
      </label>
    );
  }
}
