o-radio, o-radio-base {
  display: flex;
  align-items: center;
  line-height: 0.5;

  .o-radio__input {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    background: transparent;
    color: transparent;
    border-radius: 50%;
    place-content: center;
    cursor: pointer;

    &:disabled {
      border: 1px solid var(--theme-dark-15);
      background-color: var(--theme-dark-05);
      cursor: not-allowed;

      &::before {
        background-color: var(--theme-dark-20);
      }
    }

    &::before {
      content: "";
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: var(--transition-primary);
    }

    @each $type in $types {
      &--type-#{$type} {
        border: 1px solid var(--theme-#{$type});

        &::before {
          background: var(--theme-#{$type});
        }

        &:hover {
          border: 1px solid var(--theme-#{$type}-hover);

          &::before {
            background: var(--theme-#{$type}-hover);
          }
        }

        &:disabled {
          &:hover {
            border: 1px solid var(--theme-dark-15);
          }
        }
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        width: calc(var(--font-#{$size}) * var(--base-height));
        height: calc(var(--font-#{$size}) * var(--base-height));

        &::before {
          width: calc(calc(var(--font-#{$size}) * var(--base-height)) / 3);
          height: calc(calc(var(--font-#{$size}) * var(--base-height)) / 3);
        }

        &:checked::before {
          opacity: 1;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}
