o-option-multiple {
  .o-option-multiple {
    font-family: inherit;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--font-md);
    user-select: none;
    text-align: left;
    width: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: var(--theme-neutral-normal);
    cursor: pointer;

    &:hover {
      background-color: var(--theme-dark-10);
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    & div {
      /* slot width needs to be 100% minus the checkbox size */
      width: calc(100% - var(--font-xxl));
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & span {
      height: var(--font-xl);
      width: var(--font-xl);

      &::after {
        content: "";
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: var(--font-md);
        border: var(--border);
        border-color: var(--theme-tertiary);
        border-radius: var(--border-radius-xxs);
        color: var(--theme-dark);
        background-color: var(--theme-light);
        height: inherit;
        width: inherit;
      }
    }

    &--selected {
      background-color: var(--theme-tertiary);
      color: var(--theme-tertiary-inverse);

      &:hover {
        background-color: var(--theme-tertiary);
      }

      & span::after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        background-color: var(--theme-info-light);
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        line-height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
