$modalZIndex: 3000;
$modalSizes: (
  //300px
  "xxs": 18.75rem,
  //500px
  "xs": 31.25rem,
  //700px
  "sm": 43.75rem,
  //900px
  "md": 56.25rem,
  //1100px
  "lg": 68.75rem,
  //1300px
  "xl": 81.25rem,
  //1500px
  "xxl": 93.75rem,
  //1700px
  "xxxl": 106.25rem
);

o-modal {
  .o-modal {
    font-family: inherit;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: var(--theme-default-40);
    transition: var(--transition-secondary);
    z-index: $modalZIndex;
    pointer-events: none;

    &--show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    &__dialog {
      position: fixed;
      max-width: unset;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;

      &--position {
        &-left {
          top: 0;
          left: 0;
        }

        &-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-right {
          top: 0;
          right: 0;
        }
      }

      @include breakpoint-up(large) {

        @each $name,
        $size in $modalSizes {
          &--size-#{$name} {
            max-width: #{$size};
          }
        }
      }
    }

    &__content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-width: unset;
      max-height: 100%;
      background-color: var(--theme-light);
      box-shadow: 0 0 20px 0 var(--theme-dark-20);
      opacity: 0;
      overflow-y: auto;
      transform: translateY(-100%);
      transition: var(--transition-secondary);

      @include scrollbar();

      @each $type in $types {
        &--type-#{$type} {
          color: var(--theme-#{$type}-inverse);
          background-color: var(--theme-#{$type});

          .o-modal-footer {
            background-color: var(--theme-#{$type});
          }

          .o-modal-header .o-button {
            color: var(--theme-#{$type}-inverse);
          }
        }
      }

      &--show {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoint-up(large) {
        &--position-left {
          transform: translateX(-100%);

          &.rounded {
            border-radius: 0 var(--border-radius-xxs) var(--border-radius-xxs) 0;
          }
        }

        &--position-center {
          height: unset;
          max-height: 95%;
          transform: none;

          &.rounded {
            border-radius: var(--border-radius-xxs);
          }
        }

        &--position-right {
          transform: translateX(100%);

          &.rounded {
            border-radius: var(--border-radius-xxs) 0 0 var(--border-radius-xxs);
          }
        }

        &--show {
          transform: translateX(0);
        }

        @each $name,
        $size in $modalSizes {
          &--size-#{$name} {
            max-width: #{$size};
          }
        }
      }
    }
  }
}