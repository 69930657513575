const key1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
const key2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

/**
 * Exemplos corretos: 00000000000191 | 00.000.000/0001-91 Source:
 * https://www.macoratti.net/alg_cnpj.htm
 */

/** Applies a CNPJ validator to the argument string */
export const cnpj = (value: string | undefined | null): value is string => {
  if (typeof value !== "string") return false;

  const cleanValue = value.replace(/[^\d]+/g, "");

  const regexWithMask = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}$/;
  const regexWithoutMask = /^[0-9]{14}$/;
  const regexSameDigits = /^(\d)\1{13}$/;

  if (
    (!regexWithMask.test(value) && !regexWithoutMask.test(value)) ||
    regexSameDigits.test(cleanValue)
  )
    return false;

  const allDigits = cleanValue.split("").map(Number);

  // 1st verifying digit
  const digits1 = allDigits.slice(0, key1.length);

  const sum1 = digits1
    .map((_digit, i) => digits1[i] * key1[i])
    .reduce((m, n) => m + n);

  const mod1 = sum1 % 11;
  const verify1 = mod1 < 2 ? 0 : 11 - mod1;

  // 2nd verifying digit
  const digits2 = [...digits1, verify1];

  const sum2 = digits2
    .map((_digit, i) => digits2[i] * key2[i])
    .reduce((m, n) => m + n);

  const mod2 = sum2 % 11;
  const verify2 = mod2 < 2 ? 0 : 11 - mod2;

  return allDigits[12] === verify1 && allDigits[13] === verify2;
};
