$uploadItemHeight: 3;
$gapFileName: 0.75rem;

o-file-item {
  .file-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: var(--font-md);
    padding-right: var(--font-md);
    margin-top: var(--font-md);
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius-xxs);

    &__name {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: $gapFileName;
      font-size: var(--font-md);
    }

    &.o-card--outline-dark {
      background-color: var(--theme-light);
      border-color: var(--theme-neutral-medium);
    }
  }

  o-field-error {
    display: block;
    padding-left: var(--font-md);
  }
}
