import { createGlobalStyle } from "styled-components";
import { sizes } from "../../globals/types";

const iconSize = 1.5;
const tableTitleSize = 0.9;

function createSizes() {
  const styles = sizes.map((size: string) => {
    return `.o-input-date-dropdown--size-${size},
    .o-input-date-input--size-${size} > .o-input-date-input > input,
    .o-input-date-input--size-${size} > .o-input-date-range-separator,
    .o-input-date-dropdown--size-${size} .o-input-date-header-view > button {
      font-size: var(--font-${size});
      line-height: calc(var(--font-${size}) * var(--base-height));
    }

    .o-input-date-dropdown--size-${size} .o-input-date-ok > button {
      font-size: var(--font-${size});
      height: calc(var(--font-${size}) * var(--base-height));
    }

    .o-input-date-dropdown--size-${size} th {
      font-size: calc(var(--font-${size}) * ${tableTitleSize});
      width: calc(var(--font-${size}) * var(--base-height));
    }

    .o-input-date-dropdown--size-${size} td > div {
      font-size: var(--font-${size});
    }

    .o-input-date-dropdown--size-${size} {
      .o-input-date-header-super-prev-btn,
      .o-input-date-header-prev-btn,
      .o-input-date-header-next-btn,
      .o-input-date-header-super-next-btn {
        > o-icon > i {
          font-size: calc(var(--font-${size}) * ${iconSize});
        }
      }
    }`;
  });

  return styles.join(" ");
}

export const GlobalStyle = createGlobalStyle`
  .o-input-date {
    display: inline-flex;
    width: 100%;
    align-items: center;

    // ======================== Input =========================
    &-input {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;

      > input {
        box-sizing: border-box;
        width: 100%;
        padding: 0;
        border: 0;
        outline: 2px solid transparent;
        background: inherit;
        transition: var(--transition-primary);

        &:disabled {
          opacity: var(--disabled-opacity);
          cursor: not-allowed;
        }

        &:hover {
          border-color: var(--border-color-hover);
        }
      }
    }

    & .o-input-date-clear {
      opacity: 1;
      transition: var(--transition-primary);
    }

    & .o-input-date-suffix:nth-last-child(2) {
      opacity: 0;
      transition: var(--transition-primary);
    }

    &-suffix {
      position: absolute;
      right: 0;
    }

    &-clear {
      position: absolute;
      right: 0;
      opacity: 0;
      transform: translateX(-6%);
      transition: var(--transition-primary);
      cursor: pointer;

      &:focus {
        color: unset;
      }
    }

    // ======================= Dropdown =======================
    &-dropdown {
      position: absolute;
      z-index: 3000;
      padding-top: var(--font-xxs);

      &-hidden {
        display: none;
      }
    }

    // ===================== Shared Panel =====================
    &-decade-panel,
    &-year-panel,
    &-month-panel,
    &-week-panel,
    &-date-panel,
    &-time-panel {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      table {
        text-align: center;
        border-collapse: collapse;

        th {
          color: var(--theme-dark-80);
          font-weight: 400;
        }
      }
    }

    // Panel
    &-panel {
      display: inline-block;
      vertical-align: top;
      padding: var(--font-md);
      box-shadow: 0 0 1.25rem 0 var(--theme-dark-20);
      background-color: var(--theme-light);
      border-radius: var(--border-radius-xs);
    }

    // Panels
    &-panels {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      padding: var(--font-md);
      box-shadow: 0 0 1.25rem 0 var(--theme-dark-20);
      background-color: var(--theme-light);
      border-radius: var(--border-radius-xs);

      > .o-input-date-panel-has-range .o-input-date-cell-in-range,
      > .o-input-date-panel-has-range .o-input-date-cell-range,
      > .o-input-date-panel-has-range-hover .o-input-date-cell-range-hover {
        &-start,
        &:first-child {
          border-radius: 50% 0 0 50%;
        }

        &-end,
        &:last-child {
          border-radius: 0 50% 50% 0;
        }
      }

      > .o-input-date-panel-has-range .o-input-date-cell-in-range {
        &:not(:first-child):not(:last-child) {
          border-radius: 0;
        }
      }

      > .o-input-date-panel-has-range-hover .o-input-date-cell-range-hover,
      > .o-input-date-panel-has-range-hover .o-input-date-cell-range-hover-start,
      > .o-input-date-panel-has-range-hover .o-input-date-cell-range-hover-end {
        background: var(--theme-dark-05);
      }

      > .o-input-date-panel-has-range .o-input-date-cell-in-range,
      > .o-input-date-panel-has-range .o-input-date-cell-range-start,
      > .o-input-date-panel-has-range .o-input-date-cell-range-end {
        background: var(--theme-neutral-normal);
      }
    }

    //Datetime Panel
    &-datetime-panel {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    // Header
    &-header {
      display: flex;

      &-view {
        flex: auto;
        text-align: center;

        > button {
          appearance: none;
          border: var(--border);
          border-radius: var(--border-radius-xxs);
          background: transparent;
          cursor: pointer;
        }
      }

      &-super-prev-btn,
      &-prev-btn,
      &-next-btn,
      &-super-next-btn {
        appearance: none;
        padding: 0 var(--font-xs);
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }

    &-week-panel,
    &-date-panel {
      .o-input-date-header-view {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        > button {
          :nth-child(1) {
            padding-right: var(--font-xs);
            border-left-width: 0;
            border-radius: 0 var(--border-radius-xxs) var(--border-radius-xxs) 0;
          }

          :nth-child(2) {
            padding-left: var(--font-xs);
            border-right-width: 0;
            border-radius: var(--border-radius-xxs) 0 0 var(--border-radius-xxs);
          }
        }
      }
    }

    // Content
    &-cell {
      color: var(--theme-dark-40);

      &-disabled {
        opacity: var(--disabled-opacity);
        cursor: not-allowed;
        
        .o-input-date-cell-inner {
          cursor: not-allowed;
        }
      }

      &-inner {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        border-radius: 50%;
        outline: none;
        cursor: pointer;

        &:hover {
          background-color: var(--theme-dark-05);
        }
      }

      &-in-view {
        color: var(--theme-dark-80);
      }

      &-range-start > &-inner,
      &-range-end > &-inner,
      &-selected > &-inner {
        color: var(--theme-tertiary-inverse);
        background-color: var(--theme-tertiary);
      }

      &-today > &-inner {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 5%;
          left: 45%;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          background-color: var(--theme-tertiary);
        }
      }
    }

    // ======================= Range Picker =======================
    &-range-wrapper {
      display: flex;
    }


    // ================== Decade, Year & Month Panel ==================
    &-decade-panel,
    &-year-panel,
    &-month-panel {
      .o-input-date-cell {
        &-inner {
          padding: 0 var(--font-md);
          border-radius: var(--border-radius-xxs);
        }
      }
    }

    // ====================== Week Panel ======================
    &-week-panel {
      &-row {
        &:hover,
        &-selected,
        &-selected:hover {
          > td {
            &:first-child {
              border-radius: 50% 0 0 50%;
            }

            &:last-child {
              border-radius: 0 50% 50% 0;
            }
          }
        }

        &:hover {
          .o-input-date-cell {
            background: var(--theme-dark-05);
          }
        }

        &-selected,
        &-selected:hover {
          .o-input-date-cell {
            background: var(--theme-neutral-normal);
          }
        }
      }

      .o-input-date-cell-week {
        color: var(--theme-neutral-medium);
      }

      .o-input-date-cell:hover > .o-input-date-cell-inner,
      .o-input-date-cell-selected > .o-input-date-cell-inner {
        color: var(--theme-dark-80);
        background: transparent;
      }
    }

    // ====================== Time Panel ======================
    &-time-panel {
      width: auto;

      .o-input-date-content {
        display: flex;
        max-height: 16rem;
      }

      &-column {
        width: 3.5rem;
        margin: 0;
        padding: 0;
        overflow: hidden;
        transition: var(--transition-primary);

        &:hover {
          overflow-y: overlay;
        }

        //Scroll
        ::-webkit-scrollbar {
          width: 0.375rem;
        }

        //Scroll Track
        ::-webkit-scrollbar-track {
          background: var(--theme-neutral-light); 
        }

        //Scroll Handle on hover
        ::-webkit-scrollbar-thumb {
          background: var(--theme-neutral-medium); 
        }

        > li {
          margin: 0;
          padding: 0;
          cursor: pointer;

          &.o-input-date-time-panel-cell-disabled {
            opacity: var(--disabled-opacity);
            cursor: not-allowed;
          }

          .o-input-date-time-panel-cell-inner {
            display: block;
            width: 100%;
            margin: 0;
            text-align: center;
            color: var(--theme-dark-80);
            border-radius: 50%;
          }
        }
      }
    }

    // ======================= Buttons =======================
    &-today-btn,
    &-now-btn {
      display: block;
      text-align: center;
      text-decoration: underline;
      color: unset;
      cursor: pointer;
    }

    &-ranges {
      position: relative;
      padding: 0;
      margin: 0;
      margin-top: var(--font-sm);
    }

    &-now {
      display: block;
      width: 100%;
      text-align: center;
    }

    &-ok {
      display: block;

      > button {
        position: absolute;
        top: 0;
        right: 0;
        appearance: none;
        padding: 0 var(--font-md);
        font-weight: 400;
        vertical-align: middle;
        border: 0;
        border-radius: var(--border-radius-xxs);
        color: var(--theme-tertiary-inverse);
        background-color: var(--theme-tertiary);
        opacity: 0.9;
        overflow: hidden;
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }

        &:active:not(:disabled) {
          opacity: 1;
        }

        &:disabled,
        &:disabled:hover {
          opacity: var(--disabled-opacity);
          cursor: not-allowed;
        }
      }
    }
  }

  .o-input-date-input--outline {
    .o-input-date-suffix, .o-input-date-clear {
      right: var(--font-md);
    }
  }

  // ======================= Input Flushed Aspect =======================
  .o-input-date-input--flushed > .o-input-date-input > input {
    border-bottom: var(--border);

    &:hover {
      box-shadow: 0 1px 0 0 var(--theme-neutral-normal);
    }

    &:focus {
      box-shadow: 0 1px 0 0 var(--theme-dark);
    }
  }

  .o-input-date-input--flushed.error > .o-input-date-input > input {
    &:hover,
    &:focus {
      box-shadow: 0 1px 0 0 var(--theme-danger);
    }
  }

  // ======================= Input Outline Aspect =======================
  .o-input-date-input--outline > .o-input-date-input > input {
    border: var(--border);
    border-radius: var(--border-radius-xxs);

    &:hover {
      box-shadow: 0 0 0 1px var(--theme-neutral-normal);
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--theme-dark);
    }

    padding: 0 var(--font-md);
  }

  .o-input-date-input--outline.error > .o-input-date-input > input {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px var(--theme-danger);
    }
  }

  // ======================= Input Error =======================
  .error > .o-input-date-input > input {
    color: var(--theme-danger);
    border-color: var(--theme-danger);
  }

  // ======================= Input Sizes =======================
  ${createSizes()}
`;
