import { atom, selector } from "recoil";
import { flatten } from "ramda";
import { cartaoCreditoBadgeSelector } from "hooks/cartao-credito.state";
import { strategicPartnersBadgeSelector } from "pages/parceiros/feeds/strategic-partners.state";
import { firstAccessBadgeSelector } from "pages/empresas/clientes/detalhes/[id]/[onboardingType]/_compose/first-access-notifications/first-access-optin-notification.state";
import untypedMenuGK from "./menu.json";
import { IMenuItem, TMenu, ILeafItem } from "./menu.types";

const menuGK = untypedMenuGK as TMenu;

export const menuItemAtom = memoize((id: string) => {
  if (id === "Cartão de Crédito" || id === "Agendas") {
    return selector({
      key: `Menu.${id}`,
      get: ({ get }) => {
        const item = { ...findItemInMenu(menuGK, id) };
        item.badge = get(cartaoCreditoBadgeSelector);
        return item;
      },
    });
  }
  if (id === "Feeds" || id === "Parceiros")
    return selector({
      key: `Menu.${id}`,
      get: ({ get }) => {
        const item = { ...findItemInMenu(menuGK, id) };
        item.badge = get(strategicPartnersBadgeSelector);
        return item;
      },
    });
  if (id === "Primeiro Acesso" || id === "Monitor Primeiro Acesso")
    return selector({
      key: `Menu.${id}`,
      get: ({ get }) => {
        const item = { ...findItemInMenu(menuGK, id) };
        item.badge = get(firstAccessBadgeSelector);
        return item;
      },
    });
  return atom({
    key: `Menu.${id}`,
    default: findItemInMenu(menuGK, id),
  });
});

function findItemInMenu(menu: TMenu, id: string): IMenuItem | ILeafItem {
  return (
    menu.find((x) => x.title === id) ||
    findItemInMenu(
      flatten(
        menu.reduce<Array<TMenu>>((arr, item) => {
          if ("items" in item) {
            arr.push(item.items);
          }
          return arr;
        }, []),
      ),
      id,
    )
  );
}

type GenericFunction<T, U> = (...args: T[]) => U;

function memoize<T, U>(fn: GenericFunction<T, U>): GenericFunction<T, U> {
  let cache: { [key: string]: U } = {};
  return function(...a: T[]) {
    let k = String(a[0]);
    return Object.prototype.hasOwnProperty.call(cache, k)
      ? cache[k]
      : (cache[k] = fn(...a));
  };
}
