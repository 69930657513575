import { Component, h, Prop } from "@stencil/core";
import type { IType } from "../../../globals/types";

@Component({
  tag: "o-collapse-content",
  styleUrl: "index.scss",
})
export class CollapseContent {
  /**
   * Propriedade interna, definida pelo `o-collapse`. Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop() expanded = false;
  /**
   * Propriedade interna, definida pelo `o-collapse`. Desabilita o component.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade interna, definida pelo `o-collapse`. Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";

  render() {
    const classes = {
      [`o-collapse-content--type-${this.type}`]: true,
      "o-collapse-content--disabled": this.disabled,
    };

    return (
      <o-expander-content expanded={this.expanded} class={classes}>
        <slot />
      </o-expander-content>
    );
  }
}
