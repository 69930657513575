import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
  State,
} from "@stencil/core";
import IMask, { AnyMaskedOptions } from "imask";
import {
  IAspect,
  IAutocomplete,
  IChangeMaskedEvent,
  ISize,
} from "../../globals/types";

export type MaskOptions = IMask.AnyMaskedOptions;

@Component({
  tag: "o-input-mask",
})
export class InputMask {
  @Element() self!: HTMLElement;
  /** Evento emitido ao ocorrer input, enviando os valores com e sem máscara. */
  @Event() changeMasked!: EventEmitter<IChangeMaskedEvent>;
  @State() _maskOption?: MaskOptions;

  /**
   * Configuração da máscara.
   *
   * @sbCategory Input
   * @sbControl false
   * @sbType IMask.AnyMaskedOptions
   */
  @Prop() maskOption?: MaskOptions;
  /**
   * String de configuração da máscara a ser parseada via `JSON.parse()`.
   *
   * @sbCategory Input
   * @sbControl object
   */
  @Prop() maskOptionToParse?: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop() value?: string | number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;
  private mask?: IMask.InputMask<AnyMaskedOptions>;

  // before render, set internal state `_maskOption` based on props
  componentWillRender() {
    if (this.maskOptionToParse) {
      this._maskOption = JSON.parse(this.maskOptionToParse);
    } else {
      this._maskOption = this.maskOption;
    }
  }

  componentDidRender() {
    if (this._maskOption) {
      if (this.mask) {
        this.mask.destroy();
      }

      if (this.inputElement) {
        this.mask = IMask(this.inputElement, this._maskOption);

        this.mask.on("accept", () => {
          if (!this.mask) return;

          this.changeMasked.emit({
            value: this.mask.value,
            unmaskedValue: this.mask.unmaskedValue,
          });

          this.value = this.mask.unmaskedValue;
        });
      }
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType="text"
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }
}
