import Amplify from "aws-amplify";
import { env } from "utils/environments";

export const amplifyConfigure = () => {
  Amplify.configure({
    Auth: {
      userPoolId: env.USER_POOL_ID,
      region: "us-east-1",
      userPoolWebClientId: env.USER_POOL_WEB_CLIENT_ID,
    },
    Analytics: {
      disabled: true,
    },
    oauth: {
      domain: env.AMAZON_COGNITO_DOMAIN,
      redirectSignIn: window.location.origin,
      redirectSignOut: env.OAUTH_REDIRECT_SIGNOUT,
      responseType: "code",
    },
  });
};
