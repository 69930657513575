import IMask from "imask";

/** Applies a percentage mask to the argument string */
export const percentage = (
  value: string | number | null | undefined,
  thousandsSeparator: "," | "." | "" = "",
  inputDecimalSeparator: "," | "." = "."
) => {
  if (!value) return "";

  if (typeof value === "string") {
    value = parseFloat(value);
  }

  value = value * 100;

  return IMask.pipe(value.toFixed(2), {
    mask: "num%",
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: thousandsSeparator,
        padFractionalZeros: true,
        mapToRadix: [inputDecimalSeparator],
      },
    },
  }) as string;
};
