import {
  emailValidator,
  cpfValidator,
  cnpjValidator,
  passwordValidatorPortal,
  passwordValidatorAdmin,
} from "./validators/formValidators";
import {
  nameValidator,
  validateDate,
  phoneValidator,
  validateDocumentInput,
} from "./validators";
import { FieldType } from "components";

export const baseFormDocument: FieldType = {
  id: "cnpjOrCpf",
  label: "CNPJ / CPF",
  type: "text",
  input: "text",
  size: "full",
  validation: validateDocumentInput,
  validateOnBlur: true,
  required: true,
  options: null,
  error: null,
  value: "",
  col: 6,
  mask: "99.999.999/9999-99",
};

export const baseFormCNPJ: FieldType = {
  id: "cnpj",
  label: "CNPJ",
  type: "text",
  input: "text",
  size: "full",
  validation: cnpjValidator,
  required: true,
  options: null,
  error: null,
  value: "",
  col: 6,
  mask: "99.999.999/9999-99",
};

export const baseFormCompantType: FieldType = {
  id: "companyType",
  input: "select",
  size: "full",
  label: "Tipo da empresa",
  required: true,
  type: "select",
  col: 6,
  value: "",
  options: [
    {
      id: "Sacado",
      title: "Sacado",
    },
    {
      id: "Cedente",
      title: "Cedente",
    },
  ],
};

export const baseFormCompanyStatus: FieldType = {
  id: "companyStatus",
  input: "select",
  label: "Status da Empresa",
  disabled: false,
  required: true,
  type: "select",
  col: 6,
  options: [
    {
      id: "AUTORIZADO",
      title: "Autorizado",
    },
    {
      id: "PENDENTE_CADASTRO",
      title: "Pendente Cadastro",
    },
    {
      id: "PENDENTE_PRECADASTRO",
      title: "Pendente Pré-Cadastro",
    },
    {
      id: "PENDENTE_REQUISICAO",
      title: "Pendente Requisição",
    },
    {
      id: "PENDENTE_DOCUMENTACAO",
      title: "Pendente documentação",
    },
    {
      id: "REPROVADO",
      title: "Reprovado",
    },
  ],
  value: "",
  size: "full",
};

export const baseFormLegalNature: FieldType = {
  id: "legalNature",
  input: "select",
  label: "Natureza Legal",
  disabled: false,
  required: true,
  type: "select",
  col: 6,
  options: [
    {
      id: "Limitada",
      title: "LTDA.",
    },
    {
      id: "EmpresarioIndividual",
      title: "Empresário Individual",
    },
    {
      id: "Eireli",
      title: "EIRELI",
    },
    {
      id: "SociedadeAnonima",
      title: "S.A.",
    },
    {
      id: "Outros",
      title: "Outros",
    },
  ],
  value: "",
  size: "full",
};

export const baseFormName: FieldType = {
  id: "name",
  label: "Nome",
  type: "text",
  size: "full",
  col: 6,
  validation: nameValidator,
  input: "text",
  disabled: false,
  required: true,
  options: null,
  error: null,
  value: "",
};

export const baseFormUpload: FieldType = {
  id: "upload",
  label: "Escolher arquivo",
  type: "upload",
  size: "full",
  input: "upload",
  fileName: "",
  validation: null,
  validateOnBlur: true,
  required: true,
  options: null,
  error: null,
  value: "",
  col: 6,
};

export const baseFormCPF: FieldType = {
  id: "cpf",
  label: "CPF",
  type: "text",
  input: "text",
  size: "full",
  validation: cpfValidator,
  required: true,
  options: null,
  error: null,
  value: "",
  col: 6,
  mask: "999.999.999-99",
};

export const baseFormBirthDate: FieldType = {
  id: "birthDate",
  label: "Data de nascimento",
  type: "date",
  size: "full",
  col: 6,
  input: "text",
  validateOnBlur: true,
  validation: validateDate,
  required: true,
  options: null,
  error: null,
  value: "",
  mask: "99/99/9999",
};

export const baseFormDate: FieldType = {
  id: "date",
  label: "Data",
  type: "date",
  size: "full",
  col: 6,
  input: "text",
  validateOnBlur: true,
  validation: validateDate,
  required: true,
  options: null,
  error: null,
  value: "",
  mask: "99/99/9999",
};

export const baseFormEmail: FieldType = {
  id: "email",
  label: "E-mail",
  type: "email",
  input: "text",
  size: "full",
  validation: emailValidator,
  required: true,
  options: null,
  error: null,
  col: 6,
  value: "",
};

export const baseFormPasswordPortal: FieldType = {
  id: "password",
  label: "Senha",
  type: "password",
  input: "text",
  size: "full",
  col: 6,
  validation: passwordValidatorPortal,
  validateOnBlur: true,
  required: true,
  error: null,
  value: "",
};

export const baseFormPasswordAdmin: FieldType = {
  id: "password",
  label: "Senha",
  type: "password",
  input: "text",
  size: "full",
  col: 6,
  validation: passwordValidatorAdmin,
  validateOnBlur: true,
  required: true,
  error: null,
  value: "",
};

export const baseFormPhone: FieldType = {
  id: "phone",
  label: "Telefone",
  type: "text",
  size: "full",
  col: 6,
  input: "text",
  required: false,
  options: null,
  error: null,
  value: "",
  validation: phoneValidator,
  mask: "+55 (99) 99999-9999",
};

export const baseFormIsLegalRepresentative: FieldType = {
  id: "isLegalRepresentative",
  input: "select",
  label: "Representante Legal",
  disabled: false,
  required: true,
  type: "select",
  col: 6,
  options: [
    {
      id: "true",
      title: "Sim",
    },
    {
      id: "false",
      title: "Não",
    },
  ],
  value: "",
  size: "full",
};

export const baseFormUserCompantStatus: FieldType = {
  id: "userCompanyStatus",
  input: "select",
  label: "Status do usuário",
  disabled: false,
  required: true,
  type: "select",
  col: 6,
  options: [
    {
      id: "PENDENTE_ONBOARDING",
      title: "Pendente de onboarding",
    },
    {
      id: "PENDENTE_AUTORIZACAO",
      title: "Pendente de autorização",
    },
    {
      id: "PENDENTE_REQUISICAO",
      title: "Aguardando Requisição",
    },
    {
      id: "AUTORIZADO",
      title: "Autorizado",
    },
    {
      id: "REPROVADO",
      title: "Reprovado",
    },
  ],
  value: "",
  size: "full",
};

export const baseFormRoles: FieldType = {
  id: "roles",
  input: "select",
  label: "Permissões",
  disabled: false,
  required: true,
  type: "select",
  col: 6,
  options: [
    {
      id: "Indefinido",
      title: "Indefinido",
    },
    {
      id: "AdministradorCedente",
      title: "Administrador Cedente",
    },
    {
      id: "AdministradorGeral",
      title: "Administrador Geral",
    },
    {
      id: "UsuarioCedente",
      title: "Usuario Cedente",
    },
    {
      id: "AdministradorSacado",
      title: "Administrador Sacado",
    },
    {
      id: "SuperAdmin",
      title: "Super Admin",
    },
    {
      id: "ClientePME",
      title: "Cliente PME",
    },
    {
      id: "AdministradorEnergia",
      title: "Administrador Energia",
    },
    {
      id: "AdministradorCobranca",
      title: "Administrador Cobrança",
    },
    {
      id: "AdministradorPrimeiroAcesso",
      title: "Administrador Primeiro Acesso",
    },
  ],
  value: "",
  size: "full",
};

export const baseFormOfficialName: FieldType = {
  id: "officialName",
  label: "Razão Social",
  type: "text",
  size: "full",
  col: 6,
  input: "text",
  disabled: true,
  required: true,
  options: null,
  error: null,
  value: "",
};
