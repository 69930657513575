import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { addEnvironmentWarning } from "utils/helpers/addEnvironmentWarning";

// Adds yellow bar on UAT
document.addEventListener("DOMContentLoaded", function() {
  addEnvironmentWarning();
});

const rootEl = document.getElementById("root");

ReactDOM.render(<App />, rootEl);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}
