export function handleMenuItemsClick(
  event: MouseEvent,
  el: HTMLOMenuItemElement
) {
  const target = event.target as HTMLElement;
  const isPreviousMenu = target?.className.includes("o-menu-previous");

  if (isPreviousMenu) return;

  const subitem = el.querySelector("o-menu-subitem");
  if (subitem) subitem.active = true;
}

export function handleItemsActiveProp(
  newValue?: string,
  menuItems?: HTMLOMenuItemElement[]
) {
  menuItems?.forEach((el) => {
    if (newValue === el.id) {
      el.active = true;
    } else {
      el.active = false;

      const subitems = el.querySelectorAll("o-menu-subitem");
      subitems?.forEach((subitem) => {
        subitem.activeItem = "";
      });
    }
  });
}
