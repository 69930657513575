/**
 * .o-step--marker        - step marker (circles)
 * .o-step--marker::after - completed checkmark inside circle
 */

o-step {
  width: 100%;

  &:last-child {
    .o-step::before {
      display: none;
    }
  }

  .o-step {
    font-family: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: transparent;
    cursor: pointer;

    @each $type in $types {
      &--type-#{$type} {
        --activeColor: var(--theme-#{$type});
      }
    }

    // line linking markers
    &::before {
      content: "";
      position: absolute;
      bottom: 0.75rem;
      left: 50%;
      width: 100%;
      height: 0.125rem;
      background: var(--theme-neutral-medium);
      transition: var(--transition-primary);
    }

    // button -> step marker (circles)
    &--marker {
      all: unset;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 1px solid var(--theme-neutral-medium);
      background: var(--theme-neutral-medium);
      box-shadow: inset 0 0 0 6px var(--theme-light);
      transition: var(--transition-primary);

      // completed checkmark inside circle
      &::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f00c";
        font-weight: 400;
        color: var(--theme-light);
        opacity: 0;
      }
    }

    &--status {
      &-completed {
        .o-step--marker {
          background: var(--activeColor);
          border: 1px solid var(--activeColor);
          box-shadow: none;

          &::after {
            opacity: 1;
            transition: var(--transition-primary);
          }
        }

        // line
        &::before {
          background: var(--activeColor);
        }
      }

      &-current {
        .o-step--marker {
          background: var(--theme-light);
          border: 0;
          box-shadow: inset 0 0 0 6px var(--activeColor);
          transition: var(--transition-primary);
        }
      }
    }

    // button text
    & span {
      position: absolute;
      left: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--position {
      &-top span {
        bottom: var(--font-md);
        transform: translate(-50%, -100%);
      }

      &-bottom span {
        top: var(--font-sm);
        transform: translate(-50%, 100%);
      }
    }
  }
}
