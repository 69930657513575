o-filter-item {
  o-collapse {
    o-collapse-header {
      o-expander-header {
        @each $size in $fontSizes {
          &.o-collapse-header--size-#{$size} {
            padding: var(--font-#{$size});
            height: auto;

            div {
              display: contents;
            }
          }
        }
      }
    }

    o-collapse-content {
      .o-filter-item-content {
        padding: var(--font-md);
      }

      o-expander-content {
        @each $type in $types {
          &.o-collapse-content--type-#{$type} {
            background-color: var(--theme-neutral-light);
          }
        }
      }
    }
  }
}