o-breadcrumb-item {
  // separators (">")
  &:not(:last-child) {
    display: flex;
    align-items: center;

    &::after {
      content: attr(data-separator);
      padding: 0 var(--font-xs);
      cursor: default;
      text-decoration: none !important;
    }
  }

  a.o-breadcrumb-item {
    color: unset;
    cursor: inherit;
    line-height: 25px;
    font-family: inherit;
    text-decoration: none !important;

    @each $type in $typesComplete {
      &--type-#{$type} {
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--font-#{$size} {
        font-size: var(--font-#{$size});
        min-height: var(--font-#{$size});
      }
    }

    @each $weight in $weights {
      &--weight-#{$weight} {
        font-weight: #{$weight};
      }
    }

    &[href] {
      &:hover,
      &:active {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }
  }

  // prevent text-decoration on o-icon
  o-icon {
    display: inline;
  }
}
