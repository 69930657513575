o-banner-content {
  height: 100%;

  .o-typography {
    color: var(--theme-current-color);
  }
  
  .subtitle {
    opacity: 0.6;
  }

  .description {
    opacity: 0.8;
  }

  @each $size in $fontSizes {
    &.size-#{$size} {
      h1.o-typography {
        font-size: calc(var(--font-#{$size}) * 1.5);
        min-height: calc(var(--font-#{$size}) * 1.5);
      }
    }
  }

  @include breakpoint-up(xlarge) {
    &.limited-size {
      max-width: 1100px;
    }
  }
}
