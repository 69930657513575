import { Component, Element, h, Prop } from "@stencil/core";

@Component({
  tag: "o-image",
  styleUrl: "index.scss",
})
export class Image {
  @Element() self!: HTMLElement;

  /**
   * URL da imagem.
   *
   * @sbCategory Input
   */
  @Prop() src!: string;
  /**
   * Descrição da imagem.
   *
   * @sbCategory Input
   */
  @Prop() alt!: string;
  /**
   * Largura máxima da imagem. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() maxWidth = "unset";
  /**
   * Largura da imagem. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width = "auto";
  /**
   * Altura da imagem. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() height = "auto";
  /**
   * Faz a imagem se tornar um círculo.
   *
   * @sbCategory Style
   */
  @Prop() circle = false;
  /**
   * Propriedade `object-fit` da imagem.
   *
   * @sbCategory Style
   */
  @Prop() objectFit: "fill" | "contain" | "cover" | "scale-down" | "none" =
    "fill";

  componentDidLoad() {
    this.self.style.width = this.width;
    this.self.style.maxWidth = this.maxWidth;
    this.self.style.height = this.height;
  }

  render() {
    const { src, alt, objectFit, circle } = this;

    const classes = {
      "o-image": true,
      [`o-image--${objectFit}`]: true,
      "o-image--circle": circle,
    };

    return <img class={classes} src={src} alt={alt} />;
  }
}
