import styled from "styled-components";
import { ODropdownItem, ODropdownList } from "@maestro/react";

const entitySearchWidth = "500px";

export const EntitySearchContainer = styled.div`
  width: ${entitySearchWidth};
  margin-left: auto;
  display: flex;
`;

export const StyledDropdownList = styled(ODropdownList)`
  width: ${entitySearchWidth} !important;
`;

export const StyledDropdownItem = styled(ODropdownItem)`
  padding: 0 !important;
`;

export const AnchorItem = styled.a`
  all: unset;
  padding: var(--font-sm);
  width: 100%;
  box-sizing: border-box;

  &:hover {
    color: currentColor;
  }
`;
