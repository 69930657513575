import { IPosition } from "@maestro/core";
import React from "react";
import { OIcon, OTooltip } from "../../stencil-generated";

interface ITooltipContainer {
  position?: IPosition;
  maxWidth?: string;
  children?: React.ReactNode;
}

export const TooltipContainer = ({
  position = "top",
  maxWidth,
  children,
}: ITooltipContainer) => {
  return (
    <OTooltip slot="tooltip" position={position} maxWidth={maxWidth}>
      <div slot="tooltip-content">{children}</div>
      <OIcon category="far" icon="fa-exclamation-circle" size="md" />
    </OTooltip>
  );
};

export default TooltipContainer;
