import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import IMask from "imask";
import type {
  IAspect,
  IAutocomplete,
  IChangeMaskedEvent,
  ISize,
} from "../../globals/types";

@Component({
  tag: "o-input-currency",
})
export class InputCurrency {
  @Element() self!: HTMLElement;
  /** Evento emitido ao ocorrer input, enviando os valores com e sem máscara. */
  @Event() changeMasked!: EventEmitter<IChangeMaskedEvent>;
  @State() maskValue = "";

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: number | null;
  /**
   * Símbolo da moeda.
   *
   * @sbCategory Input
   */
  @Prop() symbol = "";
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;
  private masker?: (val: string) => string;

  private formatInput(value: string) {
    const digits = value.match(/\d/g) || ["0"];

    while (digits.length <= 2) {
      digits.unshift("0");
    }

    digits.splice(digits.length - 2, 0, ".");

    return Number(digits.join(""));
  }

  @Watch("symbol")
  private createMasker() {
    this.masker = IMask.createPipe({
      mask: [
        {
          mask: "",
        },
        {
          mask: this.symbol?.length ? `${this.symbol} num` : "num",
          lazy: false,
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: ".",
              padFractionalZeros: true,
              mapToRadix: [","],
            },
          },
        },
      ],
    });
  }

  private handleInput() {
    if (!this.inputElement || !this.masker) return;

    const value = this.inputElement.value;
    const newInput = this.formatInput(value);
    const valueMasked = this.masker(newInput.toFixed(2).replace(".", ","));

    this.value = newInput;
    this.inputElement.value = valueMasked;
    this.maskValue = valueMasked;

    this.changeMasked.emit({
      value: String(valueMasked),
      unmaskedValue: String(newInput),
    });
  }

  componentWillLoad() {
    this.createMasker();
  }

  componentWillRender() {
    if (!this.masker) return;

    this.maskValue = this.masker(
      (this.value ?? 0).toFixed(2).replace(".", ",")
    );
  }

  componentDidLoad() {
    if (this.inputElement) {
      this.inputElement.addEventListener("paste", (event: any) => {
        event.preventDefault();
        event.stopPropagation();
      });
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      maskValue,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType="tel"
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={maskValue}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
        onInput={() => this.handleInput()}
      />
    );
  }
}
