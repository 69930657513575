import { Component, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-pill-tab-content",
  styleUrl: "index.scss",
})
export class OPillTabContent {
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;

  render() {
    const { active } = this;

    const classes = {
      "o-pill-tab-content": true,
      "o-pill-tab-content--active": active,
    };

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
