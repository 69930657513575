import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-upload-input",
})
export class UploadInput {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `multiple` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() multiple = true;
  /**
   * Os formatos de arquivo que serão aceitos pelo input.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers).
   *
   * @sbCategory Input
   * @sbControl false
   */
  @Prop() accept!: string;

  render() {
    const { dataAction, dataLabel, id, name, accept, disabled, multiple } =
      this;

    return (
      <input
        data-action={dataAction}
        data-label={dataLabel}
        id={`o-${id}`}
        name={name}
        type="file"
        accept={accept}
        disabled={disabled}
        multiple={multiple}
      />
    );
  }
}
