import { Component, Element, h, Host, Prop } from "@stencil/core";
import { ITypeComplete } from "../../../globals/types";

@Component({
  tag: "o-menu-list",
  styleUrl: "index.scss",
})
export class MenuList {
  @Element() self!: HTMLOMenuSubitemElement;

  /**
   * Título do menu list.
   *
   * @sbCategory Input
   */
  @Prop() title!: string;
  /**
   * Se algum item estiver ativo.
   *
   * @sbCategory Input
   */
  @Prop() hasActiveItem = false;
  /**
   * Cor de fundo do título.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete = "light";

  render() {
    const { type, title, hasActiveItem } = this;

    const classes = {
      "o-menu-list": true,
      "o-menu-list__active": !hasActiveItem,
      [`o-menu-list--type-${type}`]: true,
    };

    return (
      <Host class={classes}>
        <o-typography tag="h3" type="dark" class="p-2">
          {title}
        </o-typography>

        <div class="o-menu-list__items">
          <slot />
        </div>
      </Host>
    );
  }
}
