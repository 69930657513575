import { Card } from "@material-ui/core";
import { Spinner } from "components/componentsv1";
import { ResumoItem } from "components/resumo-item/resumo-item.component";
import { useGet } from "hooks/rest-services";
import React, { useEffect, useState } from "react";
import { ReceivableSystemState } from "types/receivableType";
import { MoneyMask } from "utils";
import { endpoints } from "utils/endpoints";
import { styles } from "./resumo-trades-diarios.styles";
import {
  ITradesDiariosValores,
  ITradesMaturing,
} from "./resumo-trades-diarios.types";

function ResumoTradesDiarios() {
  const { data, refetch, loading } = useGet<
    ITradesDiariosValores,
    ITradesDiariosValores
  >(endpoints.quickfin.monitor.resumoDiario);

  const {
    data: maturingTrades,
    refetch: refetchMaturingDates,
    loading: loadingMaturingDates,
  } = useGet<ITradesMaturing[], ITradesMaturing[]>(
    endpoints.quickfin.monitor.tradesMaturing,
  );

  const [maturingTradesTotal, setMaturingTradesTotal] = useState(0);
  const [diffIncomingOutgoing, setDiffIncomingOutgoing] = useState(0);

  useEffect(() => {
    if (maturingTrades) {
      const trades = maturingTrades.filter((trade) =>
        [
          ReceivableSystemState.TradeEmAtraso,
          ReceivableSystemState.TradeDesembolsado,
          ReceivableSystemState.TradeLiquidado,
        ].includes(trade.state),
      );
      setMaturingTradesTotal(
        trades.reduce((prev, curr) => prev + curr.amount, 0),
      );
    }
  }, [maturingTrades]);

  useEffect(() => {
    if (maturingTradesTotal) {
      setDiffIncomingOutgoing(
        (data?.valorDesembolsoAntecipadoTotal || 0) - maturingTradesTotal,
      );
    }
  }, [maturingTradesTotal, data]);

  return (
    <>
      {(loading || loadingMaturingDates) && <Spinner />}
      <Card style={styles.card}>
        <div className="container-fluid p-4">
          <div className="row justify-content-between gx-5">
            <div className="col-md">
              <ResumoItem
                header={"Desembolsados"}
                amount={MoneyMask(data?.valorVencimentoAntecipadoTotal)}
                properties={[
                  {
                    key: "Líquido",
                    value: MoneyMask(data?.valorDesembolsoAntecipadoTotal),
                  },
                  { key: "Quantidade", value: data?.tradesAntecipadosQtd },
                ]}
                iconClassName="fal fa-sync"
                onClick={() => refetch()}
              />
            </div>
            <div className="col-md">
              <ResumoItem
                header={"Recusados"}
                amount={MoneyMask(data?.valorVencimentoRecusadoTotal)}
                properties={[
                  {
                    key: "Líquido",
                    value: MoneyMask(data?.valorDesembolsoRecusadoTotal),
                  },
                  { key: "Quantidade", value: data?.tradesRecusadosQtd },
                ]}
                iconClassName="fal fa-sync"
                onClick={() => refetch()}
              />
            </div>
            <div className="col-md">
              <ResumoItem
                header={"Pendentes"}
                amount={MoneyMask(data?.valorVencimentoPendenteTotal)}
                properties={[
                  {
                    key: "Líquido",
                    value: MoneyMask(data?.valorDesembolsoPendenteTotal),
                  },
                  { key: "Quantidade", value: data?.tradesPendentesQtd },
                ]}
                iconClassName="fal fa-sync"
                onClick={() => refetch()}
              />
            </div>
            <div className="col-md">
              <ResumoItem
                header={"Resultado"}
                amount={MoneyMask(diffIncomingOutgoing)}
                properties={[
                  {
                    key: "Entrada",
                    value: MoneyMask(data?.valorVencimentoAntecipadoTotal),
                  },
                  { key: "Saída", value: MoneyMask(maturingTradesTotal) },
                ]}
                iconClassName="fal fa-sync"
                onClick={() => {
                  refetch();
                  refetchMaturingDates();
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ResumoTradesDiarios;
