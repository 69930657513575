import React from "react";
import styled from "styled-components";
import { match } from "styled-is";
import "./styles.css";

type StyledGroupButtonType = Pick<IButtonGroupProps, "space" | "size">;

export const StyledGroupButton = styled.div<StyledGroupButtonType>`
  display: inline-flex;

  ${match("space", "right")`
    > button,
    > div {
      margin-right: 20px;

      ${match("size", "small")`
          margin-right: 10px;
      `};

      &:last-child {
        margin-right: 0;
      }
    }
  `};

  ${match("space", "left")`
    > button,
    > div {
      margin-left: 20px;

      ${match("size", "small")`
          margin-left: 10px;
      `};

      &:first-child {
        margin-left: 0;
      }
    }
  `};
`;

interface IButtonGroupProps {
  children: any;
  space: "left" | "right";
  size?: "small";
}

export const GroupButton = (props: IButtonGroupProps) => (
  <StyledGroupButton {...props}>{props.children}</StyledGroupButton>
);
