import { Component, Element, h, Prop, Watch } from "@stencil/core";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-clickable-box",
  styleUrl: "index.scss",
})
export class ClickableBox {
  @Element() self!: HTMLElement;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Valor atual do input. Funciona como identificador do o-radio.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Tipo do input.
   *
   * @sbCategory Style
   */
  @Prop() inputType: "radio" | "checkbox" = "checkbox";
  /**
   * Propriedade `checked` do radio button. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) checked = false;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Cor do radio.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Width do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Remove o _background_ e muda a cor do texto para a definida por `type`.
   *
   * @sbCategory Style
   */
  @Prop() outline = false;

  private inputElement?: HTMLInputElement;

  /**
   * Alter `checked` property of native input when <o-radio> `checked` is set
   * externally
   */
  @Watch("checked")
  handleSetChecked(newChecked: boolean) {
    if (this.inputElement) this.inputElement.checked = newChecked;
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      value,
      inputType,
      type,
      size,
      disabled,
      name,
      checked,
      outline,
    } = this;

    const classes = {
      "o-clickable-box": true,
      [`o-clickable-box--type-${type}`]: !outline,
      [`o-clickable-box--outline-${type}`]: outline,
      [`o-clickable-box--size-${size}`]: true,
    };

    return (
      <div class={classes}>
        <input
          ref={(el) => (this.inputElement = el)}
          type={inputType}
          data-action={dataAction}
          data-label={dataLabel}
          id={`o-${id}`}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
        />

        <div class="o-clickable-box__label">
          <slot />
          <div class="o-clickable-box__status" />
        </div>
      </div>
    );
  }
}
