import React from "react";

const doNothing = (e: React.MouseEvent) => {
  e.preventDefault();
};

export const ThemeSettings: React.FC = () => {
  return (
    <div
      className="modal fade js-modal-settings modal-backdrop-transparent"
      tabIndex={-1}
      role="dialog"
      style={{ display: "none", paddingRight: "17px" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-right modal-md">
        <div className="modal-content">
          <div className="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center w-100">
            <h4 className="m-0 text-center color-white">
              Configurações de Layout
              <small className="mb-0 opacity-80">
                Configurações da Interface de Usuário
              </small>
            </h4>
            <a
              href="/#"
              type="a"
              className="close text-white position-absolute pos-top pos-right p-2 m-1 mr-2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="fal fa-times" />
              </span>
            </a>
          </div>
          <div className="modal-body p-0">
            <div className="settings-panel">
              <div className="mt-4 d-table w-100 px-5">
                <div className="d-table-cell align-middle">
                  <h5 className="p-0">Layout do App</h5>
                </div>
              </div>
              <div className="list" id="fh">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="header-function-fixed"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Menu Fixo</span>
                <span className="onoffswitch-title-desc">
                  O Header permanecerá fixo
                </span>
              </div>
              <div className="list" id="nff">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-function-fixed"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Navegação Fixa</span>
                <span className="onoffswitch-title-desc">
                  Painel Esquerdo permanecerá fixo
                </span>
              </div>
              <div className="list" id="nfm">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-function-minify"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Minimizar Navegação</span>
                <span className="onoffswitch-title-desc">
                  Encolha o Painel para maximizar espaço
                </span>
              </div>
              <div className="list" id="nfh">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-function-hidden"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Esconder Navegação</span>
                <span className="onoffswitch-title-desc">
                  Passe o mouse na borda para revelar
                </span>
              </div>
              <div className="list" id="nft">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-function-top"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Navegação no Topo</span>
                <span className="onoffswitch-title-desc">
                  O Painel Esquerdo ficará no topo
                </span>
              </div>
              <div className="list" id="fff">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="footer-function-fixed"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Footer Fixo</span>
                <span className="onoffswitch-title-desc">
                  O footer da página ficará fixo
                </span>
              </div>
              <div className="list" id="mmb">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-main-boxed"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Layout no Container</span>
                <span className="onoffswitch-title-desc">
                  Encapsular conteúdo em um container
                </span>
              </div>
              <div className="expanded">
                <ul className="mb-3 mt-1">
                  <li>
                    <div
                      className="bg-fusion-50"
                      data-action="toggle"
                      data-class="mod-bg-1"
                    />
                  </li>
                  <li>
                    <div
                      className="bg-warning-200"
                      data-action="toggle"
                      data-class="mod-bg-2"
                    />
                  </li>
                  <li>
                    <div
                      className="bg-primary-200"
                      data-action="toggle"
                      data-class="mod-bg-3"
                    />
                  </li>
                  <li>
                    <div
                      className="bg-success-300"
                      data-action="toggle"
                      data-class="mod-bg-4"
                    />
                  </li>
                  <li>
                    <div
                      className="bg-white border"
                      data-action="toggle"
                      data-class="mod-bg-none"
                    />
                  </li>
                </ul>
                <div className="list" id="mbgf">
                  <a
                    href="/#"
                    onClick={doNothing}
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-fixed-bg"
                  >
                    <i></i>
                  </a>
                  <span className="onoffswitch-title">Background Fixo</span>
                </div>
              </div>
              <div className="mt-4 d-table w-100 px-5">
                <div className="d-table-cell align-middle">
                  <h5 className="p-0">Menu mobile</h5>
                </div>
              </div>
              <div className="list" id="nmp">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-mobile-push"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Puxar Conteúdo</span>
                <span className="onoffswitch-title-desc">
                  Conteúdo é empurrado ao revelar menu
                </span>
              </div>
              <div className="list" id="nmno">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-mobile-no-overlay"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Sem sobreposição</span>
                <span className="onoffswitch-title-desc">
                  Remova da frente ao revelar menu
                </span>
              </div>
              <div className="list" id="sldo">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="nav-mobile-slide-out"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Off-Canvas</span>
                <span className="onoffswitch-title-desc">
                  Conteúdo sobrepõe o menu
                </span>
              </div>
              <div className="mt-4 d-table w-100 px-5">
                <div className="d-table-cell align-middle">
                  <h5 className="p-0">Acessibilidade</h5>
                </div>
              </div>
              <div className="list" id="mbf">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-bigger-font"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Fonte do Conteúdo Maior
                </span>
                <span className="onoffswitch-title-desc">
                  A fonte é maior para a legibilidade
                </span>
              </div>
              <div className="list" id="mhc">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-high-contrast"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Texto de Auto Contraste
                </span>
                <span className="onoffswitch-title-desc">
                  Contraste de proporção 4.5:1
                </span>
              </div>
              <div className="list" id="mcb">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-color-blind"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Daltonismo</span>
                <span className="onoffswitch-title-desc">
                  Dificuldade para enxergar cores
                </span>
              </div>
              <div className="list" id="mpc">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-pace-custom"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Preloader</span>
                <span className="onoffswitch-title-desc">
                  Preloader dentro do conteúdo
                </span>
              </div>
              <div className="list" id="mpi">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-panel-icon"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Ícones do SmartPanel</span>
                <span className="onoffswitch-title-desc">
                  botões do SmartPanel aparecem como ícones
                </span>
              </div>
              <div className="mt-4 d-table w-100 px-5">
                <div className="d-table-cell align-middle">
                  <h5 className="p-0">Modificações globais</h5>
                </div>
              </div>
              <div className="list" id="mcbg">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-clean-page-bg"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Limpar o Background da Página
                </span>
                <span className="onoffswitch-title-desc">
                  Adicionar mais espaço em branco
                </span>
              </div>
              <div className="list" id="mhni">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-hide-nav-icons"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Esconder Ícones da Navegação
                </span>
                <span className="onoffswitch-title-desc">
                  Icones da Navegação invisíveis
                </span>
              </div>
              <div className="list" id="dan">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-disable-animation"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Desabilitar Animações CSS
                </span>
                <span className="onoffswitch-title-desc">
                  Desabilita animações básicas em CSS
                </span>
              </div>
              <div className="list" id="mhic">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-hide-info-card"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">
                  Esconder Card de Informações
                </span>
                <span className="onoffswitch-title-desc">
                  Esconde Card de Informações
                </span>
              </div>
              <div className="list" id="mlph">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-lean-subheader"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Encolher Subheader</span>
                <span className="onoffswitch-title-desc">
                  Estilo de Page header diferente
                </span>
              </div>
              <div className="list" id="mnl">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-nav-link"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Navegação Hierárquica</span>
                <span className="onoffswitch-title-desc">
                  Limpar o caminho dos links do SubMenu
                </span>
              </div>
              <div className="list" id="mdn">
                <a
                  href="/#"
                  onClick={doNothing}
                  className="btn btn-switch"
                  data-action="toggle"
                  data-class="mod-nav-dark"
                >
                  <i></i>
                </a>
                <span className="onoffswitch-title">Navegação Dark</span>
                <span className="onoffswitch-title-desc">
                  Background de navegação com o tema Dark
                </span>
              </div>
              <hr className="mb-0 mt-4" />
              <div className="mt-4 d-table w-100 pl-5 pr-3">
                <div className="d-table-cell align-middle">
                  <h5 className="p-0">Tamanho das Fontes Globais</h5>
                </div>
              </div>
              <div className="list mt-1">
                <div
                  className="btn-group btn-group-sm btn-group-toggle my-2"
                  data-toggle="as"
                >
                  <label
                    className="btn btn-default btn-sm waves-effect waves-themed"
                    data-action="toggle-swap"
                    data-class="root-text-sm"
                    data-target="html"
                  >
                    <input type="radio" name="changeFrontSize" /> SM
                  </label>
                  <label
                    className="btn btn-default btn-sm waves-effect waves-themed active"
                    data-action="toggle-swap"
                    data-class="root-text"
                    data-target="html"
                  >
                    <input type="radio" name="changeFrontSize" defaultChecked />{" "}
                    MD
                  </label>
                  <label
                    className="btn btn-default btn-sm waves-effect waves-themed"
                    data-action="toggle-swap"
                    data-class="root-text-lg"
                    data-target="html"
                  >
                    <input type="radio" name="changeFrontSize" /> LG
                  </label>
                  <label
                    className="btn btn-default btn-sm waves-effect waves-themed"
                    data-action="toggle-swap"
                    data-class="root-text-xl"
                    data-target="html"
                  >
                    <input type="radio" name="changeFrontSize" /> XL
                  </label>
                </div>
                <span className="onoffswitch-title-desc d-block mb-0">
                  Muda o tamanho da fonte no tamanho rem (Reseta ao recarregar
                  página)
                </span>
              </div>
              <hr className="mb-0 mt-4" />
              <div className="pl-5 pr-3 py-3 bg-faded">
                <div className="row no-gutters">
                  <div className="col-12 pr-1">
                    <a
                      href="/#"
                      className="btn btn-outline-primary fw-500 btn-block waves-effect waves-themed"
                      data-action="app-reset"
                    >
                      Limpar Config.
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <span id="saving" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
