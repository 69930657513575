o-menu-list {
  &.o-menu-list {
    display: flex;
    flex-direction: column;
    gap: var(--font-xxs);
    line-height: var(--font-xl);
    border-radius: var(--border-radius-xxs);

    .o-menu-list__items {
      display: flex;
      flex-direction: column;
      gap: var(--font-xs);
    }

    .o-menu-item {
      padding-left: var(--font-lg);
    }

    > o-typography {
      box-sizing: border-box;
      width: 100%;
      padding: var(--font-xs);
      line-height: var(--font-xl);
      border-radius: var(--font-xxs);
    }

    @each $type in $typesComplete {
      &--type-#{$type} {
        &.o-menu-list__active > o-typography {
          background-color: var(--theme-#{$type});
          color: var(--theme-#{$type}-inverse);
        }
      }
    }
  }
}
