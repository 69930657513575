import { Component, h, Host, Prop } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-tab",
  styleUrl: "index.scss",
})
export class Tab {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;
  /**
   * Faz o tab ocupar 100% da largura do container.
   *
   * @sbCategory Style
   */
  @Prop() fullWidth = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: "flushed" | "unstyled" = "flushed";
  /**
   * Desabilita o tab.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;
  /**
   * Callback chamado quando ocorre click na tab.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: () => void;

  render() {
    const {
      active,
      fullWidth,
      size,
      disabled,
      type,
      aspect,
      dataLabel,
      dataAction,
    } = this;

    const hostClass = {
      "o-tab--full-width": fullWidth,
    };

    const classes = {
      "o-tab": true,
      "o-tab--active": active,
      "o-tab--disabled": disabled,
      [`o-tab--size-${size}`]: true,
      [`o-tab--type-${type}`]: true,
      [`o-tab--aspect-${aspect}`]: true,
    };

    return (
      <Host class={hostClass}>
        <button
          class={classes}
          data-label={dataLabel}
          data-action={dataAction}
          disabled={disabled}
          onClick={() => {
            if (this.handleClick) this.handleClick();
          }}
        >
          <slot />
        </button>
      </Host>
    );
  }
}
