import { OLoader } from "@maestro/react";
import ResumoTradesDiarios from "pages/antecipacao-cartao/agendas/_pages/monitor/resumo-trades-diarios";
import React, { lazy, Suspense } from "react";
import { ExtractRouteParams, Route, Switch } from "react-router";

export const routes = {
  index: {
    path: "/" as const,
    element: lazy(() => import("pages/inicio/index.page")),
  },
  inicio: {
    path: "/inicio" as const,
    element: lazy(() => import("pages/inicio/index.page")),
  },
  cambioMonitor: {
    path: "/cambio/monitor" as const,
    element: lazy(() => import("pages/cambio/monitor/index.page")),
  },
  cambioPreTickets: {
    path: "/cambio/pre-tickets" as const,
    element: lazy(() =>
      import("pages/cambio/pre-tickets/index.page").then((comp) => ({
        default: comp.FxPreTicketsPage,
      })),
    ),
  },
  cambioPreTicketsAdicionar: {
    path: "/cambio/pre-tickets/adicionar" as const,
    element: lazy(() =>
      import("pages/cambio/pre-tickets/adicionar/index.page").then((comp) => ({
        default: comp.FxAddPreTicketPage,
      })),
    ),
  },
  cambioPreTicketsEditarById: {
    path: "/cambio/pre-tickets/editar/:id" as const,
    element: lazy(() =>
      import("pages/cambio/pre-tickets/editar/[id]").then((comp) => ({
        default: comp.FxEditPreTicketPage,
      })),
    ),
  },
  cambioPreTicketsByIdentification: {
    path: "/cambio/pre-tickets/:identification" as const,
    element: lazy(() =>
      import("pages/cambio/pre-tickets/[identification]/index.page").then(
        (comp) => ({
          default: comp.FxPreTicketsByIdentificationPage,
        }),
      ),
    ),
  },
  cambioContingencia: {
    path: "/cambio/contingencia" as const,
    element: lazy(() => import("pages/cambio/contingencia/index.page")),
  },
  cambioContingenciaBancosDeBeneficiarios: {
    path: "/cambio/contingencia/bancos-de-beneficiarios" as const,
    element: lazy(() =>
      import(
        "pages/cambio/contingencia/bancos-de-beneficiarios/index.page"
      ).then((comp) => ({
        default: comp.BeneficiaryBank,
      })),
    ),
  },
  cambioContingenciaEditarRegraDeNatureza: {
    path: "/cambio/contingencia/regras-de-natureza/:key/:subtype" as const,
    element: lazy(() =>
      import(
        "pages/cambio/contingencia/regras-de-natureza/[key]/[subtype]/index.page"
      ),
    ),
  },
  atendimentoAtendentes: {
    path: "/atendimento/atendentes" as const,
    element: lazy(() => import("pages/atendimento/atendentes/index.page")),
  },
  cambioMonitorDetalhesOperacaoById: {
    path: "/cambio/monitor/detalhes-operacao/:id" as const,
    element: lazy(() =>
      import(
        "pages/cambio/monitor/detalhes-operacao/[id]/index.page"
      ).then((comp) => ({ default: comp.FxOperationDetail })),
    ),
  },
  cambioMonitorDetalhesPreticketById: {
    path: "/cambio/monitor/detalhes-preticket/:id" as const,
    element: lazy(() =>
      import(
        "pages/cambio/monitor/detalhes-preticket/[id]/index.page"
      ).then((comp) => ({ default: comp.FxPreTicketDetail })),
    ),
  },
  cambioMonitorDetalhesOrdemById: {
    path: "/cambio/monitor/detalhes-ordem/:id" as const,
    element: lazy(() =>
      import(
        "pages/cambio/monitor/detalhes-ordem/[id]/index.page"
      ).then((comp) => ({ default: comp.FxOrderDetail })),
    ),
  },
  cambioMonitorDetalhesLimiteById: {
    path: "/cambio/monitor/detalhes-limite/:id" as const,
    element: lazy(() =>
      import(
        "pages/cambio/monitor/detalhes-limite/[id]/index.page"
      ).then((comp) => ({ default: comp.FxLimitDetail })),
    ),
  },
  cambioMonitorDetalhesContraparteById: {
    path: "/cambio/monitor/detalhes-contraparte/:id" as const,
    element: lazy(() =>
      import(
        "pages/cambio/monitor/detalhes-contraparte/[id]/index.page"
      ).then((comp) => ({ default: comp.FxCounterpartyDetail })),
    ),
  },
  fidcBatches: {
    path: "/fidc/batches" as const,
    element: lazy(() => import("pages/fidc/batches/index.page")),
  },
  fidcBatchesByFundId: {
    path: "/fidc/batches/:fundId" as const,
    element: lazy(() => import("pages/fidc/batches/index.page")),
  },
  fidcBatchesByFundIdByBatchId: {
    path: "/fidc/batches/:fundId/:batchId" as const,
    element: lazy(() =>
      import("pages/fidc/batches/[fundId]/[batchId]/index.page"),
    ),
  },
  fidcInteractions: {
    path: "/fidc/interactions" as const,
    element: lazy(() => import("pages/fidc/interactions/index.page")),
  },
  fidcImport: {
    path: "/fidc/import" as const,
    element: lazy(() => import("pages/fidc/import/index.page")),
  },
  fidcAnticipations: {
    path: "/fidc/anticipations" as const,
    element: lazy(() => import("pages/fidc/anticipations/index.page")),
  },
  fidcAnticipationsById: {
    path: "/fidc/anticipations/:id" as const,
    element: lazy(() => import("pages/fidc/anticipations/index.page")),
  },
  fidcOperations: {
    path: "/fidc/operations" as const,
    element: lazy(() =>
      import("pages/operacao/lista/index.page").then((comp) => ({
        default: () => <comp.default pageType="fidc" />,
      })),
    ),
  },
  fidcOperationsById: {
    path: "/fidc/operations/:id" as const,
    element: lazy(() =>
      import("pages/operacao/[id]/index.page").then((comp) => ({
        default: comp.BorderoView,
      })),
    ),
  },
  fidcCampaign: {
    path: "/fidc/campaign" as const,
    element: lazy(() => import("pages/fidc/campaign/index.page")),
  },
  fidcFunds: {
    path: "/fidc/funds" as const,
    element: lazy(() => import("pages/fidc/funds/index.page")),
  },
  fidcReceivables: {
    path: "/fidc/receivables" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/index.page").then((comp) => ({
        default: () => <comp.ReceivablesComponents isFIDC={true} />,
      })),
    ),
  },
  fidcReceivablesBorderoById: {
    path: "/fidc/receivables/bordero/:id" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/bordero/[id]/index.page").then(
        (comp) => ({
          default: comp.BorderoImportadoView,
        }),
      ),
    ),
  },
  fidcEligibilityRules: {
    path: "/fidc/eligibility-rules" as const,
    element: lazy(() => import("pages/fidc/eligibility-rules/index.page")),
  },
  fidcValidations: {
    path: "/fidc/validations" as const,
    element: lazy(() => import("pages/fidc/validations/index.page")),
  },
  fidcBillEdition: {
    path: "/fidc/bill-edition" as const,
    element: lazy(() => import("pages/fidc/bill-edition/index.page")),
  },
  fidcBillEditionByFundId: {
    path: "/fidc/bill-edition/:fundId" as const,
    element: lazy(() => import("pages/fidc/bill-edition/index.page")),
  },
  fidcConsolidated: {
    path: "/fidc/consolidated" as const,
    element: lazy(() => import("pages/fidc/consolidated/index.page")),
  },
  onboardingByCompanyCnpj: {
    path: "/onboarding/:companyCnpj" as const,
    element: lazy(() => import("pages/onboarding/[companyCnpj]/index.page")),
  },
  taxas: {
    path: "/taxas" as const,
    element: lazy(() =>
      import("pages/taxas/index.page").then((comp) => ({
        default: comp.Taxas,
      })),
    ),
  },
  usuarios: {
    path: "/usuarios" as const,
    element: lazy(() => import("pages/usuarios/index.page")),
  },
  usuariosNovo: {
    path: "/usuarios/novo" as const,
    element: lazy(() => import("pages/usuarios/novo/index.page")),
  },
  usuariosDetalhesByIdentification: {
    path: "/usuarios/detalhes/:identification" as const,
    element: lazy(() =>
      import("pages/usuarios/detalhes/[identification]/index.page"),
    ),
  },
  recebiveisOperacoes: {
    path: "/recebiveis/operacoes" as const,
    element: lazy(() =>
      import("pages/operacao/lista/index.page").then((comp) => ({
        default: () => <comp.default pageType="invoice" />,
      })),
    ),
  },
  antecipacaoCartaoOperacoes: {
    path: "/antecipacao-cartao/operacoes" as const,
    element: lazy(() =>
      import("pages/operacao/lista/index.page").then((comp) => ({
        default: () => (
          <comp.default
            pageType="credit_card"
            innerContent={<ResumoTradesDiarios />}
          />
        ),
      })),
    ),
  },
  operacaoById: {
    path: "/operacao/:id" as const,
    element: lazy(() =>
      import("pages/operacao/[id]/index.page").then((comp) => ({
        default: comp.BorderoView,
      })),
    ),
  },
  cedentes: {
    path: "/cedentes" as const,
    element: lazy(() =>
      import("pages/empresas/cedentes/index.page").then((comp) => ({
        default: comp.CompanyList,
      })),
    ),
  },
  sacados: {
    path: "/sacados" as const,
    element: lazy(() =>
      import("pages/empresas/sacados/index.page").then((comp) => ({
        default: comp.SacadosCompanyList,
      })),
    ),
  },
  relacionamentos: {
    path: "/relacionamentos" as const,
    element: lazy(() =>
      import("pages/empresas/relacionamentos/index.page").then((comp) => ({
        default: comp.RelationshipsScreen,
      })),
    ),
  },
  dashboardOnboardings: {
    path: "/dashboard-onboardings" as const,
    element: lazy(() =>
      import(
        "pages/tier/_pages/dashboard-onboardings/dashboard-onboardings.component"
      ).then((comp) => ({
        default: comp.DashboardOnboardings,
      })),
    ),
  },
  recebiveisTitulosBorderoById: {
    path: "/recebiveis/titulos/bordero/:id" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/bordero/[id]/index.page").then(
        (comp) => ({
          default: comp.BorderoImportadoView,
        }),
      ),
    ),
  },
  recebiveisAprovacoes: {
    path: "/recebiveis/aprovacoes" as const,
    element: lazy(() =>
      import("pages/recebiveis/aprovacoes/index.page").then((comp) => ({
        default: comp.ApprovesPage,
      })),
    ),
  },
  recebiveisCobranca: {
    path: "/recebiveis/cobranca" as const,
    element: lazy(() => import("pages/recebiveis/cobranca/index.page")),
  },
  recebiveisCancelamento: {
    path: "/recebiveis/cancelamento" as const,
    element: lazy(() =>
      import("pages/recebiveis/cancelamento/index.page").then((comp) => ({
        default: comp.CancellationPage,
      })),
    ),
  },
  recebiveisTitulosById: {
    path: "/recebiveis/titulos/:id" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/[id]/index.page").then((comp) => ({
        default: comp.ReceivableStateMachineView,
      })),
    ),
  },
  recebiveisTitulos: {
    path: "/recebiveis/titulos" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/index.page").then((comp) => ({
        default: () => <comp.ReceivablesComponents isFIDC={false} />,
      })),
    ),
  },
  recebiveisEstrategiaComercial: {
    path: "/recebiveis/estrategia-comercial" as const,
    element: lazy(() =>
      import("pages/recebiveis/estrategia-comercial/index.page").then(
        (comp) => ({
          default: comp.EstrategiaComercialComponent,
        }),
      ),
    ),
  },
  recebiveisEstrategiasLiquidacao: {
    path: "/recebiveis/estrategias-liquidacao" as const,
    element: lazy(() =>
      import("pages/recebiveis/estrategias-liquidacao/index.page").then(
        (comp) => ({
          default: comp.EstrategiasLiquidacao,
        }),
      ),
    ),
  },
  recebiveisEstrategiasLiquidacaoCreate: {
    path: "/recebiveis/estrategias-liquidacao/create" as const,
    element: lazy(() =>
      import("pages/recebiveis/estrategias-liquidacao/create/index.page").then(
        (comp) => ({
          default: comp.EstrategiasLiquidacaoCreate,
        }),
      ),
    ),
  },
  recebiveisEstrategiasLiquidacaoEdit: {
    path: "/recebiveis/estrategias-liquidacao/:id/edit" as const,
    element: lazy(() =>
      import(
        "pages/recebiveis/estrategias-liquidacao/[id]/edit/index.page"
      ).then((comp) => ({
        default: comp.EstrategiasLiquidacaoEdit,
      })),
    ),
  },
  recebiveisCartao: {
    path: "/recebiveis-cartao" as const,
    element: lazy(() =>
      import("pages/recebiveis/titulos/index.page").then((comp) => ({
        default: () => <comp.ReceivablesComponents isFIDC={false} />,
      })),
    ),
  },
  recebiveisRelatoriosEmLote: {
    path: "/recebiveis/relatorios-em-lote" as const,
    element: lazy(() =>
      import("pages/recebiveis/relatorios-em-lote/index.page").then((comp) => ({
        default: comp.RelatoriosEmLotePage,
      })),
    ),
  },
  empresasClientes: {
    path: "/empresas/clientes" as const,
    element: lazy(() => import("pages/empresas/clientes/index.page")),
  },
  empresasClientesContaCorrenteNovaConta: {
    path: "/empresas/clientes/conta-corrente/nova-conta" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/conta-corrente/nova-conta/index.page"),
    ),
  },
  empresasClientesContaCorrenteContaAdicional: {
    path: "/empresas/clientes/conta-corrente/conta-adicional" as const,
    element: lazy(() =>
      import(
        "pages/empresas/clientes/conta-corrente/conta-adicional/index.page"
      ),
    ),
  },
  empresasClientesContaCorrenteConvite: {
    path: "/empresas/clientes/conta-corrente/convite" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/conta-corrente/convite/index.page"),
    ),
  },
  empresasClientesByIdentification: {
    path: "/empresas/clientes/:identification" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/[identification]/index.page").then(
        (comp) => ({
          default: comp.CompanyTabs,
        }),
      ),
    ),
  },
  empresasClientesUsuarioCriar: {
    path: "/empresas/clientes/usuario/criar" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/usuario/criar/index.page"),
    ),
  },
  empresasClientesDetalhesByIdByOnboardingType: {
    path: "/empresas/clientes/detalhes/:id/:onboardingType" as const,
    element: lazy(() =>
      import(
        "pages/empresas/clientes/detalhes/[id]/[onboardingType]/index.page"
      ),
    ),
  },
  empresasClientesOnboardingVerificarById: {
    path: "/empresas/clientes/onboarding/verificar/:id" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/onboarding/verificar/[id]/index.page"),
    ),
  },
  empresasTemplatesByType: {
    path: "/empresas/templates/:type" as const,
    element: lazy(() => import("pages/empresas/templates/[type]/index.page")),
  },
  empresasVnps: {
    path: "/empresas/vnps" as const,
    element: lazy(() => import("pages/empresas/vnps/index.page")),
  },
  empresasGrupos: {
    path: "/empresas/grupos" as const,
    element: lazy(() => import("pages/empresas/grupos/index.page")),
  },
  empresasGruposNovo: {
    path: "/empresas/grupos/novo" as const,
    element: lazy(() => import("pages/empresas/grupos/novo/index.page")),
  },
  empresasGruposByIdentification: {
    path: "/empresas/grupos/:identification" as const,
    element: lazy(() =>
      import("pages/empresas/grupos/[identification]/index.page"),
    ),
  },
  empresasGruposByIdentificationEdit: {
    path: "/empresas/grupos/:identification/edit" as const,
    element: lazy(() =>
      import("pages/empresas/grupos/[identification]/edit/index.page"),
    ),
  },
  empresasNova: {
    path: "/empresas/nova" as const,
    element: lazy(() => import("pages/empresas/nova/index.page")),
  },
  empresasEstrategiasOnboarding: {
    path: "/empresas/estrategias-onboarding" as const,
    element: lazy(() =>
      import("pages/empresas/estrategias-onboarding/index.page").then(
        (comp) => ({
          default: comp.EstrategiasOnboarding,
        }),
      ),
    ),
  },
  empresasEstrategiasOnboardingCreate: {
    path: "/empresas/estrategias-onboarding/create" as const,
    element: lazy(() =>
      import("pages/empresas/estrategias-onboarding/create/index.page").then(
        (comp) => ({
          default: comp.EstrategiasOnboardingCreate,
        }),
      ),
    ),
  },
  empresasEstrategiasOnboardingUpdate: {
    path: "/empresas/estrategias-onboarding/:id/edit" as const,
    element: lazy(() =>
      import("pages/empresas/estrategias-onboarding/[id]/index.page").then(
        (comp) => ({
          default: comp.EstrategiasOnboardingEdit,
        }),
      ),
    ),
  },
  empresasListaRestricoes: {
    path: "/empresas/lista-restricoes" as const,
    element: lazy(() =>
      import("pages/empresas/company-flag/index.page").then((comp) => ({
        default: comp.CompanyFlag,
      })),
    ),
  },
  logs: {
    path: "/logs" as const,
    element: lazy(() => import("pages/logs/index.page")),
  },
  recebiveisCurve: {
    path: "/recebiveis/curve" as const,
    element: lazy(() => import("pages/recebiveis/curve/index.page")),
  },
  antecipacaoCartaoAgendas: {
    path: "/antecipacao-cartao/agendas" as const,
    element: lazy(() => import("pages/antecipacao-cartao/agendas/index.page")),
  },
  antecipacaoCartaoRegistros: {
    path: "/antecipacao-cartao/registros" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/registros/index.page"),
    ),
  },
  antecipacaoCartaoAntecipacaoAutomatica: {
    path: "/antecipacao-cartao/antecipacao-automatica" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/antecipacao-automatica/index.page"),
    ),
  },
  antecipacaoCartaoRealocacao: {
    path: "/antecipacao-cartao/realocacao" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/realocacao/index.page"),
    ),
  },
  antecipacaoCartaoEntubaOperacoes: {
    path: "/antecipacao-cartao/entuba-operacoes" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/entuba-operacoes/index.page"),
    ),
  },
  antecipacaoCartaoCotacao: {
    path: "/antecipacao-cartao/cotacao" as const,
    element: lazy(() => import("pages/antecipacao-cartao/cotacao/index.page")),
  },
  antecipacaoCartaoEntubaOperacoesUpload: {
    path: "/antecipacao-cartao/entuba-operacoes/upload" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/entuba-operacoes/upload/index.page"),
    ),
  },
  antecipacaoCartaoOptin: {
    path: "/antecipacao-cartao/optin" as const,
    element: lazy(() => import("pages/antecipacao-cartao/optin/index.page")),
  },
  antecipacaoCartaoCobranca: {
    path: "/antecipacao-cartao/cobranca" as const,
    element: lazy(() => import("pages/antecipacao-cartao/cobranca/index.page")),
  },
  antecipacaoCartaoConvenios: {
    path: "/antecipacao-cartao/convenios" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/convenios/index.page").then((comp) => ({
        default: comp.CreditCardConvenioComponent,
      })),
    ),
  },
  antecipacaoCartaoTrades: {
    path: "/antecipacao-cartao/trades" as const,
    element: lazy(() => import("pages/antecipacao-cartao/trades/index.page")),
  },
  ferramentasAntecipacaoCartaoPriorizacaoAgendas: {
    path: "/ferramentas/antecipacao-cartao/priorizacao-agendas" as const,
    element: lazy(() =>
      import(
        "pages/ferramentas/antecipacao-cartao/priorizacao-agendas/index.page"
      ).then((comp) => ({
        default: comp.WhitelistsList,
      })),
    ),
  },
  antecipacaoCartaoDeflatores: {
    path: "/antecipacao-cartao/deflatores" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/deflatores/index.page").then((comp) => ({
        default: comp.DeflatoresList,
      })),
    ),
  },
  antecipacaoCartaoEmailAutomatico: {
    path: "/antecipacao-cartao/email-automatico" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/email-automatico/index.page").then(
        (comp) => ({
          default: comp.EmailAutomaticoList,
        }),
      ),
    ),
  },
  antecipacaoCartaoControladorLimiteCedente: {
    path: "/antecipacao-cartao/controlador-limite-cedente" as const,
    element: lazy(() =>
      import(
        "pages/antecipacao-cartao/controlador-limite-cedente/index.page"
      ).then((comp) => ({
        default: comp.LimiteCedenteList,
      })),
    ),
  },
  antecipacaoCartaoAgrupamentoCnpj: {
    path: "/antecipacao-cartao/agrupamento-cnpj" as const,
    element: lazy(() =>
      import("pages/antecipacao-cartao/agrupamento-cnpj/index.page"),
    ),
  },
  ferramentasAntecipacaoCartaoJobs: {
    path: "/ferramentas/antecipacao-cartao/jobs" as const,
    element: lazy(() =>
      import("pages/ferramentas/antecipacao-cartao/jobs/index.page"),
    ),
  },
  ferramentasAntecipacaoCartaoHealthCheck: {
    path: "/ferramentas/antecipacao-cartao/health-check" as const,
    element: lazy(() =>
      import("pages/ferramentas/antecipacao-cartao/health-check/index.page"),
    ),
  },
  ferramentasAntecipacaoCartaoRealocacaoGarantias: {
    path: "/ferramentas/antecipacao-cartao/realocacao-garantias" as const,
    element: lazy(() =>
      import(
        "pages/ferramentas/antecipacao-cartao/realocacao-garantias/index.page"
      ),
    ),
  },
  ferramentasAntecipacaoCartaoLogs: {
    path: "/ferramentas/antecipacao-cartao/logs" as const,
    element: lazy(() =>
      import("pages/ferramentas/antecipacao-cartao/logs/index.page"),
    ),
  },
  emprestimoAvalista: {
    path: "/emprestimos/avalistas/:uuid" as const,
    element: lazy(() =>
      import("pages/emprestimos/avalistas/[uuid]/index.page"),
    ),
  },
  emprestimosMonitor: {
    path: "/emprestimos/monitor" as const,
    element: lazy(() => import("pages/emprestimos/monitor/index.page")),
  },
  emprestimosMonitorById: {
    path: "/emprestimos/monitor/:id" as const,
    element: lazy(() => import("pages/emprestimos/monitor/[id]/index.page")),
  },
  emprestimosMonitorByLoanIdAvalistasByContractId: {
    path: "/emprestimos/monitor/:loanId/avalistas/:contractId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/monitor/[loanId]/avalistas/[contractId]/index.page"
      ),
    ),
  },
  emprestimosMonitorLogById: {
    path: "/emprestimos/monitor/log/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/monitor/log/[id]/index.page").then((comp) => ({
        default: comp.LogMonitorDetail,
      })),
    ),
  },
  emprestimosConvenios: {
    path: "/emprestimos/convenios" as const,
    element: lazy(() => import("pages/emprestimos/convenios/index.page")),
  },
  emprestimosParceiros: {
    path: "/emprestimos/parceiros" as const,
    element: lazy(() => import("pages/emprestimos/parceiros/index.page")),
  },
  emprestimosParceirosByPartnerNameByPartnerId: {
    path: "/emprestimos/parceiros/:partnerName/:partnerId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/parceiros/[partnerName]/[partnerId]/index.page"
      ),
    ),
  },
  emprestimosNovoConvenio: {
    path: "/emprestimos/novo-convenio" as const,
    element: lazy(() =>
      import("pages/emprestimos/convenios/novo-convenio/index.page"),
    ),
  },
  emprestimosConveniosByIdentification: {
    path: "/emprestimos/convenios/:identification" as const,
    element: lazy(() =>
      import("pages/emprestimos/convenios/[identification]/index.page"),
    ),
  },
  emprestimosGestorLimiteAdicionarLimite: {
    path: "/emprestimos/gestor-limite/adicionar-limite" as const,
    element: lazy(() =>
      import("pages/emprestimos/gestor-limite/adicionar-limite/index.page"),
    ),
  },
  emprestimosGestorLimiteAdicionarTransacaoById: {
    path: "/emprestimos/gestor-limite/adicionar-transacao/:id" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/gestor-limite/adicionar-transacao/[id]/index.page"
      ),
    ),
  },
  emprestimosGestorLimite: {
    path: "/emprestimos/gestor-limite" as const,
    element: lazy(() => import("pages/emprestimos/gestor-limite/index.page")),
  },
  emprestimosGestorLimiteById: {
    path: "/emprestimos/gestor-limite/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/gestor-limite/[id]/index.page"),
    ),
  },
  emprestimosGestorLimiteByIdGarantiaSafraByCollateralId: {
    path: "/emprestimos/gestor-limite/:id/garantia-safra/:collateralId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/gestor-limite/[id]/garantia-safra/[collateralId]/index.page"
      ),
    ),
  },
  emprestimosGestorLimiteByIdGarantiaImovelByCollateralId: {
    path: "/emprestimos/gestor-limite/:id/garantia-imovel/:collateralId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/gestor-limite/[id]/garantia-imovel/[collateralId]/index.page"
      ),
    ),
  },
  emprestimosGestorLimiteByIdAdicionarTaxa: {
    path: "/emprestimos/gestor-limite/:id/adicionar-taxa" as const,
    element: lazy(() =>
      import("pages/emprestimos/gestor-limite/[id]/adicionar-taxa/index.page"),
    ),
  },
  emprestimosGestorLimiteByIdFinancedAssetById: {
    path: "/emprestimos/gestor-limite/:id/ativo-financiado/:financedAssetId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/gestor-limite/[id]/ativo-financiado/[financedAssetId]/index.page"
      ),
    ),
  },
  emprestimosGestorLimiteByIdEditarTaxa: {
    path: "/emprestimos/gestor-limite/:id/editar-taxa" as const,
    element: lazy(() =>
      import("pages/emprestimos/gestor-limite/[id]/editar-taxa/index.page"),
    ),
  },
  emprestimosGestorLimiteByIdAdicionarAvalista: {
    path: "/emprestimos/gestor-limite/:id/adicionar-avalista" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/gestor-limite/[id]/adicionar-avalista/index.page"
      ),
    ),
  },
  emprestimosContingencia: {
    path: "/emprestimos/contingencia" as const,
    element: lazy(() => import("pages/emprestimos/contingencia/index.page")),
  },
  emprestimosContingenciaContratosByLoanContractsId: {
    path: "/emprestimos/contingencia/contratos/:loanContractsId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/contingencia/contratos/[loanContractsId]/index.page"
      ),
    ),
  },
  emprestimosRelatorios: {
    path: "/emprestimos/relatorios" as const,
    element: lazy(() => import("pages/emprestimos/relatorios/index.page")),
  },
  emprestimosWorkflows: {
    path: "/emprestimos/workflows" as const,
    element: lazy(() => import("pages/emprestimos/workflows/index.page")),
  },
  emprestimosCriarWorkflow: {
    path: "/emprestimos/workflows/configuracoes/criar-workflow" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/workflows/configuracoes/criar-workflow/index.page"
      ),
    ),
  },
  emprestimosConfiguracoesWorkflow: {
    path: "/emprestimos/workflows/configuracoes/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/workflows/configuracoes/[id]/index.page"),
    ),
  },
  emprestimosEditarWorkflow: {
    path: "/emprestimos/workflows/configuracoes/:id/editar" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/workflows/configuracoes/[id]/editar/index.page"
      ),
    ),
  },
  emprestimosLeadsWorkflow: {
    path: "/emprestimos/workflows/leads/:id/portal" as const,
    element: lazy(() =>
      import("pages/emprestimos/workflows/leads/[id]/portal/index.page"),
    ),
  },
  emprestimosEditarProcessador: {
    path: "/emprestimos/workflows/editar-processador/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/workflows/editar-processador/[id]/index.page"),
    ),
  },
  emprestimosLeadDetalhes: {
    path: "/emprestimos/workflows/leads/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/workflows/leads/[id]/index.page"),
    ),
  },
  emprestimosLeadUnidadeConsumidoraDetalhes: {
    path: "/emprestimos/workflows/leads/:id/unidade-consumidora/:unitId" as const,
    element: lazy(() =>
      import(
        "pages/emprestimos/workflows/leads/[id]/unidade-consumidora/[id]/index.page"
      ),
    ),
  },
  emprestimosFormularios: {
    path: "/emprestimos/formularios" as const,
    element: lazy(() => import("pages/emprestimos/formularios/index.page")),
  },
  emprestimosFormulariosById: {
    path: "/emprestimos/formularios/:id" as const,
    element: lazy(() =>
      import("pages/emprestimos/formularios/[id]/index.page"),
    ),
  },
  emprestimosGeradorContratos: {
    path: "/emprestimos/gerador-contratos" as const,
    element: lazy(() =>
      import("pages/emprestimos/gerador-contratos/index.page"),
    ),
  },
  ferramentasAMLMonitorDeReprovacoes: {
    path: "/ferramentas/aml/monitor-de-reprovacoes" as const,
    element: lazy(() =>
      import("pages/ferramentas/aml/monitoramento-de-reprovacoes/index.page"),
    ),
  },
  ferramentasFirmasEPoderes: {
    path: "/ferramentas/firmas-e-poderes/monitoramento-de-expiracao-de-poderes" as const,
    element: lazy(() =>
      import(
        "pages/ferramentas/firmas-e-poderes/monitoramento-de-expiracao-de-poderes/index.page"
      ),
    ),
  },
  ferramentasGatekeeperCompanies: {
    path: "/ferramentas/gatekeeper/companies" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/companies/index.page"),
    ),
  },
  ferramentasGatekeeperCompaniesByCnpj: {
    path: "/ferramentas/gatekeeper/companies/:cnpj" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/companies/[cnpj]/index.page"),
    ),
  },
  ferramentasSegurancaAntifraudeLiberacaoBiometria: {
    path: "/ferramentas/seguranca_antifraude/liberacao-biometria" as const,
    element: lazy(() =>
      import(
        "pages/ferramentas/seguranca-antifraude/liberacao-biometria/index.page"
      ).then((comp) => ({
        default: comp.AllowedList,
      })),
    ),
  },
  ferramentasSegurancaAntifraudeFraudsys: {
    path: "/ferramentas/seguranca-antifraude/fraudsys" as const,
    element: lazy(() =>
      import("pages/ferramentas/seguranca-antifraude/fraudsys/index.page"),
    ),
  },
  ferramentasClerkSystems: {
    path: "/ferramentas/clerk/systems" as const,
    element: lazy(() => import("pages/ferramentas/clerk/systems/index.page")),
  },
  ferramentasClerkSystemsNovo: {
    path: "/ferramentas/clerk/systems/novo" as const,
    element: lazy(() =>
      import("pages/ferramentas/clerk/systems/novo/index.page"),
    ),
  },
  ferramentasClerkSystemsById: {
    path: "/ferramentas/clerk/systems/:id" as const,
    element: lazy(() =>
      import("pages/ferramentas/clerk/systems/[id]/index.page"),
    ),
  },
  ferramentasRavenTemplates: {
    path: "/ferramentas/raven/templates" as const,
    element: lazy(() => import("pages/ferramentas/raven/templates/index.page")),
  },
  ferramentasMesas: {
    path: "/ferramentas/mesas" as const,
    element: lazy(() => import("pages/ferramentas/mesas/index.page")),
  },
  primeiroAcessoTermos: {
    path: "/primeiro-acesso/termos" as const,
    element: lazy(() =>
      import(
        "pages/empresas/clientes/_pages/first-access-terms/first-access-terms.component"
      ),
    ),
  },
  parceirosFeeds: {
    path: "/parceiros/feeds" as const,
    element: lazy(() => import("pages/parceiros/feeds/index.page")),
  },
  ferramentasGatekeeperCompanyNovo: {
    path: "/ferramentas/gatekeeper/company/novo" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/companies/novo/index.page"),
    ),
  },
  ferramentasGatekeeperUsers: {
    path: "/ferramentas/gatekeeper/users" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/users/index.page"),
    ),
  },
  ferramentasGatekeeperUsersByEmailByCnpj: {
    path: "/ferramentas/gatekeeper/users/:email/:cnpj?" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/users/[email]/[...cnpj]/index.page"),
    ),
  },
  ferramentasGatekeeperUserNovo: {
    path: "/ferramentas/gatekeeper/user/novo" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/users/novo/index.page"),
    ),
  },
  ferramentasGatekeeperSystems: {
    path: "/ferramentas/gatekeeper/systems" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/systems/index.page"),
    ),
  },
  ferramentasGatekeeperSystemsBySystemName: {
    path: "/ferramentas/gatekeeper/systems/:system_name" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/systems/[system_name]/index.page"),
    ),
  },
  ferramentasGatekeeperSystemsBySystemNovo: {
    path: "/ferramentas/gatekeeper/system/novo" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/systems/novo/index.page"),
    ),
  },
  ferramentasGatekeeperProfiles: {
    path: "/ferramentas/gatekeeper/profiles" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/profiles/index.page"),
    ),
  },
  ferramentasGatekeeperRoles: {
    path: "/ferramentas/gatekeeper/roles" as const,
    element: lazy(() =>
      import("pages/ferramentas/gatekeeper/roles/index.page"),
    ),
  },
  ferramentasElegibilidade: {
    path: "/ferramentas/elegibilidade" as const,
    element: lazy(() => import("pages/ferramentas/elegibilidade/index.page")),
  },
  ferramentasControladorDeLimiteByProduct: {
    path: "/ferramentas/controlador-de-limite/:product" as const,
    element: lazy(() =>
      import("pages/ferramentas/controlador-de-limite/[product]/index.page"),
    ),
  },
  ferramentasRoadwalker: {
    path: "/ferramentas/roadwalker" as const,
    element: lazy(() => import("pages/ferramentas/roadwalker/index.page")),
  },
  consultorNegociosMonitor: {
    path: "/consultor-negocios/monitor" as const,
    element: lazy(() => import("pages/consultor-negocios/monitor/index.page")),
  },
  bankingBankingManagerCompanies: {
    path: "/banking/banking-manager-companies" as const,
    element: lazy(() =>
      import("pages/banking/banking-manager-companies/index.page"),
    ),
  },
  bankingExtratoEletronico: {
    path: "/banking/extrato-eletronico" as const,
    element: lazy(() => import("pages/banking/extrato-eletronico/index.page")),
  },
  bankingBoletosConsulta: {
    path: "/banking/boletos/consulta" as const,
    element: lazy(() => import("pages/banking/boletos/consulta/index.page")),
  },
  bankingBankingManager: {
    path: "/banking/banking-manager" as const,
    element: lazy(() => import("pages/banking/banking-manager/index.page")),
  },
  bankingLimiteMais: {
    path: "/banking/limite-mais" as const,
    element: lazy(() => import("pages/banking/limite-mais/index.page")),
  },
  bankingCnabExtratoCadastro: {
    path: "/banking/cnab/extrato-eletronico/cadastro" as const,
    element: lazy(() =>
      import("pages/banking/cnab/extrato-eletronico/cadastro/index.page"),
    ),
  },
  bankingCnabExtratoHistorico: {
    path: "/banking/cnab/extrato-eletronico/historico" as const,
    element: lazy(() =>
      import("pages/banking/cnab/extrato-eletronico/historico/index.page"),
    ),
  },
  bankingCnabExtratoProcessamento: {
    path: "/banking/cnab/extrato-eletronico/processamento" as const,
    element: lazy(() =>
      import("pages/banking/cnab/extrato-eletronico/processamento/index.page"),
    ),
  },
  clientesEnergiaConvite: {
    path: "/clientes/energia/convite" as const,
    element: lazy(() =>
      import("pages/empresas/clientes/energia/convite/index.page"),
    ),
  },
  energiaEmpresas: {
    path: "/energia/empresas" as const,
    element: lazy(() => import("pages/energia/empresas/index.page")),
  },
  energiaEmpresasNovo: {
    path: "/energia/empresas/novo" as const,
    element: lazy(() => import("pages/energia/empresas/novo/index.page")),
  },
  energiaEmpresasDetalhesById: {
    path: "/energia/empresas/detalhes/:id" as const,
    element: lazy(() =>
      import("pages/energia/empresas/detalhes/[id]/index.page"),
    ),
  },
  energiaEmpresasById: {
    path: "/energia/empresas/:id" as const,
    element: lazy(() => import("pages/energia/empresas/[id]/index.page")),
  },
  energiaMigration: {
    path: "/energia/migration" as const,
    element: lazy(() => import("pages/energia/migration/index.page")),
  },
  energiaCalculator: {
    path: "/energia/calculator" as const,
    element: lazy(() => import("pages/energia/calculator/index.page")),
  },
  energiaCalculatorRegister: {
    path: "/energia/calculator-register" as const,
    element: lazy(() => import("pages/energia/calculator-register/index.page")),
  },
  energiaRegistroCurva: {
    path: "/energia/registro-curva", // TODO: remover quando resolverem o problema de menu  as consts
    element: lazy(() => import("pages/energia/calculator-register/index.page")),
  },
  energiaTermosEmpresasFormById: {
    path: "/energia/termos-empresas/form/:id" as const,
    element: lazy(() =>
      import("pages/energia/termos-empresas/form/index.page").then((comp) => ({
        default: comp.CreateEnergyCompaniesWrapper,
      })),
    ),
  },
  energiaTermosEmpresasForm: {
    path: "/energia/termos-empresas/form" as const,
    element: lazy(() =>
      import("pages/energia/termos-empresas/form/index.page").then((comp) => ({
        default: comp.CreateEnergyCompaniesWrapper,
      })),
    ),
  },
  energiaTermosEmpresas: {
    path: "/energia/termos-empresas" as const,
    element: lazy(() => import("pages/energia/termos-empresas/index.page")),
  },
  energiaLeads: {
    path: "/energia/leads" as const,
    element: lazy(() => import("pages/energia/leads/index.page")),
  },
  emprestimosCobranca: {
    path: "/emprestimos/cobranca" as const,
    element: lazy(() => import("pages/emprestimos/cobranca/index.page")),
  },
  tiers: {
    path: "/tiers" as const,
    element: lazy(() => import("pages/tier/index.page")),
  },
  tiersByIdRelationships: {
    path: "/tiers/:id/relationships" as const,
    element: lazy(() => import("pages/tier/[id]/relationships/index.page")),
  },
  limitCedentes: {
    path: "/limit/cedentes" as const,
    element: lazy(() =>
      import("pages/tier/_pages/cedente-limit/cedente-limit.component"),
    ),
  },
  limitCedentesNew: {
    path: "/limit/cedentes/new" as const,
    element: lazy(() =>
      import("pages/tier/_pages/cedente-limit/cedente-limit-create.component"),
    ),
  },
  cedentelimitsByIdSacadosNew: {
    path: "/cedentelimits/:id/sacados/new" as const,
    element: lazy(() =>
      import(
        "pages/tier/_pages/cedente-sacado-limit/cedente-sacado-limit-create.component"
      ),
    ),
  },
  limitCedentesByIdEdit: {
    path: "/limit/cedentes/:id/edit" as const,
    element: lazy(() =>
      import("pages/tier/_pages/cedente-limit/cedente-limit-create.component"),
    ),
  },
  tierSacados: {
    path: "/tier/sacados" as const,
    element: lazy(() => import("pages/tier/sacados/index.page")),
  },
  tierSacadosNew: {
    path: "/tier/sacados/new" as const,
    element: lazy(() => import("pages/tier/sacados/new/index.page")),
  },
  tierSacadosByIdEdit: {
    path: "/tier/sacados/:id/edit" as const,
    element: lazy(() => import("pages/tier/sacados/new/index.page")),
  },
  tierUpload: {
    path: "/tier/upload" as const,
    element: lazy(() => import("pages/tier/upload/index.page")),
  },
  b2B: {
    path: "/b2b/*" as const,
    element: lazy(() => import("pages/b2b/[...pathname]/index.page")),
  },
  cartoesMotorDeOfertasArvoresCredito: {
    path: "/cartoes/motor-de-ofertas/arvores/credito" as const,
    element: lazy(() =>
      import("pages/cartoes/motor-de-ofertas/arvores/index.page").then(
        (comp) => ({
          default: () => <comp.CardEligibilityTrees productKind="CREDIT" />,
        }),
      ),
    ),
  },
  cartoesMotorDeOfertasArvoresDebito: {
    path: "/cartoes/motor-de-ofertas/arvores/debito" as const,
    element: lazy(() =>
      import("pages/cartoes/motor-de-ofertas/arvores/index.page").then(
        (comp) => ({
          default: () => <comp.CardEligibilityTrees productKind="DEBIT" />,
        }),
      ),
    ),
  },
  cartoesMotorDeOfertasOfertas: {
    path: "/cartoes/motor-de-ofertas/ofertas" as const,
    element: lazy(() =>
      import("pages/cartoes/motor-de-ofertas/ofertas/index.page"),
    ),
  },
  cartoesMotorDeOfertasReprocessarElegibilidade: {
    path: "/cartoes/motor-de-ofertas/reprocessar-elegibilidade" as const,
    element: lazy(() =>
      import(
        "pages/cartoes/motor-de-ofertas/reprocessar-elegibilidade/index.page"
      ),
    ),
  },
  cartoesMotorDeOfertasReprocessarEntuba: {
    path: "/cartoes/motor-de-ofertas/entuba" as const,
    element: lazy(() =>
      import("pages/cartoes/motor-de-ofertas/entuba/index.page"),
    ),
  },
  cartoesDashboard: {
    path: "/cartoes/dashboard" as const,
    element: lazy(() => import("pages/cartoes/dashboard/index.page")),
  },
  cartoesListOffers: {
    path: "/cartoes/contratacoes" as const,
    element: lazy(() => import("pages/cartoes/contratacoes/index.page")),
  },
  empresasById: {
    path: "/empresas/:id" as const,
    element: lazy(() => import("pages/empresas/[id]/index.page")),
  },
};

export function fillRouteParams<Path extends string = string>(
  path: Path,
  params: ExtractRouteParams<Path>,
) {
  let route = path as string;
  Object.entries(params).forEach(([key, value]) => {
    route = route.replace(`:${key}`, `${value}`);
  });

  return route;
}

export function AdminRoutes() {
  return (
    <Suspense fallback={<OLoader absolute backdrop />}>
      <Switch>
        {Object.values(routes).map((route) => {
          const Component = route.element;

          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              render={() => <Component />}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
