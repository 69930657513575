import { createGlobalStyle } from "styled-components";

export const DateRangeGlobalStyle = createGlobalStyle`
  .o-input-date-dropdown-range .o-input-date-panel {
    display: unset;
    vertical-align: unset;
    padding: unset;
    box-shadow: unset;
    background-color: unset;
    border-radius: unset;
  }

  .o-input-date-range {
   position: relative;
  }

  .o-input-date-range-separator {
    margin: var(--font-xs);
  }
`;
