o-skeleton-card {
  .o-skeleton-card {
    background-image: $skeletonBackground;
    animation: $skeletonAnimation;

    &--rounded {
      border-radius: var(--border-radius-xxs);
    }
  }
}
