o-divider {
  --divider-light: var(--theme-dark-10);
  --divider-dark: var(--theme-dark-60);

  &.position-horizontal {
    display: block;
  }

  .o-divider {
    box-sizing: border-box;
    border: 0 solid;
    margin: 0;

    &.position-horizontal {
      width: 100%;
    }

    &.position-vertical {
      width: 1px;
      height: 100%;
      margin: 0 auto;
      align-self: normal;
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        &.position-horizontal {
          border-bottom-width: calc(var(--font-#{$size}) / 4);
        }

        &.position-vertical {
          border-left-width: calc(var(--font-#{$size}) / 4);
        }
      }
    }

    @each $type in $types {
      &--type-#{$type} {
        border-color: var(--divider-#{$type});
      }
    }
  }
}
