import styled from "styled-components";
// @ts-ignore
import is, { match } from "styled-is";
import { IContainerSpacingProps } from "./container-spacing.component";

export const StyledContainerSpacing = styled.div<IContainerSpacingProps>`
  width: 96%;
  margin: 0 2%;

  @media screen and (min-width: 1100px) {
    width: 98%;
    margin: 0 1%;
  }

  ${is("card")`
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 13px 0 rgba(62, 44, 90, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid #e0e0e0;
  `};

  ${is("spacingOnTop")`
    margin: 1%;

    @media screen and (min-width: 1100px) {
      margin: 2%;
    }
  `};

  ${match("themeType", "hub")`
    padding: 0 3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: none;
  `}
`;

export const HubCard = styled.div`
  box-shadow: 0 2px 2px #e0e2e7;
  padding: 20px 35px;
  background-color: #ffffff;
  border-radius: 8px;
  height: 100%;
  h3 {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 15px 0;
  }
  p {
    color: #7e8798;
    font-size: 14px;
    margin: 0 0 15px 0;
  }
`;
