import React from "react";
import { Header } from "./header";
import Sidebar from "./sidebar/sidebar";
import ThemeSettings from "./theme-settings";
import SAColorProfiles from "./theme-settings/smart-admin-color-profiles";
import { env } from "utils";

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-inner">
          <Sidebar />
          <div className="page-content-wrapper">
            <Header />
            <div className="page-content">
              <>
                <div className="alert alert-danger" role="alert">
                  Olá, informamos que este portal será descontinuado em{" "}
                  <strong>01/10/2024</strong>. Convidamos você a experimentar
                  nosso <a href={env.NEW_ADMIN_URL}>portal novo</a>. Se tiver
                  alguma dúvida, sugestão, feedback ou se sentir falta de alguma
                  funcionalidade no novo portal, por favor, entre em contato
                  conosco pelo e-mail:{" "}
                  <a href="mailto:OL-IT-BTGEmpresas-Web@btgpactual.com">
                    OL-IT-BTGEmpresas-Web@btgpactual.com
                  </a>
                  . Agradecemos pela sua compreensão e colaboração.
                </div>
                {children}
              </>
            </div>
          </div>
        </div>
      </div>
      <ThemeSettings />
      <SAColorProfiles />
    </>
  );
};
