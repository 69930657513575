o-input-pin {
  .o-input-pin {
    font-family: inherit;
    margin-right: var(--font-xs);
    text-align: center;
    border: var(--border);
    border-radius: var(--border-radius-xxs);
    background: inherit;
    transition: var(--transition-primary);

    &:hover:not(:disabled) {
      border-color: var(--border-color-hover);
    }

    &:focus:not(:disabled) {
      border-color: var(--theme-focus);
    }

    &:disabled {
      opacity: var(--disabled-opacity);
      background-color: var(--theme-dark-05);
      cursor: not-allowed;
    }

    &--error {
      color: var(--theme-danger);
      border-color: var(--theme-danger);
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        width: calc(var(--font-#{$size}) * var(--base-height));
        height: calc(var(--font-#{$size}) * var(--base-height) * 1.25);
      }
    }
  }
}
