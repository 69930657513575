// declare module process {
//   const env: Record<string, string>
// }

export const env = {
  API_FSL: process.env.REACT_APP_API_FSL,
  OIDC_URL:
    process.env.REACT_APP_OIDC_URL || "https://id.dev.empresas.btgpactual.com",
  DD_APPLICATION_ID:
    process.env.DD_APPLICATION_ID || "40f95637-e5ec-4209-8d5c-7c8a40c59fde",
  DD_CLIENT_TOKEN:
    process.env.DD_CLIENT_TOKEN || "pub5896918b4028b0a526900c9a391a56f7",
  DD_SERVICE: process.env.DD_SERVICE || "apps-hub",
  DD_ENV: process.env.REACT_APP_DD_ENV,
  // env
  REACT_APP_PROJECT_ENV: process.env.REACT_APP_PROJECT_ENV,
  NODE_ENV: process.env.NODE_ENV,
  // Amplify
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  AMAZON_COGNITO_DOMAIN: process.env.REACT_APP_AMAZON_COGNITO_DOMAIN,
  OAUTH_REDIRECT_SIGN: process.env.REACT_APP_OAUTH_REDIRECT_SIGN,
  REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK:
    process.env.REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK,
  OAUTH_REDIRECT_SIGNOUT: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  AMAZON_COGNITO_PROVIDER: process.env.REACT_APP_AMAZON_COGNITO_PROVIDER,
  // APIs
  CLERK_API: process.env.REACT_APP_API_CLERK,
  GATEKEEPER_URL: process.env.REACT_APP_API_GATEKEEPER,
  GATEHOLDER_API: process.env.REACT_APP_API_GATEHOLDER,
  QUICKFIN_API: process.env.REACT_APP_API_URL,
  ROAD_SENNA: process.env.REACT_APP_ROAD_SENNA,
  TURTLE_FEE: process.env.REACT_APP_TURTLE_FEE,
  ROADWALKER_API: process.env.REACT_APP_ROADWALKER,
  // CoreFront
  COREFRONT_URL: process.env.REACT_APP_PORTAL_CORE_FRONT_URL,
  EMBEDDED_URL: process.env.REACT_APP_PORTAL_EMBEDDED_URL,

  API_LOAN: process.env.REACT_APP_API_LOAN,
  API_FX: process.env.REACT_APP_API_FX,
  INTEGRATION_URL: process.env.REACT_APP_INTEGRATION_URL,
  PAPERCLIPV2_URL: process.env.REACT_APP_PAPERCLIPV2_URL,
  PORTAL_URL: process.env.REACT_APP_PORTAL_URL,
  QUICKFIN_APP: process.env.REACT_APP_QUICKFIN_APP,
  PORTAL_BTG_BUSINESS_URL: process.env.REACT_APP_PORTAL_BTG_BUSINESS_URL,
  PORTAL_CORE_FRONT_URL: process.env.REACT_APP_PORTAL_CORE_FRONT_URL,
  PORTAL_B2B: process.env.REACT_APP_PORTAL_B2B,
  PAPERCLIP_URL: process.env.REACT_APP_PAPERCLIP_URL,
  RECEBIVEIS_CARTAO_URL: process.env.REACT_APP_RECEBIVEIS_CARTAO_URL,
  URL_WHITELABEL: process.env.REACT_APP_URL_WHITELABEL,
  API_PARCEIROS: process.env.REACT_APP_API_PARCEIROS,
  HUB_PARTNERS: process.env.REACT_APP_HUB_PARTNERS,
  CORBAN_URL: process.env.REACT_APP_CORBAN_URL,
  API_B2B: process.env.REACT_APP_API_B2B,
  STATIC: process.env.REACT_APP_STATIC,
  URL_BANKINGADMIN: process.env.REACT_APP_URL_BANKINGADMIN,
  API_BANKING: process.env.REACT_APP_API_BANKINGHUB,
  URL_BANKSLIPISSUE: process.env.REACT_APP_URL_BANKSLIPISSUE,
  API_ONBOARDING: process.env.REACT_APP_API_ONBOARDING,
  FIDC_URL: process.env.REACT_APP_FIDC_URL,
  FIDC_BANKSLIP_MANAGER_URL: process.env.REACT_APP_FIDC_BANKSLIP_MANAGER_URL,
  ENERGY_URL: process.env.REACT_APP_ENERGY_URL,
  ENERGY_LEADS_URL: process.env.REACT_APP_ENERGY_LEADS_URL,
  CALCULATOR_URL: process.env.REACT_APP_CALCULATOR_URL,
  CALCULATOR_VERSION: process.env.REACT_APP_CALCULATOR_VERSION,
  API_COLLECTION: process.env.REACT_APP_API_COLLECTION,
  GATEKEEPERFRAUD_URL: process.env.REACT_APP_GATEKEEPERFRAUD_URL,
  URL_DIEBOLD: process.env.REACT_APP_URL_DIEBOLD,
  API_CUSTOMER: process.env.REACT_APP_API_CUSTOMER,
  API_CREDIT_MANAGER: process.env.REACT_APP_API_CREDIT_MANAGER,
  API_RAVEN: process.env.REACT_APP_API_RAVEN,
  ASSETS: process.env.REACT_APP_ASSETS,
  API_RELATIONSHIPS: process.env.REACT_APP_API_RELATIONSHIPS,
  CARDS_OFFER_ENGINE_URL: process.env.REACT_APP_CARDS_OFFER_ENGINE_URL,
  WEBSITE_WHITELABEL: process.env.REACT_APP_WEBSITE_WHITELABEL,
  LIMIT_CONTROL_URL: process.env.REACT_APP_LIMIT_CONTROL_URL,
  NEW_ADMIN_URL: process.env.REACT_APP_NEW_ADMIN_URL,
};
