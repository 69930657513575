import { Component, h, Prop } from "@stencil/core";
import QRCodeStyling from "qr-code-styling";

@Component({
  tag: "o-qrcode",
})
export class QRCode {
  /**
   * Conteúdo do QR Code.
   *
   * @sbCategory Input
   */
  @Prop() content!: string;
  /**
   * Tamanho em pixels do QR Code.
   *
   * @sbCategory Style
   */
  @Prop() size!: number;

  private element?: HTMLDivElement;

  componentDidRender() {
    const qrCode = new QRCodeStyling({
      data: this.content,
      width: this.size,
      height: this.size,
      type: "svg",
      dotsOptions: {
        color: "var(--theme-dark)",
        type: "rounded",
      },
      backgroundOptions: {
        color: 'transparent',
      }
    });

    if (!this.element || !qrCode._svg) return;

    this.element.append(qrCode._svg);
  }

  render() {
    return <div ref={(el) => (this.element = el)} />;
  }
}
