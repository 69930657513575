import { Component, h } from "@stencil/core";

@Component({
  tag: "o-banner-info",
  styleUrl: "index.scss",
})
export class BannerInfo {
  render() {
    return <slot />;
  }
}
