import moment from "moment";

export function validateDate(date: string) {
  const parsedDate = moment(date, "DD/MM/YYYY");
  const age = moment().diff(parsedDate, "years");

  if (age < 18) {
    return "É necessário ter no mínimo 18 anos.";
  }

  if (!parsedDate.isValid()) {
    return "Insira uma data válida.";
  }

  return null;
}

export function checkValidDate(date: string) {
  if (!date) {
    return null;
  }

  const parsedDate = moment(date, "DD/MM/YYYY");
  if (parsedDate.isValid()) {
    return null;
  }

  return "Insira uma data válida.";
}
