import {
  Component,
  Event,
  EventEmitter,
  h,
  Listen,
  Prop,
  Watch,
} from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-radio",
  styleUrl: "index.scss",
})
export class Radio {
  /** Evento emitido ao selecionar o radio. */
  @Event() input!: EventEmitter<string>;

  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Valor atual do input. Funciona como identificador do o-radio.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Propriedade `checked` do radio button. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) checked = false;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "sm";
  /**
   * Cor do radio.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";

  private inputElement?: HTMLInputElement;

  @Listen("input")
  handleInput() {
    this.checked = this.inputElement?.checked ?? false;
  }

  /**
   * Alter `checked` property of native input when <o-radio> `checked` is set
   * externally
   */
  @Watch("checked")
  handleSetChecked(newChecked: boolean) {
    if (this.inputElement) this.inputElement.checked = newChecked;
    this.input.emit();
  }

  render() {
    const { id, value, size, type, disabled, name, checked } = this;

    const classes = {
      "o-radio__input": true,
      [`o-radio__input--size-${size}`]: true,
      [`o-radio__input--type-${type}`]: true,
    };

    return (
      <input
        ref={(el) => (this.inputElement = el)}
        type="radio"
        id={`o-${id}`}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        class={classes}
      />
    );
  }
}
