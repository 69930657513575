import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
} from "@stencil/core";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-confirmation-modal",
})
export class ConfirmationModal {
  /** Reference to the webcomponent DOM element */
  @Element() self!: HTMLElement;

  /** Evento emitido ao clicar no botão de confirmar. */
  @Event({ bubbles: true }) confirm!: EventEmitter;

  /**
   * Id do modal.
   *
   * @sbCategory Input
   */
  @Prop() modalId!: string;
  /**
   * Faz com que um clique no backdrop feche o modal.
   *
   * @sbCategory Input
   */
  @Prop() backdrop = true;
  /**
   * Título do modal.
   *
   * @sbCategory Input
   */
  @Prop() title!: string;
  /**
   * Exibe um `o-loader` no corpo do modal.
   *
   * @sbCategory Input
   */
  @Prop() loading = false;
  /**
   * Texto do botão de confirmação.
   *
   * @sbCategory Input
   */
  @Prop() confirmLabel = "Confirmar";
  /**
   * Texto do botão de fechar.
   *
   * @sbCategory Input
   */
  @Prop() closeLabel = "Fechar";
  /**
   * Controla a prop `divider` do `o-modal-footer`.
   *
   * @sbCategory Style
   */
  @Prop() divider = true;
  /**
   * Arredonda as bordas do modal.
   *
   * @sbCategory Style
   */
  @Prop() rounded = true;
  /**
   * Largura do modal.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do background do modal.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";

  /** Check if <div slot="footer"> is declared */
  private hasChildren = false;

  private cancelHandler() {
    document.dispatchEvent(
      new CustomEvent("modalClose", { detail: { id: this.modalId } })
    );
  }

  componentWillLoad() {
    const children = this.self.querySelectorAll("[slot='footer']");

    this.hasChildren = !!children.length;
  }

  render() {
    const {
      modalId,
      title,
      loading,
      divider,
      type,
      size,
      backdrop,
      rounded,
      confirmLabel,
      closeLabel,
      confirm,
      hasChildren,
    } = this;

    return (
      <o-modal
        id={modalId}
        position="center"
        backdrop={backdrop}
        rounded={rounded}
        type={type}
        size={size}
      >
        <div>
          {loading && <o-loader absolute backdrop></o-loader>}
          <o-modal-header close-button divider={false}>
            <o-typography tag="h2" size="lg" weight="normal">
              {title}
            </o-typography>
          </o-modal-header>
          <o-modal-body>
            <slot />
          </o-modal-body>
          <o-modal-footer divider={divider}>
            <div>
              <slot name="footer" />
              {!hasChildren && (
                <div class="d-flex justify-content-end gap-2">
                  {closeLabel && (
                    <o-button
                      dataLabel="fechar-modal"
                      dataAction="clique:botao"
                      outline
                      onClick={() => this.cancelHandler()}
                    >
                      {closeLabel}
                    </o-button>
                  )}

                  {confirmLabel && (
                    <o-button
                      dataLabel="confirmacao-modal"
                      dataAction="clique:botao"
                      type="tertiary"
                      onClick={() => confirm.emit()}
                    >
                      {confirmLabel}
                    </o-button>
                  )}
                </div>
              )}
            </div>
          </o-modal-footer>
        </div>
      </o-modal>
    );
  }
}
