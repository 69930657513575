import { Component, Element, Event, EventEmitter, h } from "@stencil/core";

@Component({
  tag: "o-filter-header",
  styleUrl: "index.scss",
})
export class FilterHeader {
  @Element() self!: HTMLElement;

  /** Evento emitido ao fechar o modal */
  @Event({ bubbles: true }) modalClose!: EventEmitter<{ id: string }>;

  private handleClose = () => {
    const id = this.self.closest("o-modal")?.id;

    if (id) this.modalClose.emit({ id });
  };

  render() {
    const { handleClose } = this;

    return (
      <div class="o-filter-header">
        <div class="flex">
          <div tabindex="0" class="slot-container">
            <slot />
          </div>

          <button type="dark" onClick={handleClose}>
            <o-icon category="fal" icon="fa-times" size="md"></o-icon>
          </button>
        </div>

        <div class="o-filter-header__divider">
          <o-divider />
        </div>
      </div>
    );
  }
}
