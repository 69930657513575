import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-skeleton-avatar",
  styleUrl: "index.scss",
})
export class SkeletonAvatar {
  /**
   * Tamanho do avatar.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  render() {
    const classes = {
      "o-skeleton-avatar": true,
      [`o-skeleton-avatar--size-${this.size}`]: true,
    };

    return <div class={classes} />;
  }
}
