import * as React from "react";
import { gray, darkGray, red } from "components/theme/color";

export type TextStyleType = {
  title: React.CSSProperties;
  title2: React.CSSProperties;
  heading: React.CSSProperties;
  // content: React.CSSProperties;
  subheading: React.CSSProperties;
  paragraph: React.CSSProperties;
  bold: React.CSSProperties;
  simple: React.CSSProperties;
  infoTitle: React.CSSProperties;
  infoValue: React.CSSProperties;
  display1: React.CSSProperties;
  display2: React.CSSProperties;
  display3: React.CSSProperties;
  display4: React.CSSProperties;
  display5: React.CSSProperties;
  display6: React.CSSProperties;
};

const baseDisplay = {
  fontFamily: "Open Sans",
  fontSize: 14,
};

const styles: TextStyleType = {
  title: {
    fontSize: "26px",
  },
  title2: {
    fontWeight: 300,
    fontSize: "24px",
  },
  heading: {
    fontSize: "18px",
  },
  subheading: {
    fontSize: "16px",
  },
  paragraph: {
    textAlign: "justify",
    fontWeight: 300,
  },
  display1: {
    ...baseDisplay,
    color: gray,
  },
  display2: {
    ...baseDisplay,
    color: darkGray,
  },
  display3: {
    ...baseDisplay,
    color: darkGray,
    fontWeight: 700,
  },
  display4: {
    ...baseDisplay,
    color: red,
    fontWeight: 700,
  },
  display5: {
    ...baseDisplay,
    color: red,
  },
  display6: {
    ...baseDisplay,
    fontSize: "20px",
  },
  bold: {
    ...baseDisplay,
    fontWeight: 700,
  },
  simple: {
    fontWeight: 300,
  },
  infoTitle: {
    color: "#fff",
    fontWeight: 200,
    fontSize: "0.7em",
  },
  infoValue: {
    color: "#fff",
    fontWeight: 500,
    fontSize: "0.9em",
  },
};

export default styles;
