import { Component, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-divider",
  styleUrl: "index.scss",
})
export class Divider {
  /**
   * Largura do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: "xxs" | "xs" | "sm" = "xs";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: "light" | "dark" = "light";
  /**
   * Orientação do componente.
   *
   * @sbCategory Style
   */
  @Prop() position: "horizontal" | "vertical" = "horizontal";

  render() {
    const { size, type, position } = this;

    const classes = {
      [`position-${position}`]: true,
    };

    const hrClasses = {
      "o-divider": true,
      [`o-divider--size-${size}`]: true,
      [`o-divider--type-${type}`]: true,
      [`position-${position}`]: true,
    };

    return (
      <Host class={classes}>
        <hr class={hrClasses} />
      </Host>
    );
  }
}
