import VMasker from "vanilla-masker";
import moment from "moment";

type maskType =
  | "CNPJ"
  | "CPF"
  | "Date"
  | "Tax"
  | "Identification"
  | "Phone"
  | "DateTime";

export enum maskTypes {
  cnpj = "CNPJ",
  cpf = "CPF",
  date = "Date",
  tax = "Tax",
  identification = "Identification",
  phone = "Phone",
  dateTime = "DateTime",
}

export function priceToBrl(price: number): string {
  if (typeof price == "number") {
    const value = price.toFixed(2).split(".");
    value[0] = "R$ " + value[0].split(/(?=(?:...)*$)/).join(".");
    return value.join(",");
  }
  return "";
}

export function genericMask(
  content: string | number | Date | null | undefined,
  maskType: maskType,
): string | number | Date | Error | null | undefined {
  if (content === null || content === undefined) {
    return content;
  }

  switch (maskType) {
    case maskTypes.identification:
      const contentStr = content.toString();
      if (contentStr.length === 11) {
        return VMasker.toPattern(content as string, "999.999.999-99");
      } else if (contentStr.length === 14) {
        return VMasker.toPattern(content as string, "99.999.999/9999-99");
      } else {
        return "-";
      }
    case maskTypes.cpf:
      return VMasker.toPattern(content as string, "999.999.999-99");
    case maskTypes.phone:
      return VMasker.toPattern(content as string, "99 (99) 99999-9999").slice(
        2,
      );
    case maskTypes.cnpj:
      return VMasker.toPattern(content as string, "99.999.999/9999-99");
    case maskTypes.date:
      return moment(content).format("DD/MM/YYYY");
    case maskTypes.dateTime:
      return moment(content).format("DD/MM/YYYY hh:mm");
    case maskTypes.tax:
      return typeof content === "number"
        ? content.toFixed(4).replace(".", ",")
        : new Error("O valor informado não é um Number");
    default:
      return content;
  }
}
