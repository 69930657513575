o-dropdown-item {
  &.o-dropdown-item {
    font-family: inherit;
    padding: var(--font-sm);
    background-color: var(--theme-light);
    cursor: pointer;

    &:hover {
      background-color: var(--theme-neutral-light);
    }

    &:active {
      background-color: var(--theme-neutral-normal);
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
}
