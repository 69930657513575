import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-modal-footer",
  styleUrl: "index.scss",
})
export class ModalFooter {
  /**
   * Adiciona um `o-divider` acima do conteúdo do _footer_.
   *
   * @sbCategory Input
   */
  @Prop() divider = false;

  render() {
    const { divider } = this;

    return (
      <div tabindex="0" class="o-modal-footer slot-container">
        {divider && (
          <div class="o-modal-footer__divider">
            <o-divider />
          </div>
        )}
        <slot />
      </div>
    );
  }
}
