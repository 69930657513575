import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Method,
  Prop,
  State,
} from "@stencil/core";

@Component({
  tag: "o-filter-item",
  styleUrl: "index.scss",
})
export class FilterItem {
  @Element() self!: HTMLElement;
  /**
   * Estado do componente.
   *
   * @sbCategory Style
   */
  @Prop() expanded = false;
  /**
   * Mostrar Notification Badge
   *
   * @sbCategory Style
   */
  @Prop() notification = true;
  /**
   * Mostrar botão "Remover"
   *
   * @sbCategory Input
   */
  @Prop() clean = true;
  /**
   * Valor utilizado por ofilter.clearFilter() ao clicar "Remover"
   *
   * @sbCategory Input
   */
  @Prop() cleanValues?: Record<string, any>;
  /**
   * Valor utilizado por ofilter.filterHasValue() para verificar se o
   * Notification Badge e botão "Remover" devem aparecer
   *
   * @sbCategory Input
   */
  @Prop() valuesToIgnore: string[] = [];

  /** Evento emitido ao clicar em remover. */
  @Event() remove!: EventEmitter<Record<string, any>>;

  @State() filterHasValue: boolean = false;

  /** Referência ao elemento flutuante */
  private filterElement?: HTMLOFilterElement;

  /** Metodo para aplicar mudanças de ofilter.filterHasValue() */
  @Method()
  async applyChanges(hasValue?: boolean) {
    this.filterHasValue =
      hasValue ??
      ((this.notification || this.clean) &&
        !!(await this.filterElement?.filterHasValue(this.valuesToIgnore)));
  }

  private async onRemove() {
    this.remove.emit(await this.filterElement?.clearFilter(this.cleanValues));
    this.applyChanges(false);
  }

  componentDidLoad() {
    this.applyChanges();
  }

  render() {
    return (
      <o-filter
        ref={(el) => {
          this.filterElement = el;
        }}
      >
        <o-collapse expanded={this.expanded} type="light" size="md">
          <o-collapse-header>
            <div class="d-flex align-items-center gap-2 flex-grow-1">
              {this.notification && this.filterHasValue && (
                <o-icon type="info" category="fa" icon="fa-circle" size="sm" />
              )}
              <slot name="title" />
            </div>
            {this.clean && this.filterHasValue && (
              <o-link
                class="px-4"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onRemove();
                }}
              >
                Remover
              </o-link>
            )}
          </o-collapse-header>
          <o-collapse-content class="o-filter-item-content">
            <slot />
          </o-collapse-content>
        </o-collapse>
      </o-filter>
    );
  }
}
