@import "../../../../../node_modules/@yaireo/tagify/dist/tagify.css";

$inputHeight: 2.5;
$marginBottomTag: 2.75;
$dropdownBoxShadow: 0 3px 6px -4px var(--theme-dark-15), 0 6px 16px 0 var(--theme-dark-10), 0 9px 28px 8px var(--theme-dark-05);
$dropdownMaxHeight: 14.5rem;

body .tagify__dropdown {
  padding-right: calc(var(--font-md) * 2);
  margin-top: var(--font-xs);
  margin-left: calc(var(--font-md) * -1);
  box-shadow: $dropdownBoxShadow;

  //Scroll
  ::-webkit-scrollbar {
    width: 0.375rem;
  }

  //Scroll Track
  ::-webkit-scrollbar-track {
    background: var(--theme-neutral-light);
  }

  //Scroll Handle on hover
  ::-webkit-scrollbar-thumb {
    background: var(--theme-neutral-medium);
  }

  .tagify__dropdown__wrapper {
    display: flex;
    flex-direction: column;
    max-height: $dropdownMaxHeight;
    padding: var(--font-xxs) 0;
    margin-right: calc(var(--font-md) * -2);
    border: 0;
    border-radius: var(--border-radius-xxs);
    background: var(--theme-light);
    transition: var(--transition-primary);

    > .tagify__dropdown__item {
      padding: var(--font-sm);

      &:hover {
        background-color: var(--theme-neutral-light);
      }

      &--active {
        color: unset;
        background-color: var(--theme-neutral-normal);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--theme-neutral-medium);
      }
    }

    > .tagify__dropdown__footer {
      display: none;
    }
  }
}

o-input-tag {
  font-family: inherit;
  
  &::after {
    content: "Tags Adicionadas";
    width: 100%;
    padding: 0;
    color: var(--theme-dark-80);
    margin-bottom: var(--font-xxs);
  }

  .tagify {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    border: 0;
    outline: 2px solid transparent;
    background: inherit;
    transition: var(--transition-primary);

    &:hover {
      border-color: var(--border-color-hover);
    }

    .tagify__tag {
      margin: 0;
      margin-right: var(--font-xs);

      > div::before {
        --tag-bg: var(--theme-light);
        --tag-hover: var(--theme-light);
        border: 1px solid var(--theme-dark-20);
      }

      &__removeBtn {
        font: unset;
        align-items: unset;
        border-radius: 0;

        &:hover {
          color: unset;
          background: unset;
        }

        &::after {
          content: "\f00d";
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
        }
      }
    }

    > .tagify__input {
      display: flex;
      align-items: center;
      order: -1;
      width: 100%;
      min-width: unset;
      padding: 0;
      margin: 0;
      padding-left: var(--font-xxl);

      &::before {
        position: absolute;
        left: var(--font-xxl);
        opacity: 1;
      }

      &:focus:not(:empty)::before {
        opacity: 0;
      }

      &::after {
        content: "\f002";
        position: absolute;
        left: 0;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        opacity: 1;
      }
    }
  }

  &.o-input-tag--flushed .tagify {
    border-bottom: var(--border);
  }

  &.o-input-tag--outline .tagify {
    border: var(--border);
    border-radius: var(--border-radius-xxs);
    padding: 0 var(--font-md);
  }

  &.o-input-tag--flushed .tagify,
  &.o-input-tag--outline .tagify {
    &--focus {
      border-color: var(--theme-focus);
    }

    &.error {
      &:focus {
        box-shadow: var(--theme-danger);
      }
    }
  }

  &.error {
    .tagify,
    .tagify:hover {
      color: var(--theme-danger);
      border-color: var(--theme-danger);
    }
  }

  @each $size in $fontSizes {
    &.o-input-tag--size-#{$size} {
      &::after,
      .tagify,
      .tagify__input {
        font-size: var(--font-#{$size});
        height: calc(var(--font-#{$size}) * $inputHeight);
        line-height: calc(var(--font-#{$size}) * $inputHeight);
      }

      .tagify__input {
        margin-bottom: calc(var(--font-#{$size}) * $marginBottomTag);
      }
    }
  }
}
