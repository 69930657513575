import React from "react";
import styled from "styled-components";
import is from "styled-is";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

interface ISpinnerProps {
  bg?: boolean;
  size?: number | string;
}

const StyledSpinner = styled.div<ISpinnerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none;

  ${is("bg")`
    background: white;
  `};
`;

export function Spinner(props: ISpinnerProps) {
  return (
    <StyledSpinner bg={props.bg}>
      <CircularProgress size={props.size} />
    </StyledSpinner>
  );
}
