o-expander-header {
  display: block;
  user-select: none;
  font-family: inherit;
  transition: var(--transition-primary);
  cursor: pointer;

  & o-icon.rotate {
    transition: var(--transition-primary);
    transform: rotate(0deg);
  }

  &.o-expander-header--expanded o-icon.rotate {
    transform: rotate(180deg);
  }
}
