import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../globals/types";

@Component({
  tag: "o-avatar",
  styleUrl: "index.scss",
})
export class Avatar {
  /**
   * URL da imagem.
   *
   * @sbCategory Input
   */
  @Prop() image!: string;
  /**
   * Descrição da imagem.
   *
   * @sbCategory Input
   */
  @Prop() alt!: string;
  /**
   * Tamanho do avatar.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Efeito de dessaturação sobre o avatar.
   *
   * @sbCategory Style
   */
  @Prop() faded = false;

  render() {
    const { size, faded, image, alt } = this;

    const classes = {
      faded: faded,
      [`avatar-size-${size}`]: true,
    };

    return (
      <o-image
        class={classes}
        src={image}
        alt={alt}
        width="none"
        height="none"
        objectFit="cover"
      />
    );
  }
}
