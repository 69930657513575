import IMask from "imask";

/**
 * Applies a currency mask to the argument string
 *
 * @param value Input value. `null` or `undefined` returns an empty string.
 *   `number` is turned into a string with `.toFixed(2)`.
 * @param symbol Symbol appended to masked value. Ex: `masks.currency("1", "$")`
 *   results in `$ 1,00`.
 * @param inputDecimalSeparator Decimal separator in the input value. If `value`
 *   is a number, this argument is ignored and `.` is used (due to the internal
 *   conversion to string).
 * @param outputDecimalSeparator Decimal separator in the output value.
 * @returns Masked string (or empty, in case `value` is `null` or `undefined`)
 */
export const currency = (
  value: string | number | null | undefined,
  symbol?: string,
  inputDecimalSeparator?: "," | ".",
  outputDecimalSeparator?: "," | "."
) => {
  if (typeof value !== "string" && typeof value !== "number") return "";

  let newValue = value.toString();
  let finalInputDecimalSeparator = inputDecimalSeparator;

  if (typeof value === "number") {
    newValue = value.toFixed(2); // proper rounding
    finalInputDecimalSeparator = ".";
  }

  const att = {
    "-": newValue.includes("-"),
    [`${symbol}`]: !!symbol?.length,
    num: true,
  };

  const mask = Object.entries(att)
    .filter(([_, value]) => !!value)
    .map(([key]) => key)
    .join(" ");

  return IMask.pipe(newValue?.length ? newValue : "0", {
    mask: mask,
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ".",
        padFractionalZeros: true,
        radix: outputDecimalSeparator ?? ",",
        mapToRadix: [finalInputDecimalSeparator ?? ","],
      },
    },
  }) as string;
};
