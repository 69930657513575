import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import { OModalCustomEvent } from "../../components";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-modal",
  styleUrl: "index.scss",
})
export class Modal {
  @Element() self!: HTMLElement;

  /** Evento emitido ao fechar o modal. */
  @Event() modalClose!: EventEmitter<{ id: string }>;
  @State() show = false;

  /**
   * Id do modal.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Largura do modal.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Posição do modal.
   *
   * @sbCategory Style
   */
  @Prop() position: "left" | "center" | "right" = "right";
  /**
   * Cor do background do modal.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Faz com que um clique no backdrop feche o modal.
   *
   * @sbCategory Input
   */
  @Prop() backdrop = true;
  /**
   * Arredonda as bordas do modal.
   *
   * @sbCategory Style
   */
  @Prop() rounded = true;

  private contentElement?: HTMLDivElement;

  /** # Event Listeners */
  @Listen("modalOpen", { target: "document" })
  handleModalOpenEvent(
    e: OModalCustomEvent<HTMLOModalElement | HTMLOModalHeaderElement>
  ) {
    if (e.detail.id === this.id && this.contentElement && !this.show) {
      this.show = true;
      this.contentElement.focus();
    }
  }

  @Listen("modalClose", { target: "document" })
  handleModalCloseEvent(
    e: OModalCustomEvent<HTMLOModalElement | HTMLOModalHeaderElement>
  ) {
    if (e.detail.id === this.id) this.show = false;
  }

  @Listen("mousedown")
  handleMouseDownEvent(event: MouseEvent) {
    if (
      this.backdrop &&
      this.contentElement &&
      !event.composedPath().includes(this.contentElement)
    ) {
      this.modalClose.emit({ id: this.id });
    }
  }

  @Watch("show")
  preventBodyScroll(newShow: boolean) {
    if (newShow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  } 

  disconnectedCallback() {
    /**
     * When the modal is removed, also remove side-effect
     *
     * Example: page redirect while modal is still open, `body` would still have
     * "modal-open" class (and so overflow would be disabled)
     */
    document.body.classList.remove("modal-open");
  }

  render() {
    const { type, size, show, position, rounded } = this;

    const modalClasses = {
      "o-modal": true,
      "o-modal--show": show,
    };

    const dialogClasses = {
      "o-modal__dialog": true,
      [`o-modal__dialog--position-${position}`]: true,
      [`o-modal__dialog--size-${size}`]: position !== "center",
    };

    const contentClasses = {
      "o-modal__content": true,
      "o-modal__content--show": show,
      "rounded": rounded,
      [`o-modal__content--type-${type}`]: true,
      [`o-modal__content--position-${position}`]: true,
      [`o-modal__content--size-${size}`]: position === "center",
    };

    return (
      <Host id={this.id}>
        <div class={modalClasses} role="dialog" aria-modal="true">
          <div class={dialogClasses}>
            <div
              tabindex="0"
              ref={(el) => (this.contentElement = el)}
              class={contentClasses}
            >
              <slot />
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
