import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  Watch,
} from "@stencil/core";

@Component({
  tag: "o-checkbox-group",
})
export class CheckboxGroup {
  @Element() self!: HTMLElement;
  /** Evento emitido ao ocorrer input. */
  @Event() input!: EventEmitter<string[]>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Valor atual do input, consiste em um array com os _values_ dos checkboxes
   * marcados. Funciona como um estado.
   *
   * @sbCategory Input
   * @sbControl false
   */
  @Prop({ mutable: true }) value?: string[] = [];
  /**
   * Desabilita o radio group.
   *
   * @sbCategory Input
   */
  @Prop() disabled?: boolean;

  @Listen("input")
  handleInput(e: InputEvent) {
    // When children Checkboxes emit input events, the CheckboxGroup will be in the composed path
    // However, the input emitted here (`this.input.emit(this.value)`) also will be listened, and
    // the CheckboxGroup will also be in the composed path, causing an infinite loop. But in this
    // latter case, it will be the first element of the array, so we can check if the index is > 0
    if (e.composedPath().findIndex((el) => el === this.self) > 0) {
      const target = e.target as HTMLOCheckboxElement;

      const value = target.value;
      const checked = target.checked;

      if (!this.value) this.value = [];

      if (checked) {
        if (this.value.includes(value)) return;

        this.value = [...this.value, value];
      } else {
        this.value = this.value.filter((val) => val !== value);
      }

      this.input.emit(this.value);
    }
  }

  /** Alter `checked` property of children in case `value` is set externally */
  @Watch("value")
  handleSetValue(newValue?: string[]) {
    this.self.querySelectorAll("o-checkbox").forEach((chk) => {
      chk.checked = !!newValue?.includes(chk.value);
    });
  }

  @Listen("refresh")
  handleRefresh() {
    this.handleSetValue(this.value);
  }

  componentWillRender() {
    if (this.disabled === undefined) return;

    const selfSlot = this.self.querySelectorAll<
      HTMLOCheckboxElement | HTMLOLabelElement
    >("o-checkbox, o-label");

    selfSlot.forEach((el) => {
      el["disabled"] = !!this.disabled;
    });
  }

  render() {
    return (
      <Host data-action={this.dataAction} data-label={this.dataLabel}>
        <slot />
      </Host>
    );
  }
}
