import { Component, h, Prop } from "@stencil/core";
import { IType } from "../../../globals/types";
import { IStepStatus } from "./types";

@Component({
  tag: "o-step",
  styleUrl: "index.scss",
})
export class Step {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Posição do texto.
   *
   * @sbCategory Internal
   */
  @Prop() position: "top" | "bottom" = "bottom";
  /**
   * Se a aba está ativa.
   *
   * @sbCategory style
   */
  @Prop() status: IStepStatus = "pending";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Callback chamado quando ocorre click na step.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick?: () => void;

  render() {
    const { status, type, position, dataLabel, dataAction } = this;

    const hostClass = {
      "o-step": true,
      [`o-step--position-${position}`]: true,
      [`o-step--status-${status}`]: true,
      [`o-step--type-${type}`]: true,
    };

    const buttonClass = {
      "o-step--marker": true,
    };

    return (
      <div class={hostClass}>
        <button
          class={buttonClass}
          data-label={dataLabel}
          data-action={dataAction}
          onClick={() => {
            if (this.handleClick) this.handleClick();
          }}
        >
          <span>
            <slot />
          </span>
        </button>
      </div>
    );
  }
}
