import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
} from "@stencil/core";
import IMask from "imask";
import {
  IAspect,
  IAutocomplete,
  IChangeMaskedEvent,
  ISize,
} from "../../globals/types";

@Component({
  tag: "o-input-cpf-cnpj",
})
export class InputCpfCnpj {
  @Element() self!: HTMLElement;
  /** Evento emitido ao ocorrer input, enviando os valores com e sem máscara. */
  @Event() changeMasked!: EventEmitter<IChangeMaskedEvent>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `autocomplete` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() autocomplete: IAutocomplete = "on";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;
  private mask?: IMask.InputMask<{ mask: { mask: string }[] }>;

  componentDidRender() {
    if (this.mask) {
      this.mask.destroy();
    }

    if (this.inputElement) {
      this.mask = IMask(this.inputElement, {
        mask: [{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }],
      });

      this.mask.on("accept", () => {
        if (!this.mask) return;

        this.changeMasked.emit({
          value: this.mask.value,
          unmaskedValue: this.mask.unmaskedValue,
        });

        this.value = this.mask.unmaskedValue;
      });
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      placeholder,
      aspect,
      autocomplete,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType="tel"
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        maxlength={18}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete={autocomplete}
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }
}
