o-modal-footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;

  .o-modal-footer {
    box-sizing: border-box;
    width: 100%;
    padding: 0 var(--font-xl) var(--font-xl);
    margin-top: var(--font-xl);

    &__divider {
      margin-bottom: var(--font-xl);
    }
  }
}