import createMuiTheme, {
  ThemeOptions,
} from "@material-ui/core/styles/createMuiTheme";
import { mergeDeepRight } from "ramda";
import { QuickFinAdminTheme } from "./quick-fin-admin.theme";
import { darkGray } from "./color";

const theme = mergeDeepRight(QuickFinAdminTheme, {
  palette: {
    primary: {
      main: darkGray,
      light: darkGray,
      dark: darkGray,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        "&$selected": {
          backgroundColor: darkGray,
        },
      },
    },
    MuiInputBase: {
      root: {
        "&$focused": {
          border: `1px solid ${darkGray}`,
        },
      },
    },
  },
} as ThemeOptions);

export const quickFinAdminTheme = createMuiTheme(theme as any);
