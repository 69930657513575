import { Component, h, Host, Listen, Prop } from "@stencil/core";
import { CalculateDesiredPosition } from "./types";
import { calculatePosition } from "./utils";

const debounceInterval = 200;

@Component({
  tag: "o-floater",
  styleUrl: "index.scss",
})
export class Floater {
  /**
   * Visibilidade do componente.
   *
   * @sbCategory Input
   */
  @Prop() visible = false;
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = false;
  /**
   * Move o elemento flutuante para evitar overflow.
   *
   * @sbCategory Style
   */
  @Prop() preventOveflow = true;
  /**
   * Callback usado para obter a posição do conteúdo flutuante. Se `floating`
   * estiver habilitado, deve retornar `top` e `left` como números.
   *
   * @sbCategory Input
   */
  @Prop() calculateDesiredPosition!: CalculateDesiredPosition;
  /**
   * Callback de referência do elemento âncora.
   *
   * @sbCategory Ref
   */
  @Prop() anchorRef?: (el: HTMLElement) => void;
  /**
   * Callback de referência do elemento flutuante.
   *
   * @sbCategory Ref
   */
  @Prop() floatingRef?: (el: HTMLElement) => void;

  /** Referência ao elemente âncora */
  private anchorElement?: HTMLElement;
  /** Referência ao elemento flutuante */
  private floatingElement?: HTMLElement;

  /** Document height before o-floater is rendered */
  private heightWithout = document.documentElement.scrollHeight;
  /** Document width before o-floater is rendered */
  private widthWithout = document.documentElement.scrollWidth;
  /** Id of debounce timeout */
  debounceId: NodeJS.Timeout | undefined = undefined;

  /** Need to recalculate position every time window is resized */
  @Listen("resize", { target: "window" })
  recalculateOnResize() {
    if (!this.debounceId) {
      this.debounceId = setTimeout(() => {
        this.debounceId = undefined;
        this.calculatePosition();
      }, debounceInterval);
    }
  }

  private calculatePosition() {
    if (!this.anchorElement || !this.floatingElement) return;

    // update document dimensions
    this.floatingElement.style.display = "none";
    this.heightWithout = document.documentElement.scrollHeight;
    this.widthWithout = document.documentElement.scrollWidth;
    this.floatingElement.style.display = "";

    calculatePosition(
      this.anchorElement,
      this.floatingElement,
      this.calculateDesiredPosition,
      this.floating,
      this.heightWithout,
      this.widthWithout,
      this.preventOveflow
    );
  }

  componentWillRender() {
    this.calculatePosition();
  }

  componentDidLoad() {
    if (this.floating && this.floatingElement) {
      document.body.appendChild(this.floatingElement);
    }
    /** Update position on render */
    this.calculatePosition();
  }

  disconnectedCallback() {
    if (this.floating) {
      /**
       * Since `floatingElement` was moved out of `o-floater` DOM tree, we need
       * manually remove it
       */
      this.floatingElement?.remove();
    }
  }

  render() {
    const anchorClasses = { "o-floater__anchor-element": true };
    const floatingClasses = {
      "o-floater__floating-element": true,
      "visible": this.visible,
    };

    return (
      <Host>
        <div
          class={anchorClasses}
          ref={(el) => {
            if (!el) return;
            this.anchorElement = el;
            this.anchorRef && this.anchorRef(el);
          }}
        >
          <div
            class={floatingClasses}
            ref={(el) => {
              if (!el) return;
              this.floatingElement = el;
              this.floatingRef && this.floatingRef(el);
            }}
            aria-expanded={`${this.visible}`}
            aria-hidden={`${!this.visible}`}
            tabIndex={0}
          >
            <slot name="floating-content"></slot>
          </div>
          <div aria-haspopup="dialog" tabIndex={0}>
            <slot />
          </div>
        </div>
      </Host>
    );
  }
}
