import { useGet } from "hooks/rest-services";
import { useCallback, useEffect } from "react";
import { atom, selector, useRecoilState } from "recoil";
import { endpoints } from "utils/endpoints";

export const firstAccessAlertData = atom({
  key: "Primeiro Acesso Alerts",
  default: [],
});

export const firstAccessBadgeSelector = selector({
  key: "Menu.Badge.Primeiro Acesso",
  get: ({ get }): any => {
    const alertsPartnersData = get(firstAccessAlertData);
    return alertsPartnersData;
  },
});

export const firstAccessQueryStorage = localStorage.getItem("firstAccessQuery");

export function useFirstAccessRefresher(props: any) {
  const [firstAccessCount, setFirstAccessCount] = useRecoilState(
    firstAccessAlertData,
  );

  const firstAccessGet = useGet<any, []>(
    `${endpoints.paperclipv2.odata.customerFromQF}${firstAccessQueryStorage ??
      ""}&$count=true`,
  );

  useEffect(() => {
    if (firstAccessGet.data) {
      setFirstAccessCount(firstAccessGet.data["@odata.count"]);
    }
  }, [firstAccessGet.data, setFirstAccessCount]);

  const refreshFirstAccessOptinBadges = useCallback(async () => {
    await firstAccessGet.refetch();
    setFirstAccessCount(firstAccessGet.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFirstAccessCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshFirstAccessOptinBadges();
    }, 600000);

    return () => clearInterval(interval);
  }, [refreshFirstAccessOptinBadges]);

  useEffect(() => {
    refreshFirstAccessOptinBadges();
  }, [refreshFirstAccessOptinBadges]);

  return firstAccessCount;
}
