$borderColor: var(--theme-dark);
$borderSize1: 0.375rem;
$borderSize2: calc($borderSize1 * 2);
$minusBorderSize2: calc($borderSize2 * -1);
$boxHeight: 3.5;

o-upload-label {
  &.o-upload-label {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    // --------------------------------------------------- //
    // ----------------- ANIMATED BORDER ----------------- //
    // source: https://codepen.io/amit_sheen/pen/xxZeyjO - //
    // --------------------------------------------------- //
    background-image: repeating-linear-gradient(
        0deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        90deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        180deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      ),
      repeating-linear-gradient(
        270deg,
        $borderColor,
        $borderColor $borderSize1,
        transparent $borderSize1,
        transparent $borderSize2
      );
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-repeat: no-repeat;

    @each $size in $fontSizes {
      &--size-#{$size} {
        height: calc(var(--font-#{$size}) * $boxHeight);

        label {
          font-size: var(--font-#{$size});
        }
      }
    }

    &--disabled {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    &--hovering:not(.o-upload-label--disabled) {
      animation: 1s infinite linear boxBorderAnimation;
      background-color: var(--theme-neutral-light);

      @keyframes boxBorderAnimation {
        from {
          background-position: 0 0, $minusBorderSize2 0, 100% $minusBorderSize2,
            0 100%;
        }
        to {
          background-position: 0 $minusBorderSize2, 0 0, 100% 0,
            $minusBorderSize2 100%;
        }
      }
    }

    label {
      font-family: inherit;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: var(--font-xs);
      height: inherit;
      width: inherit;
      padding: 0;
      margin: 0;
      cursor: inherit;
      user-select: none;
    }
  }
}
