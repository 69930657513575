import { datadogRum } from "@datadog/browser-rum";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import axios, { AxiosInstance } from "axios";
import { endpoints } from "utils/endpoints";
import { env } from "utils/environments";
import { amplifyConfigure } from "./amplify-configure";
import { customEventsBuilder } from "./custom-events";
import { ICurrent, IUserClerk, IUserGatekeeper } from "./types";
import { roleFilterBase } from "./utils";

export const sessionEvents = customEventsBuilder<Session>(
  "session-change",
  null,
);

export class Session {
  _current?: ICurrent;
  _authorization?: string;
  client: AxiosInstance;
  refreshTokenPeriod = 1000 * 60 * 5; // 5 minutes

  constructor() {
    amplifyConfigure();

    this.initDatadog();

    this.client = axios.create();

    this.client.interceptors.request.use((config) => {
      if (this.authorization) {
        config.headers = {
          Authorization: this.authorization,
          ...config.headers,
        };
      }
      return config;
    });

    this.addSignInListener();

    this.validateSession();

    this.refreshToken();
  }

  private async validateSession() {
    try {
      await this.renewSession();
      this.getUser();
    } catch (error) {
      this.simpleSignOn();
    }
  }

  private async renewSession() {
    return new Promise(
      (resolve: (value: Session) => void, reject: (err: any) => void) => {
        Auth.currentAuthenticatedUser()
          .then((cognitoUser: CognitoUser) => {
            const userSession = cognitoUser.getSignInUserSession();
            if (!userSession) return reject(cognitoUser);

            cognitoUser.refreshSession(
              userSession.getRefreshToken(),
              async (err: any, cognitoSession: CognitoUserSession) => {
                if (err) {
                  return reject(err);
                }

                this.authorization = cognitoSession
                  ?.getAccessToken()
                  ?.getJwtToken();
                return resolve(this);
              },
            );
          })
          .catch(reject);
      },
    );
  }

  private simpleSignOn() {
    // return
    Auth.federatedSignIn({
      customProvider: env.AMAZON_COGNITO_PROVIDER as string,
    });
  }

  private async getUserFromApi<T>(endpoint: string) {
    try {
      const response = await this.client.get<T>(endpoint);
      return response.data;
    } catch (err) {
      console.error(err, { errorType: `getUserFromApi: ${endpoint}` });
    }
  }

  private async getUser() {
    try {
      const gkUser = await this.getUserFromApi<IUserGatekeeper>(
        endpoints.gatekeeper.users.self,
      );
      const clerkUser = await this.getUserFromApi<IUserClerk>(
        endpoints.clerk.users.self,
      );

      if (!gkUser || !clerkUser) throw new Error("Could not get user");

      this.current = {
        ...clerkUser,
        ...gkUser,
        roles: gkUser.roles,
        profiles: gkUser.profiles,
      };
    } catch (e) {
      console.error(e, { errorType: "getUser" });
    }
  }

  private addSignInListener() {
    Hub.listen("auth", async ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          await this.renewSession();
          this.getUser();
          break;
      }
    });
  }

  private refreshToken() {
    setInterval(async () => {
      this.validateSession();
    }, this.refreshTokenPeriod);
  }

  initDatadog() {
    if (env.REACT_APP_PROJECT_ENV === "prod") {
      console.log("[datadogRum]init");
      datadogRum.init({
        applicationId: "df195d9f-d792-41cc-899d-9b5d6bae8e18",
        clientToken: "pube29eac3660d4db395799aa4e22b3e94d",
        service: "portal-admin",
        site: "datadoghq.com",
        sampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        trackSessionAcrossSubdomains: true,
        beforeSend: (event) => {
          if (
            event.type === "error" &&
            event.error.message.includes("Flash Player")
          ) {
            return false;
          }
        },
        allowedTracingOrigins: [
          /https:\/\/.*\.btgpactual\.com/,
          /https:\/\/.*\.empresas\.btgpactual\.com/,
          /https:\/\/.*\.btgpactualbusiness\.com/,
          /https:\/\/.*\.quickfin\.com\.br/,
        ],
      });

      window.console.error = (...data: any[]) => {
        if (data) {
          if (data[0]?.config?.headers != null) data[0].config.headers = null;
          if (data[1]?.config?.headers != null) data[1].config.headers = null;
          datadogRum.addError(data[0], data[1]);
        }
      };
    }
  }

  private setDataDogUser(current: ICurrent) {
    if (env.REACT_APP_PROJECT_ENV === "prod") {
      datadogRum.setUser({
        id: current.cpf,
        name: current.name,
        email: current.email,
        uuid: current.uuid,
      });
    }
  }

  roleFilter(requiredRoles: string[] | undefined) {
    if (this.current) {
      let roles = this.current.roles;
      return roleFilterBase(requiredRoles, roles);
    } else {
      return false;
    }
  }

  updateSession() {
    window.session = this;
    sessionEvents.dispatch(this);
  }

  get current() {
    return this._current;
  }

  set current(value) {
    this._current = value;
    this.setDataDogUser(value as ICurrent);
    this.updateSession();
  }

  get authorization() {
    return this._authorization;
  }

  set authorization(value) {
    this._authorization = value;
    this.updateSession();
  }
}
