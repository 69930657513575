import { Component, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-card-header",
  styleUrl: "index.scss",
})
export class CardHeader {
  /**
   * Adiciona um `o-divider` abaixo do conteúdo do _header_.
   *
   * @sbCategory Input
   */
  @Prop() divider = false;

  render() {
    return (
      <Host>
        <slot />

        {this.divider && (
          <div class="o-card-header__divider">
            <o-divider />
          </div>
        )}
      </Host>
    );
  }
}
