import { Component, h, Prop } from "@stencil/core";
import { IType } from "../../globals/types";

@Component({
  tag: "o-banner",
  styleUrl: "index.scss",
})
export class Banner {
  /**
   * Direção do banner
   *
   * @sbCategory Style
   */
  @Prop() orientation: "horizontal" | "vertical" = "horizontal";
  /**
   * Cor de fundo do card.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Define como será o text-overflow do conteúdo
   *
   * @sbCategory Style
   */
  @Prop() textTruncate = false;

  render() {
    const { orientation, type, textTruncate } = this;

    const cardClass = {
      "o-card": true,
      [`o-card--orientation-${orientation}`]: true,
      "o-card--text-truncate": textTruncate,
      "slot-container": true,
    };

    return (
      <o-card type={type} class={cardClass}>
        <slot />
      </o-card>
    );
  }
}
