o-skeleton-avatar {
  .o-skeleton-avatar {
    border-radius: 50%;
    background-image: $skeletonBackground;
    animation: $skeletonAnimation;

    @each $size in $fontSizes {
      &--size-#{$size} {
        height: calc(var(--font-#{$size}) * var(--base-height));
        width: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
