const BLACKLIST = [
  "00000000000000",
  "11111111111111",
  "22222222222222",
  "33333333333333",
  "44444444444444",
  "55555555555555",
  "66666666666666",
  "77777777777777",
  "88888888888888",
  "99999999999999",
];

const STRICT_STRIP_REGEX = /[-/.]/g;
const LOOSE_STRIP_REGEX = /[^\d]/g;

export function verifierDigitCNPJ(numbers: string) {
  let index = 2;
  const reverse = numbers.split("").reduce(function(buffer: any, number: any) {
    return [parseInt(number, 10)].concat(buffer);
  }, []);

  const sum = reverse.reduce(function(buffer: any, number: any) {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
}

export function stripCNPJ(cnpj: string, isStrict?: boolean) {
  const regex = isStrict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (cnpj || "").toString().replace(regex, "");
}

export function cnpjVerifier(cnpj: string, isStrict?: boolean) {
  const stripped = stripCNPJ(cnpj, isStrict);

  // CNPJ must be defined
  if (!stripped) {
    return false;
  }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) {
    return false;
  }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigitCNPJ(numbers);
  numbers += verifierDigitCNPJ(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
}
