$resizes: "none", "vertical";
$textareaHeight: 5;

o-textarea {
  .o-textarea {
    box-sizing: border-box;
    font-family: inherit;
    position: relative;
    width: 100%;
    padding: 0;
    border: 0;
    outline: 2px solid transparent;
    transition: var(--transition-primary);
    background: inherit;

    &:disabled {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    &:hover {
      border-color: var(--border-color-hover);
    }

    &.error {
      color: var(--theme-danger);
      border-color: var(--theme-danger);
    }

    &--flushed {
      border-bottom: var(--border);
    }

    &--outline {
      border: var(--border);
      border-radius: var(--border-radius-xxs);
      padding: var(--font-md);
    }

    &--flushed,
    &--outline {
      &:hover {
        border-color: var(--theme-neutral-normal);
      }

      &:focus {
        border-color: var(--theme-focus);
      }

      &.error {
        &:hover,
        &:focus {
          border-color: var(--theme-danger);
        }
      }
    }

    @each $resize in $resizes {
      &--resize-#{$resize} {
        resize: #{$resize};
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        height: calc(var(--font-#{$size}) * $textareaHeight);
      }
    }
  }
}
