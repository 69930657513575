import { Component, h, Host, Listen, Prop } from "@stencil/core";

@Component({
  tag: "o-dropdown-item",
  styleUrl: "index.scss",
})
export class DropdownItem {
  /**
   * Callback executado no evento de `click` e `keydown` com a tecla Enter.
   *
   * @sbCategory Callbacks
   */
  @Prop() handleInteraction?: (evt: MouseEvent | KeyboardEvent) => void;

  @Listen("click")
  @Listen("keydown")
  handleAction(evt: MouseEvent & KeyboardEvent) {
    if (evt.key && evt.key !== "Enter") return;
    this.handleInteraction?.(evt);
  }

  render() {
    return (
      <Host tabindex="0" role="option" class="o-dropdown-item">
        <slot />
      </Host>
    );
  }
}
