o-avatar {
  o-image {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    transition: var(--transition-primary);

    &.faded {
      filter: saturate(0);
      transition: var(--transition-primary);
    }

    img {
      width: 100%;
      max-width: unset;
      min-height: 100%;
    }

    // classes for avatar-size
    @each $size in $fontSizes {
      &.avatar-size-#{$size} {
        width: calc(var(--font-#{$size}) * var(--base-height));
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
