o-clickable-card {
  display: inline-block;

  .o-clickable-card {
    position: relative;

    @each $type in $types {
      &--type-#{$type} {
        > input:checked ~ .o-clickable-card__label {
          background-color: var(--theme-#{$type});
          color: var(--theme-#{$type}-inverse);
        }

        // correction light color
        > input:disabled:not(:checked) ~ .o-clickable-card__label {
          background-color: var(--theme-dark-05);
        }
      }

      // correction light color
      &--type-light {
        > input:disabled ~ .o-clickable-card__label {
          background-color: var(--theme-dark-05);
        }
      }

      &--outline-#{$type} {
        > input:checked ~ .o-clickable-card__label {
          background-color: transparent;
          border-color: var(--theme-#{$type});
          color: var(--theme-#{$type});
        }
      }

      // correction light color
      &--outline-light {
        > input:checked ~ .o-clickable-card__label {
          color: var(--theme-light-inverse);
        }
      }
    }

    > input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      appearance: unset;
      cursor: pointer;

      &:checked ~ .o-clickable-card__label .o-clickable-card__status:after {
        opacity: 1;
        visibility: visible;
      }

      &:hover:not(:checked):not(:disabled) ~ .o-clickable-card__label,
      &:focus:not(:checked):not(:disabled) ~ .o-clickable-card__label {
        color: var(--theme-dark);
        background: var(--theme-light-hover);
      }

      &:active:not(:checked):not(:disabled) ~ .o-clickable-card__label {
        color: var(--theme-dark);
        background: var(--theme-light-active);
      }

      &:disabled,
      &:disabled ~ .o-clickable-card__label {
        opacity: var(--disabled-opacity);
        cursor: not-allowed;
      }

      &:focus-visible {
        outline: 2px solid var(--theme-focus);
      }
    }

    &__label {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: var(--font-xl);
      border: var(--border);
      border-radius: var(--border-radius-xxs);
      cursor: pointer;

      .o-clickable-card__status {
        display: flex;
        justify-content: flex-end;

        &:after {
          font-family: "icomoon-orquestra" !important;
          content: "";
          font-size: var(--font-xxl);
          opacity: 0;
          visibility: hidden;
          transition: var(--transition-primary);
        }
      }
    }
  }
}
