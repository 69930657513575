import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../globals/types";
import { IFile } from "./types";

@Component({
  tag: "o-file-item",
  styleUrl: "index.scss",
})
export class FileItem {
  /** Props set by OInputUpload */
  /**
   * Objeto contendo informações do arquivo.
   *
   * @sbCategory Input
   * @sbControl object
   */
  @Prop() file!: IFile;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Desabilita o botão de remover o arquivo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Calllback chamado ao clicar no botão de download.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleDownload?: (file: IFile) => void;
  /**
   * Calllback chamado ao clicar no botão de remover.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleRemove?: (file: IFile) => void;

  render() {
    const { file, size, handleDownload, handleRemove, disabled } = this;

    return (
      <div>
        <o-card class="file-item" type="dark" outline>
          <div class="file-item__name">
            <o-icon category="far" icon="fa-file-alt" size={size} />
            <o-typography tag="span" size={size}>
              {file.name}
            </o-typography>
          </div>
          <div class="d-flex gap-1">
            {handleRemove && (
              <o-button
                ariaLabel="fechar"
                dataLabel="o-file:item:remover"
                dataAction="clique:botao"
                bordered={false}
                outline
                type="dark"
                size={size}
                disabled={disabled}
                onClick={() => !disabled && handleRemove(file)}
              >
                <o-icon category="far" icon="fa-trash-alt" size={size} />
              </o-button>
            )}

            {handleDownload && (
              <o-button
                ariaLabel="download"
                dataLabel="o-file-item:download"
                dataAction="clique:botao"
                bordered={false}
                outline
                type="dark"
                size={size}
                onClick={() => handleDownload(file)}
              >
                <o-icon category="far" icon="fa-arrow-to-bottom" size={size} />
              </o-button>
            )}
          </div>
        </o-card>
        {file.errorMessage && (
          <o-field-error size={size} visible={!!file.errorMessage}>
            {file.errorMessage}
          </o-field-error>
        )}
      </div>
    );
  }
}
