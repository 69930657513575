import red from "@material-ui/core/colors/red";
import styled from "styled-components";
// @ts-ignore
import is, { match } from "styled-is";
import { darkGray, gray, yellow, green } from "components/theme/color";
import { ITypographyProps } from "./typography.component";

export const StyledTypography = styled.p<ITypographyProps>`
  margin: 4px 0;
  font-size: 14px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Open Sans"};

  ${is("bold")`
    font-weight: 600;
  `}

  ${is("inline")`
    display: inline-block;
  `}

  ${match("display", "title")`
    font-size: 26px;
  `}

  ${match("display", "display1")`
    font-size: 18px;
  `}

  ${match("display", "display2")`
    font-size: 16px;
  `}

  ${match("color", "darkGray")`
    color: ${darkGray};
  `}

  ${match("color", "mainGray")`
    color: ${gray};
  `}

  ${match("color", "white")`
    color: #fff;
  `}

  ${match("color", "red")`
    color: ${red};
  `}

${match("color", "success")`
    color: ${green};
  `}

${match("color", "warning")`
    color: ${yellow};
  `}
`;
