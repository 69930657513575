o-modal-header {
  display: block;

  .o-modal-header {
    padding: var(--font-xl);

    &__divider {
      margin-top: var(--font-xl);
    }

    & .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
