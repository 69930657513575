import {
  IToastManagerOptions,
  IToastManagerSettings,
  TMessage,
  TNotificationsArray,
  TToastTypes,
} from "./types";

/**
 * - **containerPlacement** - values of the `inset` CSS property for the fixed
 *   positioned toast container
 * - **_verticalPosition** - helper property, consists of the first half of
 *   `position`
 * - **_arriveFrom** - helper property, consists of the second half of `position`
 *
 * For the remaining properties, see `o-toast` props
 */
const defaultSettings: IToastManagerSettings = {
  position: "top-right",
  arrivalDirection: "horizontal",
  containerPlacement: [0, 0, 0, 0],
  hasIcon: true,
  hasCloseButton: true,
  timeoutDuration: 5000,
  _verticalPosition: "top",
  _arriveFrom: "right",
};

const containerId = "o-toast";

/** Receives a string like "30px" and returns the number (30, in the example) */
function getPixels(str: string) {
  const match = str.match(/[0-9.]/g);

  if (!match) return 0;

  return Number(match.join(""));
}

export class OToastManager {
  /** State */
  /** Indicates whether the toast container was created */
  private static initialized = false;

  /** Reference to the toast container element */
  private static containerElement?: HTMLDivElement;

  /**
   * Array containing references to the currently existing toasts and some other
   * useful info (see type)
   */
  private static notifications: TNotificationsArray = [];

  /** Current settings */
  private static settings: IToastManagerSettings = defaultSettings;

  /** Methods */
  /** Helper method for generating a toast of type `success` */
  public static success(msg: TMessage) {
    this.generateNotification("success", msg);
  }

  /** Helper method for generating a toast of type `warning` */
  public static warning(msg: TMessage) {
    this.generateNotification("warning", msg);
  }

  /** Helper method for generating a toast of type `danger` */
  public static danger(msg: TMessage) {
    this.generateNotification("danger", msg);
  }

  /** Helper method for generating a toast of type `info` */
  public static info(msg: TMessage) {
    this.generateNotification("info", msg);
  }

  /** Method that actually creates the toast */
  private static async generateNotification(type: TToastTypes, msg: TMessage) {
    if (!this.initialized) this.initialize();

    const toast = document.createElement("o-toast");

    // User may pass a string or an object containing message title or body
    if (typeof msg === "string") {
      toast.messageBody = msg;
    } else if (msg.title || msg.message) {
      toast.messageTitle = msg.title;
      toast.messageBody = msg.message;
    } else {
      throw new Error(`[o-toast] Incorrect argument: ${console.dir(msg)}`);
    }

    toast.type = type;
    toast.position = this.settings.position;
    toast.arrivalDirection = this.settings.arrivalDirection;
    toast.hasIcon = this.settings.hasIcon;
    toast.hasCloseButton = this.settings.hasCloseButton;

    // Insert created toast into the DOM
    this.containerElement?.prepend(toast);

    // Wait until component is ready/hydrated, or else the `querySelector` won't find anything
    await new Promise((resolve) => toast.componentOnReady().then(resolve));

    /** The parent div inside the o-toast */
    const innerToast = toast.querySelector(".o-toast") as HTMLDivElement;

    // Timeout for toast removal
    const timeoutId = setTimeout(() => {
      this.removeNotification(toast, innerToast);
    }, this.settings.timeoutDuration);

    // Callback for when the user clicks on the close button
    const closeCallback = async () => {
      clearTimeout(
        this.notifications.find((n) => n.element === innerToast)?.timeoutId
      );
      await this.removeNotification(toast, innerToast);
      this.reflow();
      this.moveToPositions();
    };
    toast.closeButtonCallback = closeCallback;

    // Stop timeout when the user mouses over the toast
    toast.onmouseenter = () => {
      clearTimeout(
        this.notifications.find((n) => n.element === innerToast)?.timeoutId
      );
    };

    // Start again when the user removes the mouse cursor
    toast.onmouseleave = () => {
      const resetTimeoutId = setTimeout(() => {
        this.removeNotification(toast, innerToast);
      }, this.settings.timeoutDuration);

      this.notifications = this.notifications.map((n) => {
        if (n.element !== innerToast) return n;
        return {
          ...n,
          timeoutId: resetTimeoutId,
        };
      });

      this.reflow();
      this.moveToPositions();
    };

    /**
     * Vertical displacement of the element, used to move other toasts to make
     * space for the new one
     */
    const deltaY =
      getPixels(window.getComputedStyle(innerToast).height) +
      getPixels(window.getComputedStyle(innerToast).marginBottom) +
      getPixels(window.getComputedStyle(innerToast).marginTop);

    this.notifications = [
      {
        [this.settings._verticalPosition]: 0,
        displacement: deltaY,
        element: innerToast,
        timeoutId,
      },
      ...this.notifications,
    ];

    // Start css transition (it starts at a negative position)
    innerToast.style[this.settings._arriveFrom] = "0";

    // Update the positions of the toasts
    this.reflow();
    this.moveToPositions();
  }

  /**
   * Calculate the correct vertical position of each toast based on their
   * displacements
   */
  private static reflow() {
    let totalDisplacement = 0;
    this.notifications = this.notifications.map((n) => {
      const previousDisplacement = totalDisplacement;
      totalDisplacement += n.displacement;

      return {
        ...n,
        [this.settings._verticalPosition]: previousDisplacement,
      };
    });
  }

  /**
   * Set the appropriate CSS property of each toast to the calculated vertical
   * position
   */
  private static moveToPositions() {
    this.notifications.forEach((n) => {
      n.element.style[this.settings._verticalPosition] = `${
        n[this.settings._verticalPosition]
      }px`;
    });
  }

  /** Create toast container */
  private static initialize() {
    // If there's a leftover, clean it up
    document.getElementById(containerId)?.remove();

    const [top, right, bottom, left] = this.settings.containerPlacement;

    const toastContainer = document.createElement("div");

    toastContainer.style.position = "fixed";
    toastContainer.style.inset = `${top}px ${right}px ${bottom}px ${left}px`;
    toastContainer.style.pointerEvents = "none"; // make it click-through
    toastContainer.style.zIndex = "99999";
    toastContainer.id = containerId;

    document.querySelector("body")!.prepend(toastContainer);

    this.containerElement = toastContainer;
    this.initialized = true;
  }

  /** Remove all timeouts, toasts and the container */
  public static destroy() {
    this.notifications.forEach((n) => {
      clearTimeout(n.timeoutId);
      n.element.parentElement?.remove();
    });

    this.containerElement?.remove();

    this.notifications = [];
    this.containerElement = undefined;
    this.initialized = false;
  }

  /** Sets the configuration */
  public static configure(opts: IToastManagerOptions) {
    const verticalPosition = (opts.position || defaultSettings.position).split(
      "-"
    )[0] as "top" | "bottom";
    const horizontalPosition = (
      opts.position || defaultSettings.position
    ).split("-")[1] as "left" | "right";
    const arriveFrom =
      (opts.arrivalDirection || defaultSettings.arrivalDirection) ===
      "horizontal"
        ? horizontalPosition
        : verticalPosition;
    this.settings = {
      ...defaultSettings,
      ...opts,
      _verticalPosition: verticalPosition,
      _arriveFrom: arriveFrom,
    };
    if (this.initialized) this.destroy();
    this.initialize();
  }

  /**
   * Handles toast removal. It adds the `fade` class, that will generate a fade
   * transition and creates a timeout with the transition duration that will
   * remove the toast from the DOM and from `this.notifications`
   */
  private static removeNotification(
    element: HTMLOToastElement,
    innerElement: HTMLDivElement
  ) {
    innerElement.classList.add("fade");
    return new Promise((resolve) => {
      setTimeout(() => {
        this.notifications = this.notifications.filter(
          (n) => n.element !== innerElement
        );
        element?.remove();
        this.reflow();
        this.moveToPositions();
        resolve(null);
      }, 500);
    });
  }
}
