const BLACKLIST = [
  "00000000000",
  "11111111111",
  "22222222222",
  "33333333333",
  "44444444444",
  "55555555555",
  "66666666666",
  "77777777777",
  "88888888888",
  "99999999999",
  "12345678909",
];

const STRICT_STRIP_REGEX = /[.-]/g;
const LOOSE_STRIP_REGEX = /[^\d]/g;

export function verifierDigitCPF(numbers: any) {
  numbers = numbers.split("").map(function(number: string) {
    return parseInt(number, 10);
  });

  const modulus = numbers.length + 1;

  const multiplied = numbers.map(function(number: number, index: number) {
    return number * (modulus - index);
  });

  const mod =
    multiplied.reduce(function(buffer: number, number: number) {
      return buffer + number;
    }) % 11;

  return mod < 2 ? 0 : 11 - mod;
}

export function stripCPF(cpf: string, isStrict?: boolean) {
  const regex = isStrict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (cpf || "").toString().replace(regex, "");
}

export function cpfVerifier(cpf: string, isStrict?: boolean) {
  const stripped = stripCPF(cpf, isStrict);

  // CPF must be defined
  if (!stripped) {
    return false;
  }

  // CPF must have 11 chars
  if (stripped.length !== 11) {
    return false;
  }

  // CPF can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 9);
  numbers += verifierDigitCPF(numbers);
  numbers += verifierDigitCPF(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
}
