o-link {
  a.o-link.o-link {
    color: unset;
    line-height: 25px;
    font-family: inherit;

    @each $type in $typesComplete {
      &--type-#{$type} {
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--font-#{$size} {
        font-size: var(--font-#{$size});
        min-height: var(--font-#{$size});
      }
    }

    @each $weight in $weights {
      &--weight-#{$weight} {
        font-weight: #{$weight};
      }
    }

    &:hover,
    &:active {
      opacity: 0.7;
    }

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }
  }

  // prevent text-decoration on o-icon
  o-icon {
    display: inline;
  }
}
