o-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .o-icon {
    @each $type in $types {
      &--type-#{$type} {
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        line-height: 1.25;
      }
    }
  }
}
