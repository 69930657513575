import { Component, h, Prop } from "@stencil/core";
import IMask from "imask";
import { maskRegexType } from "../../molecules/pin-field/types";
import { ISize } from "../../globals/types";

@Component({
  tag: "o-input-pin",
  styleUrl: "index.scss",
})
export class InputPin {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input.
   *
   * @sbCategory Input
   */
  @Prop() value?: string;
  /**
   * Determina se os caracteres estão visíveis, mudando o tipo do input entre
   * "text" e "password"
   *
   * @sbCategory Input
   */
  @Prop() secret = false;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Determina quais tipos de caracteres são aceitos..
   *
   * @sbCategory Input
   */
  @Prop() pinType: "all" | "text" | "number" = "all";

  private inputElement?: HTMLInputElement;
  private mask?: IMask.InputMask<{ mask: RegExp }>;

  componentDidRender() {
    if (this.mask) {
      this.mask.destroy();
    }

    if (this.inputElement) {
      this.mask = IMask(this.inputElement, {
        mask: maskRegexType[this.pinType],
      });
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      disabled,
      readonly,
      size,
      secret,
      error,
    } = this;

    const classes = {
      "o-input-pin": true,
      "o-input-pin--error": error,
      [`o-input-pin--size-${size}`]: true,
    };

    const type = secret ? "password" : "text";

    return (
      <input
        ref={(el) => (this.inputElement = el)}
        class={classes}
        type={type}
        data-action={dataAction}
        data-label={dataLabel}
        id={`o-${id}`}
        name={name}
        value={value}
        maxlength={1}
        autocomplete="off"
        disabled={disabled}
        readonly={readonly}
      />
    );
  }
}
