import styled from "styled-components";
// @ts-ignore
import is, { match } from "styled-is";
import { darkGray, red, gray } from "components/theme/color";

interface IStyledText {
  display: string;
  color?: string;
  inline?: string;
  mb?: string;
  bold?: boolean;
}

export const TextStyled = styled.p<IStyledText>`
  margin: 4px 0;
  font-size: 14px;
  font-family: "Open Sans";

  ${is("bold")`
    font-weight: 600;
  `}

  ${is("inline")`
    display: inline-block;
  `}

  ${match("display", "title")`
    font-size: 26px;
  `}

  ${match("display", "display1")`
    font-size: 18px;
  `}

  ${match("display", "display2")`
    font-size: 16px;
  `}

  ${match("color", "darkGray")`
    color: ${darkGray};
  `}

  ${match("color", "mainGray")`
    color: ${gray};
  `}

  ${match("color", "white")`
    color: #fff;
  `}

  ${match("color", "red")`
    color: ${red};
  `}
`;
