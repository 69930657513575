import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import type { ISize } from "../../globals/types";
import type { IFile } from "../file-item/types";
import { fileTypes } from "./types";

/**
 * Component that handles file upload
 *
 * - Compatible with multiple files
 * - Compatible with drag and drop
 *
 * Reference: https://css-tricks.com/drag-and-drop-file-uploading/
 */
@Component({
  tag: "o-input-upload",
  styleUrl: "index.scss",
})
export class InputUpload {
  @Element() self!: HTMLElement;

  /** Evento emitido ao adicionar ou remover um arquivo. */
  @Event() fileChange!: EventEmitter<Array<IFile>>;
  /** Evento emitido ao remover o arquivo. */
  @Event() removeFile!: EventEmitter<IFile>;
  /** Evento emitido ao fazer download do arquivo. */
  @Event() downloadFile!: EventEmitter<IFile>;
  /** Evento emitido ao adicionar um arquivo. */
  @Event() addFile!: EventEmitter<IFile>;

  /** Unique id for native input and label */
  @State() nativeId =
    Date.now().toString(36) + Math.random().toString(36).substring(2);
  /** Is true when the user is dragging files on top of the component */
  @State() isHovering = false;
  /** Flag to check if the user's browser supports drag and drop */
  @State() canDragAndDrop = false;

  /**
   * Valor atual do Input. É um array contendo os arquivos.
   *
   * @sbCategory Input
   * @sbControl false
   */
  @Prop({ mutable: true }) value: IFile[] = [];
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Texto do documento.
   *
   * @sbCategory Input
   */
  @Prop() label = "Selecionar documento";
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Texto da dica abaixo do input.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) tipText?: string;
  /**
   * Controla se o input aceita o upload simultâneo de mais de um arquivo..
   *
   * @sbCategory Input
   */
  @Prop() multiple = true;
  /**
   * Controla se o input aceita arrastar e soltar arquivos. .
   *
   * @sbCategory Input
   */
  @Prop() dragAndDrop = true;
  /**
   * Os formatos de arquivo aceitos.
   *
   * @sbCategory Input
   * @sbControl false
   */
  @Prop() acceptedFormats = [
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".pdf",
    ".png",
    ".doc",
    ".docx",
  ];
  /**
   * Tamanho máximo, em Mb, aceito para um arquivo.
   *
   * @sbCategory Input
   */
  @Prop() maxFileSizeMb = 5;
  /**
   * Muda o estilo da _tip_ para indicar erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Altura do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "xxl";
  /**
   * Tamanho dos componentes dos arquivos.
   *
   * @sbCategory Style
   */
  @Prop() itemSize: ISize = "md";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() labelSize: ISize = "md";
  /**
   * Tamanho do ícone.
   *
   * @sbCategory Style
   */
  @Prop() iconSize: ISize = "md";
  /**
   * Tamanho da fonte da dica abaixo do input.
   *
   * @sbCategory Style
   */
  @Prop() tipFontSize: ISize = "md";
  /**
   * Tamanho do ícone da dica abaixo do input.
   *
   * @sbCategory Style
   */
  @Prop() tipIconSize: ISize = "md";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;

  /** Reference to the input element */
  private inputElement?: HTMLInputElement;

  private validateInputFileType(file: File) {
    const types = (fileTypes as Record<string, string[] | undefined>)[
      file.type
    ];
    return types?.some((type: string) => this.acceptedFormats.includes(type));
  }

  /** Removes file from fileList */
  private removeFileHandler(removedFile: IFile) {
    this.value = this.value.filter((item) => item !== removedFile);

    this.removeFile.emit(removedFile);
    this.fileChange.emit(this.value);
  }

  /** Stops default behavior in all drag events */
  @Listen("drag")
  @Listen("dragstart")
  @Listen("dragend")
  @Listen("dragover")
  @Listen("dragenter")
  @Listen("dragleave")
  @Listen("drop")
  stopHandler(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  /** When the user is dragging files over the component */
  @Listen("dragover")
  @Listen("dragenter")
  isHoveringHandler() {
    if (this.canDragAndDrop && this.dragAndDrop) this.isHovering = true;
  }

  /** When the user is no longer dragging files over the component */
  @Listen("dragleave")
  @Listen("dragend")
  @Listen("drop")
  stopHoveringHandler() {
    if (this.canDragAndDrop && this.dragAndDrop) this.isHovering = false;
  }

  private handleAddFile(files: File[]) {
    if (this.disabled) return;

    const invalidFiles: string[] = [];

    files.forEach((file) => {
      const validType = this.validateInputFileType(file);

      if (validType) {
        this.value = [...this.value, file];
        this.addFile.emit(file);
      } else {
        invalidFiles.push(file.name);
      }
    });

    this.fileChange.emit(this.value);

    if (invalidFiles.length) {
      this.error = true;
      this.tipText = (
        <p>
          Os seguintes arquivos não foram enviados porque possuem formatos não
          aceitos: {invalidFiles.join(", ")}
          <br />
          Os formatos aceitos são: {this.acceptedFormats.join(", ")}
        </p>
      );
    } else {
      this.error = false; // this might unintentionally clear other errors
      this.tipText = undefined;
    }
  }

  /** When the user dropped the dragged files on the component */
  @Listen("drop")
  dropFilesHandler(event: DragEvent) {
    if (this.canDragAndDrop && this.dragAndDrop && event.dataTransfer) {
      const files = [...event.dataTransfer.files];
      this.handleAddFile(files);
    }
  }

  /** Fired when user clicks on input (instead of drag and drop) */
  @Listen("input")
  inputFilesHandler() {
    if (!this.inputElement?.files) return;

    const files = [...this.inputElement.files];

    this.handleAddFile(files);

    this.inputElement.value = "";
  }

  /** Tests if user's browser supports drag and drop */
  componentWillLoad() {
    this.canDragAndDrop = (function () {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    })();
  }

  // Set tipText based on props
  componentWillRender() {
    if (!!this.tipText && this.tipText !== "undefined") return;
    this.tipText = `Serão aceitos documentos com no máximo ${
      this.maxFileSizeMb
    }mb, nos formatos: ${this.acceptedFormats.join(", ")}.`;
  }

  /**
   * Emit fileChange on initialization to notify anyone who's listening of
   * initial value
   */
  componentDidLoad() {
    this.fileChange.emit(this.value);
  }

  @Watch("value")
  watchValue(newValue: IFile[]) {
    this.value = newValue;
  }

  render() {
    const {
      dataAction,
      dataLabel,
      name,
      size,
      labelSize,
      iconSize,
      tipFontSize,
      tipIconSize,
      itemSize,
      isHovering,
      label,
      tipText,
      acceptedFormats,
      disabled,
    } = this;

    const uploadBoxClasses = {
      "upload-box": true,
      [`size-${size}`]: true,
      "is-hovering": isHovering,
      "input-upload-disabled": disabled,
    };

    return (
      <Host>
        <div
          class={uploadBoxClasses}
          onClick={() => !disabled && this.inputElement?.click()}
        >
          <input
            ref={(el) => (this.inputElement = el)}
            id={this.nativeId}
            data-action={dataAction}
            data-label={dataLabel}
            name={name}
            type="file"
            accept={acceptedFormats.join(",")}
            disabled={disabled}
            multiple
          />
          <div class="d-flex flex-row gap-2">
            <o-label htmlFor={this.nativeId} size={labelSize}>
              {label}
            </o-label>
            <o-icon category="far" icon="fa-cloud-upload" size={iconSize} />
          </div>
        </div>

        <div class={{ "tip": true, "tip-disabled": disabled }}>
          <o-icon
            category="far"
            icon="fa-info-circle"
            size={tipIconSize}
            type={this.error ? "danger" : "dark"}
          />
          <o-typography
            tag="p"
            size={tipFontSize}
            type={this.error ? "danger" : "dark"}
          >
            {tipText}
          </o-typography>
        </div>

        <div class="file-list">
          {this.value.map((item, index) => (
            <o-file-item
              key={item.name + index}
              size={itemSize}
              file={item}
              disabled={disabled}
              handleDownload={() => this.downloadFile.emit(item)}
              handleRemove={() => this.removeFileHandler(item)}
            />
          ))}
        </div>
      </Host>
    );
  }
}
