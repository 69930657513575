export class OUrl {
  public static paramsToIgnore = [
    null,
    undefined,
    "",
    '""',
    "undefined",
    "null",
    "NaN",
    "{}",
    "[]",
  ];

  private static url = new URL(window.location.href);
  private static searchParams = this.url.searchParams;

  /** Limpa o cache da url */
  public static builder() {
    this.url = new URL(window.location.href);
    this.searchParams = this.url.searchParams;
    return this;
  }

  /** Retorna a url */
  public static getUrl() {
    return this.url;
  }

  /** Retorna query params */
  public static getSearchParams() {
    return this.searchParams;
  }

  /** Retorna uma string */
  public static valueToString(value: unknown): string {
    return JSON.stringify(value);
  }

  /** Adiciona um novo query param */
  public static setParam(name: string, value: unknown) {
    const valueAsString = this.valueToString(value);

    if (this.paramsToIgnore.includes(valueAsString)) {
      this.searchParams.delete(name);
      return this;
    }

    this.searchParams.set(name, valueAsString);
    return this;
  }

  /** Aplica as mudanças dos params na url */
  public static build() {
    window.history.replaceState("", "", this.url.toString());
  }

  /** Retorna os params em forma de objeto */
  public static paramsToObject() {
    const paramsNames = new Set(this.searchParams.keys());
    const obj: Record<string, any> = {};
    paramsNames.forEach((paramName) => {
      try {
        obj[paramName] = JSON.parse(this.searchParams.get(paramName)!);
      } catch {
        obj[paramName] = this.searchParams.getAll(paramName);
      }
    });
    return obj;
  }

  /** Transforma o objeto em params */
  public static objectToParams(obj: Record<string, any>) {
    Object.entries(obj).forEach(([key, value]) => {
      this.setParam(key, value);
    });
    return this.searchParams.toString();
  }
}
