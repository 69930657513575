import { Component, h, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-dropdown-action-item",
  styleUrl: "index.scss",
})
export class DropdownActionItem {
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Callback chamado quando o componente é clicado.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleClick!: () => void;

  render() {
    const { size, handleClick } = this;

    const classes = {
      "o-dropdown-action-item": true,
      [`o-dropdown-action-item--size-${size}`]: true,
    };

    return (
      <o-dropdown-item class={classes} onClick={handleClick}>
        <slot />
      </o-dropdown-item>
    );
  }
}
