import { Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React from "react";

export interface TipProps {
  tip: string;
  tipMode: "text" | "icon";
}

export function Tip(props: TipProps) {
  return (
    <>
      {props.tip && props.tipMode === "text" && (
        <span
          style={{ color: "#9B9B9B", fontWeight: "normal", marginTop: "20px" }}
        >
          {props.tip}
        </span>
      )}

      {props.tip && props.tipMode === "icon" && (
        <Tooltip
          title={props.tip}
          style={{ color: "#8D8B8B", marginLeft: 4 }}
          placement="top"
        >
          <Help />
        </Tooltip>
      )}
    </>
  );
}
