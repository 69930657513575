o-progress-circular {
  .o-progress {
    position: relative;

    @each $size in $fontSizes {
      &--size-#{$size} {
        width: calc(var(--font-#{$size}) * var(--base-height));
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }

    &__circular {
      &--track {
        fill: transparent;
        stroke: var(--theme-neutral-light);
      }

      &--background {
        fill: transparent;
        transition: var(--transition-primary);

        @each $type in $types {
          &--#{$type} {
            stroke: var(--theme-#{$type});
          }
        }
      }
    }

    &__content {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      &.o-progress__content > * {
        display: flex;
      }

      &.inside {
        left: 0;
      }

      &.outside {
        left: 100%;
      }
    }
  }
}
