$boxHeight: 3;

o-upload-item {
  display: flex;
  flex-direction: column;

  .o-upload-item {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--font-md);
    width: 100%;
    border: 1px solid var(--theme-dark-10);
    border-radius: var(--border-radius-xxs);
    background-color: var(--theme-light);
    border-color: var(--theme-neutral-medium);
    padding: 0 var(--font-lg);

    @each $size in $fontSizes {
      &--size-#{$size} {
        min-height: calc(var(--font-#{$size}) * $boxHeight);

        & .o-upload-item__button {
          font-size: var(--font-#{$size});
          min-height: calc(var(--font-#{$size}) * var(--base-height));
        }
      }
    }

    &__button {
      padding: 0 var(--font-md);
      background-color: var(--theme-light);
      border: 0;
      border-radius: var(--border-radius-xxs);
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: var(--theme-light-hover);
      }

      &:active:not(:disabled) {
        background-color: var(--theme-light-active);
      }

      &:disabled,
      &:disabled:hover {
        background-color: var(--theme-light-hover);
        opacity: var(--disabled-opacity);
        cursor: not-allowed;
      }
    }

    &__name {
      flex: auto;
      width: 0;
      word-wrap: break-word;
    }
  }
}
