export function customEventsBuilder<PayloadType>(
  eventName: string,
  initialValue: PayloadType | undefined | null = null,
) {
  let subscriberCount = 0;
  let currentValue = initialValue;

  return {
    dispatch: (payload: PayloadType) => {
      currentValue = payload;

      if (subscriberCount === 0) {
        console.warn(
          `[CustomEvent] there are no subscribers to the event ${eventName}. Make sure you subscribe before dispatching events`,
        );
      }

      const event = new CustomEvent(eventName, {
        detail: payload,
      });

      window.dispatchEvent(event);
    },

    /**
     * By default and if available, you'll get the most recent value as soon as you subscribe,
     * unless you set the invokeImmediately flag to false */
    subscribe: (
      callback: (p: PayloadType) => void,
      invokeImmediately = true,
    ) => {
      subscriberCount++;

      if (invokeImmediately && currentValue) {
        callback(currentValue);
      }

      const handler = (ev: CustomEvent<PayloadType>) => {
        callback(ev.detail);
      };

      window.addEventListener(eventName, handler as EventListener);

      const unsubscribe = () => {
        subscriberCount--;
        window.removeEventListener(eventName, handler as EventListener);
      };

      return unsubscribe;
    },
  };
}
