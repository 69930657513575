import { Component, h } from "@stencil/core";

@Component({
  tag: "o-banner-image",
  styleUrl: "index.scss",
})
export class BannerImage {
  render() {
    return <slot />;
  }
}
