/**
 * Returns querySelector result or the element itself
 *
 * @param element Reference to Element
 * @param optionSelector CSS selector
 */
const _itselfOrSelector = (
  element: Element | null | undefined,
  optionSelector?: string
) =>
  optionSelector
    ? (element?.querySelector(optionSelector) as HTMLElement | null) ?? null
    : (element as HTMLElement | null);

/** Get first option in listbox */
const _getFirstOption = (listbox: HTMLElement, optionSelector?: string) =>
  _itselfOrSelector(listbox.firstElementChild, optionSelector);

/** Get last option in listbox */
const _getLastOption = (listbox: HTMLElement, optionSelector?: string) =>
  _itselfOrSelector(listbox.lastElementChild, optionSelector);

/** Get option after the selected one */
const _getNextOption = (
  activeElement: Element | null,
  optionSelector?: string
) =>
  _itselfOrSelector(
    activeElement?.closest("[role=option]")?.nextElementSibling,
    optionSelector
  );

/** Get option before the selected one */
const _getPrevOption = (
  activeElement: Element | null,
  optionSelector?: string
) =>
  _itselfOrSelector(
    activeElement?.closest("[role=option]")?.previousElementSibling,
    optionSelector
  );

/** Focus the next option or the first one */
const focusNextOption = (listbox: HTMLElement, optionSelector?: string) => {
  const target =
    _getNextOption(document.activeElement, optionSelector) ||
    _getFirstOption(listbox, optionSelector);
  target?.focus();
};

/** Focus the previous option or the last one */
const focusPrevOption = (listbox: HTMLElement, optionSelector?: string) => {
  const target =
    _getPrevOption(document.activeElement, optionSelector) ||
    _getLastOption(listbox, optionSelector);
  target?.focus();
};

/** Focus the first option */
const focusFirstOption = (listbox: HTMLElement, optionSelector?: string) => {
  const target = _getFirstOption(listbox, optionSelector);
  target?.focus();
};

/** Focus the last option */
const focusLastOption = (listbox: HTMLElement, optionSelector?: string) => {
  const target = _getLastOption(listbox, optionSelector);
  target?.focus();
};

/** Focus the option starting with a given character */
const focusCharacterOption = (
  char: string,
  listbox: HTMLElement,
  optionSelector?: string
) => {
  const opts = listbox.querySelectorAll("[role=option]");

  // iterate through all options
  for (let i = 0; i < opts.length; i++) {
    // if the text content starts with the given character
    if (!!opts[i].textContent?.match(RegExp(`^\\s*${char}`, "i"))?.length) {
      _itselfOrSelector(opts[i], optionSelector)?.focus();
      return;
    }
  }
};

interface KbNavigationHandlerProps {
  event: KeyboardEvent;
  listbox: HTMLElement;
  listboxVisible: boolean;
  setListboxVisibility: (listboxVisible: boolean) => void;
  optionSelector?: string;
}

/** Handle keyboard event to navigate options via keyboard */
export const keyboardNavigationEventHandler = ({
  event,
  listbox,
  listboxVisible,
  setListboxVisibility,
  optionSelector,
}: KbNavigationHandlerProps) => {
  // prevent page scrolling when pressing up/down arrows
  let consumed = true;

  if (["ArrowDown", "ArrowUp"].includes(event.key) && !listboxVisible) {
    // activate (show options) if listbox is focused and arrows are pressed
    setListboxVisibility(true);
  } else if (event.key === "Escape") {
    // deactivate (hide options) ESC is pressed
    setListboxVisibility(false);
    return;
  } else if (event.key === "Enter") {
    // toggle visibility if Enter is pressed
    setListboxVisibility(!listboxVisible);
    return;
  } else if (event.key === "ArrowDown") {
    if (listbox.contains(document.activeElement)) {
      focusNextOption(listbox, optionSelector);
    } else {
      focusFirstOption(listbox, optionSelector);
    }
  } else if (event.key === "ArrowUp") {
    if (listbox.contains(document.activeElement)) {
      focusPrevOption(listbox, optionSelector);
    } else {
      focusLastOption(listbox, optionSelector);
    }
  } else if (
    event.key.match(/^[a-z]$/i) &&
    listbox.contains(document.activeElement)
  ) {
    // only search by character if listbox is focused
    focusCharacterOption(event.key, listbox, optionSelector);
  } else {
    consumed = false;
  }

  if (consumed) {
    event.stopPropagation();
    event.preventDefault();
  }
};
