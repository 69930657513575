$object: "fill", "contain", "cover", "scale-down", "none";

o-image {
  display: block;

  .o-image {
    width: inherit;
    max-width: inherit;
    height: inherit;

    &--circle {
      border-radius: 50%;
    }

    @each $obj in $object {
      &--#{$obj} {
        object-fit: #{$obj};
      }
    }
  }
}
