import { Component, h, Prop } from "@stencil/core";
import { IType, ISize } from "../../globals/types";

@Component({
  tag: "o-badge",
  styleUrl: "index.scss",
})
export class Badge {
  /**
   * Atributo `title` da badge.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/title).
   *
   * @sbCategory Input
   */
  @Prop() title = "";
  /**
   * Cor da badge.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "primary";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Deixa sem background e com bordas e fonte da cor escolhida.
   *
   * @sbCategory Style
   */
  @Prop() outline = false;
  /**
   * Deixa as bordas arredondadas.
   *
   * @sbCategory Style
   */
  @Prop() rounded = false;
  /**
   * Faz o badge se tornar um círculo.
   *
   * @sbCategory Style
   */
  @Prop() circle = false;

  render() {
    const { type, size, title, outline, rounded, circle } = this;

    const classes = {
      "o-badge": true,
      "o-badge--rounded": rounded,
      [`o-badge--background-${type}`]: !outline,
      [`o-badge--outline-${type}`]: outline,
      [`o-badge--size-${size}`]: true,
      "o-badge--circle": circle,
    };

    return (
      <span class={classes} title={title}>
        <slot />
      </span>
    );
  }
}
