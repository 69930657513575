import { Component, Event, EventEmitter, h, Host, Prop } from "@stencil/core";
import { ICollapseInteractionDetail } from "../../../molecules/expander/types";

@Component({
  tag: "o-expander-header",
  styleUrl: "index.scss",
})
export class ExpanderHeader {
  @Event() collapseInteraction!: EventEmitter<ICollapseInteractionDetail>;

  /**
   * Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop() expanded = false;

  private handleClick = () => {
    this.collapseInteraction.emit({ expanded: !this.expanded });
  };

  render() {
    const { expanded, handleClick } = this;

    const classes = {
      "o-expander-header--expanded": expanded,
    };

    return (
      <Host class={classes} onClick={() => handleClick()}>
        <slot />
      </Host>
    );
  }
}
