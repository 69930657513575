import { Component, h, Host, Prop } from "@stencil/core";
import { ISize, ITypeComplete, IWeight } from "../../globals/types";

export type ITag = typeof tags[number];
const tags = ["h1", "h2", "h3", "h4", "h5", "h6", "span", "p"] as const;

@Component({
  tag: "o-typography",
  styleUrl: "index.scss",
})
export class Typography {
  /**
   * Tag HTML usada internamente.
   *
   * @sbCategory Input
   */
  @Prop() tag: ITag = "p";
  /**
   * Cor do texto.
   *
   * @sbCategory Style
   */
  @Prop() type: ITypeComplete | null = null;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Peso da fonte (negrito).
   *
   * @sbCategory Style
   */
  @Prop() weight: IWeight = "normal";

  render() {
    const { tag, type, size, weight } = this;

    const classes = {
      "o-typography": true,
      [`o-typography--font-${size}`]: true,
      [`o-typography--type-${type}`]: !!type,
      [`o-typography--weight-${weight}`]: true,
    };

    if (tags.find((tag) => tag === tag)) {
      // Reference: https://gilfink.medium.com/quick-tip-dynamic-stencil-component-names-with-jsx-cf7687a418e6
      const Tag = tag;

      return (
        <Host class={{ isSpan: tag === "span" }}>
          <Tag class={classes} part="element">
            <slot />
          </Tag>
        </Host>
      );
    }
  }
}
