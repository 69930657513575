import { Component, h, Prop } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-radio-base",
  styleUrl: "../radio/index.scss",
})
export class RadioBase {
  /**
   * Propriedade `id` do radio nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Valor do radio nativo.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `name` do radio nativo.
   *
   * @sbCategory Input
   */
  @Prop() name?: string;
  /**
   * Propriedade `checked` do radio nativo. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop() checked = false;
  /**
   * Propriedade `disabled` do radio nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do radio base.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";

  render() {
    const {
      id,
      name,
      value,
      checked,
      disabled,
      type,
      size,
      dataAction,
      dataLabel,
    } = this;

    const classes = {
      "o-radio__input": true,
      [`o-radio__input--size-${size}`]: true,
      [`o-radio__input--type-${type}`]: true,
    };

    return (
      <input
        type="radio"
        id={`o-${id}`}
        name={name}
        value={value}
        data-action={dataAction}
        data-label={dataLabel}
        checked={checked}
        disabled={disabled}
        class={classes}
      />
    );
  }
}
