$types: "success", "info", "warning", "danger";

.o-notification {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: var(--font-sm);
  height: auto;
  width: inherit;
  background-color: var(--theme-light);
  border-radius: var(--border-radius-xxs);
  padding: var(--font-xxl);

  @each $type in $types {
    &--type-#{$type} {
      background-color: var(--theme-#{$type}-light);
    }
  }
}
