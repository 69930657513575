o-label {
  .o-label {
    font-family: inherit;
    padding: 0;
    margin: 0;
    max-width: 100%;
    align-items: center;

    &--disabled {
      opacity: var(--disabled-opacity);
    }

    @each $type in $typesComplete {
      &--type-#{$type} {
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--font-#{$size} {
        font-size: var(--font-#{$size});
        height: calc(var(--font-#{$size}) * var(--base-height));
        line-height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }

    @each $weight in $weights {
      &--weight-#{$weight} {
        font-weight: #{$weight};
      }
    }
  }
}
