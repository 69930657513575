import axios from "axios";
import { env } from "utils";
import { getHeaders } from "utils/RestClient";

export const paperclipv2 = axios.create({
  baseURL: env.PAPERCLIPV2_URL,
});
paperclipv2.interceptors.request.use((config: any) => {
  config.headers = getHeaders();
  return config;
});

// export const raven = axios.create({
//   baseURL: ENV.RAVEN_URL,
// });
// raven.interceptors.request.use((config: any) => {
//   config.headers.Authorization = Cache.getItem("jwt")?.token;
//   return config;
// });

export const recebiveisCartaoClient = axios.create({
  baseURL: env.RECEBIVEIS_CARTAO_URL,
});
recebiveisCartaoClient.interceptors.request.use((config: any) => {
  config.headers = getHeaders();
  return config;
});
