import {
  Component,
  Event,
  EventEmitter,
  h,
  Host,
  Prop,
  Watch,
} from "@stencil/core";
import { ISize } from "../../../../globals/types";

@Component({
  tag: "o-option",
  styleUrl: "index.scss",
})
export class Option {
  @Event() changeOption!: EventEmitter<{ value: string | number }>;

  /**
   * Valor da opção, que é atribuído ao select quando ela é selecionada.
   *
   * @sbCategory Input
   */
  @Prop() value!: string | number;
  /**
   * Marca a opção como selecionada.
   *
   * @sbCategory Input
   */
  @Prop() selected = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  private handleClick = () => {
    this.changeOption.emit({ value: this.value });
  };

  @Watch("selected")
  handleSelected(newSelected: boolean) {
    if (newSelected) {
      this.changeOption.emit({ value: this.value });
    }
  }

  // advertise initial `selected`
  componentDidLoad() {
    this.handleSelected(this.selected);
  }

  render() {
    const { value, size, selected, handleClick } = this;

    const classes = {
      "o-option": true,
      [`o-option--size-${size}`]: true,
      "o-option--selected": selected,
      "slot-container": true,
    };

    return (
      <Host role="option" aria-selected={String(selected)} aria-label={value}>
        <button type="button" class={classes} onClick={handleClick}>
          <slot />
        </button>
      </Host>
    );
  }
}
