import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
} from "@stencil/core";
import type { OOptionCustomEvent } from "../../../../components";
import { keyboardNavigationEventHandler } from "../../../../globals/listbox-keyboard-navigation";
import type { IAspect, ISize, IType } from "../../../../globals/types";

@Component({
  tag: "o-select",
  styleUrl: "index.scss",
})
export class Select {
  /** Evento emitido ao selecionar uma opção. */
  @Event() input!: EventEmitter<string | number>;
  @Element() self!: HTMLElement;
  @State() isActive = false;
  @State() activeOption = null;
  @State() headerId: string = Math.random().toString(36).substring(2);

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Valor atual do input. Funciona como um estado. Corresponde ao `value` da
   * opção selecionada.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string | number | null;
  /**
   * Texto que aparece quando nenhuma opção está selecionada.
   * 
   * @deprecated Usar a propriedade `placeholder`
   * @sbCategory Input
   */
  @Prop() label = "Selecione:";
  /**
   * Texto que aparece quando nenhuma opção está selecionada.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Desabilita interação com o componente.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Desabilita interação com o componente.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Cor do background.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Tamanho da seta indicadora.
   *
   * @sbCategory Style
   */
  @Prop() iconSize: ISize = "xl";

  @State() _placeholder = this.placeholder ?? this.label;

  private optionElement?: HTMLDivElement;

  //deal with isActive state when select__header is clicked
  private handleClick = () => {
    if (this.disabled || this.readonly) return;

    this.isActive = !this.isActive;
  };

  //click outside o-select component
  @Listen("mousedown", { target: "document" })
  clickHandler(e: MouseEvent) {
    if (!this.self.contains(e.target as Node)) {
      this.isActive = false;
    }
  }

  // listbox navigation with keyboard
  @Listen("keydown", { target: "document" })
  keyDownHandler(e: KeyboardEvent) {
    // return if this select is not focused
    if (
      !this.optionElement ||
      !(
        this.self === document.activeElement ||
        this.self.contains(document.activeElement)
      )
    ) {
      return;
    }

    keyboardNavigationEventHandler({
      event: e,
      listbox: this.optionElement,
      listboxVisible: this.isActive,
      setListboxVisibility: (newActive) => {
        this.isActive = newActive;
      },
      optionSelector: "button",
    });
  }

  @Listen("changeOption")
  handleChangeOption(event: OOptionCustomEvent<any>) {
    this._placeholder = event.target?.firstElementChild?.textContent ?? this.placeholder ?? this.label;
    this.value = event.target?.value;
    this.isActive = false;

    this.input.emit(this.value);
  }

  private handleChangeValue(newValue?: string | number | null) {
    let someSelected = false;
    this.self.querySelectorAll("o-option").forEach((el) => {
      const selected = el["value"] === newValue;
      el["selected"] = selected;
      someSelected = someSelected || selected;
    });

    // reset placeholder in case no option was selected
    // (set unknown value, or null | undefined)
    if (!someSelected) {
      this._placeholder = this.placeholder ?? this.label;
    }
  }

  // fire off handleChangeValue to apply value
  componentDidRender() {
    this.handleChangeValue(this.value);
  }

  // set o-option size to the same as o-select
  componentDidLoad() {
    this.self.querySelectorAll("o-option").forEach((el) => {
      el["size"] = this.size;
    });
  }

  render() {
    const {
      dataAction,
      dataLabel,
      type,
      value,
      aspect,
      disabled,
      readonly,
      isActive,
      _placeholder,
      size,
      iconSize,
      headerId,
      handleClick,
    } = this;

    const classes = {
      "o-select": true,
      [`o-select--type-${type}`]: true,
    };

    const classesHeader = {
      "o-select__header": true,
      [`o-select__header--aspect-${aspect}`]: true,
      [`o-select__header--size-${size}`]: true,
      "o-select__header--disabled": disabled || readonly,
    };

    return (
      <Host class={classes}>
        <button
          type="button"
          class={classesHeader}
          disabled={disabled || readonly}
          data-action={dataAction}
          data-label={dataLabel}
          data-active={isActive}
          role="combobox"
          aria-label={_placeholder}
          aria-owns={headerId}
          aria-autocomplete="list"
          aria-expanded={`${isActive}`}
          aria-activedescendant={`ooption_${headerId}_${value}`}
          onClick={handleClick}
        >
          <span>{_placeholder}</span>
          <o-icon
            category="fal"
            icon="fa-angle-down"
            size={iconSize}
            aria-hidden="true"
          />
        </button>
        <div
          id={headerId}
          class="slot-container o-select__option"
          data-active={isActive}
          role="listbox"
          ref={(el) => (this.optionElement = el)}
        >
          <slot />
        </div>
      </Host>
    );
  }
}
