import { Component, h, Prop } from "@stencil/core";

@Component({
  tag: "o-video",
})
export class Video {
  /**
   * Source Path ou URL de um vídeo. Exemplo com link do youtube:
   * `https://www.youtube.com/embed/{PATH}`.
   *
   * @sbCategory Input
   */
  @Prop() src!: string;
  /**
   * Largura do vídeo. Sintaxe conforme a prorpiedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width = "auto";
  /**
   * Altura do vídeo. Sintaxe conforme a prorpiedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() height = "auto";

  render() {
    return (
      <iframe
        frameborder="0"
        src={this.src}
        width={this.width}
        height={this.height}
      />
    );
  }
}
