o-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  & [slot="before"],
  & [slot="after"] {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & [slot="before"] {
    left: 0;
    justify-content: flex-start;
  }

  & [slot="after"] {
    right: 0;
    justify-content: flex-end;
  }

  &.o-input--outline {
    & [slot="before"],
    & [slot="after"] {
      justify-content: center;
    }
  }

  @each $size in $fontSizes {
    &.size-#{$size} {
      & [slot="before"] ~ .o-input {
        padding-left: calc(var(--font-#{$size}) * var(--base-height));
      }

      & [slot="after"] ~ .o-input {
        padding-right: calc(var(--font-#{$size}) * var(--base-height));
      }

      & [slot="before"],
      & [slot="after"] {
        width: calc(var(--font-#{$size}) * 2.3);
      }

      .o-input {
        font-size: var(--font-#{$size});
        height: calc(var(--font-#{$size}) * var(--base-height));
        line-height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }

  .o-input {
    font-family: inherit;
    box-sizing: border-box;
    width: 100%;
    border: 0;
    outline: none;
    padding: 0;
    background: inherit;
    transition: var(--transition-primary);

    &:disabled {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    &:hover {
      border-color: var(--border-color-hover);
    }

    &.error {
      color: var(--theme-danger);
      border-color: var(--theme-danger);
    }

    &--flushed {
      border-bottom: var(--border);
    }

    &--outline {
      border: var(--border);
      border-radius: var(--border-radius-xxs);
      padding: 0 var(--font-md);
    }

    &--flushed,
    &--outline {
      &:hover {
        border-color: var(--theme-neutral-normal);
      }

      &:focus {
        border-color: var(--theme-focus);
      }

      &.error {
        &:hover,
        &:focus {
          border-color: var(--theme-danger);
        }
      }
    }
  }
}
