o-field {
  width: 100%;

  .o-field {
    font-family: inherit;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;

    &:disabled .description {
      opacity: var(--disabled-opacity);
    }
  }
}
