const key1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
const key2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

/**
 * Exemplos corretos: 50084425032 | 500.844.250-32 Source:
 * https://www.macoratti.net/alg_cpf.htm
 */

/** Applies a CPF validator to the argument string */
export const cpf = (value: string | undefined | null): value is string => {
  if (typeof value !== "string") return false;

  const cleanValue = value.replace(/[^\d]+/g, "");

  const regexWithMask = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/;
  const regexWithoutMask = /^[0-9]{11}$/;
  const regexSameDigits = /^(\d)\1{10}$/;

  if (
    (!regexWithMask.test(value) && !regexWithoutMask.test(value)) ||
    regexSameDigits.test(cleanValue)
  )
    return false;

  const allDigits = cleanValue.split("").map(Number);

  // 1st verifying digit
  const digits1 = allDigits.slice(0, key1.length);

  const sum1 = digits1
    .map((_digit, i) => digits1[i] * key1[i])
    .reduce((m, n) => m + n);

  const mod1 = sum1 % 11;
  const verify1 = mod1 < 2 ? 0 : 11 - mod1;

  // 2nd verifying digit
  const digits2 = [...digits1, verify1];

  const sum2 = digits2
    .map((_digit, i) => digits2[i] * key2[i])
    .reduce((m, n) => m + n);

  const mod2 = sum2 % 11;
  const verify2 = mod2 < 2 ? 0 : 11 - mod2;

  return allDigits[9] === verify1 && allDigits[10] === verify2;
};
