import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Prop,
  State,
} from "@stencil/core";
import IMask from "imask";
import { IAspect, ISize } from "../../globals/types";
import { maskRegexType } from "./types";

@Component({
  tag: "o-input-pin-text",
})
export class InputPinText {
  @Element() self!: HTMLElement;

  /** Evento emitido ao ocorrer input. */
  @Event() input!: EventEmitter<string>;
  /** Evento emitido ao validar o conteúdo do input. */
  @Event() valid!: EventEmitter<string>;

  @State() type: "text" | "password" = "text";
  @State() maskRegex?: RegExp;

  //pin props
  /**
   * Determina quais tipos de caracteres são aceitos..
   *
   * @sbCategory Pin Input
   */
  @Prop() pinType: "all" | "text" | "number" = "all";
  /**
   * Determina se o input iniciará com foco.
   *
   * @sbCategory Pin Input
   */
  @Prop() autoFocus = false;
  /**
   * Determina se os caracteres estão visíveis.
   *
   * @sbCategory Pin Input
   */
  @Prop() secret = false;
  /**
   * Callback de validação dos caracteres inputados.
   *
   * @sbCategory Pin Input
   * @sbControl false
   */
  @Prop() validate?: (value: string) => boolean;

  // o-input props
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop() value?: string;
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;
  private mask?: IMask.InputMask<{ mask: RegExp }>;

  componentDidRender() {
    if (this.mask) {
      this.mask.destroy();
    }

    if (this.inputElement && this.maskRegex) {
      this.mask = IMask(this.inputElement, { mask: this.maskRegex });

      this.mask.on("accept", () => {
        if (!this.mask) return;

        if (this.validate) {
          const validation = this.validate(this.mask.value);

          if (validation) {
            this.valid.emit(this.mask.value);
          }
        }

        this.input.emit(this.mask.value);
      });
    }
  }

  componentWillLoad() {
    this.maskRegex = maskRegexType[this.pinType];

    if (this.secret) {
      this.type = "password";
    }
  }

  componentDidLoad() {
    if (!this.inputElement) return;

    if (this.autoFocus) {
      this.inputElement.focus();
    }

    this.inputElement.addEventListener("input", (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    });
  }

  render() {
    const {
      type,
      dataAction,
      dataLabel,
      id,
      name,
      value,
      maxlength,
      placeholder,
      aspect,
      size,
      error,
      disabled,
      readonly,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        inputType={type}
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        aspect={aspect}
        autocomplete="off"
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }
}
