import { Component, Element, h, Prop } from "@stencil/core";
import { IAspect, ISize } from "../../globals/types";

@Component({
  tag: "o-textarea",
  styleUrl: "index.scss",
})
export class Textarea {
  @Element() self!: HTMLElement;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Habilita o ajuste de altura do componente.
   *
   * @sbCategory Style
   */
  @Prop() resize: "none" | "vertical" = "vertical";
  /**
   * Altura inicial do componente. Valor numérico em pixels ou `"auto"`.
   *
   * @sbCategory Style
   */
  @Prop() initialHeight?: number | "auto";
  /**
   * Se ativado, a altura do componente se ajusta automaticamente ao conteúdo.
   *
   * @sbCategory Style
   */
  @Prop() adaptHeight = false;
  /**
   * Callback de referência do `input` nativo.
   *
   * @sbCategory Style
   */
  @Prop() nativeRef?: (el?: HTMLTextAreaElement) => void;

  private textareaElement?: HTMLTextAreaElement;
  private computedHeight = 0;

  componentDidLoad() {
    if (this.initialHeight && this.textareaElement) {
      const computedHeight = window
        .getComputedStyle(this.textareaElement)
        .getPropertyValue("height")
        .match(/[0-9.]/g);

      this.textareaElement.style.height =
        this.initialHeight === "auto" ? "auto" : `${this.initialHeight}px`;

      this.computedHeight = Number(computedHeight?.join(""));
    }
  }

  componentDidRender() {
    this.adaptHeight && this.adjustHeightToContent();
  }

  private handleInput() {
    if (this.textareaElement) {
      this.value = this.textareaElement.value;
    }
  }

  private adjustHeightToContent() {
    if (!this.textareaElement) return;

    if (this.textareaElement.scrollHeight > this.textareaElement.clientHeight) {
      this.textareaElement.style.height = `${
        this.textareaElement.scrollHeight + 2
      }px`;
    } else if (
      this.textareaElement.scrollHeight < this.textareaElement.clientHeight
    ) {
      this.textareaElement.style.height = `${this.computedHeight}px`;
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      maxlength,
      placeholder,
      resize,
      aspect,
      size,
      error,
      disabled,
      readonly,
    } = this;

    const classes = {
      "o-textarea": true,
      [`o-textarea--${aspect}`]: true,
      [`o-textarea--size-${size}`]: true,
      [`o-textarea--resize-${resize}`]: true,
      "error": error,
    };

    return (
      <textarea
        ref={(el) => {
          this.textareaElement = el;
          this.nativeRef && this.nativeRef(el);
        }}
        data-action={dataAction}
        data-label={dataLabel}
        id={`o-${id}`}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        disabled={disabled}
        readonly={readonly}
        class={classes}
        onInput={() => this.handleInput()}
        onKeyDown={() => this.adaptHeight && this.adjustHeightToContent()}
      />
    );
  }
}
