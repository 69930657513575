import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Method,
  Prop,
} from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-filter-modal",
})
export class FilterModal {
  @Element() self!: HTMLElement;

  /**
   * Id do modal.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Largura do modal.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "xs";
  /**
   * Faz com que um clique no backdrop feche o filter.
   *
   * @sbCategory Input
   */
  @Prop() backdrop = true;
  /**
   * Arredonda as bordas do filter.
   *
   * @sbCategory Style
   */
  @Prop() rounded = true;

  /** Evento emitido ao iniciar filtro. */
  @Event() init!: EventEmitter<Record<string, any>>;

  /** Metodo para abrir modal do modal */
  @Method()
  async openFilter() {
    return document.dispatchEvent(
      new CustomEvent("modalOpen", { detail: { id: this.id } })
    );
  }

  /** Metodo para fechar modal do filtro */
  @Method()
  async closeFilter() {
    return document.dispatchEvent(
      new CustomEvent("modalClose", { detail: { id: this.id } })
    );
  }

  /**
   * Metodo para aplicar parametros do filtro na url.
   *
   * @returns Objeto com params do filtro
   */
  @Method()
  async filter<T extends Record<string, any>>() {
    return this.self.querySelector("o-filter")?.filter<T>();
  }

  /** Metodo para iniciar filtro com params da url */
  @Method()
  async initFilter() {
    this.self.querySelector("o-filter")?.initFilter();
  }

  /** Metodo para limpar filtro */
  @Method()
  async clearFilter<T extends Record<string, any>>(
    cleanValues: Partial<T> = {}
  ) {
    return this.self.querySelector("o-filter")?.clearFilter<T>(cleanValues);
  }

  render() {
    const { size, rounded } = this;

    return (
      <o-filter>
        <o-modal
          id={this.id}
          type="light"
          size={size}
          position="right"
          rounded={rounded}
        >
          <slot />
        </o-modal>
      </o-filter>
    );
  }
}
