import type { JSX } from "@maestro/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import { OCheckboxGroup, OField } from "../../../stencil-generated";
import { fieldOptionsBuilder, FieldProps } from "../../../utils";
import { TooltipContainer } from "../../tooltip-container";

export interface ORFieldCheckboxGroupProps
  extends JSX.OCheckboxGroup,
    FieldProps {
  name: string;
  children?: React.ReactNode;
}

export const ORFieldCheckboxGroup = (props: ORFieldCheckboxGroupProps) => {
  // DO NOT DELETE UNUSED VARIABLES
  // they are being descructured to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    children,
    ...rest
  } = props;
  const { register, formState, getFieldState } = useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  const registered = register(name);
  const fieldOptions = fieldOptionsBuilder(fieldErrors, props);

  return (
    <OField {...fieldOptions}>
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}

      <OCheckboxGroup
        {...(rest as any)}
        error={!!fieldErrors}
        {...registered}
        onInput={(e) => {
          registered.onChange(e);
        }}
      >
        {children}
      </OCheckboxGroup>
    </OField>
  );
};
