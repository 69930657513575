/* eslint-disable */
import type { JSX } from "@maestro/core";
import {
  OBanner as OBannerBase,
  OBannerBody as OBannerBodyBase,
  OButton as OButtonBase,
  OLabel as OLabelBase,
  OLink as OLinkBase,
  OList as OListBase,
  OListItem as OListItemBase,
  OModalBody as OModalBodyBase,
  OModalFooter as OModalFooterBase,
  OModalHeader as OModalHeaderBase,
  OOption as OOptionBase,
  OOrderedList as OOrderedListBase,
  OProgressCircular as OProgressCircularBase,
  OSelect as OSelectBase,
  OUnorderedList as OUnorderedListBase
} from "../stencil-generated";
import { withSlotRef } from "./slot-ref";

export const OBanner = withSlotRef<JSX.OBanner, HTMLOBannerElement>(OBannerBase);
export const OBannerBody = withSlotRef<JSX.OBannerBody, HTMLOBannerBodyElement>(OBannerBodyBase);
export const OButton = withSlotRef<JSX.OButton, HTMLOButtonElement>(OButtonBase);
export const OLabel = withSlotRef<JSX.OLabel, HTMLOLabelElement>(OLabelBase);
export const OLink = withSlotRef<JSX.OLink, HTMLOLinkElement>(OLinkBase);
export const OModalBody = withSlotRef<JSX.OModalBody, HTMLOModalBodyElement>(OModalBodyBase);
export const OModalFooter = withSlotRef<JSX.OModalFooter, HTMLOModalFooterElement>(OModalFooterBase);
export const OModalHeader = withSlotRef<JSX.OModalHeader, HTMLOModalHeaderElement>(OModalHeaderBase);
export const OOption = withSlotRef<JSX.OOption, HTMLOOptionElement>(OOptionBase);
export const OProgressCircular = withSlotRef<JSX.OProgressCircular,HTMLOProgressCircularElement>(OProgressCircularBase);
export const OSelect = withSlotRef<JSX.OSelect, HTMLOSelectElement>(OSelectBase);

/** @deprecated Usar `<ul>` do HTML nativo */
export const OList = withSlotRef<JSX.OList, HTMLOListElement>(OListBase);
/** @deprecated Usar `<li>` do HTML nativo */
export const OListItem = withSlotRef<JSX.OListItem, HTMLOListItemElement>(OListItemBase);
/** @deprecated Usar `<ol>` do HTML nativo */
export const OOrderedList = withSlotRef<JSX.OOrderedList,HTMLOOrderedListElement>(OOrderedListBase);
/** @deprecated Usar `<ul>` do HTML nativo */
export const OUnorderedList = withSlotRef<JSX.OUnorderedList,HTMLOUnorderedListElement>(OUnorderedListBase);
