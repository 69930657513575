import axios, { AxiosRequestConfig } from "axios";
import * as React from "react";
import { env } from "utils";

export const getHeaders = () => {
  return {
    Authorization: window.session.authorization!,
    "x-auth-type": "user",
    "x-auth-user": window.session.current?.email,
  };
};

export const DefaultClient = axios.create({
  baseURL: env.QUICKFIN_API,
});

DefaultClient.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = getHeaders();

  return config;
});

export const RestClientContext = React.createContext(DefaultClient);

const integrationClient = axios.create({
  baseURL: env.INTEGRATION_URL,
});

export const IntegrationClientContext = React.createContext(integrationClient);
