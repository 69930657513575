@import "../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

o-carousel {
  max-width: 100%;

  /** Making non-active slides out of focus */
  .glide__slide {
    opacity: 0.6;
    filter: saturate(0.2);
    transition: var(--transition-primary);

    &.glide__slide--active {
      opacity: 1;
      filter: saturate(1);
    }
  }

  /** Position controls (dots and/or arrows) below carousel */
  .glide__bullets {
    bottom: 0;
    transform: translate(-50%, 100%);
  }

  /** Styling arrows */
  .glide__arrow {
    position: static;
    display: inline-block;
    border: 0;
    box-shadow: none;
    padding: 0.25rem;
    transform: none;
    color: var(--theme-dark);
    transition: var(--transition-primary);

    &:hover,
    &:focus {
      color: var(--theme-tertiary-hover);
    }

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }
  }

  /** Styling dots */
  .glide__bullet {
    background-color: var(--theme-secondary-20);
    border: none;

    &:hover,
    &:focus {
      border: none;
      background-color: var(--theme-tertiary-hover);
    }

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }

    &.glide__bullet--active {
      background-color: var(--theme-tertiary);
      border: none;
    }
  }
}
