o-clickable-box {
  display: inline-block;
  width: 100%;

  .o-clickable-box {
    position: relative;

    @each $size in $fontSizes {
      &--size-#{$size} {
        .o-clickable-box__label {
          .o-label {
            line-height: calc(var(--font-#{$size}) * var(--base-height));
            min-height: calc(var(--font-#{$size}) * var(--base-height));
            font-size: var(--font-#{$size});
          }

          .o-clickable-box__status {
            line-height: calc(var(--font-#{$size}) * var(--base-height));

            &:after {
              font-size: var(--font-#{$size});
              min-height: calc(var(--font-#{$size}) * var(--base-height));
            }
          }
        }
      }
    }

    @each $type in $types {
      &--type-#{$type} {
        > input:checked ~ .o-clickable-box__label {
          background-color: var(--theme-#{$type});
          color: var(--theme-#{$type}-inverse);
        }

        // correction light color
        > input:disabled:not(:checked) ~ .o-clickable-box__label {
          background-color: var(--theme-dark-05);
        }
      }

      // correction light color
      &--type-light {
        > input:disabled ~ .o-clickable-box__label {
          background-color: var(--theme-dark-05);
        }
      }

      &--outline-#{$type} {
        > input:checked ~ .o-clickable-box__label {
          background-color: transparent;
          border-color: var(--theme-#{$type});
          color: var(--theme-#{$type});
        }
      }

      // correction light color
      &--outline-light {
        > input:checked ~ .o-clickable-box__label {
          color: var(--theme-light-inverse);
        }
      }
    }

    > input {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      appearance: unset;
      cursor: pointer;

      &:checked ~ .o-clickable-box__label .o-clickable-box__status:after {
        opacity: 1;
        visibility: visible;
      }

      &:hover:not(:checked):not(:disabled) ~ .o-clickable-box__label,
      &:focus:not(:checked):not(:disabled) ~ .o-clickable-box__label {
        color: var(--theme-dark);
        background: var(--theme-light-hover);
      }

      &:active:not(:checked):not(:disabled) ~ .o-clickable-box__label {
        color: var(--theme-dark);
        background: var(--theme-light-active);
      }

      &:disabled,
      &:disabled ~ .o-clickable-box__label {
        opacity: var(--disabled-opacity);
        cursor: not-allowed;

        label {
          cursor: not-allowed;
        }
      }

      &:focus-visible {
        outline: 2px solid var(--theme-focus);
      }
    }

    &__label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--font-md);
      align-items: center;
      padding: var(--font-xs) var(--font-xl);
      border: var(--border);
      border-radius: var(--border-radius-xxs);
      cursor: pointer;

      .o-clickable-box__status {
        display: flex;
        justify-content: flex-end;

        &:after {
          font-family: "icomoon-orquestra" !important;
          content: "";
          opacity: 0;
          visibility: hidden;
          transition: var(--transition-primary);
        }
      }
    }
  }
}
