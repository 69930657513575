import PageLogo from "components/page-logo/page-logo.component";
import React from "react";
import { EntitySearch } from "./entity-search/entity-search";
import UserInfo from "./user-info";
import { NewAdminLink } from "components/new-admin-link/new-admin-link.component";

export function Header() {
  return (
    <header className="page-header" role="banner">
      <div>
        <PageLogo />
        <NewAdminLink />
      </div>

      {window.session.roleFilter(["admin:EntitySearch.View"]) && (
        <EntitySearch />
      )}

      <div className="ml-auto d-flex">
        <div className="hidden-md-down">
          <a
            href="/#"
            className="header-icon"
            data-toggle="modal"
            data-target=".js-modal-settings"
            style={{ cursor: "pointer" }}
          >
            <i className="fal fa-cog"></i>
          </a>
        </div>
        <UserInfo />
      </div>
    </header>
  );
}
