import { Component, Element, h, Host, Prop } from "@stencil/core";
import { ISize } from "../../../globals/types";

@Component({
  tag: "o-skeleton",
  styleUrl: "index.scss",
})
export class Skeleton {
  @Element() self!: HTMLElement;

  /**
   * Tamanho da linha.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Espaçamento entre as linhas.
   *
   * @sbCategory Style
   */
  @Prop() space: ISize = "sm";
  /**
   * Quantidade de linhas geradas.
   *
   * @sbCategory Input
   */
  @Prop() lines = 1;
  /**
   * Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop() loading = false;

  componentDidLoad() {
    for (let i = 0; i < this.lines; i++) {
      const line = document.createElement("o-skeleton-line");
      line.setAttribute("size", this.size);

      this.self.querySelector(".o-skeleton")?.appendChild(line);
    }
  }

  render() {
    const { loading, space } = this;

    const classSkeleton = {
      "o-skeleton": true,
      "o-skeleton--visible": loading,
      [`o-skeleton--font-${space}`]: true,
    };

    const classSlot = {
      "o-skeleton-slot": true,
      "o-skeleton-slot--visible": !loading,
    };

    return (
      <Host>
        <div class={classSkeleton} />
        <div class={classSlot}>
          <slot />
        </div>
      </Host>
    );
  }
}
