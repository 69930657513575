import React from "react";
import { useFormContext } from "react-hook-form";
import { TooltipContainer } from "../../tooltip-container";
import {
  OField,
  OInputCep,
  OInputCnpj,
  OInputCpf,
  OInputCpfCnpj,
  OInputCurrency,
  OInputMask,
  OInputNumber,
  OInputPercentage,
  OInputPhone,
  OInputSearch,
  OInputSecret,
  OInputTag,
  OInputText,
  OTextarea,
} from "../../../stencil-generated";
import { fieldOptionsBuilder } from "../../../utils";
import type { ORFieldInputProps, Tags } from "./types";

const tagMap = {
  cep: OInputCep,
  cnpj: OInputCnpj,
  cpf: OInputCpf,
  cpfcnpj: OInputCpfCnpj,
  currency: OInputCurrency,
  mask: OInputMask,
  number: OInputNumber,
  percentage: OInputPercentage,
  phone: OInputPhone,
  search: OInputSearch,
  secret: OInputSecret,
  tag: OInputTag,
  text: OInputText,
  textarea: OTextarea,
};

export const ORFieldInput = <T extends Tags>(props: ORFieldInputProps<T>) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    tag,
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    ...rest
  } = props;
  const { register, formState, getFieldState } = useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  const registered = register(name);
  const fieldOptions = fieldOptionsBuilder(fieldErrors, props);

  const Tag = tagMap[tag];

  return (
    <OField {...fieldOptions}>
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}

      <Tag
        {...(rest as any)}
        error={!!fieldErrors}
        {...registered}
        onInput={(e) => {
          registered.onChange(e);
        }}
      />
    </OField>
  );
};

export type { ORFieldInputProps } from "./types";
