import React, { PropsWithChildren } from "react";
import styles, { TextStyleType } from "./styles";
import { TextStyled } from "./text.styles";
import Typography, {
  TypographyProps,
} from "@material-ui/core/Typography/Typography";

type textProps = {
  type: keyof TextStyleType;
  style?: any;
};

export function StyledText(
  props: PropsWithChildren<TypographyProps> & textProps,
) {
  return (
    <Typography style={{ ...props.style, ...styles[props.type] }} {...props}>
      {props.children}
    </Typography>
  );
}

type TextType = "display1" | "display2" | "title" | "heading";

type ColorType = "darkGray" | "mainGray" | "mainOrange" | "white";

interface IText extends React.ComponentProps<any> {
  display?: TextType;
  bold?: boolean;
  color?: ColorType;
  variant?: any;
  children: any;
  inline?: boolean;
}

export function Typographyv2(props: IText) {
  return (
    // @ts-ignore
    <TextStyled as={props.variant} {...props}>
      {props.children}
    </TextStyled>
  );
}
