import { cep } from "./cep";
import { cnpj } from "./cnpj";
import { cpf } from "./cpf";
import { cpfCnpj } from "./cpf-cnpj";
import { email } from "./email";
import { phone } from "./phone";

export const validators = {
  cep,
  cnpj,
  cpf,
  cpfCnpj,
  email,
  phone,
};
