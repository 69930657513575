import {
  Component,
  Event,
  EventEmitter,
  h,
  Host,
  Prop,
  Watch,
} from "@stencil/core";
import { ISize } from "../../../../globals/types";

@Component({
  tag: "o-option-multiple",
  styleUrl: "index.scss",
})
export class OptionMultiple {
  /** Evento emitido ao selecionar a opção */
  @Event() optionSelected!: EventEmitter<{ value: string; selected: boolean }>;

  /**
   * Valor da opção, que é atribuído ao select quando ela é selecionada.
   *
   * @sbCategory Input
   */
  @Prop() value!: string;
  /**
   * Marca a opção como selecionada.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) selected = false;
  /**
   * Esconde a opção.
   *
   * @sbCategory Input
   */
  @Prop() hidden = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  @Watch("selected")
  handleSetChecked() {
    this.optionSelected.emit({ value: this.value, selected: this.selected });
  }

  render() {
    const { value, size, selected } = this;

    const classes = {
      "o-option-multiple": true,
      [`o-option-multiple--size-${size}`]: true,
      "o-option-multiple--selected": selected,
    };

    return (
      <Host
        role="option"
        aria-selected={String(selected)}
        aria-label={value}
        onClick={() => {
          this.selected = !this.selected;
        }}
      >
        <button type="button" class={classes}>
          <div>
            <slot />
          </div>
          <span />
        </button>
      </Host>
    );
  }
}
