o-skeleton-line {
  .o-skeleton-line {
    width: 100%;
    background-image: $skeletonBackground;
    animation: $skeletonAnimation;

    @each $size in $fontSizes {
      &--size-#{$size} {
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }
  }
}
