o-badge {
  .o-badge {
    font-family: inherit;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: var(--font-xs);
    padding: 0 var(--font-xs);
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--border-radius-xxs);

    &--rounded {
      border-radius: var(--border-radius-xxl);
    }

    &--circle {
      border-radius: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @each $type in $types {
      &--background-#{$type} {
        background-color: var(--theme-#{$type});
        color: var(--theme-#{$type}-inverse);
      }

      &--outline-#{$type} {
        border: 1px solid var(--theme-#{$type});
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        line-height: calc(var(--font-#{$size}) * var(--base-height));
        font-size: var(--font-#{$size});

        &.o-badge--circle {
          width: calc(var(--font-#{$size}) * var(--base-height));
          height: calc(var(--font-#{$size}) * var(--base-height));
        }
      }
    }
  }
}
