import menuApp from "./menu.json";
import { TMenu } from "./menu.types";

function normalizeMenuUrls(menuArr: TMenu): TMenu {
  return menuArr.map((i) => {
    if ("items" in i) {
      return {
        ...i,
        items: normalizeMenuUrls(i.items),
      };
    } else {
      return {
        ...i,
        url: i.url,
      };
    }
  });
}

function hasChildrenActive(items: TMenu) {
  return items.some((item) => item.active === true);
}

function matchExact(r: string, str: string) {
  var match = str.match(r);
  return match && str === match[0];
}

export function getActiveMenu(menuArr: TMenu): TMenu {
  return menuArr.map((i) => {
    if ("items" in i) {
      const items = getActiveMenu(i.items);

      return {
        ...i,
        items,
        active: hasChildrenActive(items),
        open: hasChildrenActive(items),
      };
    } else {
      const active = !!matchExact(window.location.pathname, i.url!);

      return {
        ...i,
        active,
      };
    }
  });
}

/**
 * Recursive filter menu
 *
 * Conditions:
 * if child node, check pass roleFilter,
 * if parent node, check has filtered children and pass role filter
 *
 */
function filterItems(menuList: TMenu) {
  const newMenuList: TMenu = [];
  menuList.forEach((i) => {
    if ("items" in i) {
      const filteredChildren = filterItems(i.items);
      const passHasChildrenAndRole =
        !!filteredChildren.length && window.session.roleFilter(i.roles);
      if (passHasChildrenAndRole) {
        newMenuList.push({ ...i, items: filteredChildren });
      }
    } else if (window.session.roleFilter(i.roles)) {
      newMenuList.push(i);
    }
  });
  return newMenuList;
}

export default function createDynamicMenu() {
  let initialMenu = menuApp as TMenu;

  initialMenu = filterItems(initialMenu);
  initialMenu = normalizeMenuUrls(initialMenu);
  initialMenu = getActiveMenu(initialMenu);

  return initialMenu;
}
