import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Prop,
  Watch,
} from "@stencil/core";

@Component({
  tag: "o-pill-tab-header",
  styleUrl: "index.scss",
})
export class OPillTabHeader {
  @Element() self!: HTMLElement;

  /** Evento emitido quando uma pilltab é selecionada. */
  @Event() selected!: EventEmitter<number>;

  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeTab = 0;
  /**
   * Callback chamado quando uma pilltab é selecionada.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (index: number) => void;

  @Watch("activeTab")
  handleChangeActiveTab(newValue: number) {
    const pilltabs = this.self.querySelectorAll("o-pill-tab");

    pilltabs.forEach((el, index) => {
      if (newValue === index) {
        el.active = true;
      } else {
        el.active = false;
      }
    });
  }

  // listen children click events and update activeTab property
  componentDidLoad() {
    const pilltabs = this.self.querySelectorAll("o-pill-tab");

    this.handleChangeActiveTab(this.activeTab);

    pilltabs.forEach((el, index) => {
      el.addEventListener("click", () => {
        this.activeTab = index;

        this.selected.emit(index);
        if (this.handleSelected) this.handleSelected(index);
      });
    });
  }

  render() {
    return (
      <Host class="o-pill-tab-header">
        <slot />
      </Host>
    );
  }
}
