$zIndex: 999;

o-loader {
  .o-loader {
    &__spinner {
      z-index: $zIndex;
      border-radius: 100%;
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
      animation: 0.8s linear infinite loader;
      box-sizing: border-box;

      @each $type in $types {
        &--type-#{$type} {
          border-top-color: var(--theme-#{$type});
          border-right-color: var(--theme-#{$type});
        }
      }

      @each $size in $fontSizes {
        &--size-#{$size} {
          width: calc(var(--font-#{$size}) * var(--base-height));
          height: calc(var(--font-#{$size}) * var(--base-height));
        }
      }
    }

    &--absolute {
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;

      .o-loader--backdrop {
        position: absolute;
        inset: 0;
      }
    }

    &--backdrop {
      width: 100%;
      height: 100%;
      z-index: $zIndex;
      background: var(--theme-neutral-normal);
      opacity: var(--disabled-opacity);
    }

    @keyframes loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
