$orientation: (
  "horizontal": "row",
  "vertical": "column",
);

o-banner {
  width: 100%;

  .o-card {
    display: flex;

    @each $position, $dir in $orientation {
      &--orientation-#{$position} {
        flex-direction: #{$dir};
      }
    }

    &--text-truncate {
      o-banner-body,
      o-banner-content .description > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
