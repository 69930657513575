import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  Watch,
} from "@stencil/core";

@Component({
  tag: "o-radio-group",
})
export class RadioGroup {
  @Element() self!: HTMLElement;

  /** Evento emitido ao selecionar o radio. */
  @Event() input!: EventEmitter<string>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Valor do `o-radio` selecionado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value = "";
  /**
   * Desabilita o radio group.
   *
   * @sbCategory Input
   */
  @Prop() disabled?: boolean;

  @Listen("input")
  handleInput(e: CustomEvent) {
    // When children Radios emit input events, the RadioGroup will be in the composed path
    // However, the input emitted here (`this.input.emit(this.value)`) also will be listened, and
    // the RadioGroup will also be in the composed path, causing an infinite loop. But in this
    // latter case, it will be the first element of the array, so we can check if the index is > 0
    if (e.composedPath().findIndex((el) => el === this.self) > 0) {
      const target = e.target as HTMLORadioElement;

      const value = target.value;
      const checked = target.checked;

      if (checked) {
        this.value = value;
      }

      this.input.emit(this.value);
    }
  }

  /** Alter `checked` property of children in case `value` is set externally */
  @Watch("value")
  handleSetValue(newValue: string) {
    this.self.querySelectorAll("o-radio").forEach((radio) => {
      radio.checked = newValue === radio.value;
    });
  }

  componentWillRender() {
    if (this.disabled === undefined) return;

    const selfSlot =
      this.self.querySelectorAll<HTMLORadioElement | HTMLOLabelElement>(
        "o-radio, o-label"
      );

    selfSlot.forEach((el) => {
      el["disabled"] = !!this.disabled;
    });
  }

  componentDidLoad() {
    this.handleSetValue(this.value);
  }

  render() {
    return (
      <Host data-action={this.dataAction} data-label={this.dataLabel}>
        <slot />
      </Host>
    );
  }
}
