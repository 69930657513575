o-menu-previous {
  margin: var(--font-xs) 0;

  .o-menu-previous {
    all: unset;
    box-sizing: border-box;
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: var(--font-md);
    width: 100%;
    padding: var(--font-xs);
    color: var(--theme-info);
    border-radius: var(--border-radius-xxs);
    line-height: var(--font-xl);
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }
  }
}
