$tabGap: 1rem;

o-tab,
o-tab-link {
  &.o-tab--full-width {
    flex: auto;
  }

  .o-tab {
    width: 100%;
  }

  .o-tab,
  .o-tab-link {
    font-family: inherit;
    display: block;
    padding: 0;
    border: none;
    border-bottom: 2px solid transparent;
    border-color: var(--theme-neutral-normal);
    background-color: transparent;
    color: var(--theme-dark-60);
    text-decoration: none;
    text-align: center;
    transition: var(--transition-primary);
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }

    &--disabled,
    &--disabled:hover {
      border-color: var(--theme-light-hover);
      color: var(--theme-light-inverse);
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
    }

    @each $type in $types {
      &--type-#{$type} {
        &:not(.o-tab--disabled):hover {
          border-color: var(--theme-#{$type});
        }

        &.o-tab--active {
          border-color: var(--theme-#{$type}) !important;
        }
      }
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        font-size: var(--font-#{$size});
        line-height: calc(var(--font-#{$size}) * var(--base-height));
        padding: 0 var(--font-#{$size});
      }
    }

    &.o-tab--aspect-unstyled {
      border: none;
    }
  }
}
