import { Component, h, Host, Prop } from "@stencil/core";

export type ISizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto";

@Component({
  tag: "o-col",
  styleUrl: "index.scss",
})
export class Col {
  /**
   * Distribuição do conteúdo com relação ao espaço horizontal disponível.
   *
   * @sbCategory Style
   */
  @Prop() justify: "start" | "center" | "end" | "unset" = "unset";
  /**
   * Distribuição do conteúdo com relação ao espaço vertical disponível.
   *
   * @sbCategory Style
   */
  @Prop() align: "start" | "center" | "end" | "baseline" | "stretch" | "unset" =
    "unset";
  /**
   * Largura da columna a partir do _breakpoint_ `xs` (<576px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() xs?: ISizes;
  /**
   * Largura da columna a partir do _breakpoint_ `sm` (≥576px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() sm?: ISizes;
  /**
   * Largura da columna a partir do _breakpoint_ `md` (≥768px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() md?: ISizes;
  /**
   * Largura da columna a partir do _breakpoint_ `lg` (≥992px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() lg?: ISizes;
  /**
   * Largura da columna a partir do _breakpoint_ `xl` (≥1200px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() xl?: ISizes;
  /**
   * Largura da columna a partir do _breakpoint_ `xxl` (≥1400px).
   * [Referência](https://getbootstrap.com/docs/5.0/layout/grid/).
   *
   * @sbCategory Style
   */
  @Prop() xxl?: ISizes;

  private class = () => {
    const colSizes = ["sm", "md", "lg", "xl", "xxl"] as const;

    const cols = colSizes.map((size: typeof colSizes[number]) => {
      if (!this[size]) return;

      return `col-${size}-${this[size]}`;
    });

    return `col ${cols.join(" ")} justify-content-${this.justify} align-items-${
      this.align
    }`;
  };

  render() {
    return (
      <Host class={this.class()}>
        <slot />
      </Host>
    );
  }
}
