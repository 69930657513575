import { Component, h, Element, Prop } from "@stencil/core";

@Component({
  tag: "o-breadcrumb",
  styleUrl: "index.scss",
})
export class Breadcrumb {
  @Element() self!: HTMLElement;

  /**
   * Caractere separador dos _breadcrumbs_.
   *
   * @sbCategory Style
   */
  @Prop() separator = ">";

  componentWillRender() {
    this.self.querySelectorAll("o-breadcrumb-item").forEach((el) => {
      el["separator"] = this.separator;
    });
  }

  render() {
    return (
      <div class="o-breadcrumb">
        <slot />
      </div>
    );
  }
}
