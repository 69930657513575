import { Component, Element, h, Prop } from "@stencil/core";

@Component({
  tag: "o-skeleton-card",
  styleUrl: "index.scss",
})
export class SkeletonCard {
  @Element() self!: HTMLElement;

  /**
   * Largura do card. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() width!: string;
  /**
   * Altura do card. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() height!: string;
  /**
   * Arredonda as bordas.
   *
   * @sbCategory Style
   */
  @Prop() rounded = false;

  componentDidLoad() {
    const element = this.self.querySelector(".o-skeleton-card") as HTMLElement;

    element.style.width = this.width;
    element.style.height = this.height;
  }

  render() {
    const classes = {
      "o-skeleton-card": true,
      [`o-skeleton-card--rounded`]: this.rounded,
    };

    return <div class={classes} />;
  }
}
