import { Component, h } from "@stencil/core";

@Component({
  tag: "o-filter-footer",
  styleUrl: "index.scss",
})
export class FilterFooter {
  render() {
    return (
      <div tabindex="0" class="o-filter-footer">
        <o-divider />
        <div class="slot slot-container">
          <slot />
        </div>
      </div>
    );
  }
}
