import { Component, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-row",
  styleUrl: "index.scss",
})
export class Row {
  /**
   * Distribuição do conteúdo com relação ao espaço horizontal disponível.
   *
   * @sbCategory Style
   * @sbControl radio
   */
  @Prop() justify:
    | "start"
    | "center"
    | "end"
    | "between"
    | "around"
    | "evenly"
    | "unset" = "unset";
  /**
   * Distribuição do conteúdo com relação ao espaço vertical disponível.
   *
   * @sbCategory Style
   */
  @Prop() align: "start" | "center" | "end" | "baseline" | "stretch" | "unset" =
    "unset";
  /**
   * Define se o conteúdo pode ir para a próxima linha.
   *
   * @sbCategory Style
   */
  @Prop() wrap: "wrap" | "nowrap" | "wrap-reverse" = "wrap";
  /**
   * Espaçamento entre linhas e colunas.
   *
   * @sbCategory Style
   * @sbControl select
   */
  @Prop() gap: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  /**
   * Espaçamento entre linhas.
   *
   * @sbCategory Style
   * @sbControl select
   */
  @Prop() rowGap: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  /**
   * Espaçamento entre colunas.
   *
   * @sbCategory Style
   * @sbControl select
   */
  @Prop() columnGap: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  render() {
    const { justify, align, wrap, gap, rowGap, columnGap } = this;

    const classes = {
      row: true,
      [`justify-content-${justify}`]: true,
      [`align-items-${align}`]: true,
      [`flex-${wrap}`]: true,
      [`g-${gap}`]: gap !== 0,
      [`gx-${rowGap}`]: gap === 0 && rowGap !== 0,
      [`gy-${columnGap}`]: gap === 0 && columnGap !== 0,
    };

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
