import { Component, h, Listen, Prop } from "@stencil/core";
import type { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-collapse-header",
  styleUrl: "index.scss",
})
export class CollapseHeader {
  /**
   * Propriedade interna, definida pelo `o-collapse`. Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop() expanded = false;
  /**
   * Propriedade interna, definida pelo `o-collapse`. Desabilita o component.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade interna, definida pelo `o-collapse`. Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Propriedade interna, definida pelo `o-collapse`. Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  @Listen("collapseInteraction")
  handleCollapseInteraction(e: Event) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const { type, disabled, expanded, size } = this;

    const classes = {
      [`o-collapse-header--type-${type}`]: true,
      [`o-collapse-header--size-${size}`]: true,
      "o-collapse-header--disabled": disabled,
    };

    return (
      <o-expander-header class={classes} expanded={expanded}>
        <div>
          <slot />
        </div>
        <o-icon category="fal" icon="fa-angle-down" size="xl" class="rotate" />
      </o-expander-header>
    );
  }
}
