import React from "react";
import picPlaceholder from "../../../assets/images/missing-avatar.svg";
import { genericMask } from "utils";
import styled from "styled-components";

const UserInfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 20px;
  padding: 0 15px;
  color: #2e3238;

  .nav-function-top & {
    color: white;
  }
`;

export default function UserInfo() {
  return (
    <UserInfoDiv className={`header-icon px-6`} data-toggle="dropdown">
      <img
        src={picPlaceholder}
        className="profile-image rounded-circle"
        alt={window.session?.current?.name}
      />
      <div className="hidden-md-down d-flex align-items-center flex-grow-1">
        <div className="d-flex flex-column w-100 pl-3 text-left">
          <div
            className="d-flex justify-content-between"
            style={{ maxWidth: "300px" }}
          >
            <span className="d-block text-truncate fw-500">
              {window.session.current?.name}
            </span>
          </div>
          <span className="fw-300 fs-sm">
            {genericMask(window.session.current?.cpf, "CPF")}
          </span>
        </div>
      </div>
    </UserInfoDiv>
  );
}
