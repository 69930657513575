$dropdownActionGap: 0.5rem;
$dropdownActionHeight: 3;

o-dropdown-action-item {
  o-dropdown-item.o-dropdown-action-item {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $dropdownActionGap;

    @each $size in $fontSizes {
      &--size-#{$size} {
        padding: 0 var(--font-#{$size});
        min-height: calc(var(--font-#{$size}) * $dropdownActionHeight);
      }
    }
  }
}