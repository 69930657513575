import { turquoise, darkGray } from "./color";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const QuickFinAdminTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Open Sans",
  },
  palette: {
    common: {
      black: "#fd0c0c",
    },
    type: "light",
    primary: {
      main: turquoise,
      light: turquoise,
      dark: turquoise,
      contrastText: "#fff",
    },
    secondary: {
      light: darkGray,
      main: darkGray,
      dark: darkGray,
      contrastText: "#fff",
    },
    text: {
      primary: darkGray, // fontes primarias
      secondary: "#8D8B8B", // fontes secundarias
    },
    action: {
      active: "#8D8B8B",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: "0 0 13px 0 rgba(62, 44, 90, 0.08)",
        border: "1px solid rgba(0, 0, 0, 0.09)",
        borderBottom: "1px solid #e0e0e0",
        borderRadius: 4,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 4,
      },
    },
    MuiButtonBase: {
      root: {
        padding: "10px 20px",
        boxShadow: "none",
        borderRadius: 2,
      },
    },
    MuiFab: {
      root: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      root: {
        padding: "10px 20px",
        boxShadow: "none",
        borderRadius: 2,
      },
      text: {
        padding: "10px 20px",
      },
      contained: {
        boxShadow: "none",
      },
      textPrimary: {
        padding: "10px 20px",
      },
      sizeSmall: {
        fontSize: "14px",
        padding: "6px 10px",
      },
      outlinedPrimary: {
        padding: "8px 20px",
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2,
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&:disabled": {
          borderWidth: 2,
          boxShadow: "none",
        },
      },
      focusVisible: {
        boxShadow: "none",
        borderRadius: 2,
      },
    },
    MuiTabs: {
      indicator: {
        display: "none",
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: "8px 40px",
        fontSize: 12,
        backgroundColor: "#282828",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#fff",
          borderRadius: "2px 2px 0 0",
          backgroundColor: turquoise,
        },
      },
      labelContainer: {
        borderRadius: 50,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 0,

        "&:active": {
          display: "none",
        },
      },
    },
    MuiInput: {
      root: {
        height: "40px",
        borderRadius: "4px",
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #afafaf !important",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.47,
        color: "#495057",
        backgroundColor: "#fff",
        "&$focused": {
          border: `1px solid ${turquoise}`,
        },
      },
      formControl: {
        marginTop: "22px !important",
      },
      error: {
        border: `1px solid #f44336`,
        "&:after": {
          display: "none",
        },
      },
      input: {
        textIndent: 10,
        padding: 0,
        height: "2em !important",
      },
    },
    MuiInputLabel: {
      root: {
        display: "flex",
        alignItems: "center",
      },
      shrink: {
        transform: "translate(0, 0) scale(0.9)",
      },
    },
  },
});
