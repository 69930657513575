o-filter-header {
  display: block;

  .o-filter-header {
    & .flex {
      padding: var(--font-md);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        all: unset;
        cursor: pointer;
      }
    }
  }
}