import { Component, h, Host } from "@stencil/core";

@Component({
  tag: "o-card-body",
  styleUrl: "index.scss",
})
export class CardBody {
  render() {
    return (
      <Host class="slot-container o-card-body">
        <slot />
      </Host>
    );
  }
}
