import React from "react";

import { createBrowserHistory } from "history";
import { SessionProvider } from "hooks/session-provider";
import { Layout } from "layout";
import { Router } from "react-router-dom";
import { GlobalProvider } from "./hooks/GlobalProvider";
import { AdminRoutes } from "./routes/admin/routes";

import "@maestro/core/dist/components/components.css";
import "@maestro/core/dist/style/tokens.css";
import "sweetalert2/dist/sweetalert2.css";
import "./App.css";

let history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <SessionProvider>
      <GlobalProvider>
        <Router history={history}>
          <Layout>
            <AdminRoutes />
          </Layout>
        </Router>
      </GlobalProvider>
    </SessionProvider>
  );
};

export default App;
