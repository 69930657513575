import { Component, h, Prop, State } from "@stencil/core";
import { IAspect, ISize } from "../../globals/types";
import { IIcon } from "../icon/types";

@Component({
  tag: "o-input-secret",
  styleUrl: "index.scss",
})
export class InputSecret {
  @State() type: "text" | "password" = "password";
  @State() icon: IIcon = "fa-eye-slash";

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `maxlength` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() maxlength?: number;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder?: string;
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;

  private inputElement?: HTMLInputElement;

  private handleShowClick(type: string) {
    if (type === "password") {
      this.type = "text";
      this.icon = "fa-eye";
    } else {
      this.type = "password";
      this.icon = "fa-eye-slash";
    }
  }

  private handleInput() {
    if (this.inputElement) {
      this.value = this.inputElement.value;
    }
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      maxlength,
      placeholder,
      aspect,
      size,
      error,
      disabled,
      readonly,
      type,
      icon,
    } = this;

    return (
      <o-input
        nativeRef={(el) => (this.inputElement = el)}
        dataAction={dataAction}
        dataLabel={dataLabel}
        id={id}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        inputType={type}
        aspect={aspect}
        autocomplete="off"
        size={size}
        error={error}
        disabled={disabled}
        readonly={readonly}
        onInput={() => this.handleInput()}
      >
        <button
          slot="after"
          type="button"
          onClick={() => this.handleShowClick(type)}
          data-label="mostrar-ocultar-senha"
          data-action="secreto:botao:mostrar-ou-ocultar-senha"
          aria-label={
            this.type === "password" ? "mostrar senha" : "esconder senha"
          }
        >
          <o-icon category="fal" icon={icon} size="md" />
        </button>
      </o-input>
    );
  }
}
