o-switch {
  position: relative;
  display: inline-block;

  .o-switch {
    &__input {
      position: relative;
      appearance: none;
      margin: 0;
      border-radius: 9999px;
      background: var(--theme-light-hover);
      cursor: pointer;
      transition: var(--transition-primary);

      &:disabled {
        opacity: var(--disabled-opacity);
        cursor: not-allowed;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0.1875rem;
        left: 0.1875rem;
        border-radius: 9999px;
        background: var(--theme-light);
        transition: var(--transition-primary);
        box-shadow: 0px 1px 2px var(--theme-dark-30);
      }

      @each $type in $types {
        &--type-#{$type}:checked {
          background: var(--theme-#{$type});

          &:hover {
            background: var(--theme-#{$type}-hover);
          }
        }
      }

      &:checked {
        &::after {
          transform: translateX(calc(100% + 0.375rem));
        }
      }

      @each $size in $fontSizes {
        &--size-#{$size} {
          width: calc(var(--font-#{$size}) * var(--base-height) * 2);
          height: calc(var(--font-#{$size}) * var(--base-height));
          line-height: calc(var(--font-#{$size}) * var(--base-height));

          &::after {
            width: calc(var(--font-#{$size}) * var(--base-height) - 0.375rem);
            height: calc(var(--font-#{$size}) * var(--base-height) - 0.375rem);
          }
        }
      }
    }
  }
}
