$dropdownListBoxShadow: 0 3px 6px -4px var(--theme-dark-15),
  0 6px 16px 0 var(--theme-dark-10), 0 9px 28px 8px var(--theme-dark-05);

o-dropdown-list {
  &.o-dropdown-list {
    font-family: inherit;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: var(--font-xxs) 0;
    margin: var(--font-xs) 0;
    border-radius: var(--border-radius-xxs);
    box-shadow: $dropdownListBoxShadow;
    transition: var(--transition-primary);

    @include scrollbar();
  }

  &.o-dropdown-list--bordered {
    o-dropdown-item:not(:last-child) {
      border-bottom: 1px solid var(--theme-neutral-medium);
    }
  }

  @each $type in $types {
    &.o-dropdown-list--type-#{$type} {
      background-color: var(--theme-#{$type});
    }
  }
}
