o-menu {
  &.o-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--font-xs);
    height: 100%;
    padding: var(--font-xl);

    @each $type in $typesComplete {
      &--type-#{$type} {
        background-color: var(--theme-#{$type});
        color: var(--theme-#{$type}-inverse);
      }
    }
  }
}
