export enum CurrencySymbol {
  BRL = "R$",
  USD = "$",
  EUR = "€",
}

export enum Currency {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
}
