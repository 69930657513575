o-dropdown-action {
  display: inline-block;

  o-dropdown.o-dropdown-action {
    font-family: inherit;
    display: inline;
  }
}

o-dropdown-list {
  &.o-dropdown-action__list.o-dropdown-action__list {
    flex-direction: row;
    padding: 0;
    padding-left: var(--font-xs);
    max-width: max-content;

    o-dropdown-item {
      border-left: var(--border);

      &.o-dropdown-action__close-item {
        display: flex;
        align-items: center;
        padding: 0 var(--font-xs);
      }
    }
  }
}
