o-slider {
  .o-slider {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    @each $size in $fontSizes {
      &--size-#{$size} {
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }

    &__background {
      position: absolute;
      border-radius: 999px;
      height: 0.15rem;

      @each $type in $types {
        &--#{$type} {
          background-color: var(--theme-#{$type});
        }
      }
    }

    &__range {
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      width: 100%;
      height: 0.15rem;
      margin: 0;
      background-color: var(--theme-neutral-light);
      transition: var(--transition-primary);

      &::-webkit-slider-thumb {
        appearance: none;
        border-radius: 50%;
        cursor: pointer;
        transition: var(--transition-primary);
      }

      @each $size in $fontSizes {
        &--size-#{$size} {
          &::-webkit-slider-thumb {
            height: calc(var(--font-#{$size}) * var(--base-height));
            width: calc(var(--font-#{$size}) * var(--base-height));

            &:active {
              height: calc(var(--font-#{$size}) * 1.8);
              width: calc(var(--font-#{$size}) * 1.8);
            }
          }
        }
      }

      @each $type in $types {
        &--background-#{$type} {
          &::-webkit-slider-thumb {
            background-color: var(--theme-#{$type});
            box-shadow: 0 0 0 0 transparent;

            &:hover {
              box-shadow: 0 0 1px 1rem var(--theme-#{$type}-10);
            }
          }
        }
      }
    }
  }
}
