o-menu-item {
  .o-menu-item {
    all: unset;
    font-family: inherit;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--font-xs);
    border-radius: var(--border-radius-xxs);
    color: var(--theme-dark);
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }

    &:hover {
      background-color: var(--theme-neutral-normal);
    }

    &:active {
      background-color: var(--theme-neutral-medium);
    }

    &:disabled,
    &:disabled:hover {
      opacity: var(--disabled-opacity);
      cursor: not-allowed;
      background-color: unset;
    }

    &__active o-icon {
      color: var(--theme-tertiary);
    }

    @each $type in $typesComplete {
      &--type-#{$type} {
        &.o-menu-item__active {
          background-color: var(--theme-#{$type});
          color: var(--theme-#{$type}-inverse);
        }
      }
    }
  }
}
