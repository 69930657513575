import { OLoader } from "@maestro/react";
import cloneDeep from "lodash/cloneDeep";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Session, sessionEvents } from "./session";

const initialSession = new Session();

const SessionContext = createContext(initialSession);

export const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState(initialSession);

  useEffect(() => {
    const unsub = sessionEvents.subscribe((nextSession) => {
      // https://stackoverflow.com/questions/41474986/how-to-clone-a-javascript-es6-class-instance
      setSession(cloneDeep(nextSession));
    });

    return () => unsub();
  }, []);

  if (!session.current) {
    return <OLoader absolute backdrop />;
  }

  return (
    <SessionContext.Provider value={session}>
      <div id="session-provider">{children}</div>
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
