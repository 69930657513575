export function nameValidator(name: string) {
  const regExp = /[a-z]*\s[a-z]*/gi;
  const validName = regExp.exec(name.trim());

  if (!validName) {
    return "É necessário informar o nome e sobrenome";
  }

  return null;
}
