import { Component, Element, h, Prop, State, Watch } from "@stencil/core";

@Component({
  tag: "o-expander-content",
  styleUrl: "index.scss",
})
export class ExpanderContent {
  @Element() self!: HTMLElement;
  @State() contentHeight?: number;

  /**
   * Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop() expanded = false;

  private timeout?: NodeJS.Timeout;

  @Watch("expanded")
  handleExpand() {
    // `this.timeout` is defined when the transition isn't over,
    // so this means the user has interacted with the collapse
    // during the transition. In this case, we clear the timeout
    // to prevent the style being wrongfully set
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.contentHeight = this.self.scrollHeight;

    if (this.expanded) {
      this.self.style.maxHeight = `${this.contentHeight}px`;
      // unset maxHeight to allow <o-expander-content> to grow in case the content grows
      // do it in a setTimeout to wait for css transition
      this.timeout = setTimeout(() => {
        if (!this.self) return;

        this.self.style.overflow = "unset";
        this.self.style.maxHeight = "unset";
        this.timeout = undefined;
      }, 500);
    } else {
      // first set maxHeight as the currentHeight (needs to be a number for the transition to work)
      // and then sets as 0 to close, but in a timeout of 1ms because otherwise the DOM just skips
      // the first assignment (just calculating the end result as an optimization), effectively
      // transitioning from "unset" to "0", which is not animated
      this.self.style.maxHeight = `${this.contentHeight}px`;
      this.timeout = setTimeout(() => {
        if (!this.self) return;

        this.self.style.overflow = "hidden";
        this.self.style.maxHeight = "0";
        this.timeout = undefined;
      }, 1);
    }
  }

  componentDidLoad() {
    if (this.expanded) {
      this.self.style.overflow = "unset";
      this.self.style.maxHeight = "unset";
    } else {
      this.self.style.overflow = "hidden";
      this.self.style.maxHeight = "0";
    }
  }

  render() {
    return <slot />;
  }
}
