import { Component, h, Host, Listen, Prop } from "@stencil/core";
import { ISize, IType } from "../../../globals/types";

@Component({
  tag: "o-tab-link",
  styleUrl: "../item/index.scss",
})
export class TabLink {
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Se a aba está ativa.
   *
   * @sbCategory Input
   */
  @Prop() active = false;
  /**
   * Faz o tab ocupar 100% da largura do container.
   *
   * @sbCategory Style
   */
  @Prop() fullWidth = false;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: "flushed" | "unstyled" = "flushed";
  /**
   * Desabilita o tab.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;
  /**
   * Rota do link.
   *
   * @sbCategory Input
   */
  @Prop() href?: string;

  @Listen("click")
  @Listen("auxclick")
  preventClickWhenDisabler(evt: Event) {
    if (this.disabled) evt.preventDefault();
  }

  render() {
    const {
      active,
      fullWidth,
      size,
      disabled,
      type,
      aspect,
      dataLabel,
      dataAction,
      href,
    } = this;

    const hostClass = {
      "o-tab--full-width": fullWidth,
    };

    const classes = {
      "o-tab-link": true,
      [`o-tab-link--size-${size}`]: true,
      [`o-tab-link--type-${type}`]: true,
      // same classes as o-tab
      "o-tab--active": active,
      "o-tab--disabled": disabled,
      [`o-tab--aspect-${aspect}`]: true,
    };

    return (
      <Host class={hostClass}>
        <a
          class={classes}
          href={href}
          data-label={dataLabel}
          data-action={dataAction}
          tabIndex={disabled ? -1 : 0}
        >
          <slot />
        </a>
      </Host>
    );
  }
}
