import { Component, Event, EventEmitter, h, Prop } from "@stencil/core";
import { ISize, IType } from "../../globals/types";

@Component({
  tag: "o-range-slider",
  styleUrl: "index.scss",
})
export class RangeSlider {
  /** Evento emitido ao ocorrer input. */
  @Event() input!: EventEmitter<Array<number>>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor do componente é um array com dois números, sendo que o primeiro valor
   * deve ser menor que o segundo.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: number[];
  /**
   * Valor mínimo.
   *
   * @sbCategory Input
   */
  @Prop() min = 0;
  /**
   * Valor máximo.
   *
   * @sbCategory Input
   */
  @Prop() max = 100;
  /**
   * Intervalo entre valores selecionáveis.
   *
   * @sbCategory Input
   */
  @Prop() step = 1;
  /**
   * Cor da barra entre os seletores.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "tertiary";
  /**
   * Tamanho dos seletores.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "sm";

  private firstInputElement?: HTMLInputElement;
  private secondInputElement?: HTMLInputElement;

  private getTrackBackground() {
    const first = this.value?.[0] ?? 0;
    const second = this.value?.[1] ?? 0;

    const track = this.max - this.min;
    const firstPercent = ((first - this.min) / track) * 100;
    const secondPercent = ((second - this.min) / track) * 100;

    return `linear-gradient(
      to right, 
      var(--theme-neutral-light) ${firstPercent}%,
      var(--theme-${this.type}) ${firstPercent}%,
      var(--theme-${this.type}) ${secondPercent}%,
      var(--theme-neutral-light) ${secondPercent}%)`;
  }

  componentWillLoad() {
    if (!this.value) {
      this.value = [this.min, this.max];
    }
  }

  private inputEventListener(inputType: "first" | "second") {
    if (!this.firstInputElement || !this.secondInputElement) return;

    let firstValue = Number(this.firstInputElement.value);
    let secondValue = Number(this.secondInputElement.value);

    if (inputType === "first") {
      firstValue = Math.max(this.min, firstValue);
      firstValue = Math.min(secondValue, firstValue);
      
      this.firstInputElement.value = String(firstValue);
    } else {
      secondValue = Math.max(firstValue, secondValue);
      secondValue = Math.min(this.max, secondValue);
      
      this.secondInputElement.value = String(secondValue);
    }
    
    this.value = [firstValue, secondValue];

    this.input.emit(this.value);
  }

  componentDidLoad() {
    if (!this.firstInputElement || !this.secondInputElement) return;

    this.firstInputElement.value = String(this.value?.[0]);
    this.secondInputElement.value = String(this.value?.[1]);

    this.firstInputElement.addEventListener("input", () => {
      this.inputEventListener("first");
    });

    this.secondInputElement.addEventListener("input", () => {
      this.inputEventListener("second");
    });
  }

  render() {
    const { dataAction, dataLabel, name, min, max, step, type, size } = this;

    const trackBackground = this.getTrackBackground();

    const classHost = {
      "o-range-slider": true,
      [`o-range-slider--size-${size}`]: true,
    };

    const classBackground = {
      "o-range-slider__background": true,
      [`o-range-slider__background--${type}`]: true,
    };

    const classRangeBackground = {
      "o-range-slider__range": true,
      [`o-range-slider__range--size-${size}`]: true,
      [`o-range-slider__range--background-${type}`]: true,
    };

    return (
      <div class={classHost}>
        <div
          class={classBackground}
          style={{ background: `${trackBackground}` }}
        />
        <input
          ref={(el) => (this.firstInputElement = el)}
          type="range"
          data-action={dataAction}
          data-label={dataLabel}
          name={name}
          class={classRangeBackground}
          min={min}
          max={max}
          step={step}
        />
        <input
          ref={(el) => (this.secondInputElement = el)}
          type="range"
          data-action={dataAction}
          data-label={dataLabel}
          name={name}
          class={classRangeBackground}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  }
}
