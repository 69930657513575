o-menu-subitem {
  &.o-menu-subitem {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    height: 100%;
    padding: var(--font-xl);
    opacity: 0;
    transition: opacity 0.2s ease;
    animation: set-active 0.2s linear;
    cursor: default;

    &--active {
      display: flex;
      opacity: 1;
    }

    @each $type in $typesComplete {
      &--type-#{$type} {
        background-color: var(--theme-#{$type});
        color: var(--theme-#{$type}-inverse);
      }
    }

    @keyframes set-active {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
