o-input-secret {
  button {
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;

    &:focus-visible {
      outline: 2px solid var(--theme-focus);
    }
  }
}
