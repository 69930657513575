export interface IStrokeSize {
  [id: string]: string;
}

export const strokeSizeMap: IStrokeSize = {
  "xxs": "6px",
  "xs": "8px",
  "sm": "10px",
  "md": "12px",
  "lg": "14px",
  "xl": "16px",
  "xxl": "18px",
  "xxxl": "20px",
}