import { env } from "utils/environments";

const COLOR_UAT = "#f3f612";

const loginWarningTemplate = (color: string, text: string) => {
  return `<div style="width: 100%; position: fixed; top: 0; background: ${color}; height: 24px; overflow: hidden; font-size: 12px; text-transform: uppercase; display: flex; align-items: center; z-index: 1050; justify-content: center;">${text}</div>`;
};

const appWarningTemplate = (color: string) => {
  return `<div style=" width: 4px; height: 100%; position: fixed; background-color: ${color}; display: inline-block; z-index: 1050; left: 0; top: 0;"></div>`;
};

export function addEnvironmentWarning() {
  let template = "";

  if (env.REACT_APP_PROJECT_ENV === "uat") {
    template = appWarningTemplate(COLOR_UAT);

    if (window.location.pathname === "/acesso/") {
      template = loginWarningTemplate(COLOR_UAT, "Ambiente de homologação");
    }
  }

  document.querySelector("body")?.insertAdjacentHTML("afterbegin", template);
}
