o-checkbox {
  display: flex;

  .o-checkbox {
    &__input {
      position: relative;
      background-color: var(--theme-light);
      border: var(--border);
      border-radius: var(--border-radius-xxs);
      transition: var(--transition-primary);
      appearance: none;
      margin: 0;
      cursor: pointer;

      &:hover {
        border-color: var(--border-color-hover);
      }

      &::after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        position: absolute;
        color: var(--theme-light);
        text-align: center;
        opacity: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
      }

      &:checked::after {
        opacity: 1;
        transform: scale(1);
      }

      @each $size in $fontSizes {
        &--size-#{$size} {
          width: calc(var(--font-#{$size}) * var(--base-height));
          height: calc(var(--font-#{$size}) * var(--base-height));
          line-height: calc(var(--font-#{$size}) * var(--base-height));

          &::after {
            font-size: calc(var(--font-#{$size}) * 1.5);
          }
        }
      }

      @each $type in $types {
        &--type-#{$type} {
          border-color: var(--theme-#{$type});
          &:checked {
            background-color: var(--theme-#{$type});
            border-color: var(--theme-#{$type});
          }

          &:hover {
            border-color: var(--theme-#{$type});
          }
        }
      }

      &:disabled {
        background-color: var(--theme-neutral-normal);
        border-color: var(--theme-neutral-medium);
        opacity: var(--disabled-opacity);
        cursor: not-allowed;

        &::after {
          color: var(--theme-secondary);
        }

        &:checked {
          border-color: var(--theme-neutral-normal);
        }
      }
    }
  }
}
