import { Component, h, Prop } from "@stencil/core";
import { ISize, ITypeComplete } from "../../globals/types";
import { IIcon, ICategory } from "./types";

@Component({
  tag: "o-icon",
  styleUrl: "index.scss",
})
export class Icon {
  /**
   * Categoria do ícone.
   *
   * @sbCategory Input
   */
  @Prop() category!: ICategory;
  /**
   * Nome do ícone.
   *
   * @sbCategory Input
   */
  @Prop() icon!: IIcon;
  /**
   * Tamanho do ícone.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Cor do ícone.
   *
   * @sbCategory Style
   */
  @Prop() type?: ITypeComplete;

  render() {
    const { size, category, icon, type } = this;

    const classes = {
      "o-icon": true,
      [`o-icon--size-${size}`]: true,
      [`o-icon--type-${type}`]: !!type,
      [`${category}`]: true,
      [`${icon}`]: true,
    };

    return <i class={classes} />;
  }
}
