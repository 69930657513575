import React from "react";

interface HighlightTextProps {
  text: string;
  highlightValue: string;
}
export function HighlightText({ text, highlightValue }: HighlightTextProps) {
  return (
    <span>
      {text
        .replace(
          new RegExp(highlightValue.trim(), "gi"),
          (highlight) => `{{highlightTag}}${highlight}{{highlightTag}}`,
        )
        .split("{{highlightTag}}")
        .reduce(
          (previousValue, currentValue, currentIndex) => (
            <>
              {previousValue}
              {currentIndex % 2 ? (
                <strong style={{ color: "var(--theme-info)" }}>
                  {currentValue}
                </strong>
              ) : (
                currentValue
              )}
            </>
          ),
          <></>,
        )}
    </span>
  );
}
