import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import exportIcon from "./excel.svg";
import { Button } from "@material-ui/core";
import { match } from "styled-is";

export interface IExportToExcel {
  alignment?: "flex-end" | "flex-start";
}

export const TableActionsContainer = styled.div<IExportToExcel>`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;

  ${match("alignment", "flex-start")`
    justify-content: flex-start;
  `};
`;

export const ExportButton = styled(Button)`
  text-transform: capitalize !important;
`;

export const ExportIcon = styled.img`
  margin-left: 8px;
`;

export const MyGlobalStyle = createGlobalStyle`
  .dx-datagrid-header-panel {
    display: none;
  }
`;

export function ExportToExcel(props: IExportToExcel) {
  function removeElementOnDom() {
    const selector = document.querySelector(
      '[aria-label="export-excel-button"]',
    ) as HTMLElement;

    if (!!selector) {
      selector.click();
    }
  }
  return (
    <>
      <MyGlobalStyle />
      <TableActionsContainer alignment={props.alignment}>
        <ExportButton onClick={() => removeElementOnDom()}>
          Exportar <ExportIcon src={exportIcon} alt="exportar" />
        </ExportButton>
      </TableActionsContainer>
    </>
  );
}
