import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
  Watch,
} from "@stencil/core";

const calculate = {
  left: (a: number, b: number) => a - b,
  right: (a: number, b: number) => a + b,
};

@Component({
  tag: "o-tab-header",
  styleUrl: "index.scss",
})
export class TabHeader {
  @Element() self!: HTMLElement;

  /** Evento emitido ao selecionar a tab. */
  @Event() selected!: EventEmitter<number>;
  /** Whether component should show pagination */
  @State() private showPagination = false;

  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeTab = 0;
  /**
   * Habilita paginação em caso de overflow.
   *
   * @sbCategory Style
   */
  @Prop({ mutable: true }) pagination = false;
  /**
   * Callback chamado quando uma tab é selecionada.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (index: number) => void;

  /** Reference to div containing the tabs */
  private tabsContainer?: HTMLElement;
  /** Value of scroll */
  @State() horizontalScroll = 0;
  /** Position of right side of tab container inside overflow */
  @State() positionOfEnd = 0;

  private handleActiveDropdown(value: boolean) {
    const tabDropdown = this.self.querySelector("o-tab-dropdown");

    if (tabDropdown) tabDropdown.active = value;
  }

  @Watch("activeTab")
  handleChangeActiveTab(newValue: number) {
    const tabs =
      this.self.querySelectorAll<HTMLOTabElement | HTMLOTabLinkElement>(
        "o-tab, o-tab-link"
      );

    tabs.forEach((el, index) => {
      if (newValue === index) {
        el.active = true;
      } else {
        el.active = false;
      }
    });
  }

  @Listen("resize", { target: "window" })
  private controlPagination() {
    if (this.tabsContainer) {
      const overflowing =
        this.tabsContainer.scrollWidth > this.self.offsetWidth;
      this.showPagination = this.pagination && overflowing;
    }
  }

  // listen children click events and update activeTab property
  componentDidLoad() {
    const tabs =
      this.self.querySelectorAll<HTMLOTabElement | HTMLOTabLinkElement>(
        "o-tab, o-tab-link"
      );

    this.handleChangeActiveTab(this.activeTab);

    tabs.forEach((el, index) => {
      el.addEventListener("click", () => {
        const hasIgnore = el.getAttribute("data-tab-dropdown");

        if (hasIgnore || el.disabled) return;

        this.activeTab = index;

        const insideDropdown = !!el.closest("o-tab-dropdown");

        this.selected.emit(index);
        this.handleActiveDropdown(insideDropdown);
        this.handleSelected?.(index);
      });
    });

    this.controlPagination();
  }

  private scroll = (direction: "left" | "right") => {
    if (this.tabsContainer) {
      // calculate scroll destination
      const destination = calculate[direction](
        this.tabsContainer.scrollLeft,
        this.tabsContainer.offsetWidth
      );

      // do the scrolling
      this.tabsContainer.scroll({
        left: destination,
        behavior: "smooth",
      });

      // update horizontalScroll value
      this.horizontalScroll = destination;
      this.positionOfEnd =
        this.horizontalScroll + this.tabsContainer.offsetWidth;
    }
  };

  render() {
    const hostClasses = {
      "o-tab-header__pagination": this.showPagination,
      "o-tab-header__pagination-left": this.horizontalScroll > 0,
      "o-tab-header__pagination-right":
        this.positionOfEnd < (this.tabsContainer?.scrollWidth ?? 0),
    };

    const arrowClasses = {
      "o-tab-header__arrow": true,
    };

    const arrowLeftClasses = {
      ...arrowClasses,
      "o-tab-header__arrow-left": true,
    };

    const arrowRightClasses = {
      ...arrowClasses,
      "o-tab-header__arrow-right": true,
    };

    return (
      <Host class={hostClasses}>
        <button class={arrowLeftClasses} onClick={() => this.scroll("left")}>
          <o-icon category="orq" icon="orq-chevron-left"></o-icon>
        </button>
        <div
          ref={(el) => {
            this.tabsContainer = el;
          }}
          class="o-tab-header__container"
        >
          <slot />
        </div>

        <button class={arrowRightClasses} onClick={() => this.scroll("right")}>
          <o-icon category="orq" icon="orq-chevron-right"></o-icon>
        </button>
      </Host>
    );
  }
}
