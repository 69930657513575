o-progress-bar {
  .o-progress-bar {
    width: 100%;
    background-color: var(--theme-neutral-light);
    border-radius: var(--border-radius-xxxs);

    &--rounded {
      border-radius: var(--border-radius-xxxl);
    }

    @each $size in $fontSizes {
      &--size-#{$size} {
        height: calc(var(--font-#{$size}) * var(--base-height));
      }
    }

    &__background {
      height: 100%;
      border-radius: var(--border-radius-xxs);
      transition: var(--transition-primary);

      &--rounded {
        border-radius: var(--border-radius-xxxl);
      }

      @each $type in $types {
        &--#{$type} {
          background-color: var(--theme-#{$type});
        }
      }
    }
  }
}
