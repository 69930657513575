import { Component, Element, h, Prop, Watch } from "@stencil/core";

@Component({
  tag: "o-steps-timeline",
})
export class StepsTimeline {
  @Element() self!: HTMLElement;

  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeStep = 0;
  /**
   * Callback chamado quando uma step é selecionada.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (index: number) => void;

  @Watch("activeStep")
  handleChangeactiveStep(newValue: number) {
    const header = this.self.querySelector("o-step-header");
    const body = this.self.querySelector("o-step-body");

    if (header) header.activeStep = newValue;
    if (body) {
      body.activeStep = newValue;
      body.focus();
    }
  }

  componentDidLoad() {
    const header = this.self.querySelector("o-step-header");

    this.handleChangeactiveStep(this.activeStep);

    header?.addEventListener("selected", (event) => {
      const target = event.target as HTMLOStepHeaderElement;

      this.activeStep = target.activeStep;
      if (this.handleSelected) this.handleSelected(target.activeStep);
    });
  }

  render() {
    return <slot />;
  }
}
