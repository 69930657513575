import { useGet } from "hooks/rest-services";
import moment from "moment";
import { useEffect } from "react";
import { atom, selector, useRecoilState } from "recoil";
import { endpoints } from "utils/endpoints";

export const partnersAlertData = atom({
  key: "Parceiros Estratégicos Alerts",
  default: [],
});

export const strategicPartnersBadgeSelector = selector({
  key: "Menu.Badge.Parceiros Estratégicos",
  get: ({ get }): any => {
    const alertsPartnersData = get(partnersAlertData);
    return alertsPartnersData;
  },
});

export const isWeekend =
  moment().weekday() === 1
    ? moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD")
    : moment()
        .utcOffset("UTC")
        .format("YYYY-MM-DD");

export function useStrategicPartnersRefresher(props: any) {
  const [partnersCount, setPartnersCount] = useRecoilState(partnersAlertData);

  const partnersCountGet = useGet<any, []>(
    `${endpoints.apiParceiros.partnersFeed}?&$count=true&$top=0&$filter=CreatedDate ge ${isWeekend}T00:00:00Z`,
  );

  useEffect(() => {
    if (partnersCountGet.data) {
      setPartnersCount(partnersCountGet.data["@odata.count"]);
    }
  }, [partnersCountGet.data, setPartnersCount]);
  return partnersCount;
}
