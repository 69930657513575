import {
  CustomSheetParser,
  CompanyType,
  CompanyStatusType,
} from "../../models";
import { IImportListTarget } from "legacy/models/bordero/import-receivable-problem";
import { AccountType } from "legacy/models/common/accountTypes";
import { SigningPartyParams } from "pages/empresas/[id]/_compose/company-contract-templates/_compose/template-details/template-details.state";
import { IPortal } from "pages/empresas/[id]/_compose/company-convenios/estrategia.types";

export interface ICompany {
  id: number;
  cnpj: string;
  officialName: string;
  companyType: string;
  companyStatus?: string;
  country?: string;
  legalRepresentatives?: any;
  socialContract?: any;
  companyBylaw?: any;
  executiveBoard?: any;
  electionMinutes?: any;
  aditionalDocuments?: any;
  bankAccounts: AccountType[];
}

export enum TypeFIDC {
  ALUGUEL = "ALUGUEL",
  COMPRA_VENDA = "COMPRA_VENDA",
  NOTA = "NOTA",
}

export const TypeFIDCLibrary: Record<TypeFIDC, string> = {
  [TypeFIDC.ALUGUEL]: "Aluguel",
  [TypeFIDC.COMPRA_VENDA]: "Compra e venda",
  [TypeFIDC.NOTA]: "Nota",
};

export interface IEstrategiaNotificacoes {
  reporteNotasNaoAntecipadasCedente: boolean;
  diasCampanhaNotasNaoAntecipadasCedente: number[];
  notaDisponivelCedente: boolean;
  notificacaoRecebiveisAntecipados: boolean;

  notificacaoOnboardingSacado: boolean;
  reporteDiarioSacado: boolean;
  diasParaVencimentoSacado: number[];

  biometryRequired: boolean;

  notificacaoTedDevolvidaCedente: boolean;
  notificacaoTedDevolvidaSacado: boolean;
}

export interface IEstrategiaNotificacoesRiscoCedente {
  reporteDiarioRiscoCedente: boolean;
  diasParaVencimentoDataRepasseRiscoCedente: number[];
}

export interface IEstrategiaOffshore {
  tipoProduto: ProductType.Offshore;
}

export interface IEstrategiaRiscoCedente {
  tipoProduto: ProductType.RiscoCedente;
  notificacoes: IEstrategiaNotificacoesRiscoCedente;
}

export enum TipoTaxaParticular {
  TaxaParticularFinal = "TaxaParticularFinal",
  TaxaParticularAdicional = "TaxaParticularAdicional",
}

export interface IEstrategiaRiscoSacado {
  tipoProduto: ProductType.RiscoSacado;
  comandoOperacao: "Cedente" | "Sacado";
  exigeAprovacaoSacado: boolean;
  visualizaRebateSacado: boolean;
  aprovacaoAposAntecipacaoSacado: boolean;
  permiteTaxaParticular: boolean;
  tipoTaxaParticular?: TipoTaxaParticular;
  notificacoes: IEstrategiaNotificacoes;
  habilitaPagFor: boolean;
  sacadoDefineContaDesembolso: boolean;
  cancelaNaoAntecipadasD0: boolean;
}

export interface IEstrategiaComercialDto {
  approvalGroups: string[];
  companyId: string;
  company: ICompanyBasicDataDto;
  contaLiquidacaoId: number;
  marketplaceId: number;
  configuracoesCadastroRelacionamento: {
    permiteOverride: boolean;
    antecipaAutomaticamente: boolean;
    desativaFornecedor: boolean;
    tipoContratoRelacionamento: string;
    contractTemplate?: {
      id: number;
      fileKey: string;
      name: string;
    };
    tipoContratoRelacionamentoAntecipaAutomaticamente: string;
    contractTemplateAntecipaAutomaticamente?: {
      id: number;
      fileKey: string;
      name: string;
    };
  };
  estrategia: IEstrategiaRiscoSacado;
  estrategiaDesembolso: string;
  exigeAprovacaoBanco: boolean;
  exigeIndicacaoHedge: boolean;
  fccIdSacado?: number | null;
  horarioLimiteOperacao: {
    ticks: number;
    days: number;
    hours: number;
    milliseconds: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalHours: number;
    totalMilliseconds: number;
    totalMinutes: number;
    totalSeconds: number;
  };
  id: number;
  modeloDeTaxa: string;
  modoLiquidacao: string;
  nome: string;
  padrao: boolean;
  prazoLimiteOperacao: number;
  tipoOnboarding: string;
  tipoProduto: ProductType;
  uniqueId: string;
  pnlBook?: number | null;
  pnlStrategy?: number | null;
  collateralRequired?: boolean;
  collateralIdentification?: string;
  portaisAssociados: IPortal[];
}

export interface IMarketplaceDto {
  id: string;
  name: string;
  subgroup: string | null;
}

export enum EstrategiaDesembolso {
  Grupo = "Grupo",
  Janela = "Janela",
  Unitario = "Unitário",
}

export enum ModoOperacao {
  Cedente = "Cedente",
  Sacado = "Sacado/Âncora",
}

export enum CompanyEntityType {
  PessoaJuridica = "PessoaJuridica",
  PessoaFisica = "PessoaFisica",
  Offshore = "Offshore",
}

export enum CompanyEntityTypeLibrary {
  PessoaJuridica = "CNPJ",
  PessoaFisica = "CPF",
  Offshore = "Offshore",
}

export const CompanyEntityTypeLibraryInverse: Record<
  CompanyEntityTypeLibrary,
  CompanyEntityType
> = {
  CNPJ: CompanyEntityType.PessoaJuridica,
  CPF: CompanyEntityType.PessoaFisica,
  Offshore: CompanyEntityType.Offshore,
};

export enum TipoContrato {
  PorOperacao = "PorOperacao",
  CessaoFutura = "CessaoFutura",
  SemContrato = "SemContrato",
}
export enum TipoContratoLibrary {
  PorOperacao = "Por operação",
  CessaoFutura = "Cessão futura",
  SemContrato = "Sem contrato",
}

export enum ModoAprovacao {
  AprovacaoManual = "AprovacaoManual",
  AprovacaoAutomatica = "AprovacaoAutomatica",
}

export const ModoAprovacaoLibrary: Record<ModoAprovacao, string> = {
  AprovacaoAutomatica: "Não",
  AprovacaoManual: "Sim",
};

export enum TipoOnboardingCedente {
  AberturaConta = "Abertura de conta",
  Completo = "Completo",
  Simples = "Simples",
}

export enum TipoOnboarding {
  CEDENTE_COMPLETO = "CEDENTE_COMPLETO",
  CEDENTE_SIMPLES = "CEDENTE_SIMPLES",
  CONTA_ESCROW = "CONTA_ESCROW",
  CEDENTE_OFFSHORE = "CEDENTE_OFFSHORE",
  SACADO_SIMPLES = "SACADO_SIMPLES",
  SEM_ONBOARDING = "SEM_ONBOARDING",
}

export const TipoOnboardingLibrary: Record<TipoOnboarding, string> = {
  CEDENTE_COMPLETO: "Cedente completo",
  CEDENTE_SIMPLES: "Cedente simples",
  CONTA_ESCROW: "Conta escrow",
  CEDENTE_OFFSHORE: "Offshore",
  SACADO_SIMPLES: "Sacado simples",
  SEM_ONBOARDING: "Sem Onboarding",
};

export enum ProductType {
  Cartao = "Cartao",
  CCC = "CCC",
  FIDC = "FIDC",
  FundingProprio = "FundingProprio",
  Offshore = "Offshore",
  RiscoSacado = "RiscoSacado",
  RiscoCedente = "RiscoCedente",
}

export const ProductTypeLibrary: Record<ProductType, string> = {
  Cartao: "Cartão",
  RiscoCedente: "Cessão de crédito",
  CCC: "Cessão com coobrigação",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  Offshore: "Offshore",
  RiscoSacado: "Risco Sacado",
};

export const ProductTypeSlug = (type: ProductType) =>
  ({
    [ProductType.Cartao]: "cartao",
    [ProductType.FIDC]: "fidc",
    [ProductType.Offshore]: "offshore",
    [ProductType.RiscoCedente]: "risco-cedente",
    [ProductType.RiscoSacado]: "risco-sacado",
    [ProductType.FundingProprio]: "funding-proprio",
    [ProductType.CCC]: "ccc",
  }[type]);

export enum OnboardingRelationshipStatus {
  PENDENTE_PRECADASTRO = "PENDENTE_PRECADASTRO",
  PENDENTE_CADASTRO = "PENDENTE_CADASTRO",
  PENDENTE_REQUISICAO = "PENDENTE_REQUISICAO",
  PENDENTE_ONBOARDING = "PENDENTE_ONBOARDING",
  PENDENTE_DOCUMENTACAO = "PENDENTE_DOCUMENTACAO",
  PENDENTE_ANALISE = "PENDENTE_ANALISE",
  AUTORIZADO = "AUTORIZADO",
  REPROVADO = "REPROVADO",
  CONCLUIDO = "CONCLUIDO",
}

export const OnboardingRelationshipStatusLibrary: Record<
  OnboardingRelationshipStatus,
  string
> = {
  PENDENTE_PRECADASTRO: "Pendente pré cadastro",
  PENDENTE_CADASTRO: "Pendente cadastro",
  PENDENTE_REQUISICAO: "Pendente requisição",
  PENDENTE_ONBOARDING: "Pendente onboarding",
  PENDENTE_DOCUMENTACAO: "Pendente documentação",
  PENDENTE_ANALISE: "Pendente análise",
  AUTORIZADO: "Autorizado",
  REPROVADO: "Reprovado",
  CONCLUIDO: "Autorizado",
};

export interface ICompanyConfigDto {
  customSheetParser?: CustomSheetParser;
  deal?: string;
}

export interface ICompanyBasicDataDto {
  id: number;
  taxId: string;
  officialName: string;
  companyStatus: string;
  config?: ICompanyConfigDto;
  companyType: CompanyType;
  companySacadoStatus: CompanyStatusType;
  companyCedenteStatus: CompanyStatusType;
  country: string;
  usaRebate: boolean;
  email: string;
  legalNature: "string";
  companyEntityType: CompanyEntityType;
  identification?: string;
}
export interface IEntubaSacadoCedenteDto extends IImportListTarget {
  officialName: string; // Empresa
  companyEntityType: CompanyEntityType; // Tipo de documento
  identificacao: string; // CPF/CNPJ
  identificadorExterno?: string; // Identificador
  cge?: string; // CGE
  estrategiaId?: number;
  estrategiaGUID?: string; // Estrategia
  permiteOverrideEstrategia?: boolean; // Permite sobreescrita da estrategia
  tipoContrato: string; // Modelo de termo
  contractTemplateGUID?: string; //Identificador do template do contrato
  tipoProduto?: string;
  antecipaAutomaticamente?: boolean; // Antecipa automaticamente.
  desativaFornecedor?: boolean; // Desativar fornecedor
}

export interface ICreateSacadoCedenteDto extends IImportListTarget {
  officialName: string; // Empresa
  companyEntityType: CompanyEntityType; // Tipo de documento
  identificacao: string; // CPF/CNPJ
  identificadorExterno?: string; // Identificador
  cge?: string; // CGE
  estrategiaId?: number;
  estrategiaGUID?: string; // Estrategia
  permiteOverrideEstrategia: boolean; // Permite sobreescrita da estrategia
  tipoContrato: string; // Modelo de termo
  contractTemplateId?: number; //Identificador do template do contrato
  tipoProduto?: string;
  antecipaAutomaticamente?: boolean; // Antecipa automaticamente
  desativaFornecedor?: boolean; // Desativar fornecedor
  receiptTemplateId?: number | null;
}

export interface IEntubaSacadoCedente {
  cedente: IEntubaSacadoCedenteDto;
}

export enum SheetEntubaCedente {
  empresa = "Empresa",
  companyEntityType = "Tipo de documento",
  cnpj = "CNPJ/CPF",
  identificador = "Identificador externo",
  cge = "CGE",
  estrategia = "Estrategia",
  permiteOverride = "Permite sobreescrita da estrategia",
  tipoOperacao = "Modelo de Termo",
  tipoProduto = "Tipo de produto",
  contratoId = "Identificador do template de contrato",
  antecipaAutomaticamente = "Antecipa automaticamente",
  desativaFornecedor = "Desativar Fornecedor",
}

export enum SheetEntubaSacado {
  empresa = "Empresa",
  companyEntityType = "Tipo de documento",
  cnpj = "CNPJ/CPF",
  identificador = "Identificador externo",
  cge = "CGE",
  estrategia = "Estrategia",
  permiteOverride = "Permite sobreescrita da estrategia",
  tipoProduto = "Tipo de produto",
}

export interface IUpdateSacadoSacadoDto {
  sacadoId: number;
  estrategiaId?: number;
  convenioGUID?: number;
  identificacao: string;
  identificadorExterno?: string;
  companyEntityType?: CompanyEntityType;
  tipoContrato: TipoContrato;
  contractTemplateGUID: string;
  cge?: string;
  officialName?: string;
  tipoProduto?: string;
  antecipaAutomaticamente?: boolean;
  permiteOverrideEstrategia?: boolean;
}

export interface IUpdateSacadoCedenteDto {
  cedenteId?: number;
  sacadoId?: number;
  estrategiaId?: number;
  convenioGUID?: string;
  identificacao: string;
  identificadorExterno?: string;
  companyEntityType?: CompanyEntityType;
  tipoContrato: TipoContrato;
  contractTemplateId?: number | null;
  cge?: string;
  officialName?: string;
  tipoProduto?: string;
  antecipaAutomaticamente?: boolean;
  permiteOverrideEstrategia?: boolean;
  desativaFornecedor?: boolean;
  receiptTemplateId?: number | null;
  justificative?: string;
}

export interface IPayerPayeeDto extends ICompanyBasicDataDto {
  identificadorExterno?: string;
  estrategia?: IEstrategiaComercialDto;
  company: ICompanyBasicDataDto;
  identificacao?: string;
  tipoContrato: TipoContrato;
  contractTemplateId?: number;
  contractTemplate?: {
    id: string;
    fileKey: string;
    name: string;
  };
  identification?: string;
  companyEntityType: CompanyEntityType;
  cge?: string;
  tipoProduto?: string;
  antecipaAutomaticamente: boolean;
  permiteOverrideEstrategia: boolean;
  desativaFornecedor: boolean;
  receiptTemplate?: {
    id: string;
    fileKey: string;
    name: string;
  };
  receiptTemplateId?: number;
  justificative?: string;
}

interface IFile {
  fileKey?: string;
  fileName?: string;
  fileContent: string;
  originalName: string;
}

export interface ICreateContractTemplateRequest {
  name: string;
  file: IFile;
  needsSignature?: boolean;
  signingParties?: SigningPartyParams[] | null;
  geraNovosTermos?: boolean;
}

export interface IUpdateContractTemplateRequest {
  name?: string;
  file?: IFile;
  geraNovosTermos?: boolean;
  signingParties?: SigningPartyParams[] | null;
  needsSignature?: boolean;
}

export interface IContractTemplateDto {
  id: number;
  name: string;
  needsSignature: boolean;
  company?: ICompanyBasicDataDto;
  geraNovosTermos: boolean;
  file: IFile;
  templateDeContrato: string;
  isDefault: boolean;
  signingParties: SigningPartyParams[];
  isPublic?: boolean;
}

export interface IVerifyCompanyDto {
  taxId: string;
  officialName: string;
  companyStatus: string;
}

export interface IBasicDataDto {
  officialName: string;
  companyStatus: string;
}

export interface ICedenteFromSacadoItem {
  sacadoId: number;
  cedenteId: number;
  officialName: string;
  identificadorExterno: string;
  cnpj: string;
  companyType: CompanyEntityType;
  email: string;
  onboardingStatus: OnboardingRelationshipStatus;
  taxaParticular: number;
}

export interface ICompanySummaryDto {
  companies: ICompanyBasicDto[];
}

interface ICompanyBasicDto {
  id: number;
  identification: string;
  name: string;
}

export const CNPJSize = 14;
export const CPFSize = 11;
export const DefaultTypeSize = 3;
