//Reference: https://yaireo.github.io/tagify/
import { Component, h, Host, Prop, Event, EventEmitter } from "@stencil/core";
import Tagify from "@yaireo/tagify";
import { IAspect, ISize } from "../../globals/types";

@Component({
  tag: "o-input-tag",
  styleUrl: "index.scss",
})
export class InputTag {
  /** Evento emitido ao ocorrer input. */
  @Event() input!: EventEmitter<string>;

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Propriedade `id` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() id!: string;
  /**
   * Propriedade `name` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() name!: string;
  /**
   * Valor atual do input. Funciona como um estado.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string;
  /**
   * Propriedade `placeholder` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() placeholder = "Adicione uma nova tag";
  /**
   * Propriedade `disabled` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Propriedade `readonly` do `input` nativo.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estilização de erro.
   *
   * @sbCategory Style
   */
  @Prop() error = false;
  /**
   * Lista com as opções selecionáveis.
   *
   * @sbCategory Input
   */
  @Prop() list?: string[] | string = [];
  /**
   * Limita o input a apenas opções da lista.
   *
   * @sbCategory Input
   */
  @Prop() onlyListOption = true;

  private inputElement?: HTMLInputElement;

  componentDidRender() {
    if (!this.inputElement) return;

    let params = {};

    if (!!this.list) {
      params = {
        whitelist:
          typeof this.list === "string" ? JSON.parse(this.list) : this.list,
        dropdown: {
          position: "input",
          enabled: 0, // always opens dropdown when input gets focus
        },
        enforceWhitelist: this.onlyListOption, // accept only list options
      };
    }

    new Tagify(this.inputElement, params);
  }

  componentDidLoad() {
    if (!this.inputElement) return;

    this.inputElement.addEventListener("change", (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      this.value = JSON.parse(event.target.value).map(
        (item: any) => item.value
      );

      this.input.emit(event.target.value);
    });
  }

  render() {
    const {
      dataAction,
      dataLabel,
      id,
      name,
      value,
      placeholder,
      aspect,
      size,
      error,
      disabled,
      readonly,
    } = this;

    const classes = {
      "o-input-tag": true,
      [`o-input-tag--${aspect}`]: true,
      [`o-input-tag--size-${size}`]: true,
      "error": error,
    };

    return (
      <Host class={classes}>
        <input
          ref={(el) => (this.inputElement = el)}
          type="text"
          data-action={dataAction}
          data-label={dataLabel}
          id={`o-${id}`}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          readonly={readonly}
        />
      </Host>
    );
  }
}
