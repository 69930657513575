$errorGap: 0.5rem;

o-field-error {
  .o-field-error {
    font-family: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $errorGap;
    margin-top: var(--font-xs);
    visibility: hidden;

    & .o-icon {
      color: var(--theme-danger);
    }

    &--visible {
      visibility: visible;
    }
  }
}
