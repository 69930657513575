import {
  Component,
  Event,
  EventEmitter,
  h,
  Prop,
  Element,
} from "@stencil/core";

@Component({
  tag: "o-modal-header",
  styleUrl: "index.scss",
})
export class ModalHeader {
  @Element() self!: HTMLElement;

  /** Evento emitido ao fechar o modal. */
  @Event({ bubbles: true }) modalClose!: EventEmitter<{ id: string }>;

  /**
   * Exibe um botão de fechar no canto superior direito.
   *
   * @sbCategory Input
   */
  @Prop() closeButton = false;
  /**
   * Adiciona um `o-divider` abaixo do conteúdo do _header_.
   *
   * @sbCategory Input
   */
  @Prop() divider = false;

  private handleClose = () => {
    const id = this.self.closest("o-modal")?.id;

    if (id) this.modalClose.emit({ id });
  };

  render() {
    const { divider, closeButton, handleClose } = this;

    return (
      <div class="o-modal-header">
        <div class="flex">
          <div tabindex="0" class="slot-container">
            <slot />
          </div>

          {closeButton && (
            <o-button
              dataLabel="fechar"
              dataAction="clique:botao"
              bordered={false}
              outline
              onClick={handleClose}
            >
              <o-icon category="fal" icon="fa-times" size="lg"></o-icon>
            </o-button>
          )}
        </div>

        {divider && (
          <div class="o-modal-header__divider">
            <o-divider />
          </div>
        )}
      </div>
    );
  }
}
