import { Component, Element, h, Listen, Prop } from "@stencil/core";
import { IDesiredPosition, IPosition, IType } from "../../globals/types";
import { getCssVariableValue } from "../../globals/utils";

type Positions = "top" | "left" | "bottom" | "right";

type SplitPosition = [Positions, Positions | undefined];

const arrowSize = 10;

@Component({
  tag: "o-popover",
  styleUrl: "index.scss",
})
export class Popover {
  @Element() self!: HTMLElement;

  /**
   * Cor do background.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "quaternary";
  /**
   * Posição do popover com relação ao elemento no qual está ancorado.
   *
   * @sbCategory Style
   */
  @Prop() position: IPosition = "top";
  /**
   * Largura máxima do popover. Sintaxe conforme a propriedade CSS.
   *
   * @sbCategory Style
   */
  @Prop() maxWidth = "unset";
  /**
   * Se o popover possui uma seta.
   *
   * @sbCategory Style
   */
  @Prop() arrow = true;
  /**
   * Controla a visibilidade do popover.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) visible = false;
  /**
   * Adiciona um botão de fechar.
   *
   * @sbCategory Input
   */
  @Prop() closeButton = false;
  /**
   * Posiciona o componente absolutamente no fim do `body`.
   *
   * @sbCategory Style
   */
  @Prop() floating = false;
  /**
   * Callback de referência do elemento âncora.
   *
   * @sbCategory Ref
   */
  @Prop() anchorRef?: (el: HTMLElement) => void;
  /**
   * Callback de referência do elemento flutuante.
   *
   * @sbCategory Ref
   */
  @Prop() floatingRef?: (el: HTMLElement) => void;

  /** Size in px of `--font-sm` variable */
  varFontSm = getCssVariableValue("--font-sm");
  /** Size in px of `--font-xxl` variable */
  varFontXl = getCssVariableValue("--font-xl");
  /** Reference to the `.o-popover__content` element */
  contentElement?: HTMLElement;
  /** Reference to the `.o-popover__arrow` element */
  arrowElement?: HTMLElement;

  /**
   * # Event Listeners
   *
   * The popover listens for `click` on the document and shows/hides based on
   * whether or not it was the target. It also listens for custom events
   */
  @Listen("click", { target: "document" })
  clickHandler(e: MouseEvent) {
    this.visible = [this.self, this.contentElement, this.arrowElement].some(
      (el) => !!el?.contains(e.target as Node)
    );
  }

  private calculateDesiredPosition(
    anchorElement: HTMLElement,
    floatingElement: HTMLElement
  ) {
    const desiredPosition: IDesiredPosition = {
      top: undefined,
      left: undefined,
      bottom: undefined,
      right: undefined,
      transform: undefined,
    };

    const splitPosition = this.position.split("-") as SplitPosition;

    const anchorRect = anchorElement.getBoundingClientRect();
    const floatingRect = floatingElement.getBoundingClientRect();
    let top = 0;
    let left = 0;
    let arrowTop = 0;
    let arrowLeft = 0;

    switch (splitPosition[0]) {
      case "top":
        top = top - floatingRect.height - this.varFontSm;
        left = left + anchorRect.width / 2 - floatingRect.width / 2;
        arrowTop = arrowTop - this.varFontSm + arrowSize - 1;
        arrowLeft = arrowLeft + anchorRect.width / 2;
        break;
      case "left":
        top = top + anchorRect.height / 2 - floatingRect.height / 2;
        left = left - floatingRect.width - this.varFontSm;
        arrowTop = arrowTop + anchorRect.height / 2;
        arrowLeft = arrowLeft - this.varFontSm + arrowSize - 1;
        break;
      case "bottom":
        top = top + anchorRect.height + this.varFontSm;
        left = left + anchorRect.width / 2 - floatingRect.width / 2;
        arrowTop =
          arrowTop + anchorRect.height + this.varFontSm - arrowSize + 1;
        arrowLeft = arrowLeft + anchorRect.width / 2;
        break;
      case "right":
        top = top + anchorRect.height / 2 - floatingRect.height / 2;
        left = left + anchorRect.width + this.varFontSm;
        arrowTop = arrowTop + anchorRect.height / 2;
        arrowLeft =
          arrowLeft + anchorRect.width + this.varFontSm - arrowSize + 1;
        break;
      default:
        break;
    }

    switch (splitPosition[1]) {
      case "top":
        top = top - floatingRect.height / 2 + this.varFontXl;
        break;
      case "left":
        left = left - floatingRect.width / 2 + this.varFontXl;
        break;
      case "bottom":
        top = top + floatingRect.height / 2 - this.varFontXl;
        break;
      case "right":
        left = left + floatingRect.width / 2 - this.varFontXl;
        break;
      default:
        break;
    }

    if (this.floating) {
      top = top + (anchorRect.top + window.scrollY);
      left = left + (anchorRect.left + window.scrollX);
      arrowTop = arrowTop + (anchorRect.top + window.scrollY);
      arrowLeft = arrowLeft + (anchorRect.left + window.scrollX);
    }

    desiredPosition.top = top;
    desiredPosition.left = left;

    if (this.arrowElement) {
      this.arrowElement.style.top = `${arrowTop - top}px`;
      this.arrowElement.style.left = `${arrowLeft - left}px`;
    }

    return desiredPosition;
  }

  componentDidLoad() {
    this.contentElement?.style.setProperty("max-width", this.maxWidth);
  }

  render() {
    const positions = this.position.split("-");

    const baseClasses = {
      "o-popover": true,
    };

    const contentClasses = {
      "o-popover__content": true,
      "close-button": this.closeButton,
      [`o-popover--${this.type}`]: true,
    };

    const arrowClasses = {
      "o-popover__arrow": this.arrow,
      [`${positions[0]}`]: true,
    };

    return (
      <o-floater
        visible={this.visible}
        floating={this.floating}
        calculateDesiredPosition={(
          anchorElement: HTMLElement,
          floatingElement: HTMLElement
        ) => this.calculateDesiredPosition(anchorElement, floatingElement)}
        anchorRef={this.anchorRef}
        floatingRef={this.floatingRef}
        class={baseClasses}
      >
        <div
          ref={(el) => (this.contentElement = el)}
          class={contentClasses}
          slot="floating-content"
        >
          <slot name="popover-content" />
          {this.closeButton && (
            <o-button
              dataLabel="fechar"
              dataAction="clique:botao"
              bordered={false}
              type={this.type}
              size="md"
              onClick={(e) => {
                this.visible = false;
                // prevent the other listener from activating
                e.stopPropagation();
              }}
            >
              <o-icon category="fal" icon="fa-times" size="md"></o-icon>
            </o-button>
          )}
        </div>
        <div
          ref={(el) => (this.arrowElement = el)}
          class={arrowClasses}
          slot="floating-content"
        ></div>
        <slot />
      </o-floater>
    );
  }
}
