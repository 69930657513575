import { Component, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-card-footer",
  styleUrl: "index.scss",
})
export class CardFooter {
  /**
   * Adiciona um `o-divider` acima do conteúdo do _footer_.
   *
   * @sbCategory Input
   */
  @Prop() divider = false;

  render() {
    return (
      <Host>
        {this.divider && (
          <div class="o-card-footer__divider">
            <o-divider />
          </div>
        )}

        <slot />
      </Host>
    );
  }
}
