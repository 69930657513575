import { MuiThemeProvider } from "@material-ui/core";
import axios from "axios";
import { quickFinAdminTheme } from "components/theme";
import "devextreme-intl";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import { loadMessages, locale } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import { TradeServiceProvider } from "legacy/services/trades/TradeService";
import * as React from "react";
import { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { env } from "utils";
import {
  DefaultClient,
  IntegrationClientContext,
  RestClientContext,
} from "../utils/RestClient";

export const GlobalProvider = (props: any) => {
  const integrationClient = axios.create({
    baseURL: env.INTEGRATION_URL,
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale("pt");
    // locale(navigator.language);
  }, []);

  return (
    <RecoilRoot>
      <MuiThemeProvider theme={quickFinAdminTheme}>
        <IntegrationClientContext.Provider value={integrationClient}>
          <RestClientContext.Provider value={DefaultClient}>
            <TradeServiceProvider>{props.children}</TradeServiceProvider>
          </RestClientContext.Provider>
        </IntegrationClientContext.Provider>
      </MuiThemeProvider>
    </RecoilRoot>
  );
};
