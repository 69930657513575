import React from "react";
import { ReactComponent as LogoMin } from "../../assets/images/logo-min.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";

export default function PageLogo() {
  function getLogoImage() {
    return (
      <>
        <div className="logo-min">
          <LogoMin
            aria-roledescription="logo"
            style={{ width: "auto", height: "50px", maxWidth: "100%" }}
          />
        </div>
        <div className="logo">
          <Logo
            aria-roledescription="logo"
            style={{ width: "auto", height: "50px", maxWidth: "100%" }}
          />
        </div>
      </>
    );
  }

  return (
    <div className="page-logo">
      <Link
        to="/"
        className="page-logo-link press-scale-down d-flex align-items-center position-relative"
        style={{ maxWidth: "100%", justifyContent: "center", color: "white" }}
      >
        {getLogoImage()}
      </Link>
    </div>
  );
}
