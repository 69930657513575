import IMask from "imask";

/**
 * Applies an arbitrary mask to the argument string. Mask syntax:
 * https://IMask.js.org/guide.html
 */
export const generic = (
  value: string | null | undefined,
  mask: IMask.AnyMaskedOptions | IMask.AnyMasked
) => {
  if (typeof value !== "string") return "";
  return IMask.pipe(value, mask) as string;
};
