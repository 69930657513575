import {
  Component,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
} from "@stencil/core";
import { ISize } from "../../../globals/types";

/**
 * Component that handles file upload
 *
 * - Compatible with multiple files
 * - Compatible with drag and drop
 *
 * Reference: https://css-tricks.com/drag-and-drop-file-uploading/
 */
@Component({
  tag: "o-upload-label",
  styleUrl: "index.scss",
})
export class UploadLabel {
  @Event() droppedFiles!: EventEmitter<Array<File>>;
  /** Is true when the user is dragging files on top of the component */
  @State() isHovering = false;

  /**
   * Atributo `for` do label. Referencia o id de um input.
   * [Referência](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for).
   *
   * @sbCategory Input
   */
  @Prop() htmlFor!: string;
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";
  /**
   * Estiliza o componente como desabilitado.
   *
   * @sbCategory Style
   */
  @Prop() disabled = false;
  /**
   * Controla se o input aceita arrastar e soltar arquivos.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) dragAndDrop = true;
  /**
   * Callback chamado quando arquivos são soltos no input.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleDroppedFiles?: (files: File[]) => void;

  private canDragAndDrop = () => {
    const div = document.createElement("div");

    const draggable =
      "draggable" in div || ("ondragstart" in div && "ondrop" in div);
    const hasFormData = "FormData" in window;
    const hasFileReader = "FileReader" in window;

    return draggable && hasFormData && hasFileReader;
  };

  /** Stops default behavior in all drag events */
  @Listen("drag")
  @Listen("dragstart")
  @Listen("dragend")
  @Listen("dragover")
  @Listen("dragenter")
  @Listen("dragleave")
  @Listen("drop")
  stopHandler(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  /** When the user is dragging files over the component */
  @Listen("dragover")
  @Listen("dragenter")
  isHoveringHandler() {
    if (this.dragAndDrop) this.isHovering = true;
  }

  /** When the user is no longer dragging files over the component */
  @Listen("dragleave")
  @Listen("dragend")
  @Listen("drop")
  stopHoveringHandler() {
    if (this.dragAndDrop) this.isHovering = false;
  }

  /** When the user dropped the dragged files on the component */
  @Listen("drop")
  dropFilesHandler(event: DragEvent) {
    if (this.dragAndDrop && event.dataTransfer && !this.disabled) {
    const files = [...event.dataTransfer.files];

      this.droppedFiles.emit(files);

      if (this.handleDroppedFiles) {
        this.handleDroppedFiles(files);
      }
    }
  }

  /** Tests if user's browser supports drag and drop */
  componentWillLoad() {
    if (this.dragAndDrop) {
      this.dragAndDrop = this.canDragAndDrop();
    }
  }

  render() {
    const { htmlFor, size, disabled, isHovering } = this;

    const hostClass = {
      "o-upload-label": true,
      "o-upload-label--disabled": disabled,
      [`o-upload-label--size-${size}`]: true,
      "o-upload-label--hovering": isHovering,
    };

    return (
      <Host class={hostClass}>
        <label htmlFor={`o-${htmlFor}`}>
          <slot />
        </label>
      </Host>
    );
  }
}
