import { cep } from "./cep";
import { cnpj } from "./cnpj";
import { cpf } from "./cpf";
import { cpfCnpj } from "./cpf-cnpj";
import { currency } from "./currency";
import { generic } from "./generic";
import { percentage } from "./percentage";
import { phone } from "./phone";

export const masks = {
  cep,
  cnpj,
  cpf,
  cpfCnpj,
  currency,
  generic,
  percentage,
  phone,
};

/**
 * @deprecated Usar `masks.cep()`
 * @example
 *   // import { maskCep } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskCep(value);
 *   masks.cep(value);
 */
export const maskCep = cep;
/**
 * @deprecated Usar `masks.cnpj()`
 * @example
 *   // import { maskCnpj } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskCnpj(value);
 *   masks.cnpj(value);
 */
export const maskCnpj = cnpj;
/**
 * @deprecated Usar `masks.cpf()`
 * @example
 *   // import { maskCpf } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskCpf(value);
 *   masks.cpf(value);
 */
export const maskCpf = cpf;
/**
 * @deprecated Usar `masks.cpfCnpj()`
 * @example
 *   // import { maskCpfCnpj } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskCpfCnpj(value);
 *   masks.cpfCnpj(value);
 */
export const maskCpfCnpj = cpfCnpj;
/**
 * @deprecated Usar `masks.currency()`
 * @example
 *   // import { maskCurrency } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskCurrency(value);
 *   masks.currency(value);
 */
export const maskCurrency = currency;
/**
 * @deprecated Usar `masks.generic()`
 * @example
 *   // import { maskGeneric } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskGeneric(value);
 *   masks.generic(value);
 */
export const maskGeneric = generic;
/**
 * @deprecated Usar `masks.percentage()`
 * @example
 *   // import { maskPercentage } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskPercentage(value);
 *   masks.percentage(value);
 */
export const maskPercentage = percentage;
/**
 * @deprecated Usar `masks.phone()`
 * @example
 *   // import { maskPhone } from "@maestro/utils";
 *   import { masks } from "@maestro/utils";
 *
 *   // maskPhone(value);
 *   masks.phone(value);
 */
export const maskPhone = phone;
