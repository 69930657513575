import { Component, h, Prop, Host } from "@stencil/core";

@Component({
  tag: "o-container",
  styleUrl: "index.scss",
})
export class Container {
  /**
   * Determina o _breakpoint_ do container.
   * [Referência](https://getbootstrap.com/docs/5.0/layout/containers/#how-they-work).
   *
   * @sbCategory Style
   */
  @Prop() type: "sm" | "md" | "lg" | "xl" | "xxl" | "fluid" = "fluid";

  render() {
    const classes = `container-${this.type}`;

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
