import { Component, h } from "@stencil/core";

@Component({
  tag: "o-banner-body",
  styleUrl: "index.scss",
})
export class BannerBody {
  render() {
    return (
      <o-card-body class="slot-container">
        <slot />
      </o-card-body>
    );
  }
}
