import { Component, Element, h, Listen, Prop } from "@stencil/core";
import type { OExpanderHeaderCustomEvent } from "../../components";
import type { ISize, IType } from "../../globals/types";
import { ICollapseInteractionDetail } from "../expander/types";

@Component({
  tag: "o-collapse",
})
export class Collapse {
  @Element() self!: HTMLElement;

  /**
   * Estado do componente.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) expanded = false;
  /**
   * Desabilita o component.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Cor do componente.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Tamanho do componente.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  @Listen("collapseInteraction")
  handleCollapseInteraction(
    event: OExpanderHeaderCustomEvent<ICollapseInteractionDetail>
  ) {
    this.expanded = event.detail.expanded;
  }

  componentWillRender() {
    [...this.self.children].forEach((child) => {
      if (
        !["O-COLLAPSE-HEADER", "O-COLLAPSE-CONTENT"].includes(child.tagName)
      ) {
        throw new Error(
          "The only valid children for <o-collapse> are <o-collapse-header> and <o-collapse-content>"
        );
      }

      (child as HTMLOCollapseHeaderElement).expanded = this.expanded;
      (child as HTMLOCollapseHeaderElement).disabled = this.disabled;
      (child as HTMLOCollapseHeaderElement).type = this.type;

      if (child.tagName === "O-COLLAPSE-HEADER") {
        (child as HTMLOCollapseHeaderElement).size = this.size;
      }
    });
  }

  render() {
    return <slot />;
  }
}
