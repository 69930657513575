import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Listen,
  Prop,
  State,
  Watch,
} from "@stencil/core";
import { keyboardNavigationEventHandler } from "../../../../globals/listbox-keyboard-navigation";
import type { IAspect, ISize, IType } from "../../../../globals/types";

@Component({
  tag: "o-select-multiple",
  styleUrl: "../default/index.scss",
})
export class SelectMultiple {
  /** Evento emitido ao selecionar uma opção. */
  @Event() input!: EventEmitter<Array<string>>;
  @Element() self!: HTMLElement;
  @State() isActive = false;
  @State() activeOption = null;
  @State() headerId: string = Math.random().toString(36).substring(2);

  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataAction!: string;
  /**
   * Tagueamento do Google Analytics e Datadog.
   *
   * @sbCategory Input
   */
  @Prop() dataLabel!: string;
  /**
   * Valor atual do input. Funciona como um estado. Corresponde ao `value` da
   * opção selecionada.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) value?: string[] = [];
  /**
   * Texto que aparece quando nenhuma opção está selecionada.
   *
   * @sbCategory Input
   */
  @Prop() placeholder = "Selecione:";
  /**
   * Desabilita interação com o componente.
   *
   * @sbCategory Input
   */
  @Prop() disabled = false;
  /**
   * Desabilita interação com o componente.
   *
   * @sbCategory Input
   */
  @Prop() readonly = false;
  /**
   * Estilo do componente.
   *
   * @sbCategory Style
   */
  @Prop() aspect: IAspect = "flushed";
  /**
   * Cor do background.
   *
   * @sbCategory Style
   */
  @Prop() type: IType = "light";
  /**
   * Tamanho da fonte.
   *
   * @sbCategory Style
   */
  @Prop() size: ISize = "md";

  private optionElement?: HTMLDivElement;
  private options?: NodeListOf<HTMLOOptionMultipleElement>;

  //deal with isActive state when select__header is clicked
  private handleClick = () => {
    if (this.disabled || this.readonly) return;

    this.isActive = !this.isActive;
  };

  private getPlaceholder = () => {
    const label: string[] = [];

    this.options?.forEach((opt) => {
      const textContent = opt.firstElementChild?.textContent;
      const isSelected = !!this.value?.some((val) => val === opt.value);

      if (isSelected && textContent) {
        label.push(textContent);
      }
    });

    return label.join(", ") || this.placeholder;
  };

  // click outside o-select-multiple component
  @Listen("mousedown", { target: "document" })
  clickHandler(e: MouseEvent) {
    if (!this.self.contains(e.target as Node)) {
      this.isActive = false;
    }
  }

  // listbox navigation with keyboard
  @Listen("keydown", { target: "document" })
  keyDownHandler(e: KeyboardEvent) {
    // return if this select is not focused
    if (
      !this.optionElement ||
      !(
        this.self === document.activeElement ||
        this.self.contains(document.activeElement)
      )
    ) {
      return;
    }

    keyboardNavigationEventHandler({
      event: e,
      listbox: this.optionElement,
      listboxVisible: this.isActive,
      setListboxVisibility: (newActive) => {
        this.isActive = newActive;
      },
      optionSelector: "button",
    });
  }

  // listen to optionSelected event and manipulate this.value
  @Listen("optionSelected")
  optionSelectedHandler(
    event: CustomEvent<{
      value: string;
      selected: boolean;
    }>
  ) {
    const selected = event.detail.selected;
    const value = event.detail.value;

    this.options?.forEach((opt) => {
      opt.selected = !!this.value?.some((val) => val === opt.value);
    });

    if (selected) {
      // prevent duplication
      if (!this.value?.some((val) => val === value)) {
        this.value = [...(this.value ?? []), value];
      }
    } else {
      this.value = this.value?.filter((val) => val !== value) ?? [];
    }

    this.input.emit(this.value);
  }

  @Watch("value")
  handleValueChange() {
    this.options?.forEach((opt) => {
      opt.selected = !!this.value?.some((val) => val === opt.value);
    });
  }

  /**
   * Get all option components by role Set all optioncomponents size to the same
   * as o-select-multiple Set option selected when this select has value Add
   * value when option is selected
   */
  componentDidLoad() {
    this.options = this.self.querySelectorAll('[role="option"]');

    this.options?.forEach((opt) => {
      opt.size = this.size;

      const hasInitialValue = !!this.value?.some((val) => val === opt.value);
      const selected = opt.selected;
      const value = opt.value;

      if (hasInitialValue) {
        opt.selected = true;
        return;
      }

      if (selected) {
        const initial = this.value?.filter((val) => val !== "") ?? [];
        this.value = [...initial, value];
      }
    });

    this.input.emit(this.value);
  }

  render() {
    const {
      dataAction,
      dataLabel,
      type,
      value,
      aspect,
      disabled,
      readonly,
      isActive,
      size,
      headerId,
      handleClick,
    } = this;

    const placeholder = this.getPlaceholder();

    const classes = {
      "o-select": true,
      [`o-select--type-${type}`]: true,
    };

    const classesHeader = {
      "o-select__header": true,
      [`o-select__header--aspect-${aspect}`]: true,
      [`o-select__header--size-${size}`]: true,
      "o-select__header--disabled": disabled || readonly,
    };

    return (
      <Host class={classes}>
        <button
          type="button"
          class={classesHeader}
          disabled={disabled || readonly}
          data-action={dataAction}
          data-label={dataLabel}
          data-active={isActive}
          role="combobox"
          aria-label={placeholder}
          aria-owns={headerId}
          aria-autocomplete="list"
          aria-expanded={`${isActive}`}
          aria-activedescendant={`ooption_${headerId}_${value}`}
          onClick={handleClick}
        >
          <span>{placeholder}</span>
          <o-icon
            category="fal"
            icon="fa-angle-down"
            size={size}
            aria-hidden="true"
          />
        </button>
        <div
          id={headerId}
          class="o-select__option"
          data-active={isActive}
          role="listbox"
          ref={(el) => (this.optionElement = el)}
        >
          <slot />
        </div>
      </Host>
    );
  }
}
