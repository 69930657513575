import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

export const StyledButtonLink = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: -webkit-link;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
  &:disabled {
    color: #bdbdbd;
    text-decoration: none;
  }
`;

interface IButtonLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const ButtonLink: React.FC<IButtonLinkProps> = (props) => {
  return <StyledButtonLink {...props}>{props.children}</StyledButtonLink>;
};
