import {
  ODropdown,
  OIcon,
  OInputSearch,
  OLoader,
  OTypography,
} from "@maestro/react";
import { maskCpfCnpj } from "@maestro/utils";
import debounce from "lodash/debounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { fillRouteParams, routes } from "routes/admin/routes";
import { endpoints } from "utils/endpoints";
import {
  AnchorItem,
  EntitySearchContainer,
  StyledDropdownItem,
  StyledDropdownList,
} from "./entity-search.styles";
import { HighlightText } from "./_compose/highlight-text";

interface SearchEngineEntityResponse {
  data: SearchEngineEntityData[];
}

interface SearchEngineEntityData {
  identification: string;
  officialName: string | null;
  type: "user" | "costumer";
}

const item: Record<
  SearchEngineEntityData["type"],
  {
    icon: React.ReactNode;
    description: string;
    route: (identification: string) => string;
  }
> = {
  costumer: {
    icon: <OIcon category="fa" icon="fa-building" />,
    description: "cliente",
    route: (identification: string) =>
      fillRouteParams(routes.empresasClientesByIdentification.path, {
        identification,
      }),
  },
  user: {
    icon: <OIcon category="fa" icon="fa-user" />,
    description: "usuário",
    route: (identification: string) =>
      fillRouteParams(routes.usuariosDetalhesByIdentification.path, {
        identification,
      }),
  },
};

export function EntitySearch() {
  const [value, setValue] = useState<string>("");
  const [searchValues, setSearchValues] = useState<SearchEngineEntityData[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const inputSearchRef = useRef<HTMLOInputSearchElement>(null);

  const history = useHistory();

  const handleInput = ({
    currentTarget,
  }: React.FormEvent<HTMLOInputSearchElement>) => {
    if (currentTarget.value) {
      setValue(currentTarget.value);
    }
  };

  const onSelect = (selectedItem: SearchEngineEntityData) => {
    history.push(item[selectedItem.type].route(selectedItem.identification));
  };

  const search = useCallback(async (searchValue: string) => {
    try {
      setLoading(true);
      const { data } = await window.session.client.post<
        SearchEngineEntityResponse
      >(endpoints.clerk.searchEngineEntity, {
        q: searchValue,
      });

      setSearchValues(data.data);
    } catch {
      toastr.error("Erro ao buscar clientes.");
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(search, 500);
  }, [search]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  useEffect(() => {
    if (value?.trim().length) {
      debouncedSearch(value.trim());
    }
  }, [debouncedSearch, value]);

  return (
    <EntitySearchContainer>
      <ODropdown className="w-100">
        <OInputSearch
          ref={inputSearchRef}
          dataAction="search:buscar-entidade"
          dataLabel="Buscar clientes"
          placeholder="Buscar clientes"
          id="entity-search"
          name="entity-search"
          autocomplete="off"
          value={value}
          onInput={handleInput}
          handleClear={() => {
            setValue("");
            setSearchValues([]);
            inputSearchRef.current?.querySelector("input")?.focus();
          }}
        />
        <StyledDropdownList className="w-100">
          {loading && <OLoader absolute backdrop />}

          {searchValues.map((searchValue) => (
            <StyledDropdownItem
              key={`dropdownitem_${searchValue.identification}_${searchValue.type}}`}
              onKeyDown={(e) => e.key === "Enter" && onSelect(searchValue)}
            >
              <AnchorItem
                className="d-flex align-items-center gap-2"
                href={item[searchValue.type].route(searchValue.identification)}
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(searchValue);
                }}
              >
                <div>{item[searchValue.type].icon}</div>
                <div className="text-left w-100">
                  {searchValue.officialName && (
                    <OTypography tag="h3">
                      <HighlightText
                        text={searchValue.officialName.toUpperCase()}
                        highlightValue={value.split(" ").join("|")}
                      />
                    </OTypography>
                  )}
                  <div className="d-flex justify-content-between">
                    <OTypography tag="span" size="sm">
                      <HighlightText
                        text={maskCpfCnpj(searchValue.identification)}
                        highlightValue={value
                          .replace(/\D/g, "")
                          .split("")
                          .join("(.|-)?")}
                      />
                    </OTypography>

                    <OTypography tag="span" size="sm" type="dark">
                      {item[searchValue.type].description}
                    </OTypography>
                  </div>
                </div>
              </AnchorItem>
            </StyledDropdownItem>
          ))}
        </StyledDropdownList>
      </ODropdown>
    </EntitySearchContainer>
  );
}
