o-typography {
  display: block;

  .o-typography {
    font-family: inherit;
    margin: 0;
    padding: 0;
    max-width: 100%;
    align-items: center;

    @each $type in $typesComplete {
      &--type-#{$type} {
        color: var(--theme-#{$type});
      }
    }

    @each $size in $fontSizes {
      &--font-#{$size} {
        font-size: var(--font-#{$size});
        min-height: var(--font-#{$size});
      }
    }

    @each $weight in $weights {
      &--weight-#{$weight} {
        font-weight: #{$weight};
      }
    }
  }

  &.isSpan {
    display: inline;
  }
}
