import { Component, Element, h, Host, Prop } from "@stencil/core";

@Component({
  tag: "o-ordered-list",
  styleUrl: "index.scss",
})
export class OrderedList {
  @Element() self!: HTMLElement;

  /**
   * Valor inicial da lista.
   *
   * @sbCategory Input
   */
  @Prop() startValue: number | null = null;
  /**
   * Estilo dos marcadores da lista.
   *
   * @sbCategory Style
   */
  @Prop() defaultType:
    | "lower-alpha"
    | "upper-alpha"
    | "lower-roman"
    | "upper-roman"
    | "decimal" = "decimal";

  componentWillRender() {
    this.self.querySelectorAll("o-list-item").forEach((el, key) => {
      if (key === 0 && !!this.startValue) {
        el["value"] = this.startValue;
      }

      el["defaultType"] = this.defaultType;
    });
  }

  render() {
    return (
      <Host class="o-ordered-list">
        <ol class="slot-container">
          <slot />
        </ol>
      </Host>
    );
  }
}
