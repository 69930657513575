import classNames from "classnames";
import moment from "moment";
import Picker from "rc-picker";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import ptBR from "rc-picker/lib/locale/pt_BR";
import { getDefaultFormat } from "rc-picker/lib/utils/uiUtil";
import React, { useEffect, useState } from "react";
import { dropdownPosition } from "../../globals/types";
import { OIcon } from "../../stencil-generated";
import { GlobalStyle } from "./styles";
import { IOInputDate } from "./types";
import { formatPattern, getPlaceholder } from "./utils";

export const OInputDate = ({
  aspect = "flushed",
  error = false,
  disabled = false,
  allowClear = true,
  picker = "date",
  placeholder,
  position = "bottomLeft",
  size = "sm",
  value: defaultValue,
  onChange,
  onBlur,
  onFocus,
  disabledDate,
  className = "",
  ...rest
}: IOInputDate) => {
  const [momentValue, setMomentValue] = useState<moment.Moment>();
  const [value, setValue] = useState<string>();

  const format =
    formatPattern(picker) ||
    (getDefaultFormat(undefined, picker, undefined, undefined) as string);

  useEffect(() => {
    if (typeof defaultValue === "string") {
      setValue(defaultValue);
    } else {
      setValue(defaultValue?.format(format));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!value) {
      setMomentValue(undefined);
      return;
    }

    setMomentValue(moment(value, format));
  }, [value]);

  return (
    <>
      <GlobalStyle />
      <Picker
        {...rest}
        picker={picker}
        generateConfig={momentGenerateConfig}
        locale={ptBR}
        placeholder={getPlaceholder(picker, placeholder)}
        disabled={disabled}
        allowClear={allowClear}
        showToday
        dropdownAlign={dropdownPosition[position]}
        format={format}
        value={momentValue}
        suffixIcon={<OIcon category="fal" icon="fa-calendar" size={size} />}
        clearIcon={<OIcon category="fal" icon="fa-times" size={size} />}
        prevIcon={<OIcon category="fal" icon="fa-angle-left" />}
        nextIcon={<OIcon category="fal" icon="fa-angle-right" />}
        superPrevIcon={<OIcon category="fal" icon="fa-angle-double-left" />}
        superNextIcon={<OIcon category="fal" icon="fa-angle-double-right" />}
        prefixCls="o-input-date"
        className={classNames(
          {
            error: error,
            [`o-input-date-input--${aspect}`]: true,
            [`o-input-date-input--size-${size}`]: true,
          },
          className
        )}
        dropdownClassName={`o-input-date-dropdown--size-${size}`}
        onChange={(valueArg, dateString) => {
          setValue(dateString);
          onChange?.(valueArg, dateString);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        disabledDate={disabledDate}
      />
    </>
  );
};

export default OInputDate;
