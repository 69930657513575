import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RestClientContext } from "utils/RestClient";
export interface IUseGet<TResponse, TInitialData> {
  data: TResponse | TInitialData | undefined;
  loading: boolean;
  error: boolean;
  fetch: any;
  fetched: boolean;
  refetch: any;
  setUrl: any;
}
export interface IDefaultConfigProps {
  autoFetchOnInit?: boolean;
  service?: any;
  formatter?: any;
}

const defaultConfig = {
  autoFetchOnInit: true,
  service: null,
  formatter: null,
};

export function useGet<TResponse, TInitialData>(
  path: string,
  config?: IDefaultConfigProps,
): IUseGet<TResponse, TInitialData> {
  const clientv2 = useContext(RestClientContext);
  const configOptions = useRef(
    config ? Object.assign({}, defaultConfig, config) : defaultConfig,
  );
  const innerClient = useRef<any>(
    !!config?.service ? config.service : clientv2,
  );
  const fetched = useRef(false);
  const [url, setUrl] = useState(path);
  const [data, setData] = useState<TResponse | TInitialData | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    setLoading(true);

    fetched.current = true;

    try {
      const r = await innerClient.current({
        method: "GET",
        url: url,
      });

      const data = !!configOptions.current.formatter
        ? configOptions.current.formatter(r.data)
        : r.data;

      setData(data as TResponse | undefined);

      setLoading(false);

      return data as TResponse;
    } catch (e) {
      setLoading(false);
      setError(true);

      console.warn("Error on fetch serviceRequest: ", url, e);

      throw e;
    }
  }, [url]);

  async function refetch() {
    fetched.current = false;
    fetch();
  }

  useEffect(() => {
    if (!!url && !configOptions.current.autoFetchOnInit) {
      fetched.current = false;
      fetch();
    }
  }, [url, fetch]);

  useEffect(() => {
    const autoFetch =
      configOptions.current && configOptions.current.autoFetchOnInit;

    if (autoFetch && !fetched.current && !!url) {
      fetch();
    }
  }, [configOptions, fetch, url]);

  return {
    data,
    loading,
    error,
    fetch,
    fetched: fetched.current,
    refetch,
    setUrl,
  };
}
