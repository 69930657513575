import React from "react";
import { StyledTypography } from "./typography.styles";

export type TextType = "display1" | "display2" | "title" | "heading";

export type ColorType =
  | "darkGray"
  | "mainGray"
  | "mainOrange"
  | "white"
  | "success"
  | "warning";

export interface ITypographyProps {
  fontFamily?: string;
  display?: TextType;
  bold?: boolean;
  color?: ColorType;
  variant?: any;
  children: any;
  inline?: boolean;
}

export function Typography(props: ITypographyProps) {
  return (
    // @ts-ignore
    <StyledTypography as={props.variant} {...props}>
      {props.children}
    </StyledTypography>
  );
}
