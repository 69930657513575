export type stakeholderType = {
  cnpj: string;
  name: string;
  companyType: string;
};

export type receivableType = {
  id: number;
  identification: string;
  price: number;
  maturityAmount: number;
  maturityDate: Date;
  receivableStatus: string;
  sacado: stakeholderType;
  cedente: stakeholderType;
};

export enum ReceivableSystemState {
  Inicial = "Inicial",
  PendenteAgendamento = "PendenteAgendamento",
  Agendado = "Agendado",
  PendenteAprovacaoSacado = "PendenteAprovacaoSacado",
  AprovadoSacado = "AprovadoSacado",
  AprovadoCedente = "AprovadoCedente",
  PendenteAprovacaoBanco = "PendenteAprovacaoBanco",
  AprovadoBanco = "AprovadoBanco",
  DisponivelParaCedente = "DisponivelParaCedente",
  IndisponivelParaCedente = "IndisponivelParaCedente",
  PendenteAprovacaoCedente = "PendenteAprovacaoCedente",
  PendenteOnboarding = "PendenteOnboarding",
  AprovadoOnboarding = "AprovadoOnboarding",
  PendenteSolicitacaoTrade = "PendenteSolicitacaoTrade",
  TradeSolicitado = "TradeSolicitado",
  TradeDesembolsado = "TradeDesembolsado",
  TradeLiquidado = "TradeLiquidado",
  TradeEmAtraso = "TradeEmAtraso",
  Recusado = "Recusado",
  Removido = "Removido",
  Pago = "Pago",
  EmCancelamento = "EmCancelamento",
  EmCancelamentoPagfor = "EmCancelamentoPagfor",
  PagforCancelado = "PagforCancelado",
  PendenteAprovacaoSacadoPosSolicitacaoCedente = "PendenteAprovacaoSacadoPosSolicitacaoCedente",
  FundingProprioRecusado = "FundingProprioRecusado",
  AguardandoContrato = "AguardandoContrato",
  PendenteValidacaoTaxas = "PendenteValidacaoTaxas",
}

export enum ReceivableStatus {
  Aberto = "Aberto",
  EmAgendamento = "EmAgendamento",
  Agendado = "Agendado",
  EmAprovacao = "EmAprovacao",
  Aprovado = "Aprovado",
  EmAntecipacao = "EmAntecipacao",
  Antecipada = "Antecipada",
  Liquidada = "Liquidada",
  Cancelada = "Cancelada",
  EmAtraso = "EmAtraso",
  Removido = "Removido",
  Indisponivel = "Indisponivel",
  Pago = "Pago",
}

export const SacadoReceivableStatusLibrary: {
  [key in ReceivableStatus]: string;
} = {
  Aberto: "Aberto",
  EmAgendamento: "Em Agendamento",
  Agendado: "Agendado",
  Aprovado: "Aprovado",
  EmAprovacao: "Em Aprovação",
  EmAntecipacao: "Em Antecipação",
  Antecipada: "Antecipada",
  Liquidada: "Liquidada",
  Cancelada: "Cancelada",
  EmAtraso: "Em Atraso",
  Removido: "Removido",
  Indisponivel: "Indisponivel",
  Pago: "Pago",
};

export const CedenteReceivableStatusLibrary: {
  [key in ReceivableStatus]: string;
} = {
  Aberto: "Aberto",
  EmAgendamento: "Em Agendamento",
  Agendado: "Agendado",
  EmAprovacao: "Disponível",
  Aprovado: "Disponível",
  EmAntecipacao: "Pendente de Pagamento",
  Antecipada: "Paga",
  Liquidada: "Paga",
  Cancelada: "Cancelada",
  EmAtraso: "Paga",
  Removido: "Removido",
  Indisponivel: "Indisponivel",
  Pago: "Pago",
};

export enum EReceivableType {
  Nota,
  Cartao,
  FIDC,
  Offshore,
}
