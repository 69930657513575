import {
  Component,
  Element,
  Event,
  EventEmitter,
  h,
  Host,
  Prop,
  Watch,
} from "@stencil/core";

@Component({
  tag: "o-step-header",
  styleUrl: "index.scss",
})
export class StepHeader {
  @Element() self!: HTMLElement;

  /** Evento emitido quando uma step é selecionada. */
  @Event() selected!: EventEmitter<number>;

  /**
   * Índice da aba ativa.
   *
   * @sbCategory Input
   */
  @Prop({ mutable: true }) activeStep = 0;
  /**
   * Desabilita a mudança de _step_ por click.
   *
   * @sbCategory Input
   */
  @Prop() disableNavigation = false;
  /**
   * Callback chamado quando uma step é selecionada.
   *
   * @sbCategory Callback
   * @sbControl false
   */
  @Prop() handleSelected?: (index: number) => void;

  @Watch("activeStep")
  handleChangeactiveStep(newValue: number) {
    const steps = this.self.querySelectorAll("o-step");

    steps.forEach((el, index) => {
      if (index < newValue) {
        el.status = "completed";
      } else if (index === newValue) {
        el.status = "current";
      } else {
        el.status = "pending";
      }
    });
  }

  // listen children click events and update activeStep property
  componentDidLoad() {
    const steps = this.self.querySelectorAll("o-step");

    this.handleChangeactiveStep(this.activeStep);

    if (!this.disableNavigation) {
      steps.forEach((el, index) => {
        el.addEventListener("click", () => {
          this.activeStep = index;

          this.selected.emit(index);
          if (this.handleSelected) this.handleSelected(index);
        });
      });
    }
  }

  render() {
    const { disableNavigation } = this;

    const classes = {
      "o-step-header": true,
      "o-step-header--disable-navigation": disableNavigation,
    };

    return (
      <Host class={classes}>
        <slot />
      </Host>
    );
  }
}
